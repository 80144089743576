import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export const ReadMore = ({ linkText, titleText, children }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      <Button 
        color="link"
        onClick={toggle}
        className="readMore-link">
        {linkText}
      </Button>
      <Modal className="readMoreModal" isOpen={modal} toggle={toggle}>
        <ModalHeader className="readMoreModal-header" toggle={toggle}>{titleText}</ModalHeader>
        <ModalBody className="readMoreModal-body">
          {children}
        </ModalBody>
        <ModalFooter className="readMoreModal-footer">
          <Button color="primary" className="readMore-close pill-btn" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
