import { apiClient } from 'utilities';
import authHeader from './auth-header';

const register = (username, password, firstName, lastName, accountId) => {
  return apiClient.post(`Auth/SignUp`, {
    username,
    firstName,
    lastName,
    password,
    accountId
  }, authHeader());
};

const loginSuccess = (signInResult) => {
  const response = signInResult.data;
  const user = response.data;
  const accessToken = user?.sessionTokens?.idToken;

  if(accessToken){
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("clinicId", JSON.stringify(response.clinicId));
  }

  return response;
};

const submitResetPassword = (username, password, sessionId) => {
  const signInDto = {
    Email: username,
    Username: username,
    Password: password,
    SessionId: sessionId
  };

  return apiClient
      .post("Auth/ResetPassword/", signInDto)
      .then(loginSuccess);
}

  const confirmUser = (username, password, confirmationCode) => {
    const signInDto = {
      Username: username,
      Password: password,
      ConfirmationCode: confirmationCode
    };

    return apiClient
      .post("Auth/ConfirmUser/", signInDto)
      .then(loginSuccess);
  }

  const login = (username, password) => {

    const signInDto = {
        Username: username,
        Password: password,
        Email: username,
    };

    return apiClient
      .post(`Auth/SignIn/`, signInDto)
      .then(loginSuccess);
  };

  const logout = () => {
    const user = getCurrentUser();
    
    const signInDto = {
      Username: user?.username,
    };

    return apiClient.post(`Auth/SignOut/`, signInDto, authHeader())
      .catch(err => console.error({err}))
      .finally(() => {
        localStorage.removeItem("user");
        localStorage.removeItem("clinicId");
      });
  };

  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };

  const getCurrentUserClinicId = () => {
    return JSON.parse(localStorage.getItem("clinicId"));
  };

  const validatePermission = (permission) => {
    const user = getCurrentUser();
    const validatePermissionDto = {
      username: user?.username,
      permissionToCheck: permission
    };

    return apiClient.post(`User/ValidatePermissionAsync`, validatePermissionDto)
      .then((response) => {
        return response.data;
      });
  };

  const getUserPermissions = () => {
    const user = getCurrentUser();
    return apiClient.get(`User/Permissions/${user?.username}`)
  }

  const getClinicType = () => {
    const clinicId = getCurrentUserClinicId();
    return apiClient.get(`User/ClinicType/${clinicId}`)
  }

  const generateNewAccountId = () => {
    return apiClient.get("/User/GenerateNavigatorId").then(response => JSON.stringify(response.data));
}

  export const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
    confirmUser,
    submitResetPassword,
    getCurrentUserClinicId,
    getUserPermissions,
    validatePermission,
    getClinicType,
    generateNewAccountId
  };

  export default AuthService;