import React, { useState } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const menuSelected= (location, menu)=>
{

  var menus = document.getElementById("sidebar")?.getElementsByTagName("li");
  
  for (let i = 0; i <= menus?.length - 1; i++) {
    menus[i].getElementsByTagName("a")[0].classList.remove("selected");
    menus[i].getElementsByTagName("a")[0].classList.add("unselected");
  }
    
  const pathname = location.pathname;
  const hash = location.pathname.indexOf('#') > -1 ? location.pathname.split('#')[1] : location.hash.replace('#','');

  var selected = "unselected";
  if(pathname.indexOf(menu.target)>0)
    selected = "selected";
  else if(hash === menu.title.replace(/\s/g, ''))
    selected = "selected";
  
  return selected;
}

const SubMenu = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => setCollapsed(!collapsed);
  const { title, target, items, state } = props;
  const location = useLocation();

  return (
    <>
      <NavItem
        onClick={toggle}
        className={classNames({ "menu-open": !collapsed })}
      >
        <NavLink 
          id={`menu-${title.replace(/\s/g, '')}`}
          className={`${menuSelected(location,props)} dropdown-toggle text-wrap`} 
          to={target === '#' ? target + title.replace(/\s/g, ''): target} 
          tag={Link} exact={"true"}>
          {title}
        </NavLink>
      </NavItem>
      <Collapse
        isOpen={!collapsed}
        navbar
        className={classNames("items-menu", { "mb-1": !collapsed })}
      >
        {items.map((item, index) => (
          <NavItem key={index}>
            <NavLink 
              tag={Link} exact={"true"}
              to={{
                pathname: item.target === '#' ? item.target + item.title.replace(/\s/g, ''): item.target,
                state : state
              }}
              id = {`menu-${item.title.replace(/\s/g, '')}`}
              className={`${menuSelected(location,item)} ms-3`}>
              {item.title}
            </NavLink>
          </NavItem>
        ))}
      </Collapse>
    </>
  );
};

export default SubMenu;
