export const financialMeasuresJSON = {
  showProgressBar: "top",
  showCompletedPage: false,
  showQuestionNumbers: "off",
  showTOC: true,
  focusFirstQuestionAutomatic: false,
  title: "Financial Measures",
  checkErrorsMode: "onValueChanged",
  pages: [
    {
      name: "Medical",
      navigationTitle: "Medical",
      navigationDescription: "Medical",
      titleLocation: "hidden",
      elements: [
        {
          type: "panel",
          name: "didCollectMedicalFinancialMeasures",
          elements: [
            {
              type: "boolean",
              name: "didCollectMedicalFinancialMeasuresBool",
              title:
                "Did the clinic collect financial data for medical services?",
              // TODO description: "Medical measures include [insert list/description of medical data]",
              defaultValue: "false",
              isRequired: true,
            },
          ],
        },
      ],
    },
    {
      name: "MedicalMeasures",
      title: "    ● Medical Measures",
      navigationDescription: "MedicalMeasures",
      visibleIf: "{didCollectMedicalFinancialMeasuresBool} == true",
      elements: [
        {
          type: "panel",
          name: "totalMedicalCost",
          questionTitleLocation: "left",
          elements: [
            {
              type: "text",
              name: "totalMedicalCost",
              title: "Total Medical Cost",
              inputMask: "currency",
              prefix: "$",
              numericDigits: 2,
              min: 0,
              width: "400px",
              popupdescription: `Total medical personnel (including salary, benefits, and contracted staff) plus other direct costs for medical care (including, but not limited to, supplies, equipment depreciation, related travel, continuing medical education (CME) registration and travel, uniform laundering, recruitment, membership in professional societies, books, journal subscriptions, EHR system (as allocated by service line, etc.) and after allocation of overhead. Excludes: Lab and x-ray costs and donations.`
            },
          ]
        },
        {
          type: "panel",
          name: "perPatientMedicalCostSection",
          questionTitleLocation: "left",
          elements: [
            {
              type: "expression",
              name: "medicalPatientCount",
              title: "Total Medical Patients",
              startWithNewLine: false,
              expression: "getSavedDataEntries({clinicId}, 'StaffingAndUtilization','medicalPhysiciansPatients','medicalAdvPatients','medicalOMPatients')"
            },
            {
              type: "expression",
              name: "perMedicalPatientCost",
              title: "Cost Per Medical Patient",
              startWithNewLine: false,
              expression: "formatAsUSDollar({totalMedicalCost}/{medicalPatientCount})",
            },
          ]
        },
        {
          type: "panel",
          name: "perVisitMedicalCostSection",
          questionTitleLocation: "left",
          elements: [
            {
              type: "expression",
              name: "medicalVisitCount",
              title: "Total Medical Visits",
              questionTitleLocation: "left",
              startWithNewLine: false,
              expression: "getSavedDataEntries({clinicId}, 'StaffingAndUtilization','totalMedicalServicesTotalVisits','totalMedicalServicesTotalVirtualVisits')",
            },
            {
              type: "expression",
              name: "perMedicalVisitCost",
              title: "Cost Per Medical Visit",
              questionTitleLocation: "left",
              startWithNewLine: false,
              expression: "formatAsUSDollar({totalMedicalCost}/{medicalVisitCount})",
            },
          ]
        },
      ],
    },
    {
      name: "Dental",
      navigationTitle: "Dental",
      navigationDescription: "Dental",
      titleLocation: "hidden",
      elements: [
        {
          type: "panel",
          name: "didCollectDentalFinancialMeasures",
          elements: [
            {
              type: "boolean",
              name: "didCollectDentalFinancialMeasuresBool",
              title: "Did the clinic collect financial data for dental services?",
              // TODO description: "Medical measures include [insert list/description of medical data]",
              defaultValue: "false",
              isRequired: true,
            },
          ],
        },
      ],
    },
    {
      name: "DentalMeasures",
      title: "    ● Dental Measures",
      navigationDescription: "DentalMeasures",
      visibleIf: "{didCollectDentalFinancialMeasuresBool} == true",
      questionTitleLocation: "left",
      elements: [
        {
          type: "panel",
          name: "totalDentalCost",
          elements: [
            {
              type: "text",
              name: "totalDentalCost",
              title: "Total Dental Cost",
              inputMask: "currency",
              prefix: "$",
              numericDigits: 2,
              min: 0,
              width: "400px",
              popupdescription: `Total dental personnel (including salary, benefits, and contracted staff) and other costs (including, but not limited to, training, office supplies, equipment depreciation, related travel, HIT/EHR (as allocated by service line, lab services, and x-ray) after allocations of overhead.`
            },
          ]
        },
        {
          type: "panel",
          name: "perPatientDentalCostSection",
          elements: [
            {
              type: "expression",
              name: "dentalPatientCount",
              title: "Total Dental Patients",
              startWithNewLine: false,
              expression: "getSavedDataEntries({clinicId}, 'StaffingAndUtilization','dentalServicesPatients')",
            },
            {
              type: "expression",
              name: "perDentalPatientCost",
              title: "Cost Per Dental Patient",
              startWithNewLine: false,
              expression: "formatAsUSDollar({totalDentalCost}/{dentalPatientCount})",
            },
          ]
        },
        {
          type: "panel",
          name: "perVisitDentalCostSection",
          questionTitleLocation: "left",
          elements: [
            {
              type: "expression",
              name: "dentalVisitCount",
              title: "Total Dental Visits",
              startWithNewLine: false,
              expression: "getSavedDataEntries({clinicId}, 'StaffingAndUtilization', 'totalDentalVisits','totalDentalVisitsV')",
            },
            {
              type: "expression",
              name: "perDentalVisitCost",
              title: "Cost Per Dental Visit",
              startWithNewLine: false,
              expression: "formatAsUSDollar({totalDentalCost}/{dentalVisitCount})",
            },
          ]
        },
      ],
    },
  ],
};
