import React, { useState, useEffect } from 'react';
import { Label, Input, Row, Col , Form, FormGroup, Button, FormFeedback} from 'reactstrap';
import './Clinic.scss';
import { apiClient, ApiRoutes } from '../../../utilities';
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { FormStepperComponent } from 'components/FormStepperComponent';
import { useLocation, useHistory  } from 'react-router-dom';
import { useStates } from 'react-us-states';
import { trackPromise } from 'react-promise-tracker';

// Accepts the array and key
const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
      }, {}); // empty object is the initial value for result object
};

const config = {
  headers:{
    'Content-Type': 'application/json',
  }
};

export const ClinicAdd = () => {

  const location = useLocation();
  const history = useHistory();
  const updateClinic = location.state?.clinicUpdate;

  const handleCancel= () => 
  { 
    if (window.confirm('You may have unsaved changes on this page. Do you want to leave this page and discard your changes?'))
      history.push('/AccountManagement/Clinic');
  };

  const [activeStep, setActiveStep] = useState(0);

  const methods = useForm();

  const onSubmit = async (data) => {

    if(data.designations)
    {
      data.designations = [...data?.designations?.filter( e => e.selected )]
      data.designations.forEach((e)=>{
        if(e.designationDate === '')
          e.designationDate=undefined;
        if(e.designationDate)
          e.designationDate= e.designationDate +'T00:00:00.000Z';

      })
    }

    if(data.membershipParticipations)
      data.membershipParticipations = [...data.membershipParticipations.filter(e => e.selected).map(m => m.membership)]

    if(data.specialServices)
      data.specialServices = [...data.specialServices.filter(e => e.selected).map(m => m.specialService)]

    const url = ApiRoutes.AccountManagement.Clinics;

    await trackPromise(apiClient
      .put(url, data, {
        // @ts-ignore
        toastConfig:{
          showSuccess: true,
          successMessage: `Clinic ${updateClinic !== null ? "updated" : "created"} successfully!`
       }}
      )
      .then(() => history.push('/AccountManagement/Clinic'))
      .catch((error) => console.log( error )));
  };

  const handleNextStep = (nextStep) => {
    if(nextStep + 1 < 3)
      setActiveStep(nextStep + 1);
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <p className='primary title'>{updateClinic !== null ? "Update" : "Add" } Clinic</p>
        </Col>
      </Row>

      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)} className={"mb-5"}>
          <FormStepperComponent activeStep={activeStep} setActiveStep={setActiveStep}>
            <ClinicType stepNumber={0} label={"Clinic Type"} clinicUpdate={updateClinic} handleContinue={handleNextStep} handleCancel={handleCancel} />
            <ClinicDetails stepNumber={1} label={"Clinic Details"} clinicUpdate={updateClinic} handleContinue={handleNextStep} handleCancel={handleCancel} />
            <ClinicContacts stepNumber={2} label={"Clinic Contacts"} clinicUpdate={updateClinic} handleContinue={handleNextStep} handleCancel={handleCancel} />
          </FormStepperComponent>
        </Form>
      </FormProvider>
    </>
  );
}


const ClinicType = ({ stepNumber = 0, label = "", completed = null, clinicUpdate = null, handleCancel = () => {}, handleContinue = (step) => {} }) => {

  const { register, setValue, watch} = useFormContext();
  const {formState: { errors }}  = useForm();

  const { ref: nameRef, ...nameRegisterProps } = register("name", { required: true });
  const { ref: membershipTypeRef, ...membershipTypeRegisterProps } = register("membershipType", { required: false });

  const [permissions, setPermissions] = useState({});
  const [dentalOnlyChecked, setDentalOnlyChecked] = useState(false);
  const [edited, setEdited] = useState(false);
  const [userInfo, setUserInfo] = useState({ designations: [] });

  useEffect(() => {
    if(clinicUpdate){
      setValue("id", clinicUpdate.id);
      setValue("name", clinicUpdate.name);
      setValue("membershipType", clinicUpdate.membershipType);
      
      if (clinicUpdate["designations"]) {
        clinicUpdate["designations"].forEach((option) => {
          const date = option["designationDate"]
            ? option["designationDate"].split("T")[0]
            : null;

          setValue(`designations.${option.designation.key}.selected`, true);
          setValue(
            `designations.${option.designation.key}.hrsaGrantId`,
            option["hrsaGrantId"]
          );
          setValue(
            `designations.${option.designation.key}.designationDate`,
            date
          );
          setValue(
            `designations.${option.designation.key}.previouslyKnownAs`,
            option["previouslyKnownAs"]
          );

          if (option.designation.key === 1) {
            setDentalOnlyChecked(true);
          }
        });

        setUserInfo({
          designations: clinicUpdate["designations"]
            ? clinicUpdate["designations"].map((e) => e.designation.name)
            : [],
        });
      }

    }
  }, [clinicUpdate, setValue])

  useEffect(() => {
    const url = `${ApiRoutes.AccountManagement.Clinics}/options`;
    trackPromise(apiClient.get(url).then((response) => {
      setPermissions(groupBy(response.data,'groupName'));
    }).catch( resp=> {
      setPermissions({});
    }));
  }, []);

  const handleShareDataWithKdhe = (val) => {
    setValue("shareDataWithKdhe", val);
  }

  useEffect(() => {
    if(dentalOnlyChecked)
      {
        setValue(`designations.2.selected`, false);
        setValue(`designations.3.selected`, false);
        setValue(`designations.4.selected`, false);
        setValue(`designations.5.selected`, false);
        setValue(`designations.6.selected`, false);
        setValue(`designations.7.selected`, false);
      }
  }, [dentalOnlyChecked]);

  const handleCheckOption = (e) => {
    setEdited(true);
    if(e.target.id === 'DentalOnly_DSG')
      setDentalOnlyChecked(e.target.checked);

    // Destructuring
    const { id, checked } = e.target;
    const { designations } = userInfo;

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        designations: [...designations, id]
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfo({
        designations: designations.filter((e) => e !== id),
      });
    }
  }


  return(
    <div className='mb-5 mt-5'>

      <FormGroup row className='mb-4'>
      <Label className='label align-right' for="clinicName" sm={3}>
          Clinic Name
        </Label>
        <Col md={9}>
          <Input
            id="clinicName"
            type="text"
            innerRef={nameRef}
            {...nameRegisterProps}
            required
          /><FormFeedback>Clinic Name Required.</FormFeedback>
          </Col>          
        </FormGroup>
      <FormGroup>
        <Label className='label'>Designation History (Select all that formely and currently apply):</Label>
      </FormGroup>
      {permissions['Designation']?.map((option) => {
        const { ref: optionRef, ...optionRegisterProps } = register(`designations.${option.key}.selected`, { required: false });
        const { ref: hrsaGrantIdRef, ...hrsaGrantIdRegisterProps } = register(`designations.${option.key}.hrsaGrantId`, { required: false });
        const { ref: designationDateRef, ...designationDateRegisterProps } = register(`designations.${option.key}.designationDate`, { required: false });
        const { ref: previouslyKnownAsRef, ...previouslyKnownAsRegisterProps } = register(`designations.${option.key}.previouslyKnownAs`, { required: false });

        setValue(`designations.${option.key}.designation`, option.key);

        if(clinicUpdate && clinicUpdate["designations"]){
          let selected = clinicUpdate["designations"].find( x => x["designation"].key === option.key);
          if(selected && !dentalOnlyChecked && !edited)
          {
            setValue(`designations.${option.key}.selected`, true);
            if(option.key===1)
            {
              setDentalOnlyChecked(true);
            }
          }
        }

        return (
          <React.Fragment key={option.key}>
            <FormGroup check md={4}>
              <Input
                id={option.name}
                name={option.name}
                type="checkbox"
                innerRef={optionRef}
                disabled = {dentalOnlyChecked && option.key!==1}
                {...optionRegisterProps}
                onChange={handleCheckOption}
              />
              {' '}
              <Label check className='check-label'>{option.description}</Label>

              {userInfo.designations.find(e=> e === option.name)  && (option.name === 'FQHC_DSG' || option.name === 'FQHCLAL_DSG')  && (
                <Row>
                  <Label for={`hrsa-${option.name}`} sm={2} className='small-label'>HRSA Grant Id:</Label>
                  <Col sm={3}>
                    <Input
                      id={`hrsa-${option.name}`}
                      name={`hrsa-${option.name}`}
                      type="text"
                      innerRef={hrsaGrantIdRef}
                      {...hrsaGrantIdRegisterProps}
                    />
                  </Col>
                </Row>
              )}
              {userInfo.designations.find(e=> e === option.name) &&
              (<>
                <Row>
                  <Label for={`date-${option.name}`} sm={2} className='small-label'>Date of Designation:</Label>
                  <Col sm={3}>
                    <Input
                      id={`date-${option.name}`}
                      name={`date-${option.name}`}
                      type="date"
                      innerRef={designationDateRef}
                      {...designationDateRegisterProps}
                    />
                  </Col>
                </Row>
                <Row>
                  <Label for={`previous-${option.name}`} sm={2} className='small-label'>Previously Known As:</Label>
                  <Col sm={3}>
                    <Input
                      id={`previous-${option.name}`}
                      name={`previous-${option.name}`}
                      type="text"
                      innerRef={previouslyKnownAsRef}
                      {...previouslyKnownAsRegisterProps}
                    />
                  </Col>
                </Row>
              </>
              )}
            </FormGroup>
          </React.Fragment>
        );

      })}
      
      <FormGroup row className='mb-4'>
        <Label for="membershipType" sm={3} className='label'>
          Membership Type
        </Label>
        <Col md={4}>
          <Input
            id="membershipType"
            type="select"
            innerRef={membershipTypeRef}
            defaultValue={-1}
            {...membershipTypeRegisterProps}
          >
            <option value={-1} hidden disabled>-- select an option --</option>

            {permissions['Membership Type']?.map((option) => {
              return (<option key={option.key} value={option.key}>{option.description}</option>)
            })}

          </Input>
        </Col>
      </FormGroup>


      <FormGroup className='mb-2'>
        <Label className='label'>Membership Participation:</Label>

      {permissions['Membership Participation']?.map((option) => {
        const { ref: optionRef, ...optionRegisterProps } = register(`membershipParticipations.${option.key}.selected`, { required: false });
        setValue(`membershipParticipations.${option.key}.membership`, option.key);

        if(clinicUpdate && clinicUpdate["membershipParticipations"]){
          let selected = clinicUpdate["membershipParticipations"].find( x => x.key === option.key);
          if(selected)
            setValue(`membershipParticipations.${option.key}.selected`, true);
        }

        return (
          <FormGroup check key={option.key}>
            <Input
              id={option.name}
              name={option.name}
              type="checkbox"
              onChange={handleCheckOption}
              innerRef={optionRef}
              {...optionRegisterProps}
            />
            {' '}
            <Label check className='check-label'>{option.description}</Label>
          </FormGroup>);
      })}
      </FormGroup>

      <FormGroup className='mb-2'>
        <Label className='label'>Share Data with KDHE's PCO?</Label>
      <FormGroup check>
        <Input
          id={'shareDataWithKdhe'}
          type="checkbox"
          onChange={() => handleShareDataWithKdhe(true)}
          checked={watch("shareDataWithKdhe") !== undefined && watch("shareDataWithKdhe")}
        />
        {' '}
        <Label check className='check-label'>Accept</Label>
      </FormGroup>

      <FormGroup check>
        <Input
          id={'shareDataWithKdhe'}
          type="checkbox"
          onChange={() => handleShareDataWithKdhe(false)}
          checked={watch("shareDataWithKdhe") !== undefined && !watch("shareDataWithKdhe")}
        />
        {' '}
        <Label check className='check-label'>Decline</Label>
      </FormGroup>
      </FormGroup>

      <FormGroup className='mb-2'>
        <Label className='label'>Special Services Area:</Label>
      
      {permissions['Special Service Area']?.map((option) => {
        const { ref: optionRef, ...optionRegisterProps } = register(`specialServices.${option.key}.selected`, { required: false });
        setValue(`specialServices.${option.key}.specialService`, option.key);

        if(clinicUpdate && clinicUpdate["specialServices"]){
          let selected = clinicUpdate["specialServices"].find( x => x.key === option.key);
          if(selected)
            setValue(`specialServices.${option.key}.selected`, true);
        }

        return (
          <FormGroup check key={option.key}>
            <Input
              id={option.name}
              name={option.name}
              type="checkbox"
              innerRef={optionRef}
              {...optionRegisterProps}
            />
            {' '}
            <Label check className='check-label'>{option.description}</Label>
          </FormGroup>);
      })}
      </FormGroup>

      <Row className='mt-3 d-flex justify-content-end'>
        <Col sm={{size: 'auto'}}>
          <Button className="btn mt-3 px-4" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
        <Col sm={{size: 'auto'}}>
          <Button className="btn mt-3 px-4" color="primary" onClick={() => handleContinue(stepNumber)}>
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
}


const ClinicDetails = ({ 
  stepNumber = 0, 
  label = "", 
  completed = null, 
  clinicUpdate = null, 
  editMode = false, 
  handleContinue = (stepNumber) => {},
   handleCancel = () => {} }) => {

  const [isEditMode, setIsEditMode]= useState(editMode);

  const { register, setValue, watch} = useFormContext();
  const { formState: {errors} } = useForm();

  const { ref: nameRef, ...nameRegisterProps } = register("name", { required: true });
  const { ref: businessAddressLine1Ref, ...businessAddressLine1RegisterProps } = register("businessAddressLine1", { required: false });
  const { ref: businessAddressLine2Ref, ...businessAddressLine2RegisterProps } = register("businessAddressLine2", { required: false });
  const { ref: cityRef, ...cityRegisterProps } = register("city", { required: false });
  const { ref: stateRef, ...stateRegisterProps } = register("state", { required: false });
  const { ref: zipRef, ...zipRegisterProps } = register("zip", { required: false });
  const { ref: countyRef, ...countyRegisterProps } = register("county", { required: false });

  const { ref: mailingAddressLine1Ref, ...mailingAddressLine1RegisterProps } = register("mailingAddressLine1", { required: false });
  const { ref: mailingAddressLine2Ref, ...mailingAddressLine2RegisterProps } = register("mailingAddressLine2", { required: false });
  const { ref: mailingCityRef, ...mailingCityRegisterProps } = register("mailingCity", { required: false });
  const { ref: mailingStateRef, ...mailingStateRegisterProps } = register("mailingState", { required: false });
  const { ref: mailingZipRef, ...mailingZipRegisterProps } = register("mailingZip", { required: false });

  const { ref: phoneRef, ...phoneRegisterProps } = register("phone", { required: false });
  const { ref: faxRef, ...faxRegisterProps } = register("fax", { required: false });
  const { ref: emailRef, ...emailRegisterProps } = register("email", { required: false });
  const { ref: websiteRef, ...websiteRegisterProps } = register("website", { required: false });
  const { ref: notesRef, ...notesRegisterProps } = register("notes", { required: false });

  const unitedStatesStates = useStates();

  const handleIsSameMailingAddress = (val) => {
    setValue("isMailingAddressDifferent", val);
  }

  useEffect(() => {
    if(clinicUpdate){
      setValue("businessAddressLine1", clinicUpdate.businessAddressLine1);
      setValue("businessAddressLine2", clinicUpdate.businessAddressLine2);
      setValue("city", clinicUpdate.city);
      setValue("state", clinicUpdate.state);
      setValue("zip", clinicUpdate.zip);
      setValue("county", clinicUpdate.county);
      setValue("mailingAddressLine1", clinicUpdate.mailingAddressLine1);
      setValue("mailingAddressLine2", clinicUpdate.mailingAddressLine2);
      setValue("mailingCity", clinicUpdate.mailingCity);
      setValue("mailingState", clinicUpdate.mailingState);
      setValue("mailingZip", clinicUpdate.mailingZip);
      setValue("phone", clinicUpdate.phone);
      setValue("fax", clinicUpdate.fax);
      setValue("email", clinicUpdate.email);
      setValue("website", clinicUpdate.website);
      setValue("isMailingAddressDifferent", clinicUpdate.isMailingAddressDifferent);
      setValue("notes", clinicUpdate.notes);
    }

  }, [clinicUpdate, setValue])

  return(
    <div className='mb-5 mt-5'>

      <FormGroup row className='mb-4'>
        <Label className='label align-right' for="clinicName" sm={3}>
          Clinic Name
        </Label>
        <Col md={9}>
          <Input
            id="clinicName"
            type="text"
            innerRef={nameRef}
            {...nameRegisterProps}
          />
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label text-right' for="clinicBusinessAddress1" sm={3}>
          Business Address Line 1
        </Label>
        <Col md={9}>
          <Input
            id="clinicBusinessAddress1"
            type="text"
            innerRef={businessAddressLine1Ref}
            {...businessAddressLine1RegisterProps}
          />
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for="clinicBusinessAddress2" sm={3}>
          Business Address Line 2
        </Label>
        <Col md={9}>
          <Input
            id="clinicBusinessAddress2"
            type="text"
            innerRef={businessAddressLine2Ref}
            {...businessAddressLine2RegisterProps}
          />
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for="clinicCity" sm={3}>
          City
        </Label>
        <Col md={5}>
          <Input
            id="clinicCity"
            type="text"
            innerRef={cityRef}
            {...cityRegisterProps}
          />
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for="clinicState" sm={3}>
          State
        </Label>
        <Col md={5}>

          <Input
            id="clinicState"
            type="select"
            innerRef={stateRef}
            {...stateRegisterProps}
            defaultValue={-1}>
              <option value={-1} hidden disabled>-- Select State--</option>
              {unitedStatesStates.map((state) => {
                return( <option key={state.name} value={state.abbreviation}>{state.name}</option>)
              })}
            </Input>
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for="clinicZip" sm={3}>
          Zip
        </Label>
        <Col md={5}>
          <Input
            id="clinicZip"
            name="zip"
            type="number"
            innerRef={zipRef}
            {...zipRegisterProps}
          />
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for="clinicCounty" sm={3}>
          County
        </Label>
        <Col md={5}>
          <Input
            id="clinicCounty"
            type="text"
            innerRef={countyRef}
            {...countyRegisterProps}
          />
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for="mailingAddress" sm={3}>
          Is the mailing address the same as the business address?
        </Label>
        <Col sm={1} className='text-center'>
          <Input
            id="mailingAddressYes"
            type="checkbox"
            onChange={() => handleIsSameMailingAddress(true)}
            checked={watch("isMailingAddressDifferent") !== undefined && watch("isMailingAddressDifferent")}
          />
          <Label className='label' check for="mailingAddressYes" sm={3}>Yes</Label>
        </Col>
        <Col sm={2} className='text-end'>
          <Input
            id="mailingAddressNo"
            name="mailingAddressNo"
            type="checkbox"
            onChange={() => handleIsSameMailingAddress(false)}
            checked={watch("isMailingAddressDifferent") !== undefined && !watch("isMailingAddressDifferent")}
          />
          {' '}
          <Label className='label' check for="mailingAddressNo" sm={2}>No</Label>
        </Col>
      </FormGroup>

      {watch("isMailingAddressDifferent") !== undefined && !watch("isMailingAddressDifferent") && (
      <>
        <FormGroup row className='mb-4'>
          <Label className='label' for="clinicMailingAddress1" sm={3}>
            Mailing Address Line 1
          </Label>
          <Col md={9}>
            <Input
              id="clinicMailingAddress1"
              type="text"
              innerRef={mailingAddressLine1Ref}
              {...mailingAddressLine1RegisterProps}
            />
          </Col>
        </FormGroup>

        <FormGroup row className='mb-4'>
          <Label className='label' for="clinicMailingAddress2" sm={3}>
            Mailing Address Line 2
          </Label>
          <Col md={9}>
            <Input
              id="clinicMailingAddress2"
              type="text"
              innerRef={mailingAddressLine2Ref}
              {...mailingAddressLine2RegisterProps}
            />
          </Col>
        </FormGroup>

        <FormGroup row className='mb-4'>
          <Label className='label' for="clinicMailingCity" sm={3}>
            City
          </Label>
          <Col md={4}>
            <Input
              id="clinicMailingCity"
              type="text"
              innerRef={mailingCityRef}
              {...mailingCityRegisterProps}
            />
          </Col>
        </FormGroup>

        <FormGroup row className='mb-4'>
          <Label className='label' for="clinicMailingState" sm={3}>
            State
          </Label>
          <Col md={4}>
            <Input
              id="clinicMailingState"
              type="select"
              innerRef={mailingStateRef}
              {...mailingStateRegisterProps}
              defaultValue={-1}>
              <option value={-1} hidden disabled>-- Select State--</option>
              {unitedStatesStates.map((state) => {
                return( <option key={state.name} value={state.abbreviation}>{state.name}</option>)
              })}
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row className='mb-4'>
          <Label className='label' for="clinicMailingZip" sm={3}>
            Zip
          </Label>
          <Col md={4}>
            <Input
              id="clinicMailingZip"
              type="number"
              innerRef={mailingZipRef}
              {...mailingZipRegisterProps}
            />
          </Col>
        </FormGroup>
      </>
      )}


      <FormGroup row className='pt-4 mb-4'>
        <Label className='label' for="clinicPhone" sm={3}>
          Phone
        </Label>
        <Col md={4}>
          <Input
            id="clinicPhone"
            type="tel"
            innerRef={phoneRef}
            {...phoneRegisterProps}
          />
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for="clinicFax" sm={3}>
          Fax
        </Label>
        <Col md={4}>
          <Input
            id="clinicFax"
            type="tel"
            innerRef={faxRef}
            {...faxRegisterProps}
          />
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for="clinicEmail" sm={3}>
          Email
        </Label>
        <Col md={9}>
          <Input
            id="clinicEmail"
            type="email"
            innerRef={emailRef}
            placeholder="example@example.com"
            invalid = {errors.email !== undefined}
            {...emailRegisterProps}
          />
          <FormFeedback>Email not in the expected format.</FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for="clinicWebsite" sm={3}>
          Website
        </Label>
        <Col md={9}>
          <Input
            id="clinicWebsite"
            type="url"
            innerRef={websiteRef}
            {...websiteRegisterProps}
          />
          <FormFeedback>WebSite not in the expected URL format.</FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for="clinicNotes" sm={3}>
          Notes
        </Label>
        <Col md={9}>
          <Input
            id="clinicNotes"
            type="textarea"
            innerRef={notesRef}
            {...notesRegisterProps}
          />
        </Col>
      </FormGroup>

      <Row className='mt-3 d-flex justify-content-end'>
        <Col sm={{size: 'auto'}}>
          <Button className="btn mt-3 px-4" color="secondary" onClick={() => handleCancel()}>
            Cancel
          </Button>
        </Col>
        <Col sm={{size: 'auto'}}>
          <Button className="btn mt-3 px-4" color="primary" onClick={() => handleContinue(stepNumber)}>
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
}


const ClinicContacts = ({ stepNumber = 0, label = "", completed = null, clinicUpdate=null, handleContinue = (stepNumber) => {}, handleCancel = () => {}, }) => {

  const { control, setValue, watch } = useFormContext();
  const { fields, append, update } = useFieldArray({
    control,
    name: "contacts"
  });

  const handleAddContact = () => {
    append();
  }



  useEffect( () => {
    if(clinicUpdate && clinicUpdate["contacts"]){
      clinicUpdate["contacts"].map( (x, index) => {
        if(!watch(`contacts.${index}.firstName`)){
          append();
          setValue(`contacts.${index}.firstName`, x["firstName"]);
          setValue(`contacts.${index}.lastName`, x["lastName"]);
          setValue(`contacts.${index}.phone`, x["phone"]);
          setValue(`contacts.${index}.extension`, x["extension"]);
          setValue(`contacts.${index}.identifier`, x["identifier"]);
          setValue(`contacts.${index}.identifierNotApplicable`, x["identifierNotApplicable"]);
          setValue(`contacts.${index}.email`, x["email"]);
          setValue(`contacts.${index}.supervisor`, x["supervisor"]);
          setValue(`contacts.${index}.supervisorNotApplicable`, x["supervisorNotApplicable"]);

        }

        return true
      })
    }

  }, [clinicUpdate, setValue])

  return(
    <div>

      {fields.map((field, index) => (
        <fieldset key={field.id} className="my-4 p-2">
          <ClinicContactEdit index={index} contact={field} control={control} update={update} />
        </fieldset>
      ))}

      <Row>
        <Col sm={{size: 'auto'}}>
          <Button className="btn mt-3" color="primary" onClick={handleAddContact}>
            Add Another Contact
          </Button>
        </Col>
      </Row>

      <Row className='mt-3 d-flex justify-content-end'>
        <Col sm={{size: 'auto'}}>
          <Button className="btn mt-3 px-4" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
        <Col sm={{size: 'auto'}}>
          <Button 
            type="submit" 
            className="btn mt-3 px-4" 
            color="primary" onClick={() => handleContinue(stepNumber)}>
            Submit
          </Button>
        </Col>
      </Row>
    </div>
  );
}


const ClinicContactEdit = ({index, contact, update, control}) => {

  const { register } = useFormContext();

  const { ref: firstNameRef, ...firstNameRegisterProps } = register(`contacts.${index}.firstName`, { required: true });
  const { ref: lastNameRef, ...lastNameRegisterProps } = register(`contacts.${index}.lastName`, { required: true });
  const { ref: phoneRef, ...phoneRegisterProps } = register(`contacts.${index}.phone`, { required: false });
  const { ref: extensionRef, ...extensionRegisterProps } = register(`contacts.${index}.extension`, { required: false });
  const { ref: identifierRef, ...identifierRegisterProps } = register(`contacts.${index}.identifier`, { required: false });
  const { ref: identifierNaRef, ...identifierNaRegisterProps } = register(`contacts.${index}.identifierNotApplicable`, { required: false });
  const { ref: emailRef, ...emailRegisterProps } = register(`contacts.${index}.email`, { required: false });
  const { ref: supervisorRef, ...supervisorRegisterProps } = register(`contacts.${index}.supervisor`, { required: false });
  const { ref: supervisorNaRef, ...supervisorNaRegisterProps } = register(`contacts.${index}.supervisorNotApplicable`, { required: false });


  return(
    <>
      <FormGroup row className='mb-4'>
        <Label className='label' for={`firstName`} sm={2}>
          First Name
        </Label>
        <Col md={4}>
          <Input
            id={`firstName`}
            type="text"
            innerRef={firstNameRef}
            {...firstNameRegisterProps}
          />
          <FormFeedback>Name is required</FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for={`lastName`} sm={2}>
          Last Name
        </Label>
        <Col md={4}>
          <Input
            id={`lastName`}
            type="text"
            innerRef={lastNameRef}
            {...lastNameRegisterProps}
          />
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for={`phone`} sm={2}>
          Phone
        </Label>
        <Col md={4}>
          <Input
            id={`phone`}
            type="text"
            innerRef={phoneRef}
            {...phoneRegisterProps}
          />
        </Col>

        <Label className='label' for={`extension`} sm={1}>
          Ext
        </Label>
        <Col md={2}>
          <Input
            id={`extension`}
            type="text"
            innerRef={extensionRef}
            {...extensionRegisterProps}
          />
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for="identifier" sm={2}>
          ID
        </Label>
        <Col md={4}>
          <Input
            id="identifier"
            type="text"
            innerRef={identifierRef}
            {...identifierRegisterProps}
          />
        </Col>
        <Col md={4}>
          <Input
            id="identifierNa"
            type="checkbox"
            innerRef={identifierNaRef}
            {...identifierNaRegisterProps}
          />
          {' '}
          <Label className='check-label small-label' check>
            Check if not applicable
          </Label>
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for="email" sm={2}>
          Email
        </Label>
        <Col md={4}>
          <Input
            id="email"
            type="text"
            innerRef={emailRef}
            {...emailRegisterProps}
          />
        </Col>
      </FormGroup>

      <FormGroup row className='mb-4'>
        <Label className='label' for="supervisor" sm={2}>
          Supervisor
        </Label>
        <Col md={4}>
          <Input
            id="supervisor"
            type="text"
            innerRef={supervisorRef}
            {...supervisorRegisterProps}
          />
        </Col>

        <Col md={4}>
          <Input
            id="supervisorNa"
            type="checkbox"
            innerRef={supervisorNaRef}
            {...supervisorNaRegisterProps}
          />
          {' '}
          <Label className='check-label small-label' check>
            Check if not applicable
          </Label>
        </Col>
      </FormGroup>
    </>
  );
}
