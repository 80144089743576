import React from 'react';
import { Review } from 'components/ClinicManagement/Review';

export const ReviewDataEntry = ({clinicData}) => {

    const surveySections = [
        "UDSDataUpload",
        "ManualDataEntry",
        "DataSubmission",
      ];
  return (<Review surveySections={surveySections} clinicData={clinicData}/>);
  
}