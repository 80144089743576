import React, { useState } from "react";
import { Input } from "reactstrap";
import { FilterType } from "models";


export const SmartFilter = ({
    columnName,
    value = '',
    filterType = FilterType.Text,
    onChange = null,
    hidden = false,
    children = null
}) => {

    const [filterValue, setFilterValue] = useState(value);

    const onFilterChange = (e) => {
        setFilterValue(e.target.value);

        if(onChange != null){
            onChange(e);
        }
    }

    switch(filterType){
        case FilterType.Text: return (
            <Input
                id={`header-${columnName}-input`}
                value={filterValue} 
                onChange={onFilterChange}
                hidden={hidden}
                aria-label={`filter input for ${columnName}`}
                aria-labelledby={`header-${columnName}-input-label`}
            />
        );
        case FilterType.Date: return (
            <Input
                id={`header-${columnName}-input`}
                type="date"
                value={filterValue} 
                onChange={onFilterChange}
                hidden={hidden}
                aria-label={`filter date input for ${columnName}`}
                aria-labelledby={`header-${columnName}-input-label`}
            />
        );
        case FilterType.Select: 
             
        return (
            <Input
                id={`header-${columnName}-input`}
                type="select"
                value={filterValue} 
                onChange={onFilterChange}
                hidden={hidden}
                aria-label={`filter select input for ${columnName}`}
                aria-labelledby={`header-${columnName}-input-label`}
                children={children}
            />
        );
        default: return (
            <></>
        );
    }
}