import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label, List } from "reactstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import "./NetworkDataHub.scss";
import { useForm } from "react-hook-form";
import { apiClient, ApiEndpoints, ApplicationPermissions } from "utilities";
import { ReactSortable } from "react-sortablejs";
import InputMask from "react-input-mask";
import { useUserContext } from "contexts";
import { trackPromise } from "react-promise-tracker";


export const Contacts = () => {
  const user = useUserContext();
  const canCreate = user.hasPermission(ApplicationPermissions.NetworkDataHub.CreateContacts);
  const canUpdate = user.hasPermission(ApplicationPermissions.NetworkDataHub.UpdateContacts);
  const canDelete = user.hasPermission(ApplicationPermissions.NetworkDataHub.DeleteContacts);
  const { handleSubmit } = useForm();
  const [contacts, setContacts] = useState([]);
  const [isAddMode, setIsAddMode] = useState(false);
  const [shouldRerender, setShouldRerender] = useState(false);

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  const handleReorder = async (event) => {
    const url = `${ApiEndpoints.NetworkDataHub.Contacts.Reorder}`;
    const prevContacts = [...contacts]; // new scoped array
    const [movedItem] = prevContacts.splice(event.oldDraggableIndex, 1);
    const newContacts = prevContacts;
    newContacts.splice(event.newDraggableIndex, 0, movedItem);

    for (var i = 0; i < newContacts.length; i++) {
      newContacts[i].order = i;
    }

    trackPromise(apiClient.put(url, newContacts));
  };

  const handleAdd = () => {
    setIsAddMode(true);
    setContacts([...contacts, { name: "", phone: "", email: "" }]);
  };

  const handleCancel = (contact, index) => {
    contacts[index].isEditMode = false;
    setContacts([...contacts]);
    setShouldRerender(!shouldRerender);

    if (!contact.isEditMode) {
      setIsAddMode(false);
    }
  };

  const insertData = async (index, contact) => {
    const url = `${ApiEndpoints.NetworkDataHub.Contacts.Create}`;
    contact.order = index;
    await trackPromise(apiClient
      .put(url, contact, {
        // @ts-ignore
        toastConfig: {
          showSuccess: true,
          successMessage: `Contact ${
            contact.isEditMode ? "updated" : "created"
          } successfully!`,
        },
      })
      .then(() => {
        setIsAddMode(false);
        setShouldRerender(!shouldRerender);
      }));
  };

  const handleEdit = (index, contact) => {
    contacts[index] = { ...contact, isEditMode: true };
    setContacts([...contacts]);
  };

  const handleChange = (event, index) => {
    const contact = contacts[index];
    contact[event.target.name] = event.target.value;
    setContacts([...contacts]);
  };

  const handleDelete = (id) => {
    setContacts(contacts.filter((a) => a.id !== id));
    trackPromise(apiClient
      .delete(`${ApiEndpoints.NetworkDataHub.Contacts.Delete}/${id}`, {
        // @ts-ignore
        toastConfig: {
          showSuccess: true,
          successMessage: `Contact deleted successfully!`,
        },
      }));
  };

  useEffect(() => {
    trackPromise(apiClient
      .get(ApiEndpoints.NetworkDataHub.Contacts.View)
      .then((response) => {
        setContacts(response.data);
      })
      .catch((err) => {
        console.error(err);
        setContacts([]);
      }));
  }, [setContacts, shouldRerender]);

  return (
    <div
      id="contacts"
      className="tertiary-border px-4 py-3 mt-4 d-flex flex-column justify-content-between"
    >
      <h4 className="text-center">
        <u>Contact Us</u>
      </h4>
      <List type="unstyled" className="px-3 py-2">
        <ReactSortable
          list={contacts}
          disabled={!canUpdate}
          setList={setContacts}
          onUpdate={(event) => {
            handleReorder(event);
          }}
        >
          {contacts?.map((contact, index) => {
            if (contact.isEditMode || contact.id == null) {
              return (
                <li key={index}>
                  <Form
                    onSubmit={handleSubmit(
                      async () => await insertData(index, contact)
                    )}
                  >
                    <Label for={`name-${index}`}>
                      <b>{contact.isEditMode ? "Update" : "New"} Contact</b>
                    </Label>
                    <FormGroup>
                      <Input
                        id={`name-${index}`}
                        name="name"
                        placeholder="Name"
                        type="text"
                        onChange={(event) => handleChange(event, index)}
                        value={contact.name}
                      />
                      <Label hidden for={`name-${index}`}>
                        Name
                      </Label>
                    </FormGroup>
                    <FormGroup className="mt-1">
                      <Input
                        id={`email-${index}`}
                        name="email"
                        placeholder="Email"
                        type="email"
                        onChange={(event) => handleChange(event, index)}
                        value={contact.email}
                      />
                      <Label hidden for={`email-${index}`}>
                        Email
                      </Label>
                    </FormGroup>
                    <FormGroup className="mt-1">
                      <InputMask
                        id={`phone-${index}`}
                        name="phone"
                        className="form-control"
                        mask="(999) 999-9999"
                        placeholder="Phone"
                        type="text"
                        onChange={(event) => handleChange(event, index)}
                        value={contact.phone}
                      />
                      <Label hidden for={`phone-${index}`}>
                        Phone
                      </Label>
                    </FormGroup>
                    <FormGroup className="mt-1 submit-btn-group">
                      <Button
                        type="submit"
                        color="primary"
                        className="float-end ms-3 btn-sm pill-btn"
                      >
                        Save
                      </Button>
                      <Button
                        color="secondary"
                        className="float-end ms-3 btn-sm pill-btn"
                        onClick={() => handleCancel(contact, index)}
                      >
                        Cancel
                      </Button>
                    </FormGroup>
                  </Form>
                </li>
              );
            } else {
              return (
                <li key={index} className={canUpdate ? "moveable-item" : ""}>
                  <span>
                  {canUpdate &&
                    <FaEdit
                      className="me-1 link-primary"
                      onClick={(event) => {
                        handleEdit(index, contact);
                      }}
                    />
                  }
                  {canDelete && 
                    <FaTrashAlt
                      className="me-3 link-primary"
                      onClick={() => handleDelete(contact.id)}
                    />
                  }
                  </span>
                  <b>{contact.name}</b>
                  <div>
                    <b>email: </b>
                    <a href={`mailto:${contact.email}`}>{contact.email}</a>
                  </div>
                  <div>
                    <b>phone: </b>
                    {formatPhoneNumber(contact.phone)}
                  </div>
                  <hr />
                </li>
              );
            }
          })}
        </ReactSortable>
      </List>
      <div>
        {!isAddMode && canCreate && (
          <Button
            color="primary"
            className="float-end mt-3 pill-btn"
            outline
            onClick={() => handleAdd()}
          >
            + Contact
          </Button>
        )}
      </div>
    </div>
  );
};
