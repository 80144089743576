import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ApplicationPermissions, apiClient, sideMenuItems } from 'utilities';
import { FormStepperComponent } from 'components/FormStepperComponent';
import { ClinicInformation } from './ClinicInformation';
import SideBar from 'components/SideBar/SideBar';
import { StaffingAndUtilization } from './StaffingAndUtilization';
import { PatientData } from './PatientData';
import { Review } from './Review';
import { ClinicalMeasures } from './ClinicalMeasures';
import { FinancialMeasures } from './FinancialMeasures';
import { DataMeasures } from './DataMeasures';
import { ActiveAccounts } from './ActiveAccounts';
import { useUserContext } from 'contexts';
import { HasPermission } from 'components/HasPermission';

const stepRoutes = 
{
  "ClinicDetails": 0,
  "PatientData": 1,
  "StaffingAndUtilization": 2,
  "ClinicalMeasures": 3,
  "FinancialMeasures": 4,
  "DataMeasures": 5,
  "Review": 6
}

const surveySections = [
  "PatientData",
  "StaffingAndUtilization",
  "ClinicalMeasures",
  "FinancialMeasures",
  "DataMeasures",
  "DataSubmission",
];

export const ClinicManagement = () => {

  const location = useLocation();
  const history = useHistory();
  const userContext = useUserContext();
  
  const [activeStep, setActiveStep] = useState(0);
  const [clinicData, setClinicData] = useState({});  
  
  useEffect(() => {
    if(userContext?.clinicId !== '')
      setClinicData({"id":userContext.clinicId});
  }, [userContext])

  const handleNextStep = (nextStep) => {
    if(nextStep + 1 < 7) {
      setActiveStep(nextStep + 1);
      Object.keys(stepRoutes).forEach( key => {
        if( stepRoutes[key] === nextStep + 1 ){
          const url = (sideMenuItems[key] && sideMenuItems[key]['items'] && sideMenuItems[key]['items'].length > 0 && sideMenuItems[key]['items'][0].target !== "#") 
            ? sideMenuItems[key]['items'][0].target : `/ClinicManagement/${key}#`;

          history.push(url, location.state)
        }
      })
    }
  }

  const setNextActiveStep = (stepNumber) => {
    handleNextStep(stepNumber - 1);
  }
  
  useEffect(() => {

    if( location.state && location.state['clinicUpdate']) {
      clinicData["clinicUpdate"]=location.state['clinicUpdate'];
      setClinicData(clinicData);
    }

    const routeKey = Object.keys(stepRoutes)?.find( key => location.pathname.split('#')[0].indexOf(key) > 0 ) || "";
    if(routeKey)
      setActiveStep(stepRoutes[routeKey]);

  }, [location, setClinicData, setActiveStep]);


  return (
    <FormStepperComponent activeStep={activeStep} setActiveStep={setNextActiveStep}>          

      <StepperComponent stepNumber={0} label={"Clinic Details"}  handleContinue={handleNextStep}>
        {(location.pathname.indexOf('ClinicInformation') > -1 || location.hash.indexOf('ClinicInformation')> -1)
          && (
          <HasPermission permission={ApplicationPermissions.ClinicManagement.ViewClinicDetails}>
            <ClinicInformation clinicData={clinicData} />
          </HasPermission>
          )
        }
        {(location.pathname.indexOf('ActiveAccounts') > -1 || location.hash.indexOf('ActiveAccounts')> -1)
          && (
          <HasPermission permission={ApplicationPermissions.ClinicManagement.ViewClinicUsers}>
            <ActiveAccounts clinicData={clinicData} route={'ClinicManagement/ClinicDetails'}/>
          </HasPermission>
          )
        }
      </StepperComponent>
      
      <StepperComponent stepNumber={1} label={"Patient Data"} handleContinue={handleNextStep} >
      <HasPermission permission={ApplicationPermissions.ClinicManagement.UpdateDataEntry}>
        <PatientData />
      </HasPermission>
      </StepperComponent>

      <StepperComponent stepNumber={2} label={"Staffing and Utilization"}  handleContinue={handleNextStep}>
      <HasPermission permission={ApplicationPermissions.ClinicManagement.UpdateDataEntry}>
        <StaffingAndUtilization />
      </HasPermission>
      </StepperComponent>

      <StepperComponent stepNumber={3} label={"Clinical Measures"} handleContinue={handleNextStep}>
      <HasPermission permission={ApplicationPermissions.ClinicManagement.UpdateDataEntry}>
        <ClinicalMeasures />
      </HasPermission>
      </StepperComponent>

      <StepperComponent stepNumber={4} label={"Financial Measures"} handleContinue={handleNextStep}>
      <HasPermission permission={ApplicationPermissions.ClinicManagement.UpdateDataEntry}>
        <FinancialMeasures />
      </HasPermission>
      </StepperComponent>
      
      <StepperComponent stepNumber={5} label={"Data Measures"} handleContinue={handleNextStep}>
      <HasPermission permission={ApplicationPermissions.ClinicManagement.UpdateDataEntry}>
        <DataMeasures />
      </HasPermission>
      </StepperComponent>

      <StepperComponent stepNumber={6} label={"Review"} handleContinue={handleNextStep}>
      <HasPermission permission={ApplicationPermissions.ClinicManagement.SubmitDataEntry}>
        <Review surveySections={surveySections} clinicData={clinicData} />
      </HasPermission>
      </StepperComponent>
    </FormStepperComponent>
  );
};


const StepperComponent = ({stepNumber, label = "", handleContinue = () => {}, children}) => {
  
  const location = useLocation();

  let sideMenuKey = Object.keys(sideMenuItems)?.find( key => (location.pathname+location.hash).indexOf(key) > 0 ) || "";

  if(location.pathname.indexOf('Review') > 0){
    sideMenuKey = "Review";
  }

  return(
    <Container>
      <Row>

        {sideMenuItems[sideMenuKey] && (
          <Col md={3}>
            <SideBar toggle={() => {}} isOpen={undefined} sideMenuItems={sideMenuItems[sideMenuKey]} state={location.state}/>
          </Col>
        )}

        <Col md={sideMenuItems[sideMenuKey] ? 9 : 12}>
          {children}
        </Col>

      </Row>
    </Container>)
}