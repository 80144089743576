import React, { useEffect, useState } from 'react';
import { Row, Button, ButtonGroup } from 'reactstrap';
import { SmartGrid } from 'components/smart-grid';
import { ColDisplayConfig } from "models";
import { apiClient } from '../../../utilities';
import { trackPromise } from 'react-promise-tracker';

export const ClinicDeactivationRequests = ({shouldRefresh}) => {

    const [needsRefresh, setNeedsRefresh] = useState(shouldRefresh);
  
    useEffect(() => {
      if(shouldRefresh && shouldRefresh !== needsRefresh){
        setNeedsRefresh(shouldRefresh);
      }
    }, [shouldRefresh])
  
    const onApproveClick = (row) => {
      
      var data = {
        DeActivateRequestId: row.id,
        RespondingUserId: row.requestingUser.id,
        Status: 1,
        RequestType: 1,
        toastConfig:{
          showSuccess: true,
          successMessage: `${row.userToDeactivate?.clinicName} was successfully deactivated!`
        }
      };

      trackPromise(apiClient.post(`${process.env.REACT_APP_API_BASE_URL}Clinic/ProcessDeactivationRequestAsync`, data)
      .then((response) => {
        setNeedsRefresh(true);
      }));
    }
  
    const onDenyClick = (row) => {
          
      var data = {
        deActivateRequestId: row.id,
        respondingUserId: row.requestingUser.id,
        status: 2,
      };
  
      trackPromise(apiClient.post(`Clinic/ProcessDeactivationRequestAsync`, 
        data
      ).then((response) => {
        setNeedsRefresh(true);
      }));
    }
    
    const nameCell = (row) => {
      return row.userToDeactivate?.clinicName;
    }

      const requestingUserCell = (row) => {
        var user = row.requestingUser;
        return `${user?.firstName} ${user?.lastName}`;
      }
  
      const actionsCell = (row) => {
        return (
        <ButtonGroup className="role-actions-button-group">
            <Button className="btn" onClick={() => onDenyClick(row)}>
              Deny
            </Button>
            <Button color='danger' className="btn btn-danger" onClick={() => onApproveClick(row)}>
              Approve
            </Button>
      </ButtonGroup>
      )}
    
  
      const [columnConfig] = useState([
          new ColDisplayConfig("", "Clinic Name", false, false, nameCell),
          new ColDisplayConfig("", "Requested By", false, false, requestingUserCell),
          new ColDisplayConfig("", "", false, false, actionsCell)
        ]);
  
        return (
  
          <div className="col-md-12">
            <h3 className='title'>Deactivate Clinic Requests</h3>
            <Row>
              <SmartGrid
                url={`${process.env.REACT_APP_API_BASE_URL}Clinic/GetDeactivationRequests`}
                columnConfig={columnConfig}
                pageSize={50}
                pageable
                refreshGrid={needsRefresh}
              />
            </Row>
          </div>
          
        );
  };