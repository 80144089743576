import React, { useState } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, Form } from 'reactstrap';
import { AuthService } from 'services'; 
import { useHistory } from 'react-router-dom';
import { useUserContext } from 'contexts';
import { toast } from "react-toastify";
import { ResetPassword } from './ResetPassword';
import '../Clinic/Clinic.scss';
import { trackPromise } from 'react-promise-tracker';

const ConfirmUser = ({userName, userPassword, userContext, history}) => {
    const [confirmationCode, setConfirmationCode] = useState("");

    const handleSubmitConfirmationCode = () => {
        AuthService.confirmUser(userName, userPassword, confirmationCode).then(response => {
            const user = response.data;
            
            userContext.setUser(user);
            userContext.setClinicId(response.clinicId);
            toast.success(`${userName} was logged in successfully!`);
            history.push("/");
        });
    }

    return (
        <Form onSubmit={handleSubmitConfirmationCode}>
            <FormGroup className="mt-5">
                <Row className="d-flex justify-content-around">
                    <Col lg={6}>
                        <Label for="confirmationCodeInput">
                            Confirmation Code
                        </Label>
                        <Input
                            id="confirmationCodeInput"
                            name="confirmationCodeInput"
                            placeholder="Please enter the confirmation code you recieved via email: "
                            type="number"
                            onChange={(e) => setConfirmationCode(e.target.value)}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row className="d-flex justify-content-around">                    
                    <Col lg={6}>
                        <Button
                            type="submit"
                            id="confirmationCodeSubmitButton"
                            name="confirmationCodeSubmitButton"
                            color="primary"
                            className="float-end mt-3 px-5 pill-btn"
                            outline
                        >
                            Confirm
                        </Button>
                    </Col>
                </Row>
            </FormGroup>
        </Form>
    );
}

export const Login = () => {
    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    
    const [showConfirmationCode, setShowConfirmationCode] = useState(false);
    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const [sessionId, setSessionId] = useState(""); 

    const history = useHistory();
    const userContext = useUserContext();

    const handleLogin = (event) => {
        event.preventDefault();
        trackPromise(AuthService.login(userName, userPassword)
            .then(response => {

                const user = response.data;
                if(response.message === "NEED_CONFIRMATION_CODE"){
                    setShowConfirmationCode(true);
                    toast.warning("User is not confirmed.  A confirmation code has been sent to the email address associated with this account.")
                } else if(response.message === "NEW_PASSWORD_REQUIRED") {
                    setSessionId(response.sessionId);
                    setShowPasswordReset(true);
                    toast.warning("You must change your password!")
                } else if(response.status === 'BadRequest') {
                    toast.error(response.message);
                } else {
                    userContext.setUser(user);
                    userContext.setClinicId(response.clinicId);
                    userContext.setIsLoggedIn(true);
                    toast.success(`${userName} was logged in successfully!`);
                    history.push("/");
                }
            }));
    }

    return (showConfirmationCode == true ? <ConfirmUser userName={userName} userPassword={userPassword} userContext={userContext} history={history} /> :
      showPasswordReset ? <ResetPassword userName={userName} sessionId={sessionId} /> :
        <Form onSubmit={handleLogin}>
            <FormGroup className="mt-5">
                <Row className="d-flex justify-content-around">
                    <Col lg={6}>
                        <Label for="userEmailInput">
                            <b>Email</b>
                        </Label>
                        <Input
                            id="userEmailInput"
                            name="email"
                            placeholder="Your email address will also be used as your username"
                            type="email"
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row className="d-flex justify-content-around">
                    <Col lg={6}>
                        <Label for="userPasswordInput">
                            <b>Password</b>
                        </Label>
                        <Input
                            id="userPasswordInput"
                            name="userPasswordInput"
                            placeholder="Please enter your password here"
                            type="password"
                            onChange={(e) => setUserPassword(e.target.value)}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup className="row d-flex justify-content-around">
                <Col lg={6}>
                    <Button
                        type="submit"
                        id="createUserSubmitButton"
                        name="createUserSubmitButton"
                        color="primary"
                        className="float-end mt-3 px-5 pill-btn"
                        outline
                    >
                        Log In
                    </Button>
                </Col>
            </FormGroup>
        </Form>
    );
};