export const dataMeasuresJSON = {
  showProgressBar: "top",
  showCompletedPage: false,
  showQuestionNumbers: "off",
  storeOthersAsComment: false,
  width: "90%",
  minWidth: "400px",
  maxWidth: "800px",
  showTOC: true,
  focusFirstQuestionAutomatic: false,
  title: "Data Measures",
  checkErrorsMode: "onValueChanged",
  pages: [
    {
      name: "EHRCapabilitiesandQualityRecognition",
      navigationTitle: "EHR Capabilities and Quality Recognition",
      navigationDescription: "EHRCapabilitiesandQualityRecognition",
      elements: [
        {
          type: "panel",
          name: "ehr",
          title: "2022 Clinic Electronic Health Record (EHR) Capabilities and Quality Recognition",
          elements: [
            {
              type: "radiogroup",
              name: "didClinicUseEHR",
              title: "1. By December 31st of the reporting year, did your clinic currently have an Electronic Health Record (EHR) system installed and in use?",
              popupdescription: `
              <p>
                For the purposes of this response, "providers" mean all medical providers including physicians, nurse practitioners, physician assistants, and certified nurse midwives. 
                Although some or all of the dental, mental health, or other providers may also have used the system, as may medical support staff, this is not required to choose <strong>option "a"</strong>. 
                For the purposes of this response, "all sites" means all permanent sites where medical providers serve clinic medical patients and does not include administrative-only locations, 
                hospitals or nursing homes, mobile vans, or sites used on a seasonal or temporary basis. You may check this option even if a few, newly hired, untrained employees are the only 
                ones not using the system. 
              </p>

              <p>
                Select <strong>option "b"</strong> if one or more permanent sites did not have the EHR installed, or in use (even if this is planned), or if one or more medical providers (as defined above) do not
                 yet use the system. When determining if all providers have access to the system, the clinic should also consider part-time and locum providers who serve clinic patients. Do not 
                 select this option if the only medical providers who did not have access were those who were newly hired and still being trained on the system. 
              </p>
              
              <p>
                Select <strong>"no"</strong> if no EHR was in use on December 31st, even if you had the system installed and training had started. 
              </p>
              `,
              choices: [
                "a. Yes, installed at all sites and used by all providers",
                "b. Yes, but only installed at some sites",
                "c. No"
              ],
              isRequired: true
            },
            {
              type: "multipletext",
              name: "ehrProductInfo",
              visibleIf: "{didClinicUseEHR} notempty and {didClinicUseEHR} <> 'c. No'",
              title: "In the spaces provided below, enter the EHR vendor and product name in use at your clinic on December 31st of the reporting year. If you have more than one EHR (if, for example, you acquired another practice with its own EHR), report the primary EHR.",
              items: [
                {
                  name: "ehrVendor",
                  title: "Vendor",
                  isRequired: true
                },
                {
                  name: "ehrProduct",
                  title: "Product Name",
                  isRequired: true
                },
              ]
            },
            {
              type: "radiogroup",
              name: "isNotLastYearsEhr",
              visibleIf: "{didClinicUseEHR} notempty and {didClinicUseEHR} <> 'c. No'",
              title: "1a. Did you switch to your current EHR from a previous system this year?",
              isRequired: true,
              choices: [
                "a. Yes",
                "b. No"
              ]
            },
            {
              type: "radiogroup",
              name: "usesMoreThanOneEhr",
              visibleIf: "{didClinicUseEHR} notempty and {didClinicUseEHR} <> 'c. No'",
              title: "1b. Do you use more than one EHR or data system across your organization?",
              isRequired: true,
              choices: [
                "a. Yes",
                "b. No"
              ]
            },
            {
              type: "radiogroup",
              name: "usesMoreThanOneEhr_Reason",
              title: "What is the reason?",
              visibleIf: "{didClinicUseEHR} notempty and {didClinicUseEHR} <> 'c. No' and {usesMoreThanOneEhr} = 'a. Yes'",
              isRequired: true,
              choices: [
                "a. Second EHR/data system is used during transition to primary EHR",
                "b. Second EHR/data system is specific to one service type (e.g., dental, behavioral health)",
                "c. Second EHR/data system is used at specific sites with no plan to transition",
              ],
              showOtherItem: true,
              otherText: "d. Other (please describe)",
            },
            {
              type: "checkbox",
              name: "doesElectronicExchangeWithWhichProviders",
              title: "2. Which of the following key providers/health care settings does your clinic electronically exchange clinical information with? (Select all that apply)",
              isRequired: true,
              choices: [
                "a. Hospitals/Emergency Rooms",
                "b. Specialty Clinicians",
                "c. Other Primary Care Providers",
                "d. Labs or Imaging",
                "e. Health Information Exchange",
              ],
              showNoneItem: true,
              noneText: "f. None of the Above",
              showOtherItem: true,
              otherText: "g. Other (please describe)",
            },
            {
              type: "checkbox",
              name: "doesEngagePatientsThroughWhichHealthIT",
              title: "3. Does your clinic engage patients through health IT in any of the following ways? (Select all that apply)",
              isRequired: true,
              choices: [
                "a. Patient Portals",
                "b. Kiosks",
                "c. Secure Messaging",
              ],
              showNoneItem: true,
              noneText: "d. No, we do not engage patients using health IT",
              showOtherItem: true,
              otherText: "e. Other (please describe)",
            },
            {
              type: "radiogroup",
              name: "howIsDataCollectedForNDH",
              title: "4. How do you collect data for reporting to the Network Data Hub (previously referred to as QRS)?",
              isRequired: true,
              choices: [
                "a. We use the EHR to extract automated reports.",
                "b. We use the EHR but only to access individual patient charts.",
                "c. We use the EHR in combination with another data analytic system.",
                "d. We do not use the EHR."
              ],
            },
            {
              type: "checkbox",
              name: "howIsHealthITandEhrUtilized",
              title: "5. How does your clinic utilize health IT and EHR data beyond direct patient care? (Select all that apply)",
              isRequired: true,
              choices: [
                "a. Quality Improvement",
                "b. Population Health Management",
                "c. Program Evaluation",
                "d. Research",
              ],
              showNoneItem: true,
              noneText: "e. We do not utilize health IT and EHR data beyond direct patient care",
              showOtherItem: true,
              otherText: "f. Other (please describe)",
            },
            {
              type: "radiogroup",
              name: "doesCollectSocialRiskFactorData",
              title: "6. Does your clinic collect data on individual patients' social risk factors, outside of the data reportable in this system?",
              isRequired: true,
              choices: [
                "a. Yes",
                "b. No, but in planning stages to collect this information",
                "c. No, not planning to collect this information"
              ],
              popupdescription: `Clinics should respond “a. Yes.” if they are screening for social risks, meaning they have a consistent set of questions that are asked of individual patients uniformly for the purposes of collecting information on the non-medical, health-related social needs of patients, such as housing instability and/or food insecurity, beyond those demographic patient characteristics captured elsewhere. Otherwise, please select option (b) or (c) as appropriate.`
            },
            {
              type: "text",
              name: "patientsScreenedForSocialRisk",
              title: "6a. How many clinic patients were screened for social risk factors using a standardized screener during the calendar year?",
              visibleIf: "{doesCollectSocialRiskFactorData} = 'a. Yes'",
              inputType: "number",
              min: 0,
              validators: [{
                type: "regex",
                text: "Value must be a whole number",
                regex: "^\\d+$"
              }]
            },
            {
              type: "checkbox",
              name: "whichStandardizedScreenersAreUsed",
              title: "7. Which Standardized screener(s) for social risk factors, if any, do you use? (select all that apply)",
              visibleIf: "{doesCollectSocialRiskFactorData} = 'a. Yes'",
              choices: [
                "a. Accountable Health Communities Screening Guide",
                "b. Upstream risks Screening Tool and Guide",
                "c. iHELP",
                "d. Recommended Social and Behavioral Domains for EHRs",
                "e. Protocol for Responding to and Assessing Patients' Assets, Risks and Experiences (PRAPARE)",
                "f. Well Child Care, Evaluation, Community Resources, Advocacy Referral, Education (WE CARE)",
                "g. WellRx",
                "h. Health Leads Screening Toolkit",
              ],
              showNoneItem: true,
              noneText: "i. Do not use a standardized screener",
              showOtherItem: true,
              otherText: "j. Other (please describe)",
              popupdescription: `Use of a standardized screener is typically full use of the tool as designed with the understanding that all data elements may not be collected for all patients at a single encounter. If you are using a modified (e.g. shortened or customized) version of a standardized tool, please select the standardized tool and select Other to note the modifications to the tool.`,
            },
            {
              type: "multipletext",
              name: "totalPositiveScreenings",
              title: "7a. Please provide the total number of patients that screened positive for the following:",
              visibleIf: "{doesCollectSocialRiskFactorData} = 'a. Yes'",
              items: [
                {
                  name: "foodInsecurity",
                  title: "a. Food Insecurity",
                  inputType: "number",
                  validators: [
                    {
                      type: "numeric",
                      text: "Value cannot be less than 0",
                      minValue: 0,
                    },
                    {
                      type: "regex",
                      text: "Value must be a whole number",
                      regex: "^\\d+$"
                    }
                  ]
                },
                {
                  name: "housingInsecurity",
                  title: "b. Housing Insecurity",
                  inputType: "number",
                  validators: [
                    {
                      type: "numeric",
                      text: "Value cannot be less than 0",
                      minValue: 0,
                    },
                    {
                      type: "regex",
                      text: "Value must be a whole number",
                      regex: "^\\d+$"
                    }
                  ]
                },
                {
                  name: "financialStrain",
                  title: "c. Financial strain",
                  inputType: "number",
                  validators: [
                    {
                      type: "numeric",
                      text: "Value cannot be less than 0",
                      minValue: 0,
                    },
                    {
                      type: "regex",
                      text: "Value must be a whole number",
                      regex: "^\\d+$"
                    }
                  ]
                },
                {
                  name: "lackOfTransportation",
                  title: "d. Lack of transportation / access to public transportation",
                  inputType: "number",
                  validators: [
                    {
                      type: "numeric",
                      text: "Value cannot be less than 0",
                      minValue: 0,
                    },
                    {
                      type: "regex",
                      text: "Value must be a whole number",
                      regex: "^\\d+$"
                    }
                  ]
                }
              ],
            },
            {
              type: "checkbox",
              name: "reasonNotUsingStandardizedAssessment",
              title: "7b. If you do not use a standardized assessment to collect this information, please comment why. (Select all that apply)",
              visibleIf: "{doesCollectSocialRiskFactorData} <> 'a. Yes'",
              isRequired: true,
              choices: [
                "a. Have not considered/unfamiliar with assessments",
                "b. Lack of funding for addressing these unmet social needs of patients",
                "c. Lack of training for staff to discuss these issues with patients",
                "d. Recommended Social and Behavioral Domains for EHRs",
                "e. Inability to include in patient intake and clinical workflow",
                "f. Not Needed",
              ],
              showOtherItem: true,
              otherText: "g. Other (please describe)",
            },
            {
              type: "radiogroup",
              name: "doesUsePDMPdb",
              title: "8. Does your clinic integrate a statewide Prescription Drug Monitoring Program (PDMP) database into the health information systems such as Health Information Exhchanges, Electronic Health Record (EHR) systems; and/or Pharmacy Dispensing Software (PDS) to streamline provider access to controlled substance prescriptions?",
              choices: [
                "a. Yes",
                "b. No",
                "c. Not sure"
              ],
              popupdescription: `K-TRACS is the Prescription Drug Monitoring Program in Kansas. Clinics may also connect to APPRISS, which is a multi-state PDMP.`,
            },
          ]
        },
      ],
    },
    {
      name: "OtherDataElements",
      navigationTitle: "Other Data Elements",
      navigationDescription: "OtherDataElements",
      elements: [
        {
          type: "panel",
          name: "matForOpioidUseDisorder",
          title: "1. Medication-Assisted Treatment (MAT) for Opioid Use Disorder",
          description: "Enter non-negative integers",
          elements: [
            {
              type: "text",
              inputType: "number",
              name: "totalProvidersWithDATAWaiver",
              title: "a. How many qualified providers, on-site, or with whom the clinic has contracts, had obtained a Drug Addiction Treatment Act of 2000 (DATA) waiver to treat opioid use disorder with medications specifically proposed by the U.S. Food and Drug Administration (FDA) for that indication during the measurement period?",
              min: 0,
              validators: [{
                type: "regex",
                text: "Value must be a whole number",
                regex: "^\\d+$"
              }]
            },
            {
              type: "text",
              inputType: "number",
              name: "totalPatientsThatReceivedMATByProviderWithDATAWaiver",
              title: "b. How many unduplicated patients received medication-assisted tratment such as ICD-10 F11.xx for opioid use disorder from a qualified provider with a DATA waiver during the measurement period?",
              min: 0,
              validators: [{
                type: "regex",
                text: "Value must be a whole number",
                regex: "^\\d+$"
              }],
              popupdescription: `Medication-Assisted Treatment codes include, but may not be limited to, ICD-10 F11.xx.`,
            },
          ]
        },
        {
          type: "panel",
          name: "telehealth",
          title: "Telehealth",
          elements: [
            {
              type: "radiogroup",
              name: "didUseTelehealth",
              title: "2. Did your organization use telehealth in order to provide remote clinical services?",
              isRequired: true,
              choices: [
                "a. Yes",
                "b. No"
              ],
              popupdescription: `The term “telehealth” includes “telemedicine” services, but encompasses a broader scope of remote healthcare services. Telemedicine is specific to remote clinical services, whereas telehealth may include remote non-clinical services, such as provider training, administrative meetings, and continuing medical education, inaddition to clinical services. For more information about telehealth, please go to <a href="https://telehealth.hhs.gov/patients/understanding-telehealth/">https://telehealth.hhs.gov/patients/understanding-telehealth/</a>.`
            },
            {
              type: "checkbox",
              name: "whoDidYouCommunicateWithViaTelehealth",
              title: "2a1. Who did you use telehealth to communicate with? (Select all that apply)",
              visibleIf: "{didUseTelehealth} = 'a. Yes'",
              choices: [
                "Patients at remote locations from your organization (e.g., home telehealth, satellite locations)",
                "Specialists outside your organization (e.g., specialists at referral centers)"
              ],
            },
            {
              type: "checkbox",
              name: "whatTelehealthTechUsed",
              title: "2a2. What telehealth technologies do you use? (Select all that apply)",
              visibleIf: "{didUseTelehealth} = 'a. Yes'",
              choices: [
                "Real time telehalth (e.g., video conference)",
                "Store-and-forward telehealth (e.g., secure email with photos or videos of patient examinations)",
                "Remote Patient monitoring",
                "Mobile health (mHealth)",
              ]
            },
            {
              type: "checkbox",
              name: "whatPrimaryHealthServicesUsed",
              title: "2a3. What primary telehealth services were used at your organization? (Select all that apply)",
              visibleIf: "{didUseTelehealth} = 'a. Yes'",
              choices: [
                "Primary Care",
                "Oral Health",
                "Behavioral health: Mental health",
                "Behavioral health: Substance use disorder",
                "Dermatology",
                "Chronic Conditions",
                "Disaster Management",
                "Consumer and professional health education",
                "Provider-to-provider consultation",
                "Radiology",
                "Nutrition and dietary counseling",
              ],
              showOtherItem: true,
              otherText: "Other (please describe)"
            },
            {
              type: "checkbox",
              name: "reasonNoTelehealthUsed",
              title: "2b. If you did not have telehealth services, please comment why. (Select all that apply)",
              visibleIf: "{didUseTelehealth} = 'b. No'",
              choices: [
                "Have not considered/unfamiliar with telehealth service options",
                "Policy barriers",
                "Inadequate broadband/telecommunication service",
                "Lack of funding for telehealth equipment",
                "Lack of training for telehealth services",
                "Not needed",
              ],
              showOtherItem: true,
              otherText: "Other (please describe)"
            },
            {
              type: "checkbox",
              name: "policyBarriers",
              title: "Policy Barriers (Select all that apply)",
              visibleIf: "{didUseTelehealth} = 'b. No' and {reasonNoTelehealthUsed} contains 'Policy barriers'",
              choices: [
                "Lack of or limited reimbursement",
                "Credentialing, licensing, or privileging",
                "Privacy and security",
              ],
              showOtherItem: true,
              otherText: "Other (please describe)"
            },
            {
              type: "checkbox",
              name: "inadequateTelecommunicationService",
              title: "Inadequate broadband/telecommunication service (Select all that apply)",
              visibleIf: "{didUseTelehealth} = 'b. No' and {reasonNoTelehealthUsed} contains 'Inadequate broadband/telecommunication service'",
              choices: [
                "Cost of service",
                "Lack of infrastructure",
              ],
              showOtherItem: true,
              otherText: "Other (please describe)"
            },
          ]
        },
        {
          type: "panel",
          name: "outreachAndEnrollmentAssistance",
          title: "Outreach and Enrollment Assistance",
          description: "Enter non-negative integer",
          elements: [
            {
              type: "multipletext",
              name: "totalAssistsByTrainedAssisters",
              title: "3. Provide the number of all assists provided during the past year by all trained assisters (e.g., certified application counselor or equivalent) working on behalf of the clinic (employees, contractors, or volunteers), regardless of the funding source that is supporting the assisters' activities. Outreach and enrollment assists are defined as customizable education sessions about affordable health insurance coverage options (one-on-one or small group) and any other assistance provided by a clinic assister to facilitate enrollment.",
              popupdescription: `<b>Important Note:</b> According to HRSA's Outreach and Enrollment Assists Reporting at <a href="https://bphc.hrsa.gov/data-reporting/uds-training-and-technical-assistance/uds-novel-coronavirus-disease-covid-19-reporting/outreach-enrollment-assists-reporting">https://bphc.hrsa.gov/data-reporting/uds-training-and-technical-assistance/uds-novel-coronavirus-disease-covid-19-reporting/outreach-enrollment-assists-reporting</a>, "Assists reported should be limited to those provided to health center patients (i.e., individuals who had at least one UDS countable visit during the reporting year) and any family members who will benefit from that O/E assistance. In these cases where a health center patient is representing other family members, include the patient and each represented family member in the count." This is a departure from HRSA's historic stance that <b>all</b> assists should be entered; the new guidance is not reflected in this question in the 2022 HRSA UDS Manual. `,
              items: [
                {
                  name: "totalAssists",
                  title: "Enter Number of Assists",
                  inputType: "number",
                  validators: [
                    {
                      type: "numeric",
                      text: "Value cannot be less than 0",
                      minValue: 0,
                    },
                    {
                      type: "regex",
                      text: "Value must be a whole number",
                      regex: "^\\d+$"
                    }
                  ]
                }
              ]
            },
            {
              type: "multipletext",
              name: "totalPatientsThatReceivedCOVID19Vaccine",
              title: "4. How many patients received a FDA-approved COVID-19 vaccine during the calendar year at your clinic?",
              popupdescription: `COVID-19 vaccine CPT-I codes: 0001A-0004A, 0011A- 0014A, 0021A-0024A, 0031A-0034A, 0041A-0044A, 0051A-0054A, 0064A, 0071A, 0072A, 91300-91307, 91308-91310`,
              items: [
                {
                  name: "totalAssists",
                  title: "Enter Number of Patients",
                  inputType: "number",
                  validators: [
                    {
                      type: "numeric",
                      text: "Value cannot be less than 0",
                      minValue: 0,
                    },
                    {
                      type: "regex",
                      text: "Value must be a whole number",
                      regex: "^\\d+$"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          type: "panel",
          name: "COVID19",
          title: "COVID-19",
          description: "Count regardless of primacy. For example, if a patient has pneumonia confirmed due to COVID-19, both COVID-19 and pneumonia are documented in the medical record and the coding for COVID-19 would be reflected.",
          elements: [
            {
              type: "text",
              name: "totalVisitsWithICD10Coded",
              title: "Number of visits at which selected ICD-10 codes for COVID-19 have been coded.",
              inputType: "number",
              min: 0,
              popupdescription: `COVID-19 diagnosis ICD-10 code: U07.01`,
            },
            {
              type: "text",
              name: "totalPatientsWithMoreThanOneCOVID19Coding",
              title: "Number of patients who have had one or more visits where COVID-19 has been coded.",
              inputType: "number",
              min: 0,
              popupdescription: `COVID-19 diagnosis ICD-10 code: U07.01`
            },
            {
              type: "text",
              name: "totalVisitsWithCOVID19TestPerformed",
              title: "Number of visits at which COVID-19 testing occurred for clinic patients.",
              inputType: "number",
              min: 0,
              popupdescription: `COVID-19 diagnostic testing codes: CPT-4: 87426, 87428, 87635, 87636, 87637; HCPCS: U0001, U0002, U0003, U0004; and CPT PLA: 0202U, 0223U, 0225U, 0240U, 0241U
              COVID-19 antibody testing codes: CPT-4: 86318, 86328, 86408, 86409, 86413, 86769; and CPT PLA: 0224U, 0226U`
            },
            {
              type: "text",
              name: "totalPatientsWithMoreThanOneCOVID19Test",
              title: "Number of patients who have had one or more visits where COVID-19 tests occurred.",
              inputType: "number",
              min: 0,
              popupdescription: `COVID-19 diagnostic testing codes: CPT-4: 87426, 87428, 87635, 87636, 87637; HCPCS: U0001, U0002, U0003, U0004; and CPT PLA: 0202U, 0223U, 0225U, 0240U, 0241U
              COVID-19 antibody testing codes: CPT-4: 86318, 86328, 86408, 86409, 86413, 86769; and CPT PLA: 0224U, 0226U`
            },
            {
              type: "comment",
              name: "COVID19Notes",
              title: "Notes",
              titleLocation: "left"
            },
          ]
        }
      ],
    },
  ],
};
