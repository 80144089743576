import { ClinicSurveyComponent } from "components/Survey/ClinicSurveyComponent";
import React, { useState, useEffect } from "react";
import { dataMeasuresJSON } from "../../utilities/surveys/dataMeasuresJSON";
import { apiGetCurrentSurveyTemplate } from "utilities";

export const DataMeasures = (props) => {
  const surveyName = 'DataMeasures';
  const [surveyJSON, setSurveyJSON] = useState();
  const [surveyVersion, setSurveyVersion] = useState(0);

  useEffect(() => {
    apiGetCurrentSurveyTemplate(surveyName,dataMeasuresJSON).then((response)=>
    {
      if(response){
        setSurveyJSON(response.template);
        setSurveyVersion(response.version);
      }
    })
  }, []);

  return (
    <>
    {surveyJSON &&
    <ClinicSurveyComponent
      json={surveyJSON}
      SurveyName={surveyName}
      version={surveyVersion}
    />
    }
    </>
  );
};
