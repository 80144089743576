import React from "react";
import * as ReactDOM from "react-dom";
import "survey-core/defaultV2.min.css";
import { StylesManager, Model, FunctionFactory, Serializer } from "survey-core";
import { Survey } from "survey-react-ui";
import Inputmask from "inputmask";
import * as SurveyCore from "survey-core";
// @ts-ignore
import * as widgets from "surveyjs-widgets";

import { Button } from "reactstrap";
import {
  formatAsUSDollar,
  getSavedDataEntries,
  roundTo,
} from "../../utilities/surveys/functions";
import "./CoverKansasSurveyComponent.scss";
import { SurveyPDF } from "survey-pdf"
import { ApiEndpoints, apiClient } from "utilities";

// ===============================
// 'READ MORE' POP-UP DESCRIPTION
// ===============================
Serializer.addProperty("question", "popupdescription:text");

// Toggle function to show 'popupdescription'
function showReadMore(element) {
  document.getElementById("questionDescriptionText").innerHTML =
    element.popupdescription;
  var popupEl = document.getElementById("questionDescriptionPopup");
  document.getElementById("questionDescriptionPopupClose").onclick = () => {
    // @ts-ignore
    popupEl.close();
  };
  // @ts-ignore
  popupEl.showModal();
}


async function lookupNavigatorById (id) {
  const requestURL = ApiEndpoints.AccountManagement.Users.GetById.replace("{id}", id);
  const user = await apiClient.get(requestURL);

  return user;
}

// =============
// "INPUT MASK"
// For more information, see: https://surveyjs.io/form-library/examples/control-data-entry-formats-with-input-masks/jquery#content-docs
// =============
// @ts-ignore
widgets.inputmask(SurveyCore);

// =============
// "CUSTOM FUNCTIONS"
// =============
FunctionFactory.Instance.register("roundTo", roundTo);
FunctionFactory.Instance.register("formatAsUSDollar", formatAsUSDollar);
FunctionFactory.Instance.register("getSavedDataEntries", getSavedDataEntries);
FunctionFactory.Instance.register("lookupNavigatorById", lookupNavigatorById, true);

export const CoverKansasSurveyComponent = ({
  json,
  handleSubmit,
}) => {
  StylesManager.applyTheme("defaultV2");
  var defaultThemeColors = StylesManager.ThemeColors["default"];
  defaultThemeColors["$main-color"] = "#7ff07f";
  defaultThemeColors["$main-hover-color"] = "#6fe06f";
  defaultThemeColors["$text-color"] = "#4a4a4a";
  defaultThemeColors["$header-color"] = "#7ff07f";
  defaultThemeColors["$header-background-color"] = "#4a4a4a";
  defaultThemeColors["$body-container-background-color"] = "#f8f8f8";
  StylesManager.applyTheme("defaultV2");

  const exportToPdfOptions = { fontSize: 12, fontName: 'Helvetica', fontStyle:'normal'};

  const savePdf = function (surveyData) {
    const surveyPdf = new SurveyPDF(json, exportToPdfOptions);
    surveyPdf.data = surveyData;
    surveyPdf.haveCommercialLicense = true;
    surveyPdf.save();
  };

  var consumerConsentSection = 
  {
      type: "panel",
      name: "consumerConsentPanel",
      title: "",
      elements: [
      {
        type: "html",
        html: "<div> <h5><u> Consumer Consent Form Upload </u> </h5> <h6>Use \"Save as PDF\" link to securely download consumer consent forms that you have collected</h6></div>",
      }
    ]
  };  

if(json.pages[0].elements.find(a=> a?.name=== 'consumerConsentPanel') === undefined)
json.pages[0].elements= [...json.pages[0].elements , consumerConsentSection];



  const surveyModel = new Model(json);

  surveyModel.addNavigationItem({
    id: "pdf-export",
    title: "Save as PDF",
    action: () => savePdf(surveyModel.data)
  });

  // @ts-ignore
  surveyModel.onAfterRenderQuestion.add(function (survey, options) {
    // Do nothing if a question contains no description to show in a modal popup
    if (!options.question.popupdescription) return;
    // Create a 'More Info' button to invoke a modal popup
    var container = document.createElement("span");
    var question = options.question;
    ReactDOM.render(
      <Button
        color="link"
        size="sm"
        type="button"
        className="dialogBox-btn ms-2"
        onClick={function () {
          showReadMore(question);
        }}
      >
        More Info
      </Button>,
      container
    );
    //Insert the created 'More Info' button into the rendered question's header
    var titleSpan = options.htmlElement.querySelector(
      "span.sv-title-actions__title"
    );
    if (!titleSpan) titleSpan = options.htmlElement;
    titleSpan.appendChild(container);
  });

    // @ts-ignore
  surveyModel.onComplete.add(handleSubmit);

  return (
    <>
      <dialog id="questionDescriptionPopup" className="dialogBox">
        <div className="dialogBox-header">
          <h4>More Info</h4>
        </div>
        <div className="dialogBox-body">
          <div id="questionDescriptionText"></div>
        </div>
        <div id="questionDescriptionPopupClose" className="dialogBox-footer">
          <button
            type="button"
            className="dialogBox-close btn-primary pill-btn"
          >
            Close
          </button>
        </div>
      </dialog>
      <Survey model={surveyModel} className="data-entry-survey" />
    </>
  );
};
