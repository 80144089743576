import React, { useState } from 'react';
import { Row, Col, Button, ButtonGroup } from 'reactstrap';
import './Clinic.scss';
import { Link } from 'react-router-dom';
import { SmartGrid } from 'components/smart-grid';
import { ColDisplayConfig } from "models";
import { apiClient, ApplicationPermissions } from '../../../utilities';
import { useUserContext } from 'contexts';
import { ClinicDeactivationRequests } from './ClinicDeactivationRequests';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

export const Clinic = () => {
  const [refreshGrid, setRefreshGrid] = useState(false);
  const userContext = useUserContext();
  const user = userContext.user;
  const canView = userContext?.hasPermission(ApplicationPermissions.AccountManagement.ViewClinics);
  const canCreate = userContext?.hasPermission(ApplicationPermissions.AccountManagement.CreateClinics);
  const canUpdate = userContext?.hasPermission(ApplicationPermissions.AccountManagement.UpdateClinics);
  const canDelete = userContext?.hasPermission(ApplicationPermissions.AccountManagement.DeleteClinics);
  const canRequestDeactivation = userContext?.hasPermission(ApplicationPermissions.ClinicManagement.RequestClinicDeactivation);
    const canApproveDeactivation = userContext?.hasPermission(ApplicationPermissions.ClinicManagement.ApproveClinicDeactivation);

  const displayStatus = (row) => {
    return row.active? 'Active' : 'Inactive';
  }

  const displayContacts = (row, i, setRow) => {
    let value = ""
    if(row.contacts.length > 0)
      value = row.contacts.map(function(item) {
        return `${item['firstName']} ${item['lastName']}`;
      }).join(', ');

    return value
  }

  const linkClinicCell = (row) => {
    return row.name
  };

  const onDeleteClick = (row) => {
    trackPromise(apiClient.post(`Clinic/RequestDeactivationAsync`,
    {
      toastConfig:{
        showSuccess: true,
        successMessage: `A request was submitted to deactivate ${row.name}`
      },
      ClinicToRemoveId: row.id,
      RequestingUserId: user?.username
    }
    )
    .then((response) => {
      toast.success(`A request was submitted to deactivate ${row.name}`);
      setRefreshGrid(true);
    }));
  }

  const deleteClinicCell = (row) => {
    return (
    <ButtonGroup className="role-actions-button-group">
      {canUpdate &&
        <Link to={{
            pathname:"/AccountManagement/ClinicAdd",
            state : { clinicUpdate: row }
          }}>
          <Button>Update</Button>
        </Link>
      }
      {canRequestDeactivation && 
        <Button color='danger' className="btn btn-danger" onClick={() => onDeleteClick(row)}>
          Deactivate
        </Button>
      }
  </ButtonGroup>
  )}

  return (
    <>
      <h3 className='primary title'>Clinics</h3>
      {canCreate && 
        <Row>
          <Col className ='col-sm-10'>
            <form>
              <Link to="/AccountManagement/ClinicAdd">
                <Button
                  color="primary"
                  className="mt-3 pill-btn"
                  outline
                >
                  Add Clinic
                </Button>
              </Link>
            </form>
          </Col>
        </Row>
      }
      {canView &&
        <Row>
          <SmartGrid
            url={`${process.env.REACT_APP_API_BASE_URL}Clinic/GetAllAsync`}
            columnConfig={[
              new ColDisplayConfig("name", "Name", true, true, linkClinicCell),
              new ColDisplayConfig("contacts", "Contacts", true, true, displayContacts),
              new ColDisplayConfig("active", "Status", true, true, displayStatus),
              new ColDisplayConfig("", "", false, false, deleteClinicCell)
            ]}
            pageSize={50}
            pageable
            refreshGrid={refreshGrid}
          />
        </Row>
      }
      { canApproveDeactivation &&
            <Row>
              <ClinicDeactivationRequests shouldRefresh={refreshGrid} />
            </Row>
          }
    </>
  );
};