import React from "react";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { apiClient } from "utilities/api";
import { trackPromise } from "react-promise-tracker";

export const SurveyCreatorWidget = ({ surveyName, template }) => {
  
  const creatorOptions = {
    showLogicTab: false,
    showJSONEditorTab: true,
    isAutoSave: false,
    haveCommercialLicense: true,
  }
  const insertData = async (data) => {

    const url = "SurveyTemplate";
    await trackPromise(apiClient
      .put(url, data, {
        // @ts-ignore
        toastConfig: {
          showSuccess: true,
          successMessage: "Template saved successfully",
        },
      })
      .then(
        () => {
          console.log("");
        } 
      )
      .catch((error) => console.log(error.response.request._response)));
  };

  const creator = new SurveyCreator(creatorOptions);
  creator.JSON = template;
  creator.saveSurveyFunc = () => {
    const nextVersion = new Date().valueOf();
    const nextTemplate = {
      version: nextVersion,
      template: JSON.stringify(creator.JSON),
      templateName: surveyName
    }
    insertData(nextTemplate);
  };

  return (
    <div className="widget-container">
      <SurveyCreatorComponent creator={creator}></SurveyCreatorComponent>
    </div>
  );
};
