import React, { useEffect, useState } from 'react';
import { Row, Button, ButtonGroup } from 'reactstrap';
import { SmartGrid } from 'components/smart-grid';
import { ColDisplayConfig } from "models";
import { apiClient } from '../../../utilities';
import { trackPromise } from 'react-promise-tracker';

export const DeactivationRequests = ({shouldRefresh}) => {

  const [needsRefresh, setNeedsRefresh] = useState(shouldRefresh);

  useEffect(() => {
    if(shouldRefresh && shouldRefresh !== needsRefresh){
      setNeedsRefresh(shouldRefresh);
    }
  }, [shouldRefresh])

  const onApproveClick = (row) => {
    var data = {
      DeActivateUserRequestId: row.id,
      RespondingUserId: row.requestingUser.id,
      Status: 1,
      toastConfig:{
        showSuccess: true,
        successMessage: `${row.userToDeactivate?.email} was successfully deactivated!`
      }
    };
    trackPromise(apiClient.post(`${process.env.REACT_APP_API_BASE_URL}User/ProcessDeactivationRequestAsync`, data)
    .then((response) => {
      setNeedsRefresh(true);
    }));
  }

  const onDenyClick = (row) => {
        
    var data = {
      deActivateUserRequestId: row.id,
      respondingUserId: row.requestingUser.id,
      status: 2,
    };

    trackPromise(apiClient.post(`User/ProcessDeactivationRequestAsync`, 
      data
    ).then((response) => {
      setNeedsRefresh(true);
    }));
  }
  
  const firstNameCell = (row) => {
    return row.userToDeactivate?.firstName;
  }

  const lastNameCell = (row) => {
      return row.userToDeactivate?.lastName;
    }

    const emailCell = (row) => {
      return row.userToDeactivate?.email;
    }

    const actionsCell = (row) => {
      return (
      <ButtonGroup className="role-actions-button-group">
          <Button className="btn" onClick={() => onDenyClick(row)}>
            Deny
          </Button>
          <Button color='danger' className="btn btn-danger" onClick={() => onApproveClick(row)}>
            Approve
          </Button>
    </ButtonGroup>
    )}
  

    const [columnConfig] = useState([
        new ColDisplayConfig("firstName", "First Name", false, false, firstNameCell),
        new ColDisplayConfig("lastName", "Last Name", false, false, lastNameCell),
        new ColDisplayConfig("email", "Email", false, false, emailCell),
        new ColDisplayConfig("", "", false, false, actionsCell)
      ]);

      return (

        <div className="col-md-12">
          <h3 className='title'>Deactivate User Requests</h3>
          <Row>
            <SmartGrid
              url={`${process.env.REACT_APP_API_BASE_URL}User/GetDeactivationRequests`}
              columnConfig={columnConfig}
              pageSize={50}
              pageable
              refreshGrid={needsRefresh}
            />
          </Row>
        </div>
        
      );
};