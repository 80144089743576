import React, { useState, useEffect } from 'react';
import { Label, Input, Row, Col , Form, FormGroup, Button} from 'reactstrap';
import './Role.scss';
import { apiClient, ApiRoutes } from '../../../utilities';
import { useForm } from "react-hook-form";
import { useLocation, useHistory, Link  } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';

// Accepts the array and key
const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
      }, {}); // empty object is the initial value for result object
};

const config = {
  headers:{
    'Content-Type': 'application/json',
  }
};

const calculateSectionHeight = (contentCount) => {
  return `${Math.ceil(contentCount * 1.5)}em`;
}

export const RoleAdd = () => {

  const updateChecked = (permissionList) => {
    var updatedList = [];
    permissionList.forEach( e => {
      updatedList = [...updatedList, e.key];
    })
    return updatedList;
  };
    // Add/Remove checked item from list
  const handleCheck = (event) => {
    var updatedList = [...checked];
    var permission = parseInt(event.currentTarget.id.replace('check-', ''));
    if (event.currentTarget.checked) {
      updatedList = [...updatedList, permission];
    } else {
      updatedList.splice(checked.indexOf(permission), 1);
    }
    setChecked(updatedList);
  };

  const [permissions, setPermissions] = useState([]);
  const {handleSubmit} = useForm();
  const [roleName, setRoleName] = useState('');

  const location = useLocation();
  const history = useHistory();
  const updateRole = location.state?.roleUpdate;

  const [isEditMode, setIsEditMode] = useState(updateRole?true:false);
  const [checked, setChecked] = useState(isEditMode?updateChecked(updateRole.applicationPermissions):[]);

  const onRoleChange = (e) => {
    setRoleName(e.target.value);
  }

  useEffect(() => {
    trackPromise(apiClient.get("/Role/permissions/").then((response) => {
        setPermissions(groupBy(response.data,'groupName'));
        if(isEditMode)
          setRoleName(updateRole.name)
      }).catch( resp=> {
        setPermissions([]);
      }));
      }, []);

    const insertData = async (event) => {
      const data = {
        Name: roleName,
        ApplicationPermissions: checked,
      };
        await trackPromise(apiClient.put("/Role/", data ,{
          // @ts-ignore
          toastConfig:{
            showSuccess: true,
            successMessage: isEditMode ? "Role updated successfully" : "Role created successfully"
          }
        }
        ).then(
          () => history.push('/AccountManagement/Role/')
        ).catch(
          (error) => console.log( error.response.request._response ) ));
      };

    return (
      <div>
        <p className='primary title'>{isEditMode ? "Update" : "Add"} Role</p>
        <Form onSubmit={handleSubmit(async (data) => await insertData(data))}>
          <Row>
              <Col>
                <Label for="roleName" className="label">Role Name</Label>
                <Input className="form-control-left"
                    id="roleName"
                    name="roleName"
                    type="text"
                    disabled= {isEditMode}
                    defaultValue= {isEditMode?updateRole.name:''}
                    onChange={onRoleChange}
                />
              </Col>
          </Row>
          <Row>
            {Object.keys(permissions)?.map((group) =>{
                return (
                  <div key={`div-${group}`}>
                    <Label className='label mt-3' key={`label-${group}`}>{group}</Label>
                    <div className="d-flex flex-wrap flex-column"  style={{maxHeight: calculateSectionHeight(group.length)}}>
                      {permissions[group]?.map((permission) => {
                      return (
                        <div key={`div-${permission.name}-${permission.key}`} className="align-items-baseline">
                          <Input
                            type="checkbox"
                            className="check-input m-2"
                            id={`check-${permission.key}`}
                            key ={`check-${permission.name}`}
                            defaultChecked = {updateRole?.applicationPermissions.find((e)=>{return e.key === permission.key})}
                            onChange={handleCheck} />
                          <Label
                            className="label"
                            for={`check-${permission.name}`}
                            key ={`label-${permission.name}`}>
                            {permission.description}
                          </Label>
                        </div>
                      )
                      })}
                    </div>
                    <hr />
                  </div>
                  )
              })
            }
          </Row>
          <FormGroup className={"mt-5 d-flex justify-content-end"}>
            <Link
              to={{
                pathname: ApiRoutes.AccountManagement.Roles.View,
              }}
            >
              <Button className={"mx-2"} color="secondary">
                Cancel
              </Button>
            </Link>
            <Button className={"mx-2"} color="primary" type="submit">
              { isEditMode ? 'Update Role' : 'Create Role' }
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
}
