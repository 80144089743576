export const sideMenuItems =
{
    AccountManagement: {
        title: 'Account Management',
        items: [
            {
                title: "Clinics",
                target: "/AccountManagement/Clinic",
            },
            {
                title: "User Accounts",
                target: "/AccountManagement/Users",
            },
            {
                title: "Roles",
                target: "/AccountManagement/Role",
            },
            {
                title: "Notifications",
                target: "/AccountManagement/Notifications",
            },
        ]
    },
    Clinics:
    {
        title: 'Clinics',
        items: [
            {
                title: "Clinics",
                target: "Clinics/Clinic",
            },
        ],
    },
    ClinicDetails:
    {
        title: 'Clinic Details',
        items: [
            {
                title: "Clinic Information",
                target: "/ClinicManagement/ClinicDetails#ClinicInformation",
            },
            {
                title: "Active Accounts",
                target: "/ClinicManagement/ClinicDetails#ActiveAccounts",
            },
        ],
    },
    Review:
    {
        title: 'Review',
        target: '/ClinicManagement/Review',
        items: [
            {
                title: "Clinic Details",
                target: "/ClinicManagement/Review#ClinicDetails",
            },
            {
                title: "Patient Data",
                target: "/ClinicManagement/Review#PatientData",
            },
            {
                title: "Staffing And Utilization",
                target: "/ClinicManagement/Review#StaffingAndUtilization",
            },
            {
                title: "Clinical Measures",
                target: "/ClinicManagement/Review#ClinicalMeasures",
            },
            {
                title: "Financial Measures",
                target: "/ClinicManagement/Review#FinancialMeasures",
            },
            {
                title: "Data Measures",
                target: "/ClinicManagement/Review#DataMeasures",
            },
            {
                title: "Data Submission",
                target: "/ClinicManagement/Review#DataSubmission",
            },
        ]
    },
}