import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, ButtonGroup } from "reactstrap";
import "./Notifications.scss";
import { Link, useHistory } from "react-router-dom";
import { SmartGrid } from "components/smart-grid";
import { ColDisplayConfig } from "models";
import { ApiEndpoints, ApiRoutes, ApplicationPermissions } from "utilities";
import { apiClient } from "../../../utilities";
import { useUserContext } from "contexts";
import moment from "moment/moment";
import { trackPromise } from "react-promise-tracker";

export const Notifications = () => {
  const user = useUserContext();
  const { userID } = user?.user || {"user":{"userID":undefined}};
  const canView = user?.hasPermission(ApplicationPermissions.AccountManagement.ViewNotifications);
  const canCreate = user?.hasPermission(ApplicationPermissions.AccountManagement.CreateNotifications);
  const canUpdate = user?.hasPermission(ApplicationPermissions.AccountManagement.UpdateNotifications);
  const canDelete = user?.hasPermission(ApplicationPermissions.AccountManagement.DeleteNotifications);
  const [notifications, setNotifications] = useState([]);
  const [refreshGrid, setRefreshGrid] = useState(false);

  const displayNotificationType = (row, i) => {
    return row['type'];
  }
  const displayMessageBody = (row, i) => {
    return row['messageBody'];
  }
  const displayStartDate = (row, i) => {
    return moment(row['startDate']).format("MM/DD/YYYY, hh:mm A");
  }

  const displayEndDate = (row, i) => {
    return row['endDate'] ? moment(row['endDate']).format("MM/DD/YYYY, hh:mm A") : "--";
  }

  const displayStatus = (row, i) => {
    return (moment(row['endDate'])).valueOf() < moment().valueOf() ? 'Expired' : 'Active';
  }

  const onDeleteClick = (notificationId) => {
    trackPromise(apiClient.post(`${ApiEndpoints.AccountManagement.Notifications.Delete}/${notificationId}`, {
      // @ts-ignore
      toastConfig: {
        showSuccess: true,
        successMessage: `Notification deleted successfully!`,
      },
    })
    .then(() => { 
      setRefreshGrid(true) 
    }))
  }
  const manageNotification = (row) => {
    return (
    <ButtonGroup>
      {canUpdate && 
        <Link to={{
          pathname: ApiRoutes.AccountManagement.Notifications.Create,
          state : { notificationsUpdate: row }
        }}>
        <Button>Update</Button>
        </Link>
      }
      {canDelete &&
        <Button color='danger' className="btn btn-danger" onClick={() => onDeleteClick(row.id)}>
          Delete
        </Button>
      }
    </ButtonGroup>
  )}

  useEffect(() => {
    trackPromise(apiClient.get(`${ApiEndpoints.AccountManagement.Notifications.GetAllCreatedBy}/${userID}`)
    .then((response) => {
      setNotifications(response.data);
    }).catch(resp => {
      setNotifications([]);
    }));
  }, [refreshGrid, userID]);

  return (
    <>
      <h3 className="primary title">Notifications</h3>
      <Row>
        <Col className="col-sm-10">
          <form>
            {canCreate &&
            <Link to={ApiRoutes.AccountManagement.Notifications.Create}>
            <Button
                  color="primary"
                  className="mt-3 pill-btn"
                  outline
                >
                  Add Notification
                </Button>
            </Link>
            }
          </form>
        </Col>
      </Row>
        {canView &&
          <Row>
            <SmartGrid
              data={notifications}
              url={`${process.env.REACT_APP_API_BASE_URL}/${ApiEndpoints.AccountManagement.Notifications.GetAllCreatedBy}/${userID}`}
              columnConfig={[
                  new ColDisplayConfig("type", "Type", true, true, displayNotificationType),
                  new ColDisplayConfig("messageBody", "Message", true, true, displayMessageBody),
                  new ColDisplayConfig("status", "Status", true, true, displayStatus),
                  new ColDisplayConfig("startDate", "Starts", true, true, displayStartDate),
                  new ColDisplayConfig("endDate", "Ends", false, false, displayEndDate),
                  new ColDisplayConfig("", "", false, false, manageNotification),
                ]}
              refreshGrid={refreshGrid}
            />
          </Row>
        }
    </>
  );
};
