import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { ApiRoutes } from 'utilities';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
<Redirect
  to={{
    pathname: ApiRoutes.NetworkDataHub,
  }}
/>
    );
  }
}
