import React, { useEffect, useState } from 'react';
import { Container, Alert } from 'reactstrap';
import { NavMenu } from './NavMenu';
import SideBar from './SideBar/SideBar';
import {sideMenuItems} from './../utilities/sideMenuItems'
import {sideMenuItemsNDH} from './../utilities/sideMenuItemsNDH'
import { useLocation } from 'react-router-dom';
import { Footer } from './Footer';
import { apiClient, ApiEndpoints } from 'utilities';
import { useUserContext } from 'contexts';
import { trackPromise} from 'react-promise-tracker';

export const Layout = ({ children }) => {

  const location = useLocation();
  const userID = useUserContext()?.user?.userID ?? null;

  let menuKey = Object.keys(sideMenuItems)?.find( key => location.pathname.indexOf(key) > 0 ) || "";

  if(location.pathname.indexOf('ClinicManagement') > 0 || location.pathname.indexOf('ClinicDataEntry') > 0){
    menuKey = "";
  }

  const [userNotificationsIsOpen, setUserNotificationsIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const toggleUserNotifications = () => {
    setUserNotificationsIsOpen(!userNotificationsIsOpen);
    return userNotificationsIsOpen;
  }

  const handleDismiss = async (event) =>  {
    const id = event.target.parentElement.getAttribute("data-id");
    await trackPromise(apiClient.patch(`${ApiEndpoints.AccountManagement.Notifications.Dismiss}/${id}`)
    .then(() => {
      setNotifications([...notifications?.filter(n => n.id !== id)]);
    }).catch(err => {
      console.error(err)
    }));
  }

  useEffect(() => {
    if (userID) {
      trackPromise(apiClient.get(`${ApiEndpoints.AccountManagement.Notifications.GetByUserId}/${userID}`)
      .then((response) => {
        setNotifications([...response.data]);
      }).catch(err => {
        setNotifications([]);
      }));
    }
}, [userID]);  

  const actionable = notifications.filter(n => n.type === "Actionable");
  const informational = notifications.filter(n => n.type === "Informational");

  return (
    <>
      {/* <GlobalSpinerComponent /> */}
      <NavMenu toggleNotifications={toggleUserNotifications} userNotificationsCount={actionable.length} />
      <div className={`offcanvas ${userNotificationsIsOpen ? "show" : ""} offcanvas-end`} tabIndex={-1} id="offcanvas" aria-labelledby="offcanvasLabel">
        <div className="offcanvas-header border-bottom">
          <h5 className="offcanvas-title" id="offcanvasLabel">Notifications</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={toggleUserNotifications}></button>
        </div>
        <div className="offcanvas-body">
        <ul className="list-group list-group-flush">
        {actionable.map(notification => 
          <li key={notification.id} data-id={notification.id} className="list-group-item py-3 d-flex justify-content-between align-items-center">
            <div>
              {notification.messageBody}
              {notification?.url && 
                <>
                  <p>
                    Go to URL: <a href={notification.url}>{notification.url}</a>
                  </p>
                </>
              }
            </div>
            <button type="button" className="btn-close text-reset btn-sm" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleDismiss}></button>
          </li>
        )}

      </ul>
        </div>
      </div>
      <Container className="main-contanier">
              
        {informational.map(notification => 
          <Alert key={notification.id} id={`notification-${notification.id}`} color="primary" className="alert alert-dismissible fade" role="alert" data-id={notification.id}>
            {notification.messageBody}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleDismiss}></button>
          </Alert>
        )}

      <div className='row'>

        {menuKey && (
          <div className="col-lg-3 col-md-3">
            <SideBar toggle={() => {}} isOpen={true} sideMenuItems={sideMenuItems[menuKey]}/>
          </div>
        )}
        
        <div className={(menuKey !== "" ) ? "col-lg-9 col-md-9" : "col-md-12"}>
          <Container>
            {children}
          </Container>
        </div>
      </div>
      </Container>

      <Footer />
    </>
  );
}
