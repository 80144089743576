
function numeratorDenominatorElements(elementName, description = undefined) {
  return [
    {
      type: "expression",
      title: " ",
      titleLocation: "left",
      popupdescription: description
    },
    {
      type: "text",
      name: `${elementName}_numerator`,
      title: "Numerator",
      inputType: "number",
      width: "400px",
      enableIf: `{${elementName}_nanr} empty`,
      defaultValue: 0,
      min: 0,
      validators: [
        {
          type: "numeric",
          text: "Value cannot be less than 0",
          minValue: 0,
        },
        {
          type: "regex",
          text: "Value must be a whole number",
          regex: "^\\d+$"
        },
        {
          type: "expression",
          text: "Numerator should be less than or equal to Denominator",
          expression: `{${elementName}_numerator} <= {${elementName}_denominator}`,
        }
      ]
    },
    {
      type: "checkbox",
      name: `${elementName}_nanr`,
      title: " ",
      startWithNewLine: false,
      choices: ["NA/NR"],
      width: "200px",
      defaultValueExpression: `iif({didCollectMedicalMeasuresBool}, [], ["NA/NR"])`
    },
    {
      type: "text",
      name: `${elementName}_denominator`,
      title: "Denominator",
      inputType: "number",
      width: "400px",
      enableIf: `{${elementName}_nanr} empty`,
      defaultValue: 0,
      min: 0,
      validators: [
        {
          type: "numeric",
          text: "Value cannot be less than 0",
          minValue: 0,
        },
        {
          type: "regex",
          text: "Value must be a whole number",
          regex: "^\\d+$"
        }
      ]
    },
    {
      type: "expression",
      name: `${elementName}_performancePct`,
      startWithNewLine: false,
      titleLocation: "hidden",
      expression: `(roundTo({${elementName}_numerator}/({${elementName}_denominator})*100, 2)) + "   % Performance Rate"`,
      width: "200px",
    },
  ];
}

function unduplicatedPatientsElements(elementName, description = undefined) {
  return [
    {
      type: "expression",
      title: " ",
      titleLocation: "left",
      popupdescription: description
    },
    {
      type: "text",
      name: `${elementName}_patients`,
      title: "Unduplicated Patients",
      inputType: "number",
      width: "400px",
      enableIf: `{${elementName}_nanr} empty`,
      defaultValue: 0,
      min: 0,
      validators: [
        {
          type: "numeric",
          text: "Value cannot be less than 0",
          minValue: 0,
        },
        {
          type: "regex",
          text: "Value must be a whole number",
          regex: "^\\d+$"
        }
      ]
    },
    {
      type: "checkbox",
      name: `${elementName}_nanr`,
      title: " ",
      startWithNewLine: false,
      choices: ["NA/NR"],
      width: "200px",
      defaultValueExpression: `iif({didCollectDentalMeasuresBool}, [], ["NA/NR"])`
    },
  ];
}

const areaType = "panel";
const areaWidth = undefined;

export const clinicalMeasuresJSON = {
  showProgressBar: "top",
  showCompletedPage: false,
  showQuestionNumbers: "off",
  showTOC: true,
  focusFirstQuestionAutomatic: false,
  title: "Clinical Measures",
  pages: [
    {
      name: "Medical",
      navigationTitle: "Medical",
      navigationDescription: "Medical",
      titleLocation: "hidden",
      elements: [
        {
          type: areaType,
          name: "didCollectMedicalMeasures",
          titleLocation: "hidden",
          width: areaWidth,
          elements: [
            {
              type: "expression",
              title: " ",
              titleLocation: "left",
              popupdescription: `If you are submitting any of the following medical quality measures, please select "Yes" - Diabetes: Hemoglobin A1c (HbA1c) Poor Control (> 9%), Controlling High Blood Pressure, Preventive Care and Screening: Tobacco Use: Screening and Cessation Intervention, Preventive Care and Screening: Body Mass Index (BMI) Screening and Follow-Up Plan, and/or Weight Assessment and Counseling for Nutrition and Physical Activity for Children and Adolescents. <b>These measures are required for clinics providing medical services.</b> Additional optional measures are also available for reporting.
              If you are not submitting any medical quality measures, please select "No" to proceed to the next step.`,
            },
            {
              type: "boolean",
              name: "didCollectMedicalMeasuresBool",
              title: "Did Clinic Users submit data for medical measures?",
              defaultValue: "false",
              isRequired: true,
            },
          ]
        }
      ]
    },
    {
      name: "MedicalMeasures",
      title: "    ● Medical Measures",
      navigationDescription: "MedicalMeasure",
      visibleIf: "{didCollectMedicalMeasuresBool} == true",
      elements: [
        {
          type: areaType,
          name: "diabetesHbA1c",
          title: "Diabetes: Hemoglobin A1c (HbA1c) Poor Control (>9%)",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("diabetesHbA1c", `<li><b>Numerator:</b> Patients whose most recent HbA1c level (performed during the measurement period) is >9.0% or is missing, or was not performed during the measurement period.</li>
          <li><b>Denominator:</b> Patients 18-75 years of age with diabetes with a visit during the measurement period.
          See more about this measure, including applicable exclusions and exceptions, at <a href="https://ecqi.healthit.gov/ecqm/ep/2022/cms122v10">https://ecqi.healthit.gov/ecqm/ep/2022/cms122v10</a></li>`),
        },

        {
          type: areaType,
          name: "hbpCtrl",
          title: "Controlling High Blood Pressure",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("hbpCtrl", `<li><b>Numerator:</b>Patients whose most recent blood pressure is adequately controlled (systolic blood pressure < 140 mmHg and diastolic blood pressure < 90 mmHg) during the measurement period</li>
          <li><b>Denominator:</b> Patients 18-85 years of age who had a visit and diagnosis of essential hypertension starting before and continuing into, or starting during the first six months of the measurement period.
          See more about this measure, including applicable exclusions and exceptions, at <a href="https://ecqi.healthit.gov/ecqm/ep/2022/cms165v10">https://ecqi.healthit.gov/ecqm/ep/2022/cms165v10</a>.</li>`),
        },
        {
          type: areaType,
          name: "tobaccoScreen",
          title: "Preventive Care and Screening: Tobacco Use: Screening and Cessation Intervention",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("tobaccoScreen", `<li><b>Numerator:</b> Patients who were screened for tobacco use at least once during the measurement period AND who received tobacco cessation intervention if identified as a tobacco user. (Population 3)</li>
          <li><b>Denominator:</b> All patients aged 18 years and older seen for at least two visits or at least one preventive visit during the measurement period. (Population 3)
          See more about this measure, including applicable exclusions and exceptions, at <a href="https://ecqi.healthit.gov/ecqm/ep/2022/cms138v10">https://ecqi.healthit.gov/ecqm/ep/2022/cms138v10</a>.</li>`),
        },
        {
          type: areaType,
          name: "bmiPlan",
          title: "Preventive Care and Screening: Body Mass Index (BMI) Screening and Follow Up Plan",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("bmiPlan", `<li><b>Numerator:</b> Patients with a documented BMI during the encounter or during the previous twelve months, AND when the BMI is outside of normal parameters, a follow-up plan is documented during the encounter or during the previous twelve months of the current encounter.</li>
          <li><b>Denominator:</b> All patients aged 18 and older on the date of the encounter with at least one eligible encounter during the measurement period.
          See more about this measure, including applicable exclusions and exceptions, at <a href="https://ecqi.healthit.gov/ecqm/ep/2022/cms069v10">https://ecqi.healthit.gov/ecqm/ep/2022/cms069v10</a>.</li>`),
        },
        {
          type: areaType,
          name: "childWeightNutritionActivity",
          title: "Weight Assessment and Counseling for Nurtrition and Physical Activity for Children and Adolescents",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("childWeightNutritionActivity", `<li><b>Numerator:</b> Patients 3-16 years of age with a BMI percentile and counseling on nutrition and physical activity documented</li>
          <li><b>Denominator:</b> Patients 3-16 years of age with at least one outpatient medical visit during the measurement period.
          Although this measure is aligned with CMS155, there are some variations. More information about CMS155, including applicable exclusions and exceptions, is available at <a href="https://ecqi.healthit.gov/ecqm/ec/2022/cms155v10">https://ecqi.healthit.gov/ecqm/ec/2022/cms155v10</a>.</li>`)
        },
        {
          type: areaType,
          name: "childImmunizations",
          title: "Childhood Immunizations",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("childImmunizations", `If you are interested in submitting this optional measure, please see the description, including applicable exclusions and exceptions, at <a href="https.://ecqi.healthit.gov/ecqm/ep/2022/cms117v10">https.://ecqi.healthit.gov/ecqm/ep/2022/cms117v10</a>.`)
        },
        {
          type: areaType,
          name: "cervicalCancerScreen",
          title: "Cervical Cancer Screening",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("cervicalCancerScreen", `If you are interested in submitting this optional measure, please see the description, including applicable exclusions and exceptions, at <a href="https://ecqi.healthit.gov/ecqm/ep/2022/cms124v10">https://ecqi.healthit.gov/ecqm/ep/2022/cms124v10</a>.`)
        },
        {
          type: areaType,
          name: "breastCancerScreen",
          title: "Breast Cancer Screening",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("breastCancerScreen", `If you are interested in submitting this optional measure, please see the description, including applicable exclusions and exceptions, at <a href="https://ecqi.healthit.gov/ecqm/ep/2022/cms125v10">https://ecqi.healthit.gov/ecqm/ep/2022/cms125v10</a>.`)
        },
        {
          type: areaType,
          name: "cardiovascularDiseasePreventTherapy",
          title: "Statin Therapy for the Prevention and Treatment of Cardiovascular Disease",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("cardiovascularDiseasePreventTherapy", `If you are interested in submitting this optional measure, please see the description, including applicable exclusions and exceptions, at <a href="https://ecqi.healthit.gov/ecqm/ep/2022/cms347v5">https://ecqi.healthit.gov/ecqm/ep/2022/cms347v5</a>.`)
        },
        {
          type: areaType,
          name: "ischemicVascularDiaseaseAntiplateletUse",
          title: "Ischemic Vascular Disease: Use of Aspirin or Another Antiplatelet",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("ischemicVascularDiaseaseAntiplateletUse", `If you are interested in submitting this optional measure, please see the description, including applicable exclusions and exceptions, at <a href="https://ecqi.healthit.gov/sites/default/files/ecqm/measures/CMS164v7.html">https://ecqi.healthit.gov/sites/default/files/ecqm/measures/CMS164v7.html<a/>.`)
        },
        {
          type: areaType,
          name: "colorectalCancerScreen",
          title: "Colorectal Cancer Screening",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("colorectalCancerScreen", `If you are interested in submitting this optional measure, please see the description, including applicable exclusions and exceptions, at <a href="https://ecqi.healthit.gov/ecqm/ep/2022/cms130v10">https://ecqi.healthit.gov/ecqm/ep/2022/cms130v10</a>.`)
        },
        {
          type: areaType,
          name: "hivLinkageToCare",
          title: "HIV Linkage to Care",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("hivLinkageToCare", `<li><b>Numerator:</b> Patients newly diagnosed with HIV who were seen for follow-up treatment within 30 days of diagnosis.</li>
          <li><b>Denominator:</b> Patients first diagnosed wtih HIV by the health center between December 1 of the prior year through November 30 of the current measurement period and who had at least one medical visit during the measurement year.</li>`)
        },
        {
          type: areaType,
          name: "hivScreen",
          title: "HIV Screening",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("hivScreen", `If you are interested in submitting this optional measure, please see the description, including applicable exclusions and exceptions, at <a href="https://ecqi.healthit.gov/ecqm/ep/2022/cms349v4">https://ecqi.healthit.gov/ecqm/ep/2022/cms349v4</a>.`)
        },
        {
          type: areaType,
          name: "depressionScreenAndPlan",
          title: "Preventive Care and Screening: Screening for Depression and Follow-Up Plan",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("depressionScreenAndPlan", `If you are interested in submitting this optional measure, please see the description, including applicable exclusions and exceptions, at  <a href="https://ecqi.healthit.gov/ecqm/ep/2022/cms002v11">https://ecqi.healthit.gov/ecqm/ep/2022/cms002v11</a>.`)
        },
        {
          type: areaType,
          name: "depressionRemission12mo",
          title: "Depression Remission at Twelve Months",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("depressionRemission12mo", `If you are interested in submitting this optional measure, please see the description, including applicable exclusions and exceptions, at <a href="https://ecqi.healthit.gov/ecqm/ep/2022/cms159v10">https://ecqi.healthit.gov/ecqm/ep/2022/cms159v10</a>`)
        },
        {
          type: areaType,
          name: "childDentalSealantsAge6to9",
          title: "Dental Sealants for Children between 6-9 Years",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: numeratorDenominatorElements("childDentalSealantsAge6to9")
        },
        {
          type: areaType,
          name: "medicalMeasuresNotes",
          title: " ",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: [
            {
              type: "comment",
              name: "medicalMeasuresNotesElement",
              title: "Notes",
              width: "400px",
              popupdescription: `Please note any comments that you have regarding data reported for your medical measures, challenges in obtaining the data, etc.`,
            },
          ]
        }
      ]
    },
    {
      name: "Dental",
      navigationTitle: "Dental",
      navigationDescription: "Dental",
      titleLocation: "hidden",
      elements: [
        {
          type: areaType,
          name: "didCollectDentalMeasures",
          titleLocation: "hidden",
          width: areaWidth,
          elements: [
            {
              type: "expression",
              title: " ",
              titleLocation: "left",
              popupdescription: `If your clinic provided dental services during the reporting year, please select "Yes". Otherwise, please select "No" to proceed to the next step.`,
            },
            {
              type: "boolean",
              name: "didCollectDentalMeasuresBool",
              title: "Did Clinic Users submit data for dental measures?",
              defaultValue: "false",
              isRequired: true,
            },
          ]
        }
      ]
    },
    {
      name: "DentalMeasures",
      title: "    ● Dental Measures",
      navigationDescription: "DentalMeasures",
      visibleIf: "{didCollectDentalMeasuresBool} == true",
      elements: [
        {
          type: areaType,
          name: "emergencyServices",
          title: "Emergency Services",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: unduplicatedPatientsElements("emergencyServices", `Number of visits during reporting period with applicable ADA code: D0140, D9110.`)
        },
        {
          type: areaType,
          name: "oralExams",
          title: "Oral Exams",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: unduplicatedPatientsElements("oralExams", `Number of visits during reporting period with applicable ADA code: D0120, D0140, D0145, D0150, D0160, D0170, D0180.`)
        },
        {
          type: areaType,
          name: "prophylaxis",
          title: "Prophylaxis",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: unduplicatedPatientsElements("prophylaxis", `Number of visits during reporting period with applicable ADA code: D1110, D1120.`)
        },
        {
          type: areaType,
          name: "sealants",
          title: "Sealants",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: unduplicatedPatientsElements("sealants", `Number of visits during reporting period with applicable ADA code: D1351.          `)
        },
        {
          type: areaType,
          name: "flourideTreatment",
          title: "Fluoride Treatment",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: unduplicatedPatientsElements("flourideTreatment", `Number of visits during reporting period with applicable ADA code: D1351Number of visits during reporting period with applicable ADA codes D1206 and D1208 and/or CPT-4 code 99188..`)
        },
        {
          type: areaType,
          name: "restorativeServices",
          title: "Restorative Services",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: unduplicatedPatientsElements("restorativeServices", `Number of visits during reporting period with applicable ADA code: D21xx through D29xx.`)
        },
        {
          type: areaType,
          name: "oralSurgery",
          title: "Oral Surgery",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: unduplicatedPatientsElements("oralSurgery", `Number of visits during reporting period with applicable ADA code: D7xxx.`)
        },
        {
          type: areaType,
          name: "rehabServices",
          title: "Rehab Services",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: unduplicatedPatientsElements("rehabServices", `Number of visits during reporting period with applicable ADA code: D3xxx, D4xxx, D5xxx, D6xxx, D8xxx.`)
        },
        {
          type: areaType,
          name: "dentalMeasuresNotes",
          title: " ",
          width: areaWidth,
          questionTitleLocation: "left",
          elements: [
            {
              type: "comment",
              name: "dentalMeasuresNotesElement",
              title: "Notes",
              width: "400px",
              popupdescription: `Please note any comments that you have regarding data reported for your dental measures, challenges in obtaining the data, etc.`,
            },
          ]
        }
      ]
    },
  ]
};