import React, { useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { UserNavComponent } from './UserNavComponent';
import { UserContext } from 'contexts';
import { ApplicationPermissions } from 'utilities';

export const NavMenu = ( { toggleNotifications, userNotificationsCount } ) => {

  const [collapsed, setCollapsed] = useState(false);
  
  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }  

  return (
    <UserContext.Consumer>
      {({isFHQCClinicType,  hasAnyPermission, hasPermission}) => {
        return (
            <header className='navbar-header mb-3'>
                <Navbar className="navbar-main navbar-expand-sm navbar-toggleable-sm mb-2">
                    <Container className="d-flex">
                        <NavbarBrand to="/">Community Care <br /> Data Network</NavbarBrand>
                        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                {hasAnyPermission(Object.values(ApplicationPermissions.NetworkDataHub)) && (
                                <li className='mx-2 nav-item'>
                                    <NavLink to="/NetworkDataHub" className='nav-link' activeClassName='active'>Network Data Hub</NavLink>
                                </li>)}
                                <li className='mx-2 nav-item'>
                                    <NavLink to="/CoverKansas" className='nav-link' activeClassName='active'>Cover Kansas</NavLink>
                                </li>
                                {hasPermission(ApplicationPermissions.NetworkDataHub.NDHEditorTool) &&(
                                <li className='mx-2 nav-item'>
                                    <NavLink to="/NDHCreatorTool" className='nav-link' activeClassName='active'>NDH Editor Tool</NavLink>
                                </li>)}
                                <UserNavComponent toggleNotifications={toggleNotifications} userNotificationsCount={userNotificationsCount} />
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>

                <Navbar className="navbar-secondary navbar-expand-sm navbar-toggleable-sm">
                    <Container className="d-flex">
                        <Collapse className="d-sm-inline-flex flex-sm-row" isOpen={!collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                            {hasAnyPermission(Object.values(ApplicationPermissions.AccountManagement)) && (
                                <li className='mx-2 nav-item'>
                                    <NavLink to="/AccountManagement/Clinic" className='nav-link'>Account Management</NavLink>
                                </li>
                            )}
                            {(hasAnyPermission(Object.values(ApplicationPermissions.ClinicManagement)) && !isFHQCClinicType) && (
                                <li className='mx-2 nav-item'>
                                    <NavLink to="/ClinicManagement/ClinicDetails#ClinicInformation" className='nav-link'>Clinic Data Entry</NavLink>
                                </li>
                            )}
                            {(hasAnyPermission(Object.values(ApplicationPermissions.ClinicManagement)) && isFHQCClinicType) && (<li className='mx-2 nav-item'>
                                    <NavLink to="/ClinicDataEntry/ClinicDetails#ClinicInformationNDH" className='nav-link'>Clinic Data Upload/Manual Entry</NavLink>
                                </li>
                            )}
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
      }}
    </UserContext.Consumer>
  );
}
