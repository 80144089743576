import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input, Form, Button } from "reactstrap";
import { apiClient, ApplicationPermissions } from "utilities";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserContext } from "contexts";
import { AuthService } from "services";
import { trackPromise } from "react-promise-tracker";

export const UserDetails = () => {
  const user = useUserContext();
  const canEdit = user?.hasPermission(
    ApplicationPermissions.AccountManagement.UpdateUsers
  );
  const isCoverKansasUser = user?.hasPermission(
    ApplicationPermissions.CoverKansas.CoverKanasUser
  );
  const [isEditable, setIsEditable] = useState(false);
  const [originalUserDetails, setOriginalUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    supervisor: "",
    accountId: "",
    clinicId: "",
    roles: [],
    clinics: [],
  });

  //userDetails is separated from originalUserDetails because userDetails can change in edit mode.
  //we keep originalUserDetails in case the user clicks "cancel" so we can revert changes without
  //making another API call
  const [userDetails, setUserDetails] = useState(originalUserDetails);

  const { id } = useParams();

  useEffect(() => {
    trackPromise(apiClient.get(`/User/GetById/${id}`).then((response) => {
      const user = response.data;

      if (user != null) {
        setOriginalUserDetails(user);
        setUserDetails(user);
      }
    }));
  }, [id]);

  const updateUserDetailsModel = (propertyName, newValue) => {
    let newUserDetails = { ...userDetails };
    newUserDetails[propertyName] = newValue;

    setUserDetails(newUserDetails);
  };

  const onRoleCheckChange = (index) => {
    let roles = userDetails.roles;
    let checkedState = userDetails.roles[index].isSelected;

    //verify we have a value at this index and that the value is a boolean,
    //toggle the value of the boolean and update the userDetails model
    if (checkedState != null && typeof checkedState === typeof true) {
      roles[index].isSelected = !checkedState;

      updateUserDetailsModel("roles", roles);
    }
  };

  const onCancelClick = () => {
    setIsEditable(false);
    setUserDetails(originalUserDetails);
  };

  const onSubmitClick = () => {
    trackPromise(apiClient.post("/User/UpdateDetailsAsync", userDetails).then((response) => {
      setIsEditable(false);
      toast.success("Details updated successfully!");
    }));
  };

  return (
    <>
      <Row>
        <Col md={8}>
          <p className="primary title">{userDetails.email} Information</p>
        </Col>
      </Row>
      {canEdit && (
        <Row>
          <Col sm={10}>
            {isEditable ? (
              <Button color="primary" className="my-2 pill-btn" outline onClick={onCancelClick}>
                Cancel
              </Button>
            ) : (
              <Button color="primary" className="my-2 pill-btn" outline onClick={() => setIsEditable(true)}>
                Edit User
              </Button>
            )}
          </Col>
        </Row>
      )}
        <Row>
          <Col md={8}>
            <FormGroup>
              <Label for="firstNameInput" className="label">First Name</Label>
              <Input
                id="firstNameInput"
                name="firstNameInput"
                type="text"
                onChange={(e) =>
                  updateUserDetailsModel("firstName", e.target.value)
                }
                value={userDetails.firstName}
                disabled={!isEditable}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <FormGroup>
              <Label for="lastNameInput" className="label">Last Name</Label>
              <Input
                id="lastNameInput"
                name="lastNameInput"
                type="text"
                onChange={(e) =>
                  updateUserDetailsModel("lastName", e.target.value)
                }
                value={userDetails.lastName}
                disabled={!isEditable}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <FormGroup>
              <Label for="emailInput" className="label">Email</Label>
              <Input
                id="emailInput"
                name="emailInput"
                type="text"
                onChange={(e) => updateUserDetailsModel("email", e.target.value)}
                value={userDetails.email}
                disabled={!isEditable}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <FormGroup>
              <Label for="supervisorInput" className="label">Supervisor</Label>
              <Input
                required
                id="supervisorInput"
                name="supervisorInput"
                type="text"
                onChange={(e) =>
                  updateUserDetailsModel("supervisor", e.target.value)
                }
                value={userDetails.supervisor}
                disabled={!isEditable}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <FormGroup>
              <Label for="clinicSelect" className="label">Clinic Membership</Label>
              <Input
                id="clinicSelect"
                name="clinicSelect"
                type="select"
                value={userDetails.clinicId}
                disabled={!isEditable}
                onChange={(e) =>
                  updateUserDetailsModel("clinicId", e.target.value)
                }
              >
                <option value={"00000000-0000-0000-0000-000000000000"}>
                  None
                </option>
                {userDetails.clinics?.map((clinic, index) => {
                  return (
                    <option value={clinic.id} key={`${clinic.id}-option`}>
                      {clinic.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        {isCoverKansasUser && (<Label for="accountIdInput" className="label">Navigator Account ID</Label>)}
        {isCoverKansasUser && (<Row>
          <Col md={4}>
            <FormGroup>
              <Input
                id="accountIdInput"
                name="accountIdInput"
                type="text"
                value={userDetails.accountId}
                disabled
              />
            </FormGroup>
          </Col>
          {isEditable && 
          <Col md={4}>
            {userDetails.accountId !== "" && 
            <Button 
                outline
                id="clearAccountId"
                name="clearAccountId"
                className="mx-2" 
                color="primary" 
                onClick={() => updateUserDetailsModel("accountId", "")}
            >
                Clear
            </Button>
            }
            <Button 
                id="generateAccountId"
                name="generateAccountId"
                className="mx-2" 
                color="primary" 
                onClick={async () => updateUserDetailsModel("accountId", await AuthService.generateNewAccountId())}
            >
                Generate Account ID
            </Button>
          </Col>
          }
        </Row>)}
        <Row>
          <Col md={8}>
            <h4>User Roles</h4>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={8}>
            {userDetails.roles?.map((role, index) => {
              return (
                <FormGroup check key={`${role.id}-fg`} className="mb-1">
                  <Input
                    type="checkbox"
                    className="check-input m-2"
                    checked={role.isSelected}
                    onChange={(e) => {
                      onRoleCheckChange(index);
                    }}
                    disabled={!isEditable}
                    key={`${role.id}-checkbox`}
                  />
                  <Label check key={`${role.id}-label`}  className="label p-1">
                    {role.name}
                  </Label>
                </FormGroup>
              );
            })}
          </Col>
        </Row>
        {isEditable ? (
          <Row>
            <Col md={3}>
            <Button
              type="submit"
              color="primary"
              className="mb-5 pill-btn"
              outline
              onClick={onSubmitClick}
            >
              Submit
            </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
    </>
  );
};
