import { DEFAULT_KEEP_ALIVE_TIMEOUT } from "@aws-amplify/pubsub/lib-esm/Providers/constants";
import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthService } from "services";
import { toast } from "react-toastify";

const defaultAuthState = {
  user: AuthService.getCurrentUser(),
  clinicId: AuthService.getCurrentUserClinicId(),
  permissions: null,
  setUser: null,
  isLoggedIn: false,
  isFHQCClinicType: false,
  accessToken: null,
  hasPermission: null,
  hasAnyPermission: null,
  logOut: null
};

export const UserContext = createContext(defaultAuthState);

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(defaultAuthState.user);
  const [permissions, setPermissions] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [clinicId, setClinicId] = useState(defaultAuthState.clinicId);
  const [isFHQCClinicType, setIsFHQCClinicType] = useState(defaultAuthState.isFHQCClinicType);

  useEffect(() => {
    var expirationDateString = user?.sessionTokens?.expirationTime;
    var expirationDate = new Date(new Date(expirationDateString).toUTCString().slice(0, -4));

    var date = new Date();
    var currentDate = new Date(date.toUTCString().slice(0, -4));

    if (expirationDate == null ||
      expirationDate < currentDate ||
      user == null ||
      user.sessionTokens == null) {
      setIsLoggedIn(false);
      setAccessToken(null);
    }
    else if (user?.sessionTokens?.idToken != null) {
      setIsLoggedIn(true);
      setAccessToken(user.sessionTokens.idToken);
    }

    AuthService.getUserPermissions().then((response) => {
      setPermissions(response.data);
    });

    AuthService.getClinicType().then((response) => {
      setIsFHQCClinicType(response.data);
    });
  }, [user]);

  return (
    <UserContext.Provider value={{
      user: user,
      clinicId: clinicId,
      permissions: permissions,
      setUser: setUser,
      setClinicId: setClinicId,
      isLoggedIn: isLoggedIn,
      setIsLoggedIn: setIsLoggedIn,
      accessToken: accessToken,
      isFHQCClinicType: isFHQCClinicType,
      hasPermission: (inputPermission) => {
        return permissions?.some(p => p === inputPermission) ?? false;
      },
      hasAnyPermission: (inputPermissions) => {
        return inputPermissions?.some(ip => permissions?.includes(ip)) ?? false;
      },
      logOut: () => {
        AuthService.logout().then(() => {
          setUser(AuthService.getCurrentUser());
          setClinicId('');
          setIsLoggedIn(false);
          toast.success(`Logged out successfully!`);
          // @ts-ignore
          window.location.reload(false);
        });
      }
    }}>
      {children}
    </UserContext.Provider>
  );
}

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
};
