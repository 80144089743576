export const dataSubmissionJSON =
{
    showProgressBar: "top",
    showCompletedPage: false,
    // navigateToUrl: "/",
    // completedHtml: "<p style=\"font-size:14px; color=#142a4c;font-weight: Normal;\"> <b>{clinicName}</b> data has been submitted for calendar year <b>{currentYear}</b>. If any submitted data requires correction you may submit an amendment request for the data section.</p>",
    pages:
        [{
            name: "dataSubmission",
            elements: [
                {
                    type: "panel",
                    name: "dataSubmissionPanel",
                    elements: [
                        {
                            type: "html",
                            name: "clinicDataSubmission",
                            html: "<p style=\"font-size:14px; color=#142a4c;font-weight: Normal;\">Are you ready to submit your data for <b>{clinicName}</b> for the calendar year <b>{currentYear}</b>?</p>",
                        },
                        {
                            type: "html",
                            name: "udsDataUpload",
                        }
                    ]
                }
            ],
            title: "Data Submission",
            navigationDescription: "Data Submission"
        }
        ]
}