import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { useStates } from "react-us-states";
import {
  Row,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Button,
  Form,
} from "reactstrap";
import { GoBackIcon } from "components/Icon";
import { apiClient, ApiRoutes } from "utilities";
import { useUserContext } from "contexts";
import { trackPromise } from "react-promise-tracker";

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {}); // empty object is the initial value for result object
};

export const ReviewClinicInformation = ({ clinicData = {} }) => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
    handleSubmit,
  } = useForm();

  const unitedStatesStates = useStates();

  const userContext = useUserContext();
  
  const location = useLocation();
  const history = useHistory();

  const [showClinicInformation, setShowClinicInformation] = useState(true);
  const [permissions, setPermissions] = useState({});
  const [clinic, setClinic] = useState({});
  const [userInfo, setUserInfo] = useState({ designations: [] });
  const [dentalOnlyChecked, setDentalOnlyChecked] = useState(false);
  const [showDesignationChange, setShowDesignationChange] = useState(false);

  const { ref: nameRef, ...nameRegisterProps } = register("name", {
    required: true,
  });
  const { ref: businessAddressLine1Ref, ...businessAddressLine1RegisterProps } =
    register("businessAddressLine1", { required: false });
  const { ref: businessAddressLine2Ref, ...businessAddressLine2RegisterProps } =
    register("businessAddressLine2", { required: false });
  const { ref: cityRef, ...cityRegisterProps } = register("city", {
    required: false,
  });
  const { ref: stateRef, ...stateRegisterProps } = register("state", {
    required: false,
  });
  const { ref: zipRef, ...zipRegisterProps } = register("zip", {
    required: false,
  });
  const { ref: countyRef, ...countyRegisterProps } = register("county", {
    required: false,
  });

  const { ref: mailingAddressLine1Ref, ...mailingAddressLine1RegisterProps } =
    register("mailingAddressLine1", { required: false });
  const { ref: mailingAddressLine2Ref, ...mailingAddressLine2RegisterProps } =
    register("mailingAddressLine2", { required: false });
  const { ref: mailingCityRef, ...mailingCityRegisterProps } = register(
    "mailingCity",
    { required: false }
  );
  const { ref: mailingStateRef, ...mailingStateRegisterProps } = register(
    "mailingState",
    { required: false }
  );
  const { ref: mailingZipRef, ...mailingZipRegisterProps } = register(
    "mailingZip",
    { required: false }
  );

  const { ref: phoneRef, ...phoneRegisterProps } = register("phone", {
    required: false,
  });
  const { ref: faxRef, ...faxRegisterProps } = register("fax", {
    required: false,
  });
  const { ref: emailRef, ...emailRegisterProps } = register("email", {
    required: false,
  });
  const { ref: websiteRef, ...websiteRegisterProps } = register("website", {
    required: false,
  });
  const { ref: notesRef, ...notesRegisterProps } = register("notes", {
    required: false,
  });

  const handleIsSameMailingAddress = (val) => {
    setValue("isMailingAddressDifferent", val);
  };

  const clinicId =
    clinicData && clinicData["id"] !== undefined
      ? clinicData["id"]
      : userContext?.clinicId;

  useEffect(() => {
    if (clinicId) {
      const url = `${ApiRoutes.AccountManagement.ClinicDetails.replace(
        ":id",
        clinicId
      )}`;
      trackPromise(apiClient
        .get(url)
        .then((response) => {
          setClinic(response.data);
        })
        .catch((resp) => {}));
    }
  }, [clinicId]);

  useEffect(() => {
    if (clinic) {
      setValue("id", clinic["id"]);
      setValue("name", clinic["name"]);
      setValue("membershipType", clinic["membershipType"]);

      setValue("businessAddressLine1", clinic["businessAddressLine1"]);
      setValue("businessAddressLine2", clinic["businessAddressLine2"]);
      setValue("city", clinic["city"]);
      setValue("state", clinic["state"]);
      setValue("zip", clinic["zip"]);
      setValue("county", clinic["county"]);

      setValue(
        "isMailingAddressDifferent",
        clinic["isMailingAddressDifferent"]
      );
      setValue("mailingAddressLine1", clinic["mailingAddressLine1"]);
      setValue("mailingAddressLine2", clinic["mailingAddressLine2"]);
      setValue("mailingCity", clinic["mailingCity"]);
      setValue("mailingState", clinic["mailingState"]);
      setValue("mailingZip", clinic["mailingZip"]);

      setValue("phone", clinic["phone"]);
      setValue("fax", clinic["fax"]);
      setValue("email", clinic["email"]);
      setValue("website", clinic["website"]);
      setValue("notes", clinic["notes"]);


      if (clinic["designations"]) {
        clinic["designations"].forEach((option) => {
          const date = option["designationDate"]
            ? option["designationDate"].split("T")[0]
            : null;

          setValue(`designations.${option.designation.key}.selected`, true);
          // setValue(`designations.${option.designation.key}.designation`, option.designation.key);
          setValue(
            `designations.${option.designation.key}.hrsaGrantId`,
            option["hrsaGrantId"]
          );
          setValue(
            `designations.${option.designation.key}.designationDate`,
            date
          );
          setValue(
            `designations.${option.designation.key}.previouslyKnownAs`,
            option["previouslyKnownAs"]
          );

          if (option.designation.key === 1) {
            setDentalOnlyChecked(true);
          }
        });

        setUserInfo({
          designations: clinic["designations"]
            ? clinic["designations"].map((e) => e.designation.name)
            : [],
        });
      }
    }
  }, [clinic, setValue, setUserInfo, setDentalOnlyChecked]);

  useEffect(() => {
    const clinicId =
      clinicData && clinicData["id"] !== undefined ? clinicData["id"] : null; // "fcbc90a4-0cd5-488f-8d99-b94ca1e97d56";

    if (clinicId) {
      const url = `${ApiRoutes.AccountManagement.ClinicDetails.replace(
        ":id",
        clinicId
      )}`;
      trackPromise(apiClient
        .get(url)
        .then((response) => {
          setClinic(response.data);
        })
        .catch((resp) => {}));

      const optionsUrl = `${ApiRoutes.AccountManagement.Clinics}/options`;
      trackPromise(apiClient
        .get(optionsUrl)
        .then((response) => {
          setPermissions(groupBy(response.data, "groupName"));
        })
        .catch((resp) => {
          setPermissions({});
        }));
    }
  }, [clinicData, setClinic]);

  return (
    showClinicInformation && (
      <Container>
        <Form>
          <Row>
            <Col>
              <Row className="mb-4 mt-4 text-center">
                <Col>
                  <h3>
                    {" "}
                    {watch("name")
                      ? `${watch("name")} Information`
                      : "[Clinic Name****] Information"}
                  </h3>
                </Col>
              </Row>

              <FormGroup row className="mb-4">
                <Label for="clinicName" sm={3} className="small-label-inline">
                  Clinic Name
                </Label>
                <Col md={9}>
                  <Input
                    id="clinicName"
                    type="text"
                    innerRef={nameRef}
                    {...nameRegisterProps}
                    required
                    disabled
                  />
                  <FormFeedback>Clinic Name Required.</FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label md={3} className="small-label-inline">
                  Designation:
                </Label>
                <Col md={"auto"}>
                  {permissions["Designation"]?.map((option, index) => {
                    const selected =
                      clinic &&
                      clinic["designations"] &&
                      clinic["designations"].find(
                        (x) => x["designation"].key === option.key
                      );

                    return (
                      selected && (
                        <Label
                          key={index}
                          className={"small-label-inline col-form-label mx-1"}
                        >
                          {option.description},{" "}
                        </Label>
                      )
                    );
                  })}
                </Col>
                {!showDesignationChange && (
                  <Col md={"auto"}>
                    <Button color="primary" type="button" className="">
                      Request Change
                    </Button>
                  </Col>
                )}
              </FormGroup>

              {showDesignationChange &&
                permissions["Designation"]?.map((option, index) => {
                  const { ref: optionRef, ...optionRegisterProps } = register(
                    `designations.${option.key}.selected`,
                    { required: false }
                  );
                  const { ref: hrsaGrantIdRef, ...hrsaGrantIdRegisterProps } =
                    register(`designations.${option.key}.hrsaGrantId`, {
                      required: false,
                    });
                  const {
                    ref: designationDateRef,
                    ...designationDateRegisterProps
                  } = register(`designations.${option.key}.designationDate`, {
                    required: false,
                  });
                  const {
                    ref: previouslyKnownAsRef,
                    ...previouslyKnownAsRegisterProps
                  } = register(`designations.${option.key}.previouslyKnownAs`, {
                    required: false,
                  });

                  setValue(
                    `designations.${option.key}.designation`,
                    option.key
                  );

                  const lastItem =
                    permissions["Designation"].length - 1 === index;

                  return (
                    <React.Fragment key={option.key}>
                      {index === 0 && (
                        <Row>
                          <Col md={12} className={"text-center"}>
                            <Label className="small-label-inline col-form-label">
                              Designation Change
                            </Label>
                          </Col>
                        </Row>
                      )}

                      <FormGroup check className={`${lastItem ? "mb-4" : ""}`}>
                        <Input
                          id={option.name}
                          name={option.name}
                          type="checkbox"
                          innerRef={optionRef}
                          disabled
                          {...optionRegisterProps}
                        />{" "}
                        <Label check className="check-label">
                          {option.description}
                        </Label>
                        {userInfo.designations.find((e) => e === option.name) &&
                          (option.name === "FQHC_DSG" ||
                            option.name === "FQHCLAL_DSG") && (
                            <Row>
                              <Label
                                for={`hrsa-${option.name}`}
                                sm={3}
                                className="small-label-inline"
                              >
                                HRSA Grant Id:
                              </Label>
                              <Col sm={3}>
                                <Input
                                  id={`hrsa-${option.name}`}
                                  name={`hrsa-${option.name}`}
                                  type="text"
                                  innerRef={hrsaGrantIdRef}
                                  disabled
                                  {...hrsaGrantIdRegisterProps}
                                />
                              </Col>
                            </Row>
                          )}
                        {userInfo.designations.find(
                          (e) => e === option.name
                        ) && (
                          <>
                            <Row>
                              <Label
                                for={`date-${option.name}`}
                                sm={3}
                                className="small-label-inline"
                              >
                                Date of Designation:
                              </Label>
                              <Col sm={3}>
                                <Input
                                  id={`date-${option.name}`}
                                  name={`date-${option.name}`}
                                  type="date"
                                  innerRef={designationDateRef}
                                  disabled
                                  {...designationDateRegisterProps}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Label
                                for={`previous-${option.name}`}
                                sm={3}
                                className="small-label-inline"
                              >
                                Previously Known As:
                              </Label>
                              <Col sm={3}>
                                <Input
                                  id={`previous-${option.name}`}
                                  name={`previous-${option.name}`}
                                  type="text"
                                  innerRef={previouslyKnownAsRef}
                                  disabled
                                  {...previouslyKnownAsRegisterProps}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </FormGroup>
                    </React.Fragment>
                  );
                })}

              {showDesignationChange && (
                <Row row className="mb-4">
                  <Col md={9} className={"col-text-right"}>
                    <Button color="secondary" className="mx-2">
                      Cancel
                    </Button>
                    <Button color="primary">Submit Amendment</Button>
                  </Col>
                </Row>
              )}

              <FormGroup row className="mb-4">
                <Label
                  className="small-label-inline"
                  for="clinicBusinessAddress1"
                  sm={3}
                >
                  Business Address Line 1
                </Label>
                <Col md={9}>
                  <Input
                    id="clinicBusinessAddress1"
                    type="text"
                    innerRef={businessAddressLine1Ref}
                    {...businessAddressLine1RegisterProps}
                    disabled
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label
                  className="small-label-inline"
                  for="clinicBusinessAddress2"
                  sm={3}
                >
                  Business Address Line 2
                </Label>
                <Col md={9}>
                  <Input
                    id="clinicBusinessAddress2"
                    type="text"
                    innerRef={businessAddressLine2Ref}
                    {...businessAddressLine2RegisterProps}
                    disabled
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label className="small-label-inline" for="clinicCity" sm={3}>
                  City
                </Label>
                <Col md={5}>
                  <Input
                    id="clinicCity"
                    type="text"
                    innerRef={cityRef}
                    {...cityRegisterProps}
                    disabled
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label className="small-label-inline" for="clinicState" sm={3}>
                  State
                </Label>
                <Col md={5}>
                  <Input
                    id="clinicState"
                    type="select"
                    innerRef={stateRef}
                    {...stateRegisterProps}
                    defaultValue={-1}
                    disabled
                  >
                    <option value={-1} hidden disabled>
                      -- Select State--
                    </option>
                    {unitedStatesStates.map((state) => {
                      return (
                        <option key={state.name} value={state.name}>
                          {state.name}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label className="small-label-inline" for="clinicZip" sm={3}>
                  Zip
                </Label>
                <Col md={5}>
                  <Input
                    id="clinicZip"
                    name="zip"
                    type="number"
                    innerRef={zipRef}
                    {...zipRegisterProps}
                    disabled
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label className="small-label-inline" for="clinicCounty" sm={3}>
                  County
                </Label>
                <Col md={5}>
                  <Input
                    id="clinicCounty"
                    type="text"
                    innerRef={countyRef}
                    {...countyRegisterProps}
                    disabled
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label
                  className="small-label-inline"
                  for="mailingAddress"
                  sm={4}
                >
                  Is the mailing address the same as the business address?
                </Label>
                <Col sm={1} className="d-flex align-items-center">
                  <Input
                    id="mailingAddressYes"
                    className="me-2"
                    type="checkbox"
                    onChange={() => handleIsSameMailingAddress(true)}
                    checked={
                      watch("isMailingAddressDifferent") !== undefined &&
                      watch("isMailingAddressDifferent")
                    }
                    disabled
                  />
                  <Label
                    className="small-label-inline"
                    check
                    for="mailingAddressYes"
                    sm={3}
                  >
                    Yes
                  </Label>
                </Col>
                <Col sm={1} className="d-flex align-items-center">
                  <Input
                    id="mailingAddressNo"
                    className="me-2"
                    name="mailingAddressNo"
                    type="checkbox"
                    checked={
                      watch("isMailingAddressDifferent") !== undefined &&
                      !watch("isMailingAddressDifferent")
                    }
                    disabled
                  />{" "}
                  <Label
                    className="small-label-inline"
                    check
                    for="mailingAddressNo"
                    sm={2}
                  >
                    No
                  </Label>
                </Col>
              </FormGroup>

              {watch("isMailingAddressDifferent") !== undefined &&
                !watch("isMailingAddressDifferent") && (
                  <>
                    <FormGroup row className="mb-4">
                      <Label
                        className="small-label-inline"
                        for="clinicMailingAddress1"
                        sm={3}
                      >
                        Mailing Address Line 1
                      </Label>
                      <Col md={9}>
                        <Input
                          id="clinicMailingAddress1"
                          type="text"
                          innerRef={mailingAddressLine1Ref}
                          {...mailingAddressLine1RegisterProps}
                          disabled
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4">
                      <Label
                        className="small-label-inline"
                        for="clinicMailingAddress2"
                        sm={3}
                      >
                        Mailing Address Line 2
                      </Label>
                      <Col md={9}>
                        <Input
                          id="clinicMailingAddress2"
                          type="text"
                          innerRef={mailingAddressLine2Ref}
                          {...mailingAddressLine2RegisterProps}
                          disabled
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4">
                      <Label
                        className="small-label-inline"
                        for="clinicMailingCity"
                        sm={3}
                      >
                        City
                      </Label>
                      <Col md={4}>
                        <Input
                          id="clinicMailingCity"
                          type="text"
                          innerRef={mailingCityRef}
                          {...mailingCityRegisterProps}
                          disabled
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4">
                      <Label
                        className="small-label-inline"
                        for="clinicMailingState"
                        sm={3}
                      >
                        State
                      </Label>
                      <Col md={4}>
                        <Input
                          id="clinicMailingState"
                          type="select"
                          innerRef={mailingStateRef}
                          {...mailingStateRegisterProps}
                          defaultValue={-1}
                          disabled
                        >
                          <option value={-1} hidden disabled>
                            -- Select State--
                          </option>
                          {unitedStatesStates.map((state) => {
                            return (
                              <option key={state.name} value={state.name}>
                                {state.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4">
                      <Label
                        className="small-label-inline"
                        for="clinicMailingZip"
                        sm={3}
                      >
                        Zip
                      </Label>
                      <Col md={4}>
                        <Input
                          id="clinicMailingZip"
                          type="number"
                          innerRef={mailingZipRef}
                          {...mailingZipRegisterProps}
                          disabled
                        />
                      </Col>
                    </FormGroup>
                  </>
                )}

              <FormGroup row className="pt-4 mb-4">
                <Label className="small-label-inline" for="clinicPhone" sm={3}>
                  Phone
                </Label>
                <Col md={4}>
                  <Input
                    id="clinicPhone"
                    type="tel"
                    innerRef={phoneRef}
                    {...phoneRegisterProps}
                    disabled
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label className="small-label-inline" for="clinicFax" sm={3}>
                  Fax
                </Label>
                <Col md={4}>
                  <Input
                    id="clinicFax"
                    type="tel"
                    innerRef={faxRef}
                    {...faxRegisterProps}
                    disabled
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label className="small-label-inline" for="clinicEmail" sm={3}>
                  Email
                </Label>
                <Col md={9}>
                  <Input
                    id="clinicEmail"
                    type="email"
                    innerRef={emailRef}
                    placeholder="example@example.com"
                    invalid={errors.email !== undefined}
                    {...emailRegisterProps}
                    disabled
                  />
                  <FormFeedback>Email not in the expected format.</FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label
                  className="small-label-inline"
                  for="clinicWebsite"
                  sm={3}
                >
                  Website
                </Label>
                <Col md={9}>
                  <Input
                    id="clinicWebsite"
                    type="url"
                    innerRef={websiteRef}
                    {...websiteRegisterProps}
                    disabled
                  />
                  <FormFeedback>
                    WebSite not in the expected URL format.
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label
                  className="small-label-inline"
                  for="clinicNotes"
                  sm={3}
                >
                  Notes
                </Label>
                <Col md={9}>
                  <Input
                    id="clinicNotes"
                    type="textarea"
                    innerRef={notesRef}
                    {...notesRegisterProps}
                    disabled
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Container>
    )
  );
};
