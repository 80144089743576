import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useUserContext } from 'contexts';

export const UserNavComponent = ( {toggleNotifications, userNotificationsCount} ) => {
  const userContext = useUserContext();

  const handleLogout = () => {
    userContext.logOut();
  }
    const isAuthenticated = userContext.isLoggedIn;

    return isAuthenticated ? (
      <>
      <UncontrolledDropdown nav className='mx-2'>
        {userNotificationsCount > 0 && (
          <span id="notification-icon" className="d-flex position-absolute top-100 start-100 translate-middle badge rounded-pill bg-danger" onClick={toggleNotifications}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-megaphone-fill me-2" viewBox="0 0 16 16">
              <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z"/>
            </svg>
            <span>{userNotificationsCount}</span>
          </span>
        )}
        <DropdownToggle className="custom-font text-right nav-user-icon" nav >          
            <div>
              <svg width="30" height="30" viewBox="0 0 60 60" fill="#ECE81A" xmlns="http://www.w3.org/2000/svg">
              <path d="M41.25 22.5C41.25 25.4837 40.0647 28.3452 37.955 30.455C35.8452 32.5647 32.9837 33.75 30 33.75C27.0163 33.75 24.1548 32.5647 22.045 30.455C19.9353 28.3452 18.75 25.4837 18.75 22.5C18.75 19.5163 19.9353 16.6548 22.045 14.545C24.1548 12.4353 27.0163 11.25 30 11.25C32.9837 11.25 35.8452 12.4353 37.955 14.545C40.0647 16.6548 41.25 19.5163 41.25 22.5V22.5Z" fill="#ECE81A"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M0 30C0 22.0435 3.16071 14.4129 8.7868 8.7868C14.4129 3.16071 22.0435 0 30 0C37.9565 0 45.5871 3.16071 51.2132 8.7868C56.8393 14.4129 60 22.0435 60 30C60 37.9565 56.8393 45.5871 51.2132 51.2132C45.5871 56.8393 37.9565 60 30 60C22.0435 60 14.4129 56.8393 8.7868 51.2132C3.16071 45.5871 0 37.9565 0 30V30ZM30 3.75C25.0567 3.75026 20.2139 5.14636 16.029 7.7776C11.8441 10.4088 8.48731 14.1683 6.34487 18.6232C4.20242 23.0782 3.36145 28.0475 3.91874 32.9593C4.47604 37.8712 6.40895 42.5258 9.495 46.3875C12.1575 42.0975 18.0188 37.5 30 37.5C41.9812 37.5 47.8388 42.0937 50.505 46.3875C53.5911 42.5258 55.524 37.8712 56.0813 32.9593C56.6386 28.0475 55.7976 23.0782 53.6551 18.6232C51.5127 14.1683 48.1559 10.4088 43.971 7.7776C39.7861 5.14636 34.9433 3.75026 30 3.75V3.75Z" fill="#ECE81A"/>
              </svg>
            </div>
        </DropdownToggle>
        <DropdownMenu>          
          <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      </>
    ) : (
      <NavItem className='mx-2'>
        <NavLink tag={Link} to={"/login"}>
          Login
        </NavLink>
      </NavItem>
    );
}