import { CoverKansasSurveyComponent } from "components/Survey/CoverKansasSurveyComponent";
import { SurveyCreatorWidget } from "components/SurveyCreatorWidget";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { ApiEndpoints, ApplicationPermissions, apiClient, coverKansasJSON } from "utilities";
import { BsFillGearFill } from "react-icons/bs";
import "./CoverKansas.scss";
import { trackPromise } from "react-promise-tracker";
import { useUserContext } from "contexts";

export const CoverKansas = () => {
  const surveyName = "CoverKansas";
  const reportingWeekDays = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  const [surveyTemplate, setSurveyTemplate] = useState({
    version: 0, 
    template: coverKansasJSON
  });
  
  const user = useUserContext();
  const isAdmin = user?.hasPermission(ApplicationPermissions.CoverKansas.UpdateForm);
  const [isEditorMode, setIsEditorMode] = useState(false);
  const [editWeekStartOpen, setEditWeekStartOpen] = useState(false);
  const [shouldRerender, setShouldRerender] = useState(false);
  const [navigator, setNavigator] = useState(null);
  const [navigatorClinic, setNavigatorClinic] = useState(null);
  const [navigatorClinicId, setNavigatorClinicId] = useState(null);
  const [reportingWeek, setReportingWeek] = useState();
  const [reportingWeekStartDay, setReportingWeekStartDay] = useState(5);
  const [navigatorId, setNavigatorId] = useState(null);
  
  const toggleEditorMode = () => {
    setNavigator(null);
    setNavigatorClinic(null);
    setNavigatorClinicId(null);
    setNavigatorId(null);
    setIsEditorMode(!isEditorMode);
    setShouldRerender(!shouldRerender);
  };

  // TODO - JIRA #191 Replace local storage with API call to get from App DB
  const apiGetCurrentSurveyTemplate = async () => {
    await trackPromise(apiClient.get("SurveyTemplate/CoverKansas").then((response)=>
     {
      if(response){
      const data={
        version: response.data.version, 
        template: JSON.parse(response.data.template)
      };
      setSurveyTemplate(data);
    }
    }));

  }

  const insertData = async (survey) => {
    const data = {
      Data: JSON.stringify({
        NavigatorAccountId: navigatorId,
        NavigatorName: navigator,
        ClinicName: navigatorClinic,
        ClinicId: navigatorClinicId,
        Reportingweekstart: reportingWeek ,
        Version: surveyTemplate.version,
        SubmitionDate: new Date(),
        Questions: surveyTemplate.template,
        Answers: survey.data,
      }),
      NavigatorAccountId: navigatorId,
      Version: surveyTemplate.version,
      Reportingweekstart: reportingWeek ,
  };

    const url = "CoverKansasData";
    await trackPromise(apiClient
      .put(url, data, {
        // @ts-ignore
        toastConfig: {
          showSuccess: true,
          successMessage: `Navigation Assistance Report submitted successfully!`,
        },
      })
      .then(
        () => {
          console.log("");
        } 
      )
      .catch((error) => console.log(error.response.request._response)));
  };
  const submitSurveyResponses = (survey) => {
  const result = survey.data;
  survey.getAllQuestions().forEach(function(question) {
    if(result[question.name] === undefined && question.html ===undefined) {
        result[question.name] = null; 
    }
    });
    survey.data=result;
    insertData(survey);
    setTimeout(() => {
      setShouldRerender(!shouldRerender);
    }, 2000);
  }

  const handleUserLookup = async (event) => {
    if (event.currentTarget.value.length < 6) return;
    await trackPromise(apiClient.get(ApiEndpoints.CoverKansas.GetNavigator.replace("{id}", event.currentTarget.value))
              .then((response) => {
                setNavigator(`${response.data.firstName} ${response.data.lastName}`);
                setNavigatorClinic(response.data.clinicName);
                setNavigatorClinicId(response.data.clinicId);
                setNavigatorId(event.target.value);
              }));
  }

  const updateReportingWeekStartDay = async (startDay) => {
    await trackPromise(apiClient.put(ApiEndpoints.CoverKansas.Settings.Create, {
      id: "reportingWeekStartDay",
      value: startDay
    }, {
      // @ts-ignore
      toastConfig: {
        showSuccess: true,
        successMessage: `Reporting Week start day successfully updated to ${reportingWeekDays[startDay]}.`,
      },
    })
    .then(() => {
      setEditWeekStartOpen(false);
      setShouldRerender(!shouldRerender);
    }));
  }

  const getReportingWeeks = () => {
    const today = new Date();

    const date = new Date(today);
    const diff = date.getDay() - reportingWeekStartDay;
    // starting 8 weeks ago, map 16 reporting weeks
    date.setDate(date.getDate() - diff - 56);
    const weeks = [];
    for (var i = 0; i < 16; i++) {
      const weekStart = new Date(date);
      weekStart.setHours(0,0,0,0);
      const advance = 7 * i;
      weekStart.setDate(weekStart.getDate() + advance);
      const weekEnd = new Date(weekStart);
      const nextWeek = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6);
      nextWeek.setDate(weekStart.getDate() + 7);

      weeks.push({
          value: weekStart,
          isCurrentWeek: (today >= weekStart && today < nextWeek),
          displayDate: `${weekStart.toLocaleDateString()} - ${weekEnd.toLocaleDateString()}`
      })
      if(weeks[i].isCurrentWeek && reportingWeek === undefined )
        // @ts-ignore
        setReportingWeek(weekStart.toISOString());
    }

    return weeks;
  }

  useEffect(() => {
    trackPromise(apiClient.get(ApiEndpoints.CoverKansas.Settings.View).then((response) => {
      setReportingWeekStartDay(parseInt(response.data[0].value));
    }))
    // TODO - JIRA #191 Replace local storage with API call to get from App DB
    apiGetCurrentSurveyTemplate().then((response) => {
      // @ts-ignore
      if (response) {
        setSurveyTemplate(JSON.parse(response));
      }
    });
  }, [shouldRerender]);

  return (
    <>
      <div className="d-flex justify-content-between my-3">
        <h3>Cover Kansas</h3>
        {isAdmin && 
          <Button
            outline
            color="primary"
            className="pill-btn"
            onClick={toggleEditorMode}
          >
            {!isEditorMode ? "Open" : "Close"} Form Editor
          </Button>
        }
      </div>
      {isAdmin && isEditorMode ? (
        <SurveyCreatorWidget
          surveyName={surveyName}
          template={surveyTemplate.template}
        />
      ) : (
        <>
          {navigator && navigatorClinic &&
            <Col lg={8} className="m-auto">
              <p className="h4 mb-0"><strong>{navigator}, {navigatorClinic}</strong></p>
            </Col>
          }
          <Col lg={8} className="m-auto">
            <Form>
              <FormGroup className="row mt-4">
                <Label sm={3} for="userID"><b>Navigator Account ID</b></Label>
                <Col sm={8}>
                  <Input id="userID" name="userID" required onChange={async (event) => await handleUserLookup(event)} autoFocus={true} />
                </Col>
              </FormGroup>
              <FormGroup className="row g-3 align-items-center">
                <Label sm={3} for="reportingWeek"><b>Reporting Week</b></Label>
                <Col sm={8}>
                  <Input
                    className="mb-1"
                    type="select"
                    // @ts-ignore
                    onChange={(e) => {setReportingWeek(e.currentTarget.value)}}
                  >
                      {getReportingWeeks().map((week, index) => {
                        return (
                          <option key={index} selected={week.isCurrentWeek} value={week.value.toISOString()}>{week.displayDate}</option>
                        )
                      })}
                  </Input>
                </Col>
                <Col>
                  {editWeekStartOpen === false && 
                    <Button
                      color="primary"
                      outline
                      onClick={() => setEditWeekStartOpen(true)}
                    >
                      <BsFillGearFill size="25"/>
                    </Button>
                  }
                </Col>
              </FormGroup>
              <FormGroup className="row g-3 align-items-center">
              {editWeekStartOpen === true &&
                <>
                  <Label sm={3} for="reportingWeekStart"><b>Reporting Week Start</b></Label>
                  <Col sm={4}>
                    <Input
                      className="mb-1"
                      type="select"
                      onChange={(e) => {updateReportingWeekStartDay(e.currentTarget.value)}}
                    >
                      {reportingWeekDays.map((day, index) => {
                        return (
                          <option key={index} value={index} selected={reportingWeekStartDay === index}>{day}</option>
                        )
                      })
                      }
                    </Input>
                  </Col>
                </>
                }
              </FormGroup>
            </Form>
          </Col>
          <CoverKansasSurveyComponent
            json={surveyTemplate.template}
            handleSubmit={(survey) => {submitSurveyResponses(survey)}}
        />
        </>
      )}
    </>
  );
};
