import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUserContext } from 'contexts';

function PrivateRoute({ component: Component, ...rest }) {
    const user = useUserContext();
    return (
        <Route {...rest} render={props => {
            if (!user.user) {
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
            
            return <Component {...props} />
        }} />
    );
}
    
export { PrivateRoute };