import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ApplicationPermissions, sideMenuItemsNDH } from 'utilities';
import { FormStepperComponent } from 'components/FormStepperComponent';
import { useUserContext } from 'contexts';
import SideBar from 'components/SideBar/SideBar';
import { ClinicInformation } from 'components/ClinicManagement/ClinicInformation';
import { ActiveAccounts } from 'components/ClinicManagement/ActiveAccounts';
import { HasPermission } from 'components/HasPermission';
import { ManualDataEntry } from './ManualDataEntry';
import { ReviewDataEntry } from './ReviewDataEntry';
import { UDSDataUpload } from './UDSDataUpload';

const stepRoutes = 
{
  "ClinicDetails": 0,
  "UDSDataUpload": 1,
  "ManualDataEntry": 2,
  "DataEntryReview": 3,
}

export const ClinicDataEntry = () => {

  const location = useLocation();
  const history = useHistory();
  const userContext = useUserContext();
  
  const [activeStep, setActiveStep] = useState(0);
  const [clinicData, setClinicData] = useState({});  
  
  useEffect(() => {
    if(userContext?.clinicId !== '')
      setClinicData({"id":userContext.clinicId});
  }, [userContext])

  const handleNextStep = (nextStep) => {
    if(nextStep + 1 < 7) {
      setActiveStep(nextStep + 1);
      Object.keys(stepRoutes).forEach( key => {
        if( stepRoutes[key] === nextStep + 1 ){
          const url = (sideMenuItemsNDH[key] && sideMenuItemsNDH[key]['items'] && sideMenuItemsNDH[key]['items'].length > 0 && sideMenuItemsNDH[key]['items'][0].target !== "#") 
            ? sideMenuItemsNDH[key]['items'][0].target : `/ClinicDataEntry/${key}#`;

          history.push(url, location.state)
        }
      })
    }
  }

  const setNextActiveStep = (stepNumber) => {
    handleNextStep(stepNumber - 1);
  }
  
  useEffect(() => {

    if( location.state && location.state['clinicUpdate']) {
      clinicData["clinicUpdate"]=location.state['clinicUpdate'];
      setClinicData(clinicData);
    }

    const routeKey = Object.keys(stepRoutes)?.find( key => location.pathname.split('#')[0].indexOf(key) > 0 ) || "";
    if(routeKey)
      setActiveStep(stepRoutes[routeKey]);

  }, [location, setClinicData, setActiveStep]);


  return (
    <FormStepperComponent activeStep={activeStep} setActiveStep={setNextActiveStep}>
        <StepperComponent stepNumber={0} label={"Clinic Details"}  handleContinue={handleNextStep}>
              {(location.pathname.indexOf('ClinicInformation') > -1 || location.hash.indexOf('ClinicInformation')> -1)
              && (
              <HasPermission permission={ApplicationPermissions.ClinicManagement.ViewClinicDetails}>
                <ClinicInformation clinicData={clinicData} />
              </HasPermission>
              )
              }
              {(location.pathname.indexOf('ActiveAccounts') > -1 || location.hash.indexOf('ActiveAccounts')> -1)
              && (
              <HasPermission permission={ApplicationPermissions.ClinicManagement.ViewClinicUsers}>
                <ActiveAccounts clinicData={clinicData} route={'ClinicDataEntry/ClinicDetails'} />)
              </HasPermission>
              )
              }
        </StepperComponent>
        <StepperComponent stepNumber={1} label={"UDS Data Upload"} handleContinue={handleNextStep} >
            <HasPermission permission={ApplicationPermissions.ClinicManagement.UDSFileUpload}>
                <UDSDataUpload />
            </HasPermission>
        </StepperComponent>
        <StepperComponent stepNumber={2} label={"Manual Data Entry"}  handleContinue={handleNextStep}>
            <HasPermission permission={ApplicationPermissions.ClinicManagement.UpdateDataEntry}>
                <ManualDataEntry />
            </HasPermission>
        </StepperComponent>
        <StepperComponent stepNumber={3} label={"Review"} handleContinue={handleNextStep}>
            <HasPermission permission={ApplicationPermissions.ClinicManagement.SubmitDataEntry}>
                <ReviewDataEntry clinicData={clinicData} />
            </HasPermission>
        </StepperComponent>
    </FormStepperComponent>
  );
};


const StepperComponent = ({stepNumber, label = "", handleContinue = () => {}, children}) => {
  
  const location = useLocation();

  let sideMenuKey = Object.keys(sideMenuItemsNDH)?.find( key => (location.pathname+location.hash).indexOf(key) > 0 ) || "";

  if(location.pathname.indexOf('DataEntryReview') > 0){
    sideMenuKey = "DataEntryReview";
  }

  return(
    <Container>
      <Row>

        {sideMenuItemsNDH[sideMenuKey] && (
          <Col md={3}>
            <SideBar toggle={() => {}} isOpen={undefined} sideMenuItems={sideMenuItemsNDH[sideMenuKey]} state={location.state}/>
          </Col>
        )}

        <Col md={sideMenuItemsNDH[sideMenuKey] ? 9 : 12}>
          {children}
        </Col>

      </Row>
    </Container>)
}