import React, { useState } from 'react';
import { Row, Col, ButtonGroup, Button } from 'reactstrap';
import { apiClient } from '../../../utilities';
import { Link } from 'react-router-dom';
import { SmartGrid } from 'components/smart-grid';
import { ColDisplayConfig } from "models";
import { useUserContext } from 'contexts';
import { DeactivationRequests } from './DeactivationRequests';
import { ApplicationPermissions } from '../../../utilities';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

export const User = ({clinicId, route='AccountManagement'}) => {
    const [shouldRefresh, setShouldRefresh] = useState(true);
    const userContext = useUserContext();
    const history = useHistory();
    const canView = clinicId ? userContext?.hasPermission(ApplicationPermissions.ClinicManagement.ViewClinicUsers): userContext?.hasPermission(ApplicationPermissions.AccountManagement.ViewUsers);
    const canCreate = clinicId? userContext?.hasPermission(ApplicationPermissions.ClinicManagement.CreateClinicUsers):userContext?.hasPermission(ApplicationPermissions.AccountManagement.CreateUsers);
    const canRequestDeactivation = clinicId? userContext?.hasPermission(ApplicationPermissions.ClinicManagement.RequestUserDeactivation):userContext?.hasPermission(ApplicationPermissions.AccountManagement.RequestUserDeactivation);
    const canApproveDeactivation = clinicId? userContext?.hasPermission(ApplicationPermissions.ClinicManagement.ApproveUserDeactivation):userContext?.hasPermission(ApplicationPermissions.AccountManagement.ApproveUserDeactivation);

    const linkUserCell = (row) => (
      <Link to={{
        pathname:`/${route}/User/${row.email}`,
      }}
      //target="_blank"
    >
      {row.firstName}
    </Link>
    );
  
    const onDeleteClick = (row) => {
      trackPromise(apiClient.post(`User/RequestDeactivationAsync`,
          {
            toastConfig:{
              showSuccess: true,
              successMessage: `A request was submitted to deactivate ${userContext.user?.username}`
            },
            UserToRemoveId: row.id,
            RequestingUserId: userContext.user?.username
          }
      )
      .then((response) => {
        toast.success(`A request was submitted to deactivate ${row.email}`);
          setShouldRefresh(true);
          history.push(`/${route}/Users`)
      }));
    }

    const displayStatus = (row) => {
      return row.active? 'Active' : 'Inactive';
    }
  
    const deleteUserCell = (row) => {
      if (!canRequestDeactivation) return <></>;
      return (
        <ButtonGroup className="role-actions-button-group">         
          {row.active ? 
            <Button color='danger' 
              className="btn btn-danger" 
              onClick={() => onDeleteClick(row)}
            >
              Deactivate
            </Button> : 
            <Button color='danger' 
              className="btn btn-danger" 
              onClick={() => onDeleteClick(row)}
            >
              Reactivate
            </Button>  
          }
        </ButtonGroup>
    )}

    return (
      <div className="col-md-12">
        <h3 className='title'>Users</h3>
        {canCreate &&
          <Row>
            <Col className ='col-sm-10' sm={6}>
              <form>
                <Link to={"/" + route + "/Users/Create"}>
                  <Button
                    color="primary"
                    className="mt-3 pill-btn"
                    outline
                  >
                    Create User
                  </Button>
                </Link>     
              </form>
            </Col>
          </Row>
        }
        {canView &&
          <Row>
              <SmartGrid
                url={clinicId?`User/GetAllAsync/`+clinicId :`User/GetAllAsync/` }
                columnConfig={[
                  new ColDisplayConfig("firstName", "First Name", false, true, linkUserCell),
                  new ColDisplayConfig("lastName", "Last Name", false, true),
                  new ColDisplayConfig("email", "Email", false, false),
                  new ColDisplayConfig("clinicName", "Clinic Name", false, false),
                  new ColDisplayConfig("active", "Status", false, false, displayStatus),
                  new ColDisplayConfig("", "", false, false, deleteUserCell)
                ]}
                pageSize={50}
                pageable
                refreshGrid={shouldRefresh}
              />
          </Row>
        }
          {canApproveDeactivation &&
            <Row>
              <DeactivationRequests shouldRefresh={shouldRefresh} />
            </Row>
          }
      </div>
    );
};