import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardText, Button, ButtonGroup } from 'reactstrap';
import './Role.scss';
import { apiClient, ApplicationPermissions } from '../../../utilities';
import { Link } from 'react-router-dom';
import { useUserContext } from 'contexts';
import { trackPromise } from 'react-promise-tracker';

// Accepts the array and key
const groupBy = (array, key) => {
  // Return the end result

    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
      }, {}); // empty object is the initial value for result object
};

export const Role = () => {
  const user = useUserContext();
  const [roles, setRoles] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const canView = user?.hasPermission(ApplicationPermissions.AccountManagement.ViewRoles);
  const canCreate = user?.hasPermission(ApplicationPermissions.AccountManagement.CreateRoles);
  const canUpdate = user?.hasPermission(ApplicationPermissions.AccountManagement.UpdateRoles);
  const canDelete = user?.hasPermission(ApplicationPermissions.AccountManagement.DeleteRoles);
  
  const onDeleteClick = (id) => {
    trackPromise(apiClient.delete(`Role/DeleteAsync/${id}`,
        {
          // @ts-ignore
          toastConfig:{
            showSuccess: true,
            successMessage: "Role deleted successfully"
          }
        }
    )
    .then((response) => {
      setShouldRefresh(true);
    }));
  }

  useEffect(() => {
    if(shouldRefresh){
      trackPromise(apiClient.get(`Role/GetAllAsync`).then((response) => {
        setShouldRefresh(false);
        setRoles(response.data);
      }).catch(resp => {
        setRoles([]);
      }));
    }
    }, [shouldRefresh]);

    return (
      <Col>
        <h3 className='title' >User Roles</h3>
        {canCreate &&
          <Row>
            <Col className ='col-sm-10'>
              <form>
                <Link to="/AccountManagement/RoleAdd">
                  <Button
                    color="primary"
                    className="mt-3 pill-btn"
                    outline
                  >
                    Add Role
                  </Button>
                </Link>
              </form>
            </Col>
          </Row>
        }
        <Row>
          <Col md={12}>
            {roles?.map((role, index) => {
              const permissionsbyRole= (groupBy(role.applicationPermissions,'groupName'));
              if (!canView) return <></>;
              return (
                <div key={index}>
                  <Row className="my-4">
                    <Col md={8}>
                      <h5>{role.name}</h5>
                    </Col>
                    <Col md={4}>
                      <ButtonGroup className="role-actions-button-group">
                      {canUpdate &&
                        <Link to={{
                            pathname:'/AccountManagement/RoleAdd',
                            state : { roleUpdate: role}
                            }}>
                          <Button>Update</Button>
                        </Link>
                      }
                      {canDelete &&
                        <Button color='danger' className="btn btn-danger" onClick={() => onDeleteClick(role.id)}>
                          Delete
                        </Button>
                      }
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <Col>
                      {Object.keys(permissionsbyRole)?.map((group, idx) =>{
                        return(
                          <li key={idx} className="mb-3">
                            {permissionsbyRole[group]?.map((permission, i) => {
                              return (
                                <React.Fragment key={i}>
                                  {`${permission.name.replace(group.replace(/\s/g, '' ), "").replace(/([A-Z])/g, ' $1').trim() }, ` }
                                </React.Fragment>)
                              })}
                              <b>{group}</b>
                            </li>
                          );
                        })
                      }
                    </Col>
                  </Row>
                  <hr />
                </div>
              );
              })}
          </Col>
        </Row>
      </Col>
    );
};