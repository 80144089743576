import { ClinicSurveyComponent } from 'components/Survey/ClinicSurveyComponent';
import React, { useEffect, useState } from 'react';
import { patientDataJSON } from '../../utilities/surveys/patientDataJSON';
import { apiGetCurrentSurveyTemplate } from 'utilities';

export const PatientData = () => {
  const surveyName = 'PatientData';
  const [surveyJSON, setSurveyJSON] = useState();
  const [surveyVersion, setSurveyVersion] = useState(0);

  useEffect(() => {
    apiGetCurrentSurveyTemplate(surveyName,patientDataJSON).then((response)=>
    {
      if(response)
      {
        setSurveyJSON(response.template);
        setSurveyVersion(response.version);
      }
    })
  }, []);

  return (
    <>
    {surveyJSON && <ClinicSurveyComponent json={surveyJSON} SurveyName={surveyName} version={surveyVersion} />}
    </>
  )
}