import React, {useState, useEffect} from 'react';
import { AuthService } from 'services';
import { useUserContext } from 'contexts';

export const HasPermission = ({children, permission}) => {
    
    const userContext = useUserContext();   
    const [hasPermission, setHasPermission] = useState(false);
    
    useEffect(() => {
        setHasPermission(userContext.permissions.includes(permission));
    }, [userContext.permissions]);

    if(userContext.isLoggedIn && hasPermission){
        return children;
    }
    
    return (<></>);
};

export const HasAnyPermission = ({children, permissions}) => {
    const [hasPermission, setHasPermission] = useState(false);
    const [isPending, setIsPending] = useState(true);

    useEffect(() => {
        if(isPending){
            AuthService.validateAnyPermission(permissions)
            .then(isValid => {
                setHasPermission(isValid);
                setIsPending(false);
            });
        }
    }, []);

    if(!isPending && hasPermission){
        return children;
    }
    
    return (<></>);

};