import React, { useContext, createContext } from "react";
import { toast, ToastContainer } from "react-toastify";

const ToastContext = createContext(undefined);

export const ToastContextProvider = ({ children }) => {

  const error = async (content) => {
    toast.error(content);
  };

  const success = async (content) => {
    toast.success(content);
  };

  return (
    <ToastContext.Provider value={{ error, success }}>
        {children}
        <div aria-live="assertive" role="alert">
          <ToastContainer
            position={toast.POSITION.BOTTOM_RIGHT}
            newestOnTop={true}
            rtl={false}
            draggable={false}
            className="col-lg-4 col-md-6 col-sm-8 col-xs-10"
            role="status"
          ></ToastContainer>
        </div>
      </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToastContext must be used within a ToastContextProvider");
  }
  return context;
};
