import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Label, Input, Button, Form } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useUserContext } from "contexts";
import { AuthService } from "services";
import { toast } from "react-toastify";

export const ResetPassword = ({ userName, sessionId }) => {
  const history = useHistory();
  const userContext = useUserContext();

  const [userPassword, setUserPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isMatch, setIsMatch] = useState(false);

  useEffect(() => {
    if (userPassword === confirmPassword && userPassword.length > 0) {
      setIsMatch(true);
    }
  }, [userPassword, confirmPassword]);

  const submitResetPassword = () => {
    AuthService.submitResetPassword(userName, userPassword, sessionId)
      .then((response) => {
        const user = response.data;
        userContext.setUser(user);
        toast.success(`${userName} was logged in successfully!`);
        history.push("/");
      })
      .catch((err) => {
        console.error(err, { err });
      });
  };

  return (
    <Form onSubmit={submitResetPassword}>
      <FormGroup className="mt-5">
        <Row className="d-flex justify-content-around">
          <Col lg={6}>
            <Label for="userPasswordInput">
              <b>Password</b>
            </Label>
            <Input
              id="userPasswordInput"
              name="userPasswordInput"
              placeholder="Please enter your password here"
              type="password"
              onChange={(e) => setUserPassword(e.target.value)}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row className="d-flex justify-content-around">
          <Col lg={6}>
            <Label for="userPasswordInput">
              <b>Confirm Password</b>
            </Label>
            <Input
              id="userConfirmPasswordInput"
              name="userConfirmPasswordInput"
              placeholder="Please confirm your password here"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup className="row d-flex justify-content-around">
        <Col lg={6}>
          <Button
            type="submit"
            id="resetPasswordSubmitButton"
            name="resetPasswordSubmitButton"
            color="primary"
            className="float-end mt-3 px-5 pill-btn"
            outline
          >
            Reset Password
          </Button>
        </Col>
      </FormGroup>
    </Form>
  );
};
