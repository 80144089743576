import React, { useEffect, useState } from 'react';
import { User } from 'components/AccountManagement';
import { useUserContext } from 'contexts';

export const ActiveAccounts = ({ clinicData, route }) => {
    const userContext = useUserContext();
    const [clinicId, setClinicId] = useState(userContext.clinicId);
    
    useEffect(() => {
        if(clinicData && clinicData["id"] !== undefined) 
            setClinicId(clinicData["id"]);
    }, [clinicData]);
    
    if(clinicId !== '')
     return (
            <User clinicId={clinicId} route={route}/>        
    )
    else
    return(<>No Clinic Assigned</>);
};