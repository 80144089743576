import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label, List } from "reactstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import "./NetworkDataHub.scss";
import { useForm } from "react-hook-form";
import { apiClient, ApiEndpoints, ApplicationPermissions } from "utilities";
import { ReactSortable } from "react-sortablejs";
import { useUserContext } from "contexts";
import { trackPromise } from "react-promise-tracker";


export const Resources = () => {
  const user = useUserContext();
  const canCreate = user.hasPermission(ApplicationPermissions.NetworkDataHub.CreateResources);
  const canUpdate = user.hasPermission(ApplicationPermissions.NetworkDataHub.UpdateResources);
  const canDelete = user.hasPermission(ApplicationPermissions.NetworkDataHub.DeleteResources);
  const { handleSubmit } = useForm();
  const [resources, setResources] = useState([]);
  const [isAddMode, setIsAddMode] = useState(false);
  const [shouldRerender, setShouldRerender] = useState(false);

  const handleReorder = async (event) => {
    const url = `${ApiEndpoints.NetworkDataHub.Resources.Reorder}`;
        const prevResources = [...resources]; // new scoped array
    const [movedItem] = prevResources.splice(event.oldDraggableIndex, 1);
    const newResources = prevResources;
    newResources.splice(event.newDraggableIndex, 0, movedItem);

    for (var i = 0; i < newResources.length; i++) {
      newResources[i].order = i;
    }

    await trackPromise(apiClient.put(url, newResources));
  };

  const handleAdd = () => {
    setIsAddMode(true);
    setResources([...resources, { title: "", url: "" }]);
  };

  const handleCancel = (resource, index) => {
    resources[index].isEditMode = false;
    setResources([...resources]);
    setShouldRerender(!shouldRerender);

    if (!resource.isEditMode) {
      setIsAddMode(false);
    }
  };

  const insertData = async (index, resource) => {
    const url = `${ApiEndpoints.NetworkDataHub.Resources.Create}`;
    resource.order = index;
    await trackPromise(apiClient
      .put(url, resource, {
        // @ts-ignore
        toastConfig: {
          showSuccess: true,
          successMessage: `Resource ${
            resource.isEditMode ? "updated" : "created"
          } successfully!`,
        },
      })
      .then(() => {
        setIsAddMode(false);
        setShouldRerender(!shouldRerender);
      }));
  };

  const handleEdit = (index, resource) => {
    resources[index] = { ...resource, isEditMode: true };
    setResources([...resources]);
  };

  const handleChange = (event, index) => {
    const resource = resources[index];
    resource[event.target.name] = event.target.value;
    setResources([...resources]);
  };

  const handleDelete = (id) => {
    setResources(resources.filter((a) => a.id !== id));
    trackPromise(apiClient
      .delete(`${ApiEndpoints.NetworkDataHub.Resources.Delete}/${id}`, {
        // @ts-ignore
        toastConfig: {
          showSuccess: true,
          successMessage: `Resource deleted successfully!`,
        },
      }));
  };

  useEffect(() => {
    trackPromise(apiClient
      .get(ApiEndpoints.NetworkDataHub.Resources.View)
      .then((response) => {
        setResources(response.data);
      })
      .catch((err) => {
        console.error(err);
        setResources([]);
      }));
  }, [setResources, shouldRerender]);

  return (
    <div
      id="resources"
      className="tertiary-border px-4 py-3 d-flex flex-column justify-content-between"
    >
      <div>
        <h4 className="text-center">
          <u>Resources</u>
        </h4>
        <List type="unstyled" className="px-3 py-2">
          <ReactSortable
            list={resources}
            disabled={!canUpdate}
            setList={setResources}
            onUpdate={(event) => {
              handleReorder(event);
            }}
          >
            {resources?.map((resource, index) => {
              if (resource.isEditMode || resource.id == null) {
                return (
                  <li key={index} className="mt-3">
                    <Form
                      onSubmit={handleSubmit(
                        async () => await insertData(index, resource)
                      )}
                    >
                      <Label for={`title-${index}`}>
                        <b>
                          {resource.isEditMode ? "Update" : "New"} Announcement
                        </b>
                      </Label>
                      <FormGroup className="mt-1">
                        <Input
                          id={`title-${index}`}
                          name="title"
                          placeholder="Title"
                          type="text"
                          onChange={(event) => handleChange(event, index)}
                          value={resource.title}
                        />
                        <Label hidden for={`title-${index}`}>
                          Title
                        </Label>
                      </FormGroup>
                      <FormGroup className="mt-1">
                        <Input
                          id={`url-${index}`}
                          name="url"
                          placeholder="Url"
                          type="url"
                          onChange={(event) => handleChange(event, index)}
                          value={resource.url}
                        />
                        <Label hidden for={`url-${index}`}>
                          Url
                        </Label>
                      </FormGroup>
                      <FormGroup className="mt-1 submit-btn-group">
                        <Button
                          type="submit"
                          color="primary"
                          className="float-end ms-1 btn-sm pill-btn"
                          onClick={() => {}}
                        >
                          Save
                        </Button>
                        <Button
                          color="secondary"
                          className="float-end ms-1 btn-sm pill-btn"
                          onClick={() => handleCancel(resource, index)}
                        >
                          Cancel
                        </Button>
                      </FormGroup>
                    </Form>
                  </li>
                );
              } else {
                return (
                  <li key={index} className={`${canUpdate ? "moveable-item" : ""} m-2`}>
                    <span>
                      {canUpdate &&
                        <FaEdit
                          className="me-1 link-primary"
                          onClick={(event) => {
                            handleEdit(index, resource);
                          }}
                        />
                      }
                      {canDelete && 
                        <FaTrashAlt
                          className="me-3 link-primary"
                          onClick={() => handleDelete(resource.id)}
                        />
                      }
                    </span>
                    <a href={resource.url}>{resource.title}</a>
                    <hr />
                  </li>
                );
              }
            })}
          </ReactSortable>
        </List>
      </div>
      <div>
        {!isAddMode && canCreate && (
          <Button
            color="primary"
            className="float-end mt-3 pill-btn"
            outline
            onClick={() => handleAdd()}
          >
            + Resource
          </Button>
        )}
      </div>
    </div>
  );
};
