import React, { useEffect, useState } from "react";
import { Label, Input, Row, Col, Form, FormGroup, Button, FormText } from "reactstrap";
import "./Notifications.scss";
import { apiClient, ApiEndpoints, ApiRoutes } from "../../../utilities";
import { useLocation, useHistory, Link } from "react-router-dom";
import { NIL as NIL_UUID } from "uuid";
import { useUserContext } from "contexts";
import moment from "moment/moment";
import { trackPromise } from "react-promise-tracker";

export const NotificationsAdd = () => {
  const history = useHistory();
  const location = useLocation();
  const updateNotifications = location.state?.notificationsUpdate;
  const [isEditMode] = useState(updateNotifications ? true : false);
  const [clinics, setClinics] = useState([]);
  const { userID } = useUserContext()?.user;
  const [shouldRerender, setShouldRerender] = useState(false);


  const [notificationData, setNotificationData] = useState({
    id: updateNotifications?.id,
    type: updateNotifications?.type ?? "Actionable",
    messageBody: updateNotifications?.messageBody,
    url: updateNotifications?.url,
    createdBy: updateNotifications?.createdBy,
    startDate: updateNotifications?.startDate,
    endDate: updateNotifications?.endDate ?? null,
    clinicIds: updateNotifications?.clinicIds?.split(",") ?? []
  });

  const convertDateForDisplay = (dateString) => {
    return moment(dateString).format("YYYY-MM-DD HH:mm");
  }

  const handleChange = (event) => {
    setNotificationData({
      ...notificationData,
      [event.target.name]: event.target.value ?? "",
    });
  };

  const handleChangeClinics = (event) => {
    const newSelected = [...event.target.options].filter(o => o.selected === true).map(o => o.value);
    setNotificationData({
      ...notificationData,
      [event.target.name]: newSelected ?? [],
    });
  };

  useEffect(() => {
    trackPromise(apiClient
      .get("Clinic/GetAllAsync")
      .then((response) => {
        setClinics([...response.data.results]);
        setShouldRerender(true);
      }));

  }, [shouldRerender]);

  const insertData = async (event) => {
    event.preventDefault();
    const notificationFormData = notificationData;
    notificationFormData.id = isEditMode ? notificationData.id : NIL_UUID;
    notificationFormData.createdBy = userID;
    notificationFormData.startDate = moment(notificationData.startDate).utc();
    notificationFormData.endDate = notificationData.endDate ? moment(notificationData.endDate).utc() : null;
    notificationFormData.clinicIds = notificationData.clinicIds.join(",");
    
    const url = `${ApiEndpoints.AccountManagement.Notifications.CreateClinicNotification}`;
    await trackPromise(apiClient
      .put(url, notificationFormData, {
        // @ts-ignore
        toastConfig: {
          showSuccess: true,
          successMessage: `Notification ${
            isEditMode ? "updated" : "created"
          } successfully!`,
        },
      })
      .then(() => history.push(ApiRoutes.AccountManagement.Notifications.View))
      .catch((error) => console.log(error)));
  };

  return (
    <>
      <Row>
        <p className="primary title">
          {isEditMode ? "Update" : "Add"} Notification
        </p>
        <Col md={10}>
          <Form
            onSubmit={insertData}
            className={"mb-5"}
          >
            <FormGroup>
              <Label for="" className="label ms-0 m-1">
                Type
              </Label>
                <FormText>
                  <p className="ms-2">Select 'Actionable' to create a notification that can include a URL link. Select 'Informational' to create a notification that appears as a banner across the top of the application.</p>
                </FormText>
              <FormGroup>
                <Input
                  id="actionable"
                  name="type"
                  className="m-2"
                  type="radio"
                  checked={notificationData.type === "Actionable"}
                  onChange={handleChange}
                  value="Actionable"
                />{" "}
                <Label for="actionable">Actionable</Label>
                <Input
                  id="informational"
                  name="type"
                  className="m-2"
                  type="radio"
                  checked={notificationData.type === "Informational"}
                  onChange={handleChange}
                  value="Informational"
                />{" "}
                <Label for="informational">Informational</Label>
              </FormGroup>
            </FormGroup>
            <FormGroup row>
              <Col>
                <Label for="clinicIds" className="label ms-0">
                  Clinics
                </Label>
                <FormText>
                  <p className="ms-2">Select all clinics to receive this notification.</p>
                </FormText>
                <Input
                  id="clinicIds"
                  name="clinicIds"
                  multiple
                  required
                  onChange={handleChangeClinics}
                  type="select"
                  value={notificationData.clinicIds}
                >
                  {clinics?.map((clinic, index) => {
                    return (
                      <option 
                        key={index} 
                        value={clinic.id}
                      >
                        {clinic.name}
                      </option>
                  )})}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row className={"mt-3"}>
              <Col>
                <Label for="messageBody" className="label ms-0">
                  Message
                </Label>
                <Input
                  id="messageBody"
                  name="messageBody"
                  type="textarea"
                  value={notificationData.messageBody}
                  required
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
            {notificationData.type === "Actionable" && (
              <FormGroup row className={"mt-3"}>
                <Col>
                  <Label for="url" className="label ms-0">
                    URL <span className="label-subtext">(Optional)</span>
                  </Label>
                  <Input
                    id="url"
                    name="url"
                    type="url"
                    value={notificationData.url}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
            )}
            <FormGroup row className={"mt-3"}>
              <Col md={5}>
                <FormGroup>
                  <Label className="label ms-0">Starts</Label>
                  <Input
                    id="startDate"
                    name="startDate"
                    type="datetime-local"
                    onChange={handleChange}
                    value={convertDateForDisplay(notificationData?.startDate)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label className="label ms-0">
                    Expires <span className="label-subtext">(Optional)</span>
                  </Label>
                  <Input
                    id="endDate"
                    name="endDate"
                    type="datetime-local"
                    onChange={handleChange}
                    value={convertDateForDisplay(notificationData?.endDate)}
                  />
                </FormGroup>
              </Col>
            </FormGroup>
            <FormGroup className={"mt-5 d-flex justify-content-end"}>
              <Link
                to={{
                  pathname: ApiRoutes.AccountManagement.Notifications.View,
                }}
              >
                <Button className={"mx-2"} color="secondary">
                  Cancel
                </Button>
              </Link>
              <Button className={"mx-2"} color="primary" type="submit">
                {isEditMode ? "Update Notification" : "Create Notification"}
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </>
  );
};
