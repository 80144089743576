import axios from "axios";
import { toast } from "react-toastify";

// https://axios-http.com/docs/intro
export const apiClient = axios.create();

apiClient.interceptors.request.use(async (config) => {

  // https://axios-http.com/docs/req_config
  // set baseURL to use api by default for any relative URLs
  config.baseURL = process.env.REACT_APP_API_BASE_URL

  // add API bearer token if available
  const user = JSON.parse(localStorage.getItem('user'));

  let accessToken = user?.sessionTokens?.idToken;

  if(accessToken != null){
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
});

// Response interceptor for handling toast messages
apiClient.interceptors.response.use(
  async (resp) => {
    let toastConfig = resp?.config?.toastConfig;
    if (!toastConfig) {
      return resp;
    }

    if (toastConfig.showSuccess) {
      let message = "Request Succeeded";

      if (toastConfig.successMessage) {
        message = toastConfig.successMessage;
      }

      toast.success(message);
    }

    return resp;
  },
  async (err) => {
    let toastConfig = err?.config?.toastConfig;
    if (toastConfig?.ignoreError) {
      return err;
    }

    if (err.response.status === 403) {
      toast.error("User is not authorized to perform this action.");
    }

    let defaultError = "Request Failed.";
    let errorMessage = defaultError;
    let errorId = "";

    try {
      if (toastConfig?.errorMessage) {
        errorMessage = toastConfig.errorMessage;
      } else {
        const errResponse = err?.response?.data;
        if (errResponse.message &&
          errResponse.message.length > 0) {
          errorMessage = errResponse.message
          errorId = errResponse.errorId;
        }
      }
    } catch {
    }

    throw err;
  }
);

const parseErrorMessage = (data) => {
  if (data?.errors?.length > 0)
    return {
      message: data.errors[0],
      errorId: data.errorId,
    };
  if (data?.message) return { message: data.message };
  if (typeof data === "string") return { message: data };
  if (data?.title && data?.traceId && data?.errors) {
    return {
      message: `${data.title}: ${data.errors[Object.keys(data.errors)[0]][0]
        }. Error ID: ${data.traceId}`,
      errorId: data.traceId,
    };
  }
  if (data?.title && data?.traceId) {
    return {
      message: `${data.title}: Error ID: ${data.traceId}`,
      errorId: data.traceId,
    };
  }
  return { message: "Request Failed" };
};