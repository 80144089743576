import React, { useEffect, useState } from 'react';
import { Row, Button, ButtonGroup } from 'reactstrap';
import { SmartGrid } from 'components/smart-grid';
import { ColDisplayConfig } from "models";
import { apiClient } from '../../../utilities';
import { useHistory } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';

export const DesignationChangeRequest = ({shouldRefresh, designations}) => {

  const [needsRefresh, setNeedsRefresh] = useState(shouldRefresh);
  const history = useHistory();
  
  useEffect(() => {
    if(shouldRefresh && shouldRefresh !== needsRefresh){
      setNeedsRefresh(shouldRefresh);
    }
  }, [shouldRefresh])

  const onApproveClick = (row) => {
    var data = {
      DesignationRequestId: row.id,
      Status: 2,
      toastConfig:{
        showSuccess: true,
        successMessage: `${row.designation?.description} was successfully changed!`
      }
    };
    trackPromise(apiClient.post(`${process.env.REACT_APP_API_BASE_URL}Clinic/ProcessDesignationChangeRequestAsync`, data)
    .then((response) => {
      setNeedsRefresh(true);
      history.go(0);
    }));
  }

  const onDenyClick = (row) => {
        
    var data = {
      DesignationRequestId: row.id,
      status: 3,
    };

    trackPromise(apiClient.post(`Clinic/ProcessDesignationChangeRequestAsync`, 
      data
    ).then((response) => {
      setNeedsRefresh(true);
      history.go(0);
    }));
  }

  const descriptionCell = (row) => {
    return row.designation?.description;
  }
    const actionsCell = (row) => {
      return (
      <ButtonGroup className="role-actions-button-group">
          <Button className="btn" onClick={() => onDenyClick(row)}>
            Deny
          </Button>
          <Button color='danger' className="btn btn-danger" onClick={() => onApproveClick(row)}>
            Approve
          </Button>
    </ButtonGroup>
    )}
  

    const [columnConfig] = useState([
        new ColDisplayConfig("description", "Designation", false, false, descriptionCell),
        new ColDisplayConfig("hrsaGrantId", "HrsaGrantId", false, false),
        new ColDisplayConfig("designationDate", "Designation Date", false, false),
        new ColDisplayConfig("previouslyKnownAs", "PreviouslyKnownAs", false, false),
        new ColDisplayConfig("", "", false, false, actionsCell)
      ]);

      return (

        <div className="col-md-12">
          <h3 className='title'>Designation Change Requests</h3>
          <Row>
            <SmartGrid
              data= {designations}
              columnConfig={columnConfig}
              pageSize={50}
              pageable
              refreshGrid={needsRefresh}
            />
          </Row>
        </div>
        
      );
};