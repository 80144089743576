import React, { Children, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import { Button, Col, Container, Row } from "reactstrap";

import './FormStepperComponent.scss'; 

export const FormStepperComponent = ({ activeStep = 0, children, setActiveStep = (stepNumber) => {} }) => {
  
  const arrayChildren = Children.toArray(children);

  const stepStyleConfig = {
    activeBgColor: '#FFFFFF',
    inactiveBgColor: 'red',
    completedBgColor:'#13294B',
    size: '4em',
    fontWeight: 'bold',
    labelFontSize: '1rem'
  }

  return (
    <Container id="stepper-wrapper">
      <Stepper activeStep={activeStep} nonLinear connectorStateColors hideConnectors stepClassName={"stepssss-nav-item"} className={"step-nav-container"}>

        {Children.map(arrayChildren, (child, index) => {
          return (
          <Step 
            className={`step-nav-item ${activeStep===index?'selected-step': ''}`}
            onClick={(e) => {e.preventDefault(); setActiveStep(index);}} index={index} 
            label={child.props.label? child.props.label : ""} 
            completed={child.props.completed? child.props.completed : false} 
            disabled={child.props.disabled? child.props.disabled : false} 
            styleConfig={stepStyleConfig} 
          />);
        })}

      </Stepper>

      {Children.map(arrayChildren, (child, index) => {
        return (activeStep === index && (
          <FirstSampleForm stepNumber={index}>
            {child}
          </FirstSampleForm>
        ));
      })}

    </Container>
  );
};


const FirstSampleForm = ({ stepNumber, handleNextStep = null, handlePrevStep = null, children }) => {
  
  return(
    <>
            
      {children}
      
      {/* <Row>
        <Col sm={{size: 'auto'}}>
          <Button className="btn mt-3" color="primary" onClick={() => handlePrevStep(stepNumber-1)}>
            Prev Step
          </Button>
        </Col>
        <Col sm={{size: 'auto'}}>
          <Button className="btn mt-3" color="primary" onClick={() => handleNextStep(stepNumber+1)}>
            Next Step
          </Button>
        </Col>
      </Row> */}
    </>
  );
}