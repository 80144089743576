import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { ThreeDots} from 'react-loader-spinner';
import './LoadingSpinnerComponent.scss'; 

export const LoadingSpinnerComponent = (props) => {
const { promiseInProgress } = usePromiseTracker();

  return (
    <>
    {
      (promiseInProgress === true) ?
        <div className='loader'>
           <ThreeDots 
            height="80" 
            width="80" 
            radius="1"
            color="#ECA820" 
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            visible= {true}
            />
        </div>
      :
        null
    }
  </>
  )
};