import React from "react";
import { Alert, Container } from "reactstrap";
import "./AmendmentBanner.scss";
import { BsExclamationDiamondFill } from "react-icons/bs";

export const AmendmentBanner = ({status}) => {

    let message = '';
    switch(status){
        case 'Submitted':
            message = '\tData has been submitted. If any submitted data requires correction, you may submit an amendment request for that data from the data section.';
            break;
        case 'AmendmentRequest':
            message = '\tAn amendment for this section has been submitted and is pending approval.';
            break;
        case 'Amendment':
            message = '\tAn amendment for this section has been requested.';
            break;
        default: 
            message ='';
    }

    return(
        <>
        {message !== '' && (
            <Container >
             <Alert color="warning">
                <BsExclamationDiamondFill className="icon"/>
                <span>{message}</span>
             </Alert>
            </Container>
           )}
      </>
    );
}