import React, { useEffect, useState } from 'react';
import { staffingAndUtilizationJSON } from '../../utilities/surveys/staffingAndUtilizationJSON';
import { ClinicSurveyComponent } from 'components/Survey/ClinicSurveyComponent';
import { apiGetCurrentSurveyTemplate } from 'utilities';

export const StaffingAndUtilization = () => {
  const surveyName = 'StaffingAndUtilization';
  const [surveyJSON, setSurveyJSON] = useState();

  useEffect(() => {
    apiGetCurrentSurveyTemplate(surveyName,staffingAndUtilizationJSON).then((response)=>
    {
      if(response)
        setSurveyJSON(response.template);
    })
  }, []);
  return (
    <>
    {surveyJSON && <ClinicSurveyComponent json={surveyJSON} SurveyName={surveyName}/>}
    </>
  )
}
