const workforceRecord = (elementName, title) => {
    return [
        {
            type: "panel",
            name: `panelWorkforce${elementName}`,
            elements: [
                {
                    type: "text",
                    name: `${elementName}_pregraduateCertificate`,
                    title: "Pre-Graduate Certificate",
                    inputType: "number",
                    min: "0",
                    defaultValue: 0,
                },
                {
                    type: "text",
                    name: `${elementName}_postgraduateCertificateFamily`,
                    title: "Post Graduate Certificate",
                    inputType: "number",
                    min: "0",
                    defaultValue: 0,
                }
            ],
            questionTitleLocation: "left",
            title: `${title}`,
            showQuestionNumbers: "off"
        }
    ]
}

export const staffingAndUtilizationJSON =
{
    showProgressBar: "top",
    showCompletedPage: false,
    width: "90%",
    showTOC: true,
    focusFirstQuestionAutomatic: false,
    title: "Staffing And Utilization",
    checkErrorsMode: "onValueChanged",
    pages: [
        {
            name: "medicalServices",
            elements: [
                {
                    type: "panel",
                    name: "medicalServicesPanel",
                    showQuestionNumbers: "off",
                    width: "480px",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul><li> Medical services are provided by physicians, nurse practitioners (NPs), physician assistants (PAs), and certified nurse midwives (CNMs). Medical services are supported by medical nurses, medical laboratory, and medical x-ray personnel.</li>
                                  <li> Volunteers provide services for which your clinic does not pay (e.g. pro bono, in-kind, etc.). Only visits (including virtual visits) provided by volunteer providers should be entered in the Volunteer section.</li></ul`,
                        },
                        {
                            type: "boolean",
                            name: "medical1",
                            title: "Do you provide medical services?",
                            isRequired: true,
                        },
                        {
                            type: "boolean",
                            name: "medical2",
                            visibleIf: "{medical1} = true",
                            title: "Do you have volunteers that provide medical services?",
                        }
                    ]
                }
            ],
            title: "Medical Services",
            navigationDescription: "Medical Services",
        },
        {
            name: "Physicians",
            title: "    ● Physicians",
            navigationDescription: "Physicians",
            visibleIf: "{medical1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelPhysicians",
                    title: "Please enter the Paid Provider FTE and correllating vist data: ",
                    questionTitleLocation: "left",
                    width: "90%",
                    showQuestionNumbers: "off",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul><li> Physicians with dual boarding may be allocated into two lines, such as internist and pediatrician, based on time spent or patients seen, but both provider FTE and visits must be allocated. 
                             Report licensed interns and residents on the line for the specialty designation they are working toward and credit them with their own visits.
                             Psychiatrists, psychiatric nurse practitioners, and psychiatric physician assistances will be reported in Behavioral Health.
                             </ul>
                            <ul>
                            <li> <b>Family Physicians:</b> Family Physician MDs and DOs</li>                            
                            <li> <b>General Practitioners:</b> General Practice MDs and Dos</li>
                            <li> <b>Internists:</b> MDs and DOs with specialty training to treat adults from 18 years old through the geriatric years</li>
                            <li> <b>OB/GYN:</b> Obstetrician/Gynecologists </li>
                            <li> <b>Pediatricians:</b> Pediatric MDs and Dos</li>
                            <li> <b>Other Specialty Physicians:</b> Allergists, Cardiologists, Dermatologists, Orthopedists, Surgeons, Urologists, and Other Specialists and Sub-Specialists</li>
                            </ul>
                            `,
                        },
                        {
                            type: "text",
                            name: "family",
                            title: "Family Physicians",
                            inputType: "number",
                            min: "0",
                            max: 999,
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "familyVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({family}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "familyVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({familyVisits} / {totalVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "familyVirtual",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "familyVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({familyVirtual} / {totalVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "generalPractitioner",
                            title: "General Practitioner ",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "gpVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({generalPractitioner}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "gpVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({gpVisits} / {totalVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "gpVirtual",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "gpVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({gpVirtual} / {totalVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "internist",
                            title: "Internist",
                            inputType: "number",
                            width: "220px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "internistVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({internist}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "internistVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({internistVisits} / {totalVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "internistVirtual",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "internistVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({internistVirtual} / {totalVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "obgyn",
                            title: "OB/GYN",
                            inputType: "number",
                            width: "220px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "obgynVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({obgyn}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "obgynVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({obgynVisits} / {totalVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "obgynVirtual",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "obgynVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({obgynVirtual} / {totalVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "pediatricians",
                            title: "Pediatricians",
                            inputType: "number",
                            width: "220px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "pediatriciansVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({pediatricians}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "pediatriciansVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({pediatriciansVisits} / {totalVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "pediatriciansVirtual",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "pediatriciansVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({pediatriciansVirtual} / {totalVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "otherPhysician",
                            title: "Other Speciality Physicians",
                            inputType: "number",
                            width: "220px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherPhysicianVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherPhysician}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherPhysicianVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherPhysicianVisits} / {totalVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "otherPhysicianVirtual",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherPhysicianVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherPhysicianVirtual} / {totalVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                    ],
                }
                ,
                {
                    type: "panel",
                    name: "panelTotalFTE",
                    elements: [
                        {
                            type: "expression",
                            name: "totalPaidProvidersFTE",
                            title: "Total Paid Providers FTES",
                            expression: "{family} + {generalPractitioner} + {internist} + {obgyn} + {pediatricians} + {otherPhysician}"
                        },
                        {
                            type: "expression",
                            name: "totalVisits",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{familyVisits} + {gpVisits} + {internistVisits} + {obgynVisits} + {pediatriciansVisits} + {otherPhysicianVisits}"
                        },
                        {
                            type: "expression",
                            name: "totalVirtualVisits",
                            startWithNewLine: false,
                            title: "Total Virtual Visits",
                            expression: "{familyVirtual} + {gpVirtual} + {internistVirtual} + {obgynVirtual} + {pediatriciansVirtual} + {otherPhysicianVirtual}"
                        },
                        {
                            type: "expression",
                            name: "totalClinicVisits",
                            startWithNewLine: false,
                            title: "Total Clinic Visits",
                            expression: "{totalVisits}+ {totalVirtualVisits}"
                        },

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelMedicalPhysiciansCount",
                    showQuestionNumbers: "off",
                    width: "90%",
                    elements: [
                        {
                            type: "text",
                            name: "medicalPhysiciansPatients",
                            title: "Physicians Patients",
                            inputType: "number",
                            min: "0",
                            questionTitleLocation: "left",
                            defaultValueExpression: "0",
                        },
                    ]
                },
                {
                    type: "panel",
                    name: "panelPhysiciansV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    questionTitleLocation: "left",
                    showQuestionNumbers: "off",
                    visibleIf: "{medical2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "familyV",
                            title: "Family Physicians",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "familyHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "familyVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "familyVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({familyVisitsV} / {totalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "generalPractitionerV",
                            title: "General Practitioner ",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "gpHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "gpVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({generalPractitionerV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "gpVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({gpVisitsV} / {totalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "internistV",
                            title: "Internist",
                            inputType: "number",
                            width: "200px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "internistHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "internistVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({internistV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "internistVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({internistVisitsV} / {totalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "obgynV",
                            title: "OB/GYN",
                            inputType: "number",
                            width: "200px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "obgynHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "obgynVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({obgynV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "obgynVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({obgynVisitsV} / {totalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "pediatriciansV",
                            title: "Pediatricians",
                            inputType: "number",
                            width: "200px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "pediatriciansHoursV",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "pediatriciansVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({pediatriciansV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "pediatriciansVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({pediatriciansVisitsV} / {totalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "otherPhysicianV",
                            title: "Other Speciality Physicians",
                            inputType: "number",
                            width: "200px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherPhysicianHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherPhysicianVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherPhysicianV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherPhysicianVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherPhysicianVisitsV} / {totalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                    ],
                }
                ,
                {
                    type: "panel",
                    name: "panelTotalVolunteers",
                    visibleIf: "{medical2} = true",
                    elements: [
                        {
                            type: "expression",
                            name: "totalVolunteers",
                            title: "Total Volunteers",
                            expression: "{familyV} + {generalPractitionerV} + {internistV} + {obgynV} + {pediatriciansV} + {otherPhysicianV}"
                        },
                        {
                            type: "expression",
                            name: "totalHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{familyHoursV} + {gpHoursV} + {internistHoursV} + {obgynHoursV} + {pediatriciansHoursV} + {otherPhysicianHoursV}"
                        },
                        {
                            type: "expression",
                            name: "totalVisitsV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{familyVisitsV} + {gpVisitsV} + {internistVisitsV} + {obgynVisitsV} + {pediatriciansVisitsV} + {otherPhysicianVisitsV}"
                        },

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                }
            ],
        },
        {
            name: "AdvancePracticeProfessionals",
            title: "    ● Advance Practice Professionals",
            navigationDescription: "Advance Practice Professionals",
            visibleIf: "{medical1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelAdvancePractice",
                    title: "Please enter the Paid Provider FTE and correllating vist data:",
                    questionTitleLocation: "left",
                    // width: "90%",
                    showQuestionNumbers: "off",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            <li> <b>Nurse Practitioners:</b> Nurse Practitioners (NPs) and Advanced Practice Nurses (APRNs), except psychiatric nurse practitioners.</li>
                            <li> <b>Physician Assistants: </b> A person who is licensed in accordance with the provisions of K.S.A. 65-28a04 and amendments thereto and who provides patient services under the direction and supervision of a responsible physician</li>
                            <li> <b>Certified Nurse Midwives</b>A registered nurse with additional training. Midwives are trained and credentialed to offer expert care, education, counseling and support to women for pregnancy, birth and the postpartum period.</li>
                            `,
                        },
                        {
                            type: "text",
                            name: "nurse",
                            title: "Nurse Practitioners",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "nurseVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({family}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "nurseVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({nurseVisits} / {totalVisitsAdv}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "nurseVirtual",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "nurseVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({nurseVirtual} / {totalVirtualVisitsAdv}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },

                        {
                            type: "text",
                            name: "physicianAssistants",
                            title: "Physician Assistants",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "physicianAssistantsVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({physicianAssistants}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "physicianAssistantsVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({physicianAssistantsVisits} / {totalVisitsAdv}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "physicianAssistantsVirtual",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "physicianAssistantsVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({physicianAssistantsVirtual} / {totalVirtualVisitsAdv}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },

                        {
                            type: "text",
                            name: "certifiedNurse",
                            title: "Certified Nurse Midwives",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "certifiedNurseVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({certifiedNurse}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "certifiedNurseVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({certifiedNurseVisits} / {totalVisitsAdv}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "certifiedNurseVirtual",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "certifiedNurseVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({certifiedNurseVirtual} / {totalVirtualVisitsAdv}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                    ],
                },
                {
                    type: "panel",
                    name: "panelTotalFTEAdv",
                    elements: [
                        {
                            type: "expression",
                            name: "totalPaidProvidersFTEAdv",
                            title: "Total Paid Providers FTES Adv",
                            expression: "{nurse} + {physicianAssistants} + {certifiedNurse}"
                        },
                        {
                            type: "expression",
                            name: "totalVisitsAdv",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{nurseVisits} + {physicianAssistantsVisits} + {certifiedNurseVisits} "
                        },
                        {
                            type: "expression",
                            name: "totalVirtualVisitsAdv",
                            startWithNewLine: false,
                            title: "Total Virtual Visits",
                            expression: "{nurseVirtual} + {physicianAssistantsVirtual} + {certifiedNurseVirtual} "
                        },
                        {
                            type: "expression",
                            name: "totalClinicVisitsAdv",
                            startWithNewLine: false,
                            title: "Total Clinic Visits",
                            expression: "{totalVisitsAdv}+ {totalVirtualVisitsAdv}"
                        },

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelMedicalAdvCount",
                    showQuestionNumbers: "off",
                    width: "90%",
                    elements: [
                        {
                            type: "text",
                            name: "medicalAdvPatients",
                            title: "Advance Practice Professionals Patients",
                            inputType: "number",
                            min: "0",
                            questionTitleLocation: "left",
                            defaultValueExpression: "0",
                        },
                    ]
                },
                {
                    type: "panel",
                    name: "panelAdvancePracticeV",
                    title: "Please enter the Volunteers and correllating vist data:",
                    questionTitleLocation: "left",
                    showQuestionNumbers: "off",
                    visibleIf: "{medical2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "nurseV",
                            title: "Nurse Practitioners",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "nurseHoursV",
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            width: "150px",
                            startWithNewLine: false,
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "nurseVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({nurseV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "nurseVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({nurseVisitsV} / {totalVisitsAdvV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "physicianAssistantsV",
                            title: "Physician Assistants",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "physicianAssistantsHoursV",
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            width: "150px",
                            startWithNewLine: false,
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "physicianAssistantsVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({physicianAssistantsV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "physicianAssistantsVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({physicianAssistantsVisitsV} / {totalVisitsAdvV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "certifiedNurseV",
                            title: "Certified Nurse Midwives",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "certifiedNurseHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "certifiedNurseVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({certifiedNurseV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "certifiedNurseVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({certifiedNurseVisitsV} / {totalVisitsAdvV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                    ],
                },
                {
                    type: "panel",
                    name: "panelTotalFTEAdvV",
                    elements: [
                        {
                            type: "expression",
                            name: "totalVolunteersAdvV",
                            title: "Total Volunteers",
                            expression: "{nurseV} + {physicianAssistantsV} + {certifiedNurseV}"
                        },
                        {
                            type: "expression",
                            name: "totalHoursAdvV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{nurseHoursV} + {physicianAssistantsHoursV} + {certifiedNurseHoursV}"
                        },
                        {
                            type: "expression",
                            name: "totalVisitsAdvV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{nurseVisitsV} + {physicianAssistantsVisitsV} + {certifiedNurseVisitsV} "
                        }
                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                },
            ],
        },

        {
            name: "OtherMedical",
            title: "    ● Other Medical",
            navigationDescription: "OtherMedical",
            visibleIf: "{medical1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelOtherMedical",
                    title: "Please enter the Paid Provider FTE and correllating vist data:",
                    questionTitleLocation: "left",
                    showQuestionNumbers: "off",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            <li> <b>Nurses:</b> Licensed registered nurses, licensed practical and vocational nurses, home health and visiting nurses, clinical nurse specialists, and public health nurses </li>
                            <li> <b>Other Medical Personnel:</b> Medical assistants, nurses' aides, and all other personnel, including unlicensed interns or residents, providing services in conjunction with services provided by a physician, NP, PA, CNM, or nurse. Do not report non-medical personnel, personnel dedicated to QI or HIT/EHR informatics, or patient health records or patient support personnel here. </li>
                            <li> <b>Laboratory Personnel:</b> Pathologists, medical technologists, laboratory technicians and assistants, and phlebotomists </li>
                            <li> <b>X-Ray Personnel:</b> Radiologists, x-ray technologists, x-ray technician, and radiology assistants. Physician time (except radiologists) would not be included here, even if they were taking x-rays or performing a sonogram.                            </li>
                            </ul>
                            `,
                        },
                        {
                            type: "text",
                            name: "nurseOM",
                            title: "Nurses",
                            inputType: "number",
                            min: "0",
                            width: "250px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "nurseVisitsOM",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({nurseOM}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "nurseVisitsPctOM",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({nurseVisitsOM} / {totalVisitsOM}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "nurseVirtualOM",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "nurseVirtualPctOM",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({nurseVirtualOM} / {totalVirtualVisitsOM}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "otherPersonnelOM",
                            title: "Other Medical Personnel",
                            inputType: "number",
                            min: "0",
                            width: "250px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherPersonnelVisitsOM",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherPersonnelOM}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherPersonnelVisitsPctOM",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherPersonnelVisitsOM} / {totalVisitsOM}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "otherPersonnelVirtualOM",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherPersonnelVirtualPctOM",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherPersonnelVirtualOM} / {totalVirtualVisitsOM}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "laboratoryPersonnelOM",
                            title: "Laboratory Personel",
                            inputType: "number",
                            min: "0",
                            width: "250px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "laboratoryPersonnelVisitsOM",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({laboratoryPersonnelOM}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "laboratoryPersonnelVisitsPctOM",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({laboratoryPersonnelVisitsOM} / {totalVisitsOM}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "laboratoryPersonnelVirtualOM",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "laboratoryPersonnelVirtualPctOM",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({laboratoryPersonnelVirtualOM} / {totalVirtualVisitsOM}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "xrayPresonnelOM",
                            title: "X-Ray Personnel",
                            inputType: "number",
                            min: "0",
                            width: "250px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "xrayPresonnelVisitsOM",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({xrayPresonnelOM}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "xrayPresonnelVisitsPctOM",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({xrayPresonnelVisitsOM} / {totalVisitsOM}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "xrayPresonnelVirtualOM",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "xrayPresonnelVirtualPctOM",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({xrayPresonnelVirtualOM} / {totalVirtualVisitsOM}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                    ],
                },
                {
                    type: "panel",
                    name: "panelTotalFTEAdv",
                    elements: [
                        {
                            type: "expression",
                            name: "totalPaidProvidersFTEAOM",
                            title: "Total Paid Providers FTES",
                            expression: "{nurseOM} + {otherPersonnelOM} + {laboratoryPersonnelOM} + {xrayPresonnelOM}"
                        },
                        {
                            type: "expression",
                            name: "totalVisitsOM",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{nurseVisitsOM} + {otherPersonnelVisitsOM} + {laboratoryPersonnelVisitsOM} + {xrayPresonnelVisitsOM} "
                        },
                        {
                            type: "expression",
                            name: "totalVirtualVisitsOM",
                            startWithNewLine: false,
                            title: "Total Virtual Visits",
                            expression: "{nurseVirtualOM} + {otherPersonnelVirtualOM} + {laboratoryPersonnelVirtualOM} + {xrayPresonnelVirtualOM} "
                        },
                        {
                            type: "expression",
                            name: "totalClinicVisitsOM",
                            startWithNewLine: false,
                            title: "Total Clinic Visits",
                            expression: "{totalVirtualVisitsOM} + {totalVisitsOM}"
                        },

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelMedicaOMCount",
                    showQuestionNumbers: "off",
                    width: "90%",
                    elements: [
                        {
                            type: "text",
                            name: "medicalOMPatients",
                            title: "Other Medical Patients",
                            inputType: "number",
                            min: "0",
                            questionTitleLocation: "left",
                            defaultValueExpression: "0",
                        },
                    ]
                },
                {
                    type: "panel",
                    name: "panelOtherMedicalV",
                    title: "Please enter the Volunteers and correllating vist data:",
                    questionTitleLocation: "left",
                    showQuestionNumbers: "off",
                    visibleIf: "{medical2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "nurseOMV",
                            title: "Nurse Practitioners",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "nurseHoursOMV",
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            width: "150px",
                            startWithNewLine: false,
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "nurseVisitsOMV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({nurseOMV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "nurseVisitsPctOMV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({nurseVisitsOMV} / {totalVisitsOMV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "otherPersonnelOMV",
                            title: "Other Medical Personnel",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherPersonnelHoursOMV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherPersonnelVisitsOMV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherPersonnelOMV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherPersonnelVisitsPctOMV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherPersonnelVisitsOMV} / {totalVisitsOMV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "laboratoryPersonnelOMV",
                            title: "Laboratory Personel",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "laboratoryPersonnelHoursOMV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "laboratoryPersonnelVisitsOMV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({laboratoryPersonnelOMV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "laboratoryPersonnelVisitsPctOMV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({laboratoryPersonnelVisitsOMV} / {totalVisitsOMV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "xrayPresonnelOMV",
                            title: "X-Ray Personnel",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "xrayPresonnelHoursOMV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "xrayPresonnelVisitsOMV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({xrayPresonnelOMV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "xrayPresonnelVisitsPctOMV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({xrayPresonnelVisitsOMV} / {totalVisitsOMV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        }
                    ],
                },
                {
                    type: "panel",
                    name: "panelTotalFTEOMV",
                    elements: [
                        {
                            type: "expression",
                            name: "totalVolunteersOMV",
                            title: "Total Volunteers",
                            expression: "{nurseOMV} + {otherPersonnelOMV} + {laboratoryPersonnelOMV} + {xrayPresonnelOMV}"
                        },
                        {
                            type: "expression",
                            name: "totalHoursOMV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{nurseHoursOMV} + {otherPersonnelHoursOMV} + {laboratoryPersonnelHoursOMV} + {xrayPresonnelHoursOMV}"
                        },
                        {
                            type: "expression",
                            name: "totalVisitsOMV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{nurseVisitsOMV} + {otherPersonnelVisitsOMV} + {laboratoryPersonnelVisitsOMV} + {xrayPresonnelVisitsOMV} "
                        }

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                },
            ],
        },


        {
            name: "BehavioralHealthServiceDetail",
            title: "    ● Behavioral Health Service Detail",
            navigationDescription: "Behavioral Health Service Detail",
            visibleIf: "{medical1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelBehavioral",
                    description: "Enter non-negative integers for all data - personnel, visits, and patients.",
                    elements: [
                        {
                            type: "panel",
                            name: "panelPhysicians",
                            elements: [
                                {
                                    type: "text",
                                    name: "behavioralPhysicians",
                                    title: "Physicians (other than Psychiatrists)",
                                    defaultValueExpression: "0",
                                    popupdescription: `Physicians (MDs or DOs) that provided mental health services during a medical visit.`
                                },
                                {
                                    type: "text",
                                    name: "behavioralNursePractitioners",
                                    title: "Nurse Practitioners",
                                    defaultValueExpression: "0",
                                    popupdescription: `NPs that provided mental health services during a medical visit.`,
                                },
                                {
                                    type: "text",
                                    name: "behavioralPhysiciansAssistants",
                                    title: "Physician Assistants",
                                    defaultValueExpression: "0",
                                    popupdescription: `PAs that provided mental health services during a medical visit.`,
                                },
                                {
                                    type: "text",
                                    name: "behavioralCertifiedNurse",
                                    title: "Certified Nurse Midwives",
                                    defaultValueExpression: "0",
                                    popupdescription: `CNMs that provided mental health services  during a medical visit.`,
                                }
                            ],
                            questionTitleLocation: "left",
                            title: "Mental Health Service Detail",
                            showQuestionNumbers: "off"
                        },
                        {
                            type: "panel",
                            name: "panelSubstance",
                            description: "Enter non-negative integers for all data - personnel, visits, and patients.",
                            elements: [
                                {
                                    type: "expression",
                                    title: " ",
                                    titleLocation: "left",
                                    popupdescription: `<ul>
                                    <li> Personnel who provide substance use disorder services, including substance use disorder social workers, psychiatric nurses, psychiatric social workers, mental health nurses, clinical psychologists, clinical social workers, alcohol and drug abuse counselors, family therapists, and other individuals providing substance use disorder counseling and/or treatment services. Licenses/Credentials are not required for substance use disorder personnel. Substance use disorder providers are credentialed according to the clinic's standards. </li>
                                    <li> Report medical providers treating patients with substance use diagnoses in the medical services staffing and utilization, not as substance use disorder providers. </li>
                                    <li> DO NOT report physicians, NPs, PAs, CNMs, or Certified Registered Nurse Anesthetists (CRNAs) who obtained a Drug Addiction Treatment Act of 2000 (DATA) waiver to provide medication-assisted treatment (MAT) for opioid use disorder here.</li>
                                    </ul>
                                    `,
                                },
                                {
                                    type: "text",
                                    name: "substanceNursePractitioners",
                                    title: "Nurse Practitioners (Medical)",
                                    defaultValueExpression: "0",
                                    popupdescription: `NPs that provided substance use disorder services during a medical or mental visit.`,
                                },
                                {
                                    type: "text",
                                    name: "substancePhysicianAssistants",
                                    title: "Physician Assistants",
                                    defaultValueExpression: "0",
                                    popupdescription: `PAs that provided substance use disorder services during a medical or mental visit.`,
                                },
                                {
                                    type: "text",
                                    name: "substanceCertifiedNurse",
                                    title: "Certified Nurse Midwives",
                                    defaultValueExpression: "0",
                                    popupdescription: `CNMs that provided substance use disorder services during a medical or mental visit.`,
                                },
                                {
                                    type: "text",
                                    name: "substancePsychiatrists",
                                    title: "Psychiatrists",
                                    defaultValueExpression: "0",
                                    popupdescription: `NPs that provided substance use disorder services during a medical or mental visit.`,
                                },
                                {
                                    type: "text",
                                    name: "substanceLCP",
                                    title: "Licensed Clinical Psychologists",
                                    defaultValueExpression: "0",
                                    popupdescription: `Psychiatrists that provided substance use disorder services during a mental visit.`,
                                },
                                {
                                    type: "text",
                                    name: "substanceLCSW",
                                    title: "Licensed Clinical Social Workers",
                                    defaultValueExpression: "0",
                                    popupdescription: `Licensed Clinical Psychologists that provided substance use disorder services during a mental visit.`,
                                },
                                {
                                    type: "text",
                                    name: "substanceOLMHP",
                                    title: "Other Licensed Mental Health Providers",
                                    defaultValueExpression: "0",
                                    popupdescription: `Other licensed mental health providers that provided substance use disorder services during a mental visit.`,
                                }
                            ],
                            questionTitleLocation: "left",
                            title: "Substance Use Disorder Service Detail"
                        },
                    ],
                    title: "Behavioral Health Service Detail",
                    showQuestionNumbers: "off"
                }
            ]
        },
        {
            title: "    ● Total Medical Services",
            navigationDescription: "Total Medical Services",
            name: "TotalMedicalServices",
            visibleIf: "{medical1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelTotalMedicalServices",
                    elements: [
                        {
                            type: "expression",
                            name: "ot1",
                            maxwidth: "220px",
                            titleLocation: "hidden",
                            expression: "\"Total Paid Providers FTEs\""
                        },
                        {
                            type: "expression",
                            name: "totalMedicalServicesPaidFTE",
                            maxwidth: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: " {totalPaidProvidersFTE} + {totalPaidProvidersFTEAdv} + {totalPaidProvidersFTEAOM} ",
                        },
                        {
                            type: "expression",
                            name: "ot2",
                            maxwidth: "220px",
                            titleLocation: "hidden",
                            expression: "\"Total Visits\""
                        },
                        {
                            type: "expression",
                            name: "totalMedicalServicesVisits",
                            maxwidth: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalVisits} + {totalVisitsAdv} + {totalVisitsOM}",
                        },
                        {
                            type: "expression",
                            name: "ot3",
                            maxwidth: "220px",
                            titleLocation: "hidden",
                            expression: "\"Total Virtual Visits\""
                        },
                        {
                            type: "expression",
                            name: "totalMedicalServicesVirtualVisits",
                            maxwidth: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalVirtualVisits} + {totalVirtualVisitsAdv} + {totalVirtualVisitsOM}",
                        },
                        {
                            type: "expression",
                            name: "ot4",
                            maxwidth: "220px",
                            titleLocation: "hidden",
                            expression: "\"Total Clinic Visits\""
                        },
                        {
                            type: "expression",
                            name: "totalMedicalServicesClinicVisits",
                            maxwidth: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalClinicVisits}+{totalClinicVisitsAdv}+{totalClinicVisitsOM}",
                        }
                    ],
                    questionTitleLocation: "left",
                    title: "Full-time Employees:",
                    width: "700px",
                    minWidth: "700px",
                    maxWidth: "700px",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelVolunteers",
                    visibleIf: "{medical2}=true",
                    elements: [
                        {
                            type: "expression",
                            name: "ov1",
                            maxwidth: "220px",
                            titleLocation: "hidden",
                            expression: "\"Total Volunteers\""
                        },
                        {
                            type: "expression",
                            name: "totalMedicalServicesVolunteers",
                            maxwidth: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalVolunteers} + {totalVolunteersAdvV} + {totalVolunteersOMV}",
                        },
                        {
                            type: "expression",
                            name: "ov2",
                            maxwidth: "220px",
                            titleLocation: "hidden",
                            expression: "\"Total Hours\""
                        },
                        {
                            type: "expression",
                            name: "totalMedicalServicesVolunteersHours",
                            maxwidth: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalHoursV}+ {totalHoursAdvV} + {totalHoursOMV}",
                        },
                        {
                            type: "expression",
                            name: "ov3",
                            maxwidth: "220px",
                            titleLocation: "hidden",
                            expression: "\"Total Visits\""
                        },
                        {
                            type: "expression",
                            name: "totalMedicalServicesVolunteersVisits",
                            maxwidth: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalVisitsV}+ {totalVisitsAdvV} + {totalVisitsOMV}",
                        }

                    ],
                    questionTitleLocation: "bottom",
                    title: "Volunteers:",
                    width: "700px",
                    minWidth: "700px",
                    maxWidth: "700px",
                    showQuestionNumbers: "off",
                },
                {
                    type: "panel",
                    name: "panelTotalMedicalServicesTotals",
                    showQuestionNumbers: "off",
                    questionTitleLocation: "left",
                    width: "700px",
                    elements: [
                        {
                            type: "expression",
                            name: "ott1",
                            width: "220px",
                            titleLocation: "hidden",
                            expression: "\"Total Virtual Visits \""
                        },
                        {
                            type: "expression",
                            name: "totalMedicalServicesTotalVirtualVisits",
                            startWithNewLine: false,
                            width: "220px",
                            expression: "iif({medical2}=true, {totalMedicalServicesVirtualVisits}, {totalMedicalServicesVirtualVisits} )",
                            title: " ",
                        },
                        {
                            type: "expression",
                            name: "ott2",
                            titleLocation: "hidden",
                            width: "220px",
                            expression: "\"Total  Visits\""
                        },
                        {
                            type: "expression",
                            name: "totalMedicalServicesTotalVisits",
                            width: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: "iif({medical2}=true, {totalMedicalServicesVisits} + {totalMedicalServicesVolunteersVisits}, {totalMedicalServicesVisits} )",
                        }
                    ]
                }
            ],
        },
        {
            name: "DentalServicesPage",
            title: "Dental Services",
            navigationDescription: "Dental Services",
            showQuestionNumbers: "off",
            elements: [
                {
                    type: "panel",
                    name: "dentalPanel",
                    showQuestionNumbers: "off",
                    width: "480px",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            <li> Dental services are provided by dentists, dental hygienists, dental therapists, and other dental personnel.</li>
                            <li> Volunteers provide services for which your clinic does not pay (e.g. pro bono, in-kind, etc.). Only visits (including virtual visits) provided by volunteer providers should be entered in the Volunteer section.</li>
                            </ul>`,
                        },
                        {
                            type: "boolean",
                            name: "dental1",
                            title: "Do you provide Dental services?",
                            isRequired: true
                        },
                        {
                            type: "boolean",
                            name: "dental2",
                            visibleIf: "{dental1} = true",
                            title: "Do you have volunteers that provide Dental services?"
                        }
                    ]
                }
            ]
        },
        {
            name: "Dental",
            title: "    ● Dental",
            navigationDescription: "Dental",
            visibleIf: "{dental1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelDentals",
                    title: "Please enter the Paid Provider FTE and correllating vist data: ",
                    questionTitleLocation: "left",
                    width: "90%",
                    showQuestionNumbers: "off",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            <li> <b>Dentists:</b> General practitioners, oral surgeons, periodontists, and endodontists providing prevention, assessment, or treatment of a dental problem, including restoration. Does not include dental hygienists or therapists.</li>
                            <li> <b>Dental Hygienists:</b> A licensed oral health professional that can perform preventive services under the supervision of a dentist. Extended Care Permit (I & II) Dental Hygienists can perform preventive services in a variety of public health settings. Extended Care Permit III Dental Hygienists can perform preventive services and atraumatic restorative treatment in a variety of public health settings. </li>
                            <li> <b>Dental Therapists:</b> Dental therapists are licensed providers that work under the supervision of a dentist to provide routine dental care like exams and fillings.</li>
                            <li> <b>Other Dental Personnel:</b> Dental assistants, advanced dental assistants, aides, and technicians. </li>
                            </ul>
                            `,
                        },
                        {
                            type: "text",
                            name: "dentist",
                            title: "Dentists",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentistVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({dentist}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "dentistVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({dentistVisits} / {totalDentalVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "dentistVirtual",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "dentistVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({dentistVirtual} / {totalDentalVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "dentalHygientist",
                            title: "Dental Hygientists ",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentalHygenistVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({dentalHygientist}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "dentalHygenistVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({dentalHygenistVisits} / {totalDentalVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "dentalHygenistVirtual",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "dentalHygenistVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({dentalHygenistVirtual} / {totalDentalVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "dentalTherapist",
                            title: "Dental Therapists",
                            inputType: "number",
                            width: "220px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentalTherapistVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({dentalTherapist}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "dentalTherapistVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({dentalTherapistVisits} / {totalDentalVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "dentalTherapistVirtual",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "dentalTherapistVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({dentalTherapistVirtual} / {totalDentalVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "otherDental",
                            title: "Other Dental Personnel",
                            inputType: "number",
                            width: "220px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherDentalVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherDental}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherDentalVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherDentalVisits} / {totalDentalVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "otherDentalVirtual",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherDentalVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherDentalVirtual} / {totalDentalVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                    ],
                }
                ,
                {
                    type: "panel",
                    name: "panelTotalFTE",
                    elements: [
                        {
                            type: "expression",
                            name: "totalDentalsFTE",
                            title: "Total Paid Providers FTES",
                            expression: "{dentist} + {dentalHygientist} + {dentalTherapist} + {otherDental} "
                        },
                        {
                            type: "expression",
                            name: "totalDentalVisits",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{dentistVisits} + {dentalHygenistVisits} + {dentalTherapistVisits} + {otherDentalVisits} "
                        },
                        {
                            type: "expression",
                            name: "totalDentalVirtualVisits",
                            startWithNewLine: false,
                            title: "Total Virtual Visits",
                            expression: "{dentistVirtual} + {dentalHygenistVirtual} + {dentalTherapistVirtual} + {otherDentalVirtual} "
                        },
                        {
                            type: "expression",
                            name: "totalDentalClinicVisits",
                            startWithNewLine: false,
                            title: "Total Clinic Visits",
                            expression: "{totalDentalVisits}+ {totalDentalVirtualVisits}"
                        },

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelDentalCount",
                    showQuestionNumbers: "off",
                    width: "90%",
                    elements: [
                        {
                            type: "text",
                            name: "dentalServicesPatients",
                            title: "Dental Services Patients",
                            inputType: "number",
                            min: "0",
                            questionTitleLocation: "left",
                            defaultValueExpression: "0",
                        },
                    ]
                },
                {
                    type: "panel",
                    name: "panelDentalsV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    questionTitleLocation: "left",
                    showQuestionNumbers: "off",
                    visibleIf: "{dental2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "dentistV",
                            title: "Dentists",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentistHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentistVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({dentistV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "dentistVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({dentistVisitsV} / {totalDentalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "dentalHygenistV",
                            title: "Dental Hygientist ",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentalHygenistHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentalHygenistVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({dentalHygenistV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "dentalHygenistVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({dentalHygenistVisitsV} / {totalDentalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "dentalTherapistV",
                            title: "Dental Therapist",
                            inputType: "number",
                            width: "200px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentalTherapistHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentalTherapistVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({dentalTherapistV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "dentalTherapistVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({dentalTherapistVisitsV} / {totalDentalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "otherDentalV",
                            title: "Other Dental Personnel",
                            inputType: "number",
                            width: "200px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherDentalHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherDentalVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherDentalV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherDentalVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherDentalVisitsV} / {totalDentalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        }
                    ],
                    width: "90%",
                },
                {
                    type: "panel",
                    name: "panelDentalTotalVolunteers",
                    visibleIf: "{dental2} = true",
                    elements: [
                        {
                            type: "expression",
                            name: "totalDentalVolunteers",
                            title: "Total Volunteers",
                            expression: "{dentistV} + {dentalHygenistV} + {dentalTherapistV} + {otherDentalV} "
                        },
                        {
                            type: "expression",
                            name: "totalDentalHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{dentistHoursV} + {dentalHygenistHoursV} + {dentalTherapistHoursV} + {otherDentalHoursV} "
                        },
                        {
                            type: "expression",
                            name: "totalDentalVisitsV",
                            startWithNewLine: false,
                            title: "Total Clinic Visits",
                            expression: "{dentistVisitsV} + {dentalHygenistVisitsV} + {dentalTherapistVisitsV} + {otherDentalVisitsV} "
                        }
                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                }
            ],
        },

        {
            name: "VisionServicesPage",
            title: "Vision Services",
            navigationDescription: "Vision Services",
            elements: [
                {
                    type: "panel",
                    name: "visionPanel",
                    showQuestionNumbers: "off",
                    width: "480px",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            <li>Vision services are provided by ophthalmologists, optometrists, and other vision care personnel. </li>
                            <li>Volunteers provide services for which your clinic does not pay (e.g. pro bono, in-kind, etc.). Only visits (including virtual visits) provided by volunteer providers should be entered in the Volunteer section.</li>
                            </ul>`,
                        },
                        {
                            type: "boolean",
                            name: "vision1",
                            title: "Do you provide Vision services?",
                            isRequired: true
                        },
                        {
                            type: "boolean",
                            name: "vision2",
                            visibleIf: "{vision1} = true",
                            title: "Do you have volunteers that provide Vision services?"
                        }
                    ]
                }
            ]
        },
        {
            name: "Vision",
            title: "    ● Vision",
            navigationDescription: "Vision",
            visibleIf: "{vision1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelVisions",
                    title: "Please enter the Paid Provider FTE and correllating vist data: ",
                    questionTitleLocation: "left",
                    width: "90%",
                    showQuestionNumbers: "off",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            <li> <b>Ophthamologists:</b> MDs specializing in medical and surgical eye problems. </li>
                            <li> <b>Optometrists:</b> Doctors of optometry (ODs) who provide primary eye care services. </li>
                            <li> <b>Other Vision Personnel:</b> Ophthalmologist/optometric assistants, aides and technicians. </li>                           
                            </ul>
                            `,
                        },
                        {
                            type: "text",
                            name: "ophthalmologists",
                            title: "Ophthalmologists",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "ophthalmologistsVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({ophthalmologists}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "ophthalmologistsVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({ophthalmologistsVisits} / {totalVisionVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "ophthalmologistsVirtual",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "ophthalmologistsVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({ophthalmologistsVirtual} / {totalVisionVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "optometrists",
                            title: "Optometrists",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "optometristsVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({optometrists}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "optometristsVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({optometristsVisits} / {totalVisionVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "optometristsVirtual",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "optometristsVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({optometristsVirtual} / {totalVisionVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "otherVision",
                            title: "Other Vision Personnel",
                            inputType: "number",
                            width: "220px",
                            min: "0",
                            defaultValueExpression: "0",
                        },


                    ],
                }
                ,
                {
                    type: "panel",
                    name: "panelTotalFTEVision",
                    elements: [
                        {
                            type: "expression",
                            name: "totalVisionsFTE",
                            title: "Total Paid Providers FTES",
                            expression: "{ophthalmologists} + {optometrists} + {otherVision}"
                        },
                        {
                            type: "expression",
                            name: "totalVisionVisits",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{ophthalmologistsVisits} + {optometristsVisits}"
                        },
                        {
                            type: "expression",
                            name: "totalVisionVirtualVisits",
                            startWithNewLine: false,
                            title: "Total Virtual Visits",
                            expression: "{ophthalmologistsVirtual} + {optometristsVirtual}"
                        },
                        {
                            type: "expression",
                            name: "totalVisionClinicVisits",
                            startWithNewLine: false,
                            title: "Total Clinic Visits",
                            expression: "{totalVisionVisits} + {totalVisionVirtualVisits}"
                        },

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelVisionCount",
                    showQuestionNumbers: "off",
                    width: "90%",
                    elements: [
                        {
                            type: "text",
                            name: "visionServicesPatients",
                            title: "Vision Services Patients",
                            inputType: "number",
                            min: "0",
                            questionTitleLocation: "left",
                            defaultValueExpression: "0",
                        },
                    ]
                },
                {
                    type: "panel",
                    name: "panelVisionV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    questionTitleLocation: "left",
                    showQuestionNumbers: "off",
                    visibleIf: "{vision2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "ophthalmologistsV",
                            title: "Ophthalmologists",
                            inputType: "number",
                            min: "0",
                            width: "250px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "ophthalmologistsHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "ophthalmologistsVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({ophthalmologistsV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "ophthalmologistsVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({ophthalmologistsVisitsV} / {totalVisionVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "optometristsV",
                            title: "Optometrists",
                            inputType: "number",
                            min: "0",
                            width: "250px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "optometristsHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "optometristsVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({ophthalmologistsV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "optometristsVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({optometristsVisitsV} / {totalVisionVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "otherVisionV",
                            title: "Other Vision Personnel",
                            inputType: "number",
                            width: "250px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherVisionHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },


                    ],
                    width: "90%",
                },
                {
                    type: "panel",
                    name: "panelVisionTotalVolunteers",
                    visibleIf: "{vision2} = true",
                    elements: [
                        {
                            type: "expression",
                            name: "totalVisionVolunteers",
                            title: "Total Volunteers",
                            expression: "{ophthalmologistsV} + {optometristsV} + {otherVisionV}  "
                        },
                        {
                            type: "expression",
                            name: "totalVisionHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{ophthalmologistsHoursV} + {optometristsHoursV} + {otherVisionHoursV} "
                        },
                        {
                            type: "expression",
                            name: "totalVisionVisitsV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{ophthalmologistsVisitsV} + {optometristsVisitsV}   "
                        }

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                }
            ],
        },

        {
            name: "BehavioralHealthServicesPage",
            title: "Behavioral Health",
            navigationDescription: "Behavioral Health",
            elements: [
                {
                    type: "panel",
                    name: "behavioralHealthPanel",
                    showQuestionNumbers: "off",
                    width: "480px",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            <li>Behavioral Health services are provided by psychiatrists, licensed clinical psychologists, licensed clinical social workers, other licensed mental health providers, other mental health personnel, and substance use disorder personnel.</li>
                            </li>Volunteers provide services for which your clinic does not pay (e.g. pro bono, in-kind, etc.). Only visits (including virtual visits) provided by volunteer providers should be entered in the Volunteer section.</li>                            
                            </ul>`,
                        },
                        {
                            type: "boolean",
                            name: "behavioralHealth1",
                            title: "Do you provide Behaviors Health services?",
                            isRequired: true,
                            //requiredErrorText: "Answer required to move to the next section."
                        },
                        {
                            type: "boolean",
                            name: "behavioralHealth2",
                            visibleIf: "{behavioralHealth1} = true",
                            title: "Do you have volunteers that provide Behavioral Health services?"
                        }
                    ]
                }
            ]
        },
        {
            name: "mentalHealthPage",
            title: "    ● Mental Health",
            navigationDescription: "Mental Health",
            visibleIf: "{behavioralHealth1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelMentalHealth",
                    title: "Please enter the Paid Provider FTE and correllating vist data: ",
                    questionTitleLocation: "left",
                    width: "90%",
                    showQuestionNumbers: "off",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            <li> <b>Psychiatrists:</b>  A person with a medical degree who can prescribe drugs and medical treatments for mental health and substance use disorder. Can conduct psychotherapy. </li>
                            <li> <b>Licensesd Clinical Psychologists:</b> Specialty provider concerned with diagnosing and treating diseases of the brain, emotional disturbance, and behavior problems. </li>
                            <li> <b>Licensed Clinical Social Workers:</b> Licensed Clinical Social Worker (L.C.S.W.) who provides services related to mental health. </li>
                            <li> <b>Other Licensed Mental Health Providers:</b> Psychiatric social workers, psychiatric nurse practitioners and/or physician assistants, family therapists, and other licensed Master's Degree prepared providers. </li>
                            <li> <b>Other Mental Health Personnel:</b> Unlicensed personnel and support personnel, including “certified” personnel, who provide counseling or treatment, or who support mental health providers. </li>
                            </ul>
                            `,
                        },
                        {
                            type: "text",
                            name: "psychiatrists",
                            title: "Psychiatrists",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "psychiatristsVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({psychiatrists}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "psychiatristsVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({psychiatristsVisits} / {totalMentalHealthVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "psychiatristsVirtual",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "psychiatristsVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({psychiatristsVirtual} / {totalMentalHealthVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "licensedClinicalPsychologists",
                            title: "Licensed Clinical Psychologists",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "licensedClinicalPsychologistsVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({licensedClinicalPsychologists}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "licensedClinicalPsychologistsVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({licensedClinicalPsychologistsVisits} / {totalMentalHealthVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "licensedClinicalPsychologistsVirtual",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "licensedClinicalPsychologistsVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({licensedClinicalPsychologistsVirtual} / {totalMentalHealthVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "licensedClinicalSocialWorkers",
                            title: "Licensed Clinical Social Workers",
                            inputType: "number",
                            width: "220px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "licensedClinicalSocialWorkersVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({licensedClinicalSocialWorkers}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "licensedClinicalSocialWorkersVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({licensedClinicalSocialWorkersVisits} / {totalMentalHealthVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "licensedClinicalSocialWorkersVirtual",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "licensedClinicalSocialWorkersVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({licensedClinicalSocialWorkersVirtual} / {totalMentalHealthVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "otherLicensedMental",
                            title: "Other Licensed Mental Health Providers",
                            inputType: "number",
                            width: "220px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherLicensedMentalVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherLicensedMental}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherLicensedMentalVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherLicensedMentalVisits} / {totalMentalHealthVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "otherLicensedMentalVirtual",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherLicensedMentalVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherLicensedMentalVirtual} / {totalMentalHealthVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },

                        {
                            type: "text",
                            name: "otherMental",
                            title: "Other Licensed Mental Health Personnel",
                            inputType: "number",
                            width: "220px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherMentalVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherLicensedMental}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherMentalVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherMentalVisits} / {totalMentalHealthVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "otherMentalVirtual",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherMentalVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherMentalVirtual} / {totalMentalHealthVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                    ],
                },
                {
                    type: "panel",
                    name: "panelTotalFTEMental",
                    elements: [
                        {
                            type: "expression",
                            name: "totalMentalFTE",
                            title: "Total Paid Providers FTES",
                            expression: "{psychiatrists} + {licensedClinicalPsychologists} + {licensedClinicalSocialWorkers} + {otherLicensedMental} + {otherMental} "
                        },
                        {
                            type: "expression",
                            name: "totalMentalHealthVisits",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{psychiatristsVisits} + {licensedClinicalPsychologistsVisits} + {licensedClinicalSocialWorkersVisits} + {otherLicensedMentalVisits} + {otherMentalVisits} "
                        },
                        {
                            type: "expression",
                            name: "totalMentalHealthVirtualVisits",
                            startWithNewLine: false,
                            title: "Total Virtual Visits",
                            expression: "{psychiatristsVirtual} + {licensedClinicalPsychologistsVirtual} + {licensedClinicalSocialWorkersVirtual} + {otherLicensedMentalVirtual} + {otherMentalVirtual} "
                        },
                        {
                            type: "expression",
                            name: "totalMentalHealthClinicVisits",
                            startWithNewLine: false,
                            title: "Total Clinic Visits",
                            expression: "{totalMentalHealthVisits}+ {totalMentalHealthVirtualVisits}"
                        },

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelMentalHealthCount",
                    showQuestionNumbers: "off",
                    width: "90%",
                    elements: [
                        {
                            type: "text",
                            name: "mentalHealthPatients",
                            title: "Mental Health Patients",
                            inputType: "number",
                            min: "0",
                            questionTitleLocation: "left",
                            defaultValueExpression: "0",
                            description: "Enter non-negative integer",
                        },
                    ]
                },
                {
                    type: "panel",
                    name: "panelMentalV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    questionTitleLocation: "left",
                    showQuestionNumbers: "off",
                    visibleIf: "{behavioralHealth2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "psychiatristsV",
                            title: "Psychiatrists",
                            inputType: "number",
                            min: "0",
                            width: "250px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "psychiatristsHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "psychiatristsVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({psychiatristsV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "psychiatristsVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({psychiatristsVisitsV} / {totalMentalHealthVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "licensedClinicalPsychologistsV",
                            title: "Licensed Clinical Psychologists",
                            inputType: "number",
                            min: "0",
                            width: "250px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "licensedClinicalPsychologistsHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "licensedClinicalPsychologistsVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({licensedClinicalPsychologistsV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "licensedClinicalPsychologistsVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({licensedClinicalPsychologistsVisitsV} / {totalMentalHealthVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "licensedClinicalSocialWorkersV",
                            title: "Licensed Clinical Social Workers",
                            inputType: "number",
                            width: "250px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "licensedClinicalSocialWorkersHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "licensedClinicalSocialWorkersVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({licensedClinicalSocialWorkersV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "licensedClinicalSocialWorkersVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({licensedClinicalSocialWorkersVisitsV} / {totalMentalHealthVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "otherLicensedMentalV",
                            title: "Other Lisensed Mental Health Providers",
                            inputType: "number",
                            width: "250px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherLicensedMentalHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherLicensedMentalVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherLicensedMentalV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherLicensedMentalVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherLicensedMentalVisitsV} / {totalMentalHealthVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "otherMentalV",
                            title: "Other Licensed Mental Health Staff",
                            inputType: "number",
                            width: "250px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherMentalHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherMentalVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherMentalV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherMentalVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherMentalVisitsV} / {totalMentalHealthVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        }
                    ],
                    width: "90%",
                },
                {
                    type: "panel",
                    name: "panelMentalTotalVolunteers",
                    visibleIf: "{behavioralHealth2} = true",
                    elements: [
                        {
                            type: "expression",
                            name: "totalMentalHealthVolunteers",
                            title: "Total Volunteers",
                            expression: "{psychiatristsV} + {licensedClinicalPsychologistsV} + {licensedClinicalSocialWorkersV} + {otherLicensedMentalV} + {otherMentalV}  "
                        },
                        {
                            type: "expression",
                            name: "totalMentalHealthHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{psychiatristsHoursV} + {licensedClinicalPsychologistsHoursV} + {licensedClinicalSocialWorkersHoursV} + {otherLicensedMentalHoursV} + {otherMentalHoursV}  "
                        },
                        {
                            type: "expression",
                            name: "totalMentalHealthVisitsV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{psychiatristsVisitsV} + {licensedClinicalPsychologistsVisitsV} + {licensedClinicalSocialWorkersVisitsV} + {otherLicensedMentalVisitsV} + {otherMentalVisitsV}  "
                        }

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                }
            ],
        },

        {
            name: "substanceUseDisorderPage",
            title: "    ● Substance Use Disorder",
            navigationDescription: "Substance Use Disorder",
            visibleIf: "{behavioralHealth1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelSubstanceUse",
                    title: "Please enter the Paid Provider FTE and correllating vist data: ",
                    questionTitleLocation: "left",
                    width: "90%",
                    showQuestionNumbers: "off",
                    elements: [
                        {
                            type: "text",
                            name: "substanceAbuseServices",
                            title: "Substance Abuse Services",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "substanceAbuseServicesVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({substanceAbuseServices}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "substanceAbuseServicesVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({substanceAbuseServicesVisits} / {totalSubstanceUseDisorderVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "substanceAbuseServicesVirtual",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "substanceAbuseServicesVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({substanceAbuseServicesVirtual} / {totalSubstanceUseDisorderVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        }
                    ],
                },
                {
                    type: "panel",
                    name: "panelSubstanceUseTotalFTE",
                    elements: [
                        {
                            type: "expression",
                            name: "totalSubstanceUseDisorderFTE",
                            title: "Total Paid Providers FTES",
                            expression: "{substanceAbuseServices}  "
                        },
                        {
                            type: "expression",
                            name: "totalSubstanceUseDisorderVisits",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{substanceAbuseServicesVisits} "
                        },
                        {
                            type: "expression",
                            name: "totalSubstanceUseDisorderVirtualVisits",
                            startWithNewLine: false,
                            title: "Total Virtual Visits",
                            expression: "{substanceAbuseServicesVirtual}  "
                        },
                        {
                            type: "expression",
                            name: "totalSubstanceUseDisorderClinicVisits",
                            startWithNewLine: false,
                            title: "Total Clinic Visits",
                            expression: "{totalSubstanceUseDisorderVisits}+ {totalSubstanceUseDisorderVirtualVisits}"
                        },

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelSubstanceUseDisorderCount",
                    showQuestionNumbers: "off",
                    width: "90%",
                    elements: [
                        {
                            type: "text",
                            name: "substanceUseDisorderPatients",
                            title: "Substance Use Disorder Patients",
                            inputType: "number",
                            min: "0",
                            questionTitleLocation: "left",
                            defaultValueExpression: "0",
                        },
                    ]
                },
                {
                    type: "panel",
                    name: "panelSubstanceUseV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    questionTitleLocation: "left",
                    showQuestionNumbers: "off",
                    visibleIf: "{behavioralHealth2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "substanceAbuseServicesV",
                            title: "Substance Abuse Services",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "substanceAbuseServicesHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "substanceAbuseServicesVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({substanceAbuseServicesV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "substanceAbuseServicesVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({substanceAbuseServicesVisitsV} / {totalSubstanceUseDisorderVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        }
                    ],
                },
                {
                    type: "panel",
                    name: "panelSubstanceUseTotalVolunteers",
                    visibleIf: "{behavioralHealth2} = true",
                    elements: [
                        {
                            type: "expression",
                            name: "totalSubstanceUseDisorderVolunteers",
                            title: "Total Volunteers",
                            expression: "{substanceAbuseServicesV}  "
                        },
                        {
                            type: "expression",
                            name: "totalSubstanceUseDisorderHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{substanceAbuseServicesHoursV}  "
                        },
                        {
                            type: "expression",
                            name: "totalSubstanceUseDisorderVisitsV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{substanceAbuseServicesVisitsV}  "
                        }

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                }
            ],
        },
        {
            name: "totalBehavioralHealth",
            title: "    ● Total Behavioral Health Services",
            navigationDescription: "Total Behavioral Health Services",
            visibleIf: "{behavioralHealth1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelTotalBehavioralHealthFTE",
                    title: "Full-time Employees:",
                    elements: [
                        {
                            type: "expression",
                            name: "totalTotalPaidFTEBehavioralHealth",
                            title: "Total Paid Providers FTEs",
                            titleLocation: "left",
                            expression: " {totalMentalFTE} + {totalSubstanceUseDisorderFTE}"
                        },
                        {
                            type: "expression",
                            name: "totalTotalVisitsBehavioralHealth",
                            title: "Total Visits",
                            titleLocation: "left",
                            expression: "{totalMentalHealthVisits} +  {totalSubstanceUseDisorderVisits}"
                        },
                        {
                            type: "expression",
                            name: "totalTotalVirtualVisitsBehavioralHealth",
                            title: "Total Virtual Visits",
                            titleLocation: "left",
                            expression: "{totalMentalHealthVirtualVisits} + {totalSubstanceUseDisorderVirtualVisits}"
                        },
                        {
                            type: "expression",
                            name: "totalTotalHoursBehavioralHealth",
                            title: "Total Clinic Visits",
                            titleLocation: "left",
                            expression: "{totalTotalVisitsBehavioralHealth} + {totalTotalVirtualVisitsBehavioralHealth}"
                        },

                    ],
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelTotalBehavioralHealthV",
                    title: "Volunteers:",
                    visibleIf: "{behavioralHealth2} = true",
                    elements: [
                        {
                            type: "expression",
                            name: "totalTotalBehavioralHealthVolunteers",
                            title: "Total Volunteers",
                            titleLocation: "left",
                            expression: " {totalMentalHealthVolunteers} + {totalSubstanceUseDisorderVolunteers}"
                        },
                        {
                            type: "expression",
                            name: "totalTotalBehavioralHealthHours",
                            title: "Total Hours",
                            titleLocation: "left",
                            expression: "{totalMentalHealthHoursV} + {totalSubstanceUseDisorderHoursV}"
                        },
                        {
                            type: "expression",
                            name: "totalTotalBehavioralHealthVisitsV",
                            title: "Total Visits",
                            titleLocation: "left",
                            expression: "{totalMentalHealthVisitsV} + {totalSubstanceUseDisorderVisitsV}"
                        }

                    ],
                    showQuestionNumbers: "off"
                }
            ],

        },
        {
            name: "otherProfessionalServicesPage",
            title: "Other Professional Services",
            navigationDescription: "Other Professional Services",
            elements: [
                {
                    type: "panel",
                    name: "otherProfessionalServicesPanel",
                    showQuestionNumbers: "off",
                    width: "480px",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            <li> Other professional services are provided by occupational, speech, and physical therapists; registered dieticians; nutritionists; podiatrists; naturopaths; chiropractors; acupuncturists; community and behavioral health aides and practitioners; etc.</li>
                            <li> Volunteers provide services for which your clinic does not pay (e.g. pro bono, in-kind, etc.). Only visits (including virtual visits) provided by volunteer providers should be entered in the Volunteer section.</li>
                            </ul>
                            `,
                        },
                        {
                            type: "boolean",
                            name: "otherProfessionalServices1",
                            title: "Do you provide Other Professional services?",
                            isRequired: true
                        },
                        {
                            type: "boolean",
                            name: "otherProfessionalServices2",
                            visibleIf: "{otherProfessionalServices1} = true",
                            title: "Do you have volunteers that provide Other Professional services?"
                        }
                    ]
                }
            ]
        },
        {
            name: "otherProfessionalPersonnelPage",
            title: "    ● Other Professional Personnel",
            navigationDescription: "Other Professional Personnel",
            visibleIf: "{otherProfessionalServices1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelOtherProfessionalPersonnel",
                    title: "Please enter the Paid Provider FTE and correllating vist data: ",
                    questionTitleLocation: "left",
                    width: "90%",
                    showQuestionNumbers: "off",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `Providers who provide other professional health services including, but not limited to, occupational, speech, and physical therapists; registered dieticians; nutritionists; podiatrists; naturopaths; chiropractors; acupuncturists; and community and behavioral health aides and practitioners  `,
                        },
                        {
                            type: "text",
                            name: "otherProfessionalPersonnel",
                            title: "Other Prof. Personnel",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherProfessionalPersonnelVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherProfessionalPersonnel}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherProfessionalPersonnelVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherProfessionalPersonnelVisits} / {totalOtherProfessionalPersonnelVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "otherProfessionalPersonnelVirtual",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherProfessionalPersonnelVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherProfessionalPersonnelVirtual} / {totalOtherProfessionalPersonnelVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        }
                    ],
                },
                {
                    type: "panel",
                    name: "panelOtherProfessionalPersonnelTotalFTE",
                    elements: [
                        {
                            type: "expression",
                            name: "totalOtherProfessionalPersonnelFTE",
                            title: "Total Paid Providers FTES",
                            expression: "{otherProfessionalPersonnel}  "
                        },
                        {
                            type: "expression",
                            name: "totalOtherProfessionalPersonnelVisits",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{otherProfessionalPersonnelVisits} "
                        },
                        {
                            type: "expression",
                            name: "totalOtherProfessionalPersonnelVirtualVisits",
                            startWithNewLine: false,
                            title: "Total Virtual Visits",
                            expression: "{otherProfessionalPersonnelVirtual}  "
                        },
                        {
                            type: "expression",
                            name: "totalOtherProfessionalPersonnelClinicVisits",
                            startWithNewLine: false,
                            title: "Total Clinic Visits",
                            expression: "{totalOtherProfessionalPersonnelVisits}+ {totalOtherProfessionalPersonnelVirtualVisits}"
                        },

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelOtherProfessionalServicesCount",
                    showQuestionNumbers: "off",
                    width: "90%",
                    elements: [
                        {
                            type: "text",
                            name: "otherProfessionalServicesPatients",
                            title: "Other Professional Services Patients",
                            inputType: "number",
                            min: "0",
                            questionTitleLocation: "left",
                            defaultValueExpression: "0",
                        },
                    ]
                },
                {
                    type: "panel",
                    name: "panelOtherProfessionalPersonnelV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    visibleIf: "{otherProfessionalServices2} = true",
                    questionTitleLocation: "left",
                    width: "90%",
                    showQuestionNumbers: "off",
                    elements: [
                        {
                            type: "text",
                            name: "otherProfessionalPersonnelV",
                            title: "Other Prof. Personnel",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherProfessionalPersonnelHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherProfessionalPersonnelVisitsV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherProfessionalPersonnelV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherProfessionalPersonnelVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherProfessionalPersonnelVisitsV} / {totalOtherProfessionalPersonnelVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        }
                    ],
                },
                {
                    type: "panel",
                    name: "panelOtherProfessionalPersonnelTotalFTEV",
                    elements: [
                        {
                            type: "expression",
                            name: "totalOtherProfessionalPersonnelFTEV",
                            title: "Total Volunteers",
                            expression: "{otherProfessionalPersonnelV}"
                        },
                        {
                            type: "expression",
                            name: "totalOtherProfessionalPersonnelHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{otherProfessionalPersonnelHoursV}"
                        },
                        {
                            type: "expression",
                            name: "totalOtherProfessionalPersonnelVisitsV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{otherProfessionalPersonnelVisitsV}"
                        }
                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelOtherProfessionalPersonnelCount",
                    showQuestionNumbers: "off",
                    width: "90%",
                    questionTitleLocation: "left",
                    elements: [
                        {
                            type: "text",
                            name: "otherProfessionalPersonnelPatients",
                            title: "Other Professional Personnel Patients",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                    ]
                }
            ],
        },
        {
            name: "enablingServicesPage",
            title: "Enabling Services",
            navigationDescription: "Enabling Services",
            elements: [
                {
                    type: "panel",
                    name: "enablingServicesPanel",
                    showQuestionNumbers: "off",
                    width: "480px",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `
                            Volunteers provide services for which your clinic does not pay (e.g. pro bono, in-kind, etc.). Only visits (including virtual visits) provided by volunteer providers should be entered in the Volunteer section. 
                            <ul>
                            <li><b>Case Managers:<b> Personnel who assist patients in the management of their health and social needs, including assessment of patient medical and/or social service needs; establishment of service plans; and maintenance of referral, tracking, and follow-up systems. Include case managers, care coordinators, referral coordinators, and other local titles. Case managers may provide health education and/or eligibility assistance in the course of their case management functions; do not parse out this time unless the personnel has dedicated time to other enabling service categories. </li>
                            <li><b>Patient/Community Education Specialists:<b> Health educators, with or without specific degrees in this area. Only services provided one-on-one with the patient can be reported. Group education classes or visits are not reported. Health education is provided to support the delivery of other health care services as described above and is rarely the only type of service provided to a patient. </li>
                            <li><b>Outreach Workers:<b> Personnel conducting case finding, education, or other services designed to identify potential patients or clients and/or facilitate access or referral of potential clinic patients to available clinic services. </li>
                            <li><b>Transportation Personnel:<b> Personnel who provide transportation for patients (e.g., van drivers) or arrange for transportation (e.g., for bus or taxi vouchers), including personnel who arrange for local transportation or longer-distance transportation to major cities in extremely remote clinic locations. </li>
                            <li><b>Eligibility Assistance Workers:<b> Personnel (e.g., patient navigators, certified assisters, eligibility workers) who provide assistance in securing access to available health, social service, pharmacy, and other assistance programs, including Medicaid, Medicare, WIC, Supplemental Security Income (SSI), food stamps through the Supplemental Nutrition Assistance Program (SNAP), Temporary Assistance for Needy Families (TANF), PAPs, and related assistance programs. For health centers, this includes personnel hired under the HRSA Outreach and Enrollment grants. </li>
                            <li><b>Interpretation Staff:<b> Personnel whose full-time or dedicated time is devoted to translation and/or interpretation services. Do not include the portion of the time a nurse, medical assistant, or other support personnel providing interpretation, translation, or bilingual services during their other activities. </li>
                            <li><b>Community Health Workers:<b> Members of communities who work in association with the local health care system in both urban and rural environments and usually share ethnicity, language, socioeconomic status, and/or life experiences with the community members they serve. Personnel may be called community health workers, community health advisors, lay health advocates, promotoras, community health representatives, peer health promoters, or peer health educators. They may perform some or all of the tasks of other enabling services workers. If some of their time is dedicated to these other functions, report them on those lines. Do not include personnel better classified under other service categories, such as Other Medical Personnel or Other Dental Personnel. </li>
                            <li><b>Other Enabling Services:<b> Any staff not previously reported performing enabling services such as child care. </li>
                            </ul>
                            `,
                        },
                        {
                            type: "boolean",
                            name: "enablingServices1",
                            title: "Do you provide Enabling services?",
                            isRequired: true
                        },
                        {
                            type: "boolean",
                            name: "enablingServices2",
                            visibleIf: "{enablingServices1} = true",
                            title: "Do you have volunteers that provide Enabling services?"
                        }
                    ]
                }
            ]
        },
        {
            name: "enablingPage",
            title: "    ● Enabling",
            visibleIf: "{enablingServices1} = true",
            navigationDescription: "Enabling",
            elements: [
                {
                    type: "panel",
                    name: "panelEnabling",
                    title: "Please enter the Paid Provider FTE and correllating vist data: ",
                    questionTitleLocation: "left",
                    width: "90%",
                    showQuestionNumbers: "off",
                    elements: [
                        {
                            type: "text",
                            name: "caseManagers",
                            title: "Case Managers",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "caseManagersVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({caseManagers}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "caseManagersVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({caseManagersVisits} / {totalEnablingVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "caseManagersVirtual",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "caseManagersVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({caseManagersVirtual} / {totalEnablingVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "communityEducationSpecialists",
                            title: "Patient/ Community Education Specialists",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "communityEducationSpecialistsVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({communityEducationSpecialists}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "communityEducationSpecialistsVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({communityEducationSpecialistsVisits} / {totalEnablingVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "communityEducationSpecialistsVirtual",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "communityEducationSpecialistsVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({communityEducationSpecialistsVirtual} / {totalEnablingVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "outreachWorkers",
                            title: "Outreach Workers",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "transportationPersonnel",
                            title: "Transportation Personnel",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "transportationPersonnelVisits",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({transportationPersonnel}>0,9999,0)",
                            maxErrorText: "If there is a paid provider visit there should be a paid provider FTE ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "transportationPersonnelVisitsPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({transportationPersonnelVisits} / {totalEnablingVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "transportationPersonnelVirtual",
                            startWithNewLine: false,
                            title: "Virtual",
                            inputType: "number",
                            min: "0",
                            width: "160px",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "transportationPersonnelVirtualPct",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({transportationPersonnelVirtual} / {totalEnablingVirtualVisits}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                        {
                            type: "text",
                            name: "eligibilityAssistanceWorkers",
                            title: "Eligibility Assistance Workers",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "interpretationPersonnel",
                            title: "Interpretation Personnel",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "communityHealthWorkers",
                            title: "Community Health Workers",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherEnabling",
                            title: "Other Enabling Services",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },

                    ],
                },
                {
                    type: "panel",
                    name: "panelEnablingTotalFTE",
                    elements: [
                        {
                            type: "expression",
                            name: "totalEnablingFTE",
                            title: "Total Paid Providers FTES",
                            expression: "{caseManagers} +{communityEducationSpecialists} +{outreachWorkers} +{eligibilityAssistanceWorkers}+{transportationPersonnel} +{interpretationPersonnel} + {communityHealthWorkers}+ {otherEnabling}"
                        },
                        {
                            type: "expression",
                            name: "totalEnablingVisits",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{caseManagersVisits} +{communityEducationSpecialistsVisits}  +{transportationPersonnelVisits}   "
                        },
                        {
                            type: "expression",
                            name: "totalEnablingVirtualVisits",
                            startWithNewLine: false,
                            title: "Total Virtual Visits",
                            expression: "{caseManagersVirtual} +{communityEducationSpecialistsVirtual} +{transportationPersonnelVirtual}   "
                        },
                        {
                            type: "expression",
                            name: "totalEnablingClinicVisits",
                            startWithNewLine: false,
                            title: "Total Clinic Visits",
                            expression: "{totalEnablingVisits}+ {totalEnablingVirtualVisits}"
                        },

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelEnablingCount",
                    showQuestionNumbers: "off",
                    width: "90%",
                    elements: [
                        {
                            type: "text",
                            name: "enablingServicesPatients",
                            title: "Enabling Services Patients",
                            inputType: "number",
                            min: "0",
                            questionTitleLocation: "left",
                            defaultValueExpression: "0",
                            description: "Enter non-negative integer"
                        },
                    ]

                },
                {
                    type: "panel",
                    name: "panelEnablingV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    questionTitleLocation: "left",
                    showQuestionNumbers: "off",
                    visibleIf: "{enablingServices2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "caseManagersV",
                            title: "Case Managers",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "caseManagersHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "caseManagersVisitsV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({caseManagersV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "caseManagersVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({caseManagersVisitsV} / {totalEnablingVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "communityEducationSpecialistsV",
                            title: "Patient/ Community Education Specialists",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "communityEducationSpecialistsHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "communityEducationSpecialistsVisitsV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({communityEducationSpecialistsV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "communityEducationSpecialistsVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({communityEducationSpecialistsVisitsV} / {totalEnablingVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "outreachWorkersV",
                            title: "Outreach Workers",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "outreachWorkersHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },


                        {
                            type: "text",
                            name: "transportationPersonnelV",
                            title: "Transportation Personnel",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "transportationPersonnelHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "transportationPersonnelVisitsV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({transportationPersonnelV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "transportationPersonnelVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({transportationPersonnelVisitsV} / {totalEnablingVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "eligibilityAssistanceWorkersV",
                            title: "Eligibility Assistance Workers",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "eligibilityAssistanceWorkersHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },


                        {
                            type: "text",
                            name: "interpretationPersonnelV",
                            title: "Interpretation Personnel",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "interpretationPersonnelHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },


                        {
                            type: "text",
                            name: "communityHealthWorkersV",
                            title: "Community Health Workers",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "communityHealthWorkersHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },


                        {
                            type: "text",
                            name: "otherEnablingV",
                            title: "Other Enabling Services",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherEnablingHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                    ],
                    width: "90%",
                },
                {
                    type: "panel",
                    name: "panelEnablingTotalVolunteers",
                    visibleIf: "{enablingServices2} = true",
                    elements: [
                        {
                            type: "expression",
                            name: "totalEnablingFTEV",
                            title: "Total Paid Providers FTES",
                            expression: "{caseManagersV} + {communityEducationSpecialistsV} + {outreachWorkersV} + {eligibilityAssistanceWorkersV} + {transportationPersonnelV} + {interpretationPersonnelV} + {communityHealthWorkersV} + {otherEnablingV}"
                        },
                        {
                            type: "expression",
                            name: "totalEnablingHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: `{caseManagersHoursV} + {communityEducationSpecialistsHoursV}
                                    + {outreachWorkersHoursV} + {transportationPersonnelHoursV}
                                    + {eligibilityAssistanceWorkersHoursV} + {interpretationPersonnelHoursV}
                                    + {communityHealthWorkersHoursV} + {otherEnablingHoursV}`
                        },
                        {
                            type: "expression",
                            name: "totalEnablingVisitsV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{caseManagersVisitsV} + {communityEducationSpecialistsVisitsV} + {transportationPersonnelVisitsV}"
                        }
                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                }
            ],
        },
        {
            name: "PharmacyPage",
            elements: [
                {
                    type: "panel",
                    name: "pharmacyPanel",
                    width: "480px",
                    elements: [
                        {
                            type: "boolean",
                            name: "pharmacy1",
                            title: "Do you provide Pharmacy services?",
                            isRequired: true
                        }
                    ]
                }
            ],
            title: "Pharmacy Services",
            navigationDescription: "PharmacyServices"
        },
        {
            name: "pagePharmacyServices",
            visibleIf: "{pharmacy1} = true",
            navigationDescription: "Pharmacy",
            elements: [
                {
                    type: "panel",
                    name: "panelPharmacy",
                    description: "Enter non-negative integer",
                    elements: [
                        {
                            type: "text",
                            name: "pharmacyPersonnel",
                            title: "Pharmacy Personnel",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            popupdescription: `Pharmacists (including clinical pharmacists), pharmacy technicians, pharmacist assistants, and others supporting pharmaceutical services. <b><u>Please note:</u></b> The time (and cost) of individuals spending all or part of their time in assisting patients to apply for free medications from pharmaceutical companies (pharmacy assistance programs [PAP]) are to be reported under “Eligibility Assistance Workers” in the Enabling Services section of Staffing and Utilization.`,
                        },
                        {
                            type: "text",
                            name: "pharmacyVolunteers",
                            title: "Pharmacy Volunteers",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "pharmacyHours",
                            title: "Pharmacy Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        }
                    ],
                    questionTitleLocation: "left",
                    title: "Pharmacy Services",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelTotalPharmacy",
                    description: "Enter non-negative integer",
                    elements: [
                        {
                            type: "text",
                            name: "PAPProgramApplications",
                            title: "PAP Program Applications Filed",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }],
                            popupdescription: `Indicate the number of Prescription Assistance Program new and refill applications that have been filed during the reporting year.`,
                        },
                        {
                            type: "text",
                            name: "340BProgramMedications",
                            title: "340B Program Medications Dispensed - Health centers Only",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }],
                            popupdescription: `Indicate the number of medications dispensed through the 340B Drug Pricing Program during the reporting period.`,
                        },
                        {
                            type: "text",
                            name: "unusedMedicationsProgram",
                            title: "Unused Medications Program Prescriptions Dispensed",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }],
                            popupdescription: `Indicate the number of medications dispensed through the Unused Medications Repository during the reporting period.`,
                        },
                        {
                            type: "html",
                            html: "<hr></hr>"
                        },
                        {
                            type: "expression",
                            name: "totalTotalPharmacy",
                            title: "Total :",
                            titleLocation: "top",
                            expression: `({PAPProgramApplications} + {340BProgramMedications} + {unusedMedicationsProgram})`
                        },
                    ],
                    questionTitleLocation: "left",
                    title: "Total Pharmacy Counts",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelTotalPharmacyPatients",
                    description: "Enter non-negative integer",
                    elements: [
                        {
                            type: "text",
                            name: "PAPProgram",
                            title: "PAP Program",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            popupdescription: `Indicate the number of unduplicated patients who have received a prescription through a Prescription Assistance Program during the reporting year.`,
                        },
                        {
                            type: "text",
                            name: "340BProgramFQHC",
                            title: "340B Program - Health centers Only",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            popupdescription: `Indicate the number of unduplicated patients who have received a prescription through the 340B Drug Pricing Program during the reporting year.`,
                        },
                        {
                            type: "text",
                            name: "unusedMedicationsProgramPatients",
                            title: "Unused Medications Program",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            popupdescription: `Indicate the number of unduplicated patients who have received a prescription through the Unused Medications Repository during the reporting year.`,
                        },
                        {
                            type: "html",
                            html: "<hr></hr>"
                        },
                        {
                            type: "expression",
                            name: "totalTotalPharmacyPatients",
                            title: "Total :",
                            titleLocation: "top",
                            expression: `({PAPProgram} + {340BProgramFQHC} + {unusedMedicationsProgramPatients})`
                        },
                    ],
                    questionTitleLocation: "left",
                    title: "Total Pharmacy Patients",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panel340BClinic",
                    elements: [
                        {
                            type: "radiogroup",
                            name: "is340BClinic",
                            title: "Is the Clinic a 340B Clinic?",
                            titleLocation: "top",
                            choices: [
                                "Yes",
                                "No",
                                "NA"
                            ],
                            popupdescription: `Clinics with a 340B program can have a clinic owned and managed program, a program managed in-house, and/or external contracts.`
                        },
                        {
                            type: "checkbox",
                            name: "clinicType",
                            visibleIf: "{is340BClinic} = 'Yes'",
                            title: " ",
                            choices: [
                                {
                                    value: "Owned",
                                    text: "Clinic Owned and Managed"
                                },
                                {
                                    value: "Managed",
                                    text: "Clinic Managed In House"
                                },
                                {
                                    value: "External",
                                    text: "External Contract"
                                }
                            ]
                        }
                    ],
                    questionTitleLocation: "left",
                    title: "340B Clinic",
                    showQuestionNumbers: "off"
                }
            ],
            title: "    ● Pharmacy"
        },

        {
            name: "SchoolPage",
            elements: [
                {
                    type: "panel",
                    name: "SchoolPanel",
                    width: "480px",
                    elements: [
                        {
                            type: "boolean",
                            name: "school1",
                            title: "Do you provide School services?",
                            isRequired: true
                        }
                    ]
                }
            ],
            title: "School Services",
            navigationDescription: "School Services"
        },
        {
            name: "pageSchoolServices",
            title: "    ● School Provided In Schools",
            navigationDescription: "ServicesProvidedInSchools",
            visibleIf: "{school1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelServicesProvided",
                    questionStartIndex: "1",
                    showQuestionNumbers: "onpanel",
                    elements: [
                        {
                            type: "radiogroup",
                            name: "isProvidedServicesSchool",
                            title: "Does your clinic provide services in a school?",
                            choices: [
                                "Yes",
                                "No"
                            ]
                        },
                        {
                            type: "text",
                            name: "numberOfSchool-based",
                            title: "Number of School-based sites where the clinic offers services?",
                            visibleIf: "{isProvidedServicesSchool} = 'Yes'",
                        },
                        {
                            type: "checkbox",
                            name: "schoolServicesOffered",
                            title: "What services does your clinic offer at one or more of these sites?",
                            visibleIf: "{isProvidedServicesSchool} = 'Yes'",
                            choices: [
                                "Primary Medical",
                                "Behavioral Health/Counseling",
                                "Oral Health Screening/Referrals",
                                "Dental Sealants",
                                "Full Dental ",
                                "Substance Use/Opioid Use",
                                "Integrated Primary and Behavioral Health Care",
                                "Vision",
                                "Pharmacy"
                            ],
                            showOtherItem: true,
                            otherText: "Other (please describe)"
                        }
                    ],
                    title: "Services Provided In Schools"
                }
            ],
        },
        {
            name: "pageSchoolBasedClinics",
            title: "    ● School Based",
            navigationDescription: "SchoolBased",
            visibleIf: "{school1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelSchoolBasedClinics",
                    questionStartIndex: "1",
                    showQuestionNumbers: "onpanel",
                    elements: [
                        {
                            type: "radiogroup",
                            name: "isSchoolBasedClinics",
                            title: "Does your clinic provide services in a school-based clinic?",
                            choices: [
                                "Yes",
                                "No"
                            ]
                        },
                        {
                            type: "text",
                            name: "numberOfSchool-sites",
                            title: "Number of school sites where the clinic offers services?",
                            visibleIf: "{isSchoolBasedClinics} = 'Yes'",
                        },
                        {
                            type: "checkbox",
                            name: "schoolSitesServicesOffered",
                            title: "What services does your clinic offer at one or more of these sites?",
                            visibleIf: "{isSchoolBasedClinics} = 'Yes'",
                            choices: [
                                "Primary Medical",
                                "Behavioral Health/Counseling",
                                "Oral Health Screening/Referrals",
                                "Dental Sealants",
                                "Full Dental ",
                                "Substance Use/Opioid Use",
                                "Integrated Primary and Behavioral Health Care",
                                "Vision",
                                "Pharmacy"
                            ],
                            showOtherItem: true,
                            otherText: "Other (please describe)"
                        }
                    ],
                    title: "School-Based Clinics"
                }
            ],
        },
        {
            name: "otherProgramsServicesPage",
            elements: [
                {
                    type: "panel",
                    name: "panelOtherProgramsFTE",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            <li> Personnel that spend all or a substantial portion of their time dedicated to design and oversight of QI systems. They may have clinical, information technology (IT), or research backgrounds, and may include QI nurses, data specialists, statisticians, and designers of health IT (including EHRs and electronic medical records [EMRs]). Report personnel who support health IT to the extent that they are working with the QI system. Do not include the time of providers, such as physicians or dentists, who are also involved in the QI process; their time is to remain in their service category.</li>
                            <li> Some clinics, including “umbrella agencies", operate programs that – although within their scope of service and often important to the overall health of their patients – are not directly a part of the listed medical, dental, behavioral, or other health services. Include personnel who assist with WIC programs, job training programs, Head Start or Early Head Start programs, shelters, housing programs, child care, frail elderly support programs, adult day health care programs, fitness or exercise programs, public/retail pharmacies, etc. </li>
                            `,
                        },
                        {
                            type: "expression",
                            name: "otherProgramsServices1",
                            title: "Quality Improvement Personnel:"
                        },
                        {
                            type: "text",
                            name: "qualityImprovementPaidFTE",
                            title: "Paid Provider FTE",
                            inputType: "number",
                            min: "0",
                            max: 999,
                            defaultValue: 0,
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "qualityImprovementVolunteers",
                            title: "Number of Volunteers",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "qualityImprovementHours",
                            title: "Volunteer Hours",
                            inputType: "number",
                            defaultValue: 0,
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "expression",
                            name: "otherProgramsServices2",
                            title: "Other Programs/Services:"
                        },
                        {
                            type: "text",
                            name: "otherProgramsServicesPaidFTE",
                            title: "Paid Provider FTE",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            max: 999,
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "otherProgramsServicesVolunteers",
                            title: "Number of Volunteers",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "otherProgramsServicesHours",
                            title: "Volunteer Hours",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "panel",
                            name: "panelTotalOtherPrograms",
                            elements: [
                                {
                                    type: "expression",
                                    name: "totalOtherProgramsServicesFTE",
                                    title: "Paid Provider FTE"
                                },
                                {
                                    type: "expression",
                                    name: "totalOtherProgramsServicesFTE1",
                                    startWithNewLine: false,
                                    title: " ",
                                    expression: "{qualityImprovementPaidFTE} + {otherProgramsServicesPaidFTE}"
                                },
                                {
                                    type: "expression",
                                    name: "totalOtherProgramsServicesVolunteers",
                                    title: "Number of Volunteers"
                                },
                                {
                                    type: "expression",
                                    name: "totalOtherProgramsServicesVolunteers1",
                                    startWithNewLine: false,
                                    title: " ",
                                    expression: "{qualityImprovementVolunteers} + {otherProgramsServicesVolunteers}"
                                },
                                {
                                    type: "expression",
                                    name: "totalOtherProgramsServicesHours",
                                    title: "Volunteer Hours"
                                },
                                {
                                    type: "expression",
                                    name: "totalOtherProgramsServicesHours1",
                                    startWithNewLine: false,
                                    title: " ",
                                    expression: "{qualityImprovementHours} + {otherProgramsServicesHours}"
                                }
                            ],
                            title: "Total Other Programs/Services:"
                        }
                    ],
                    questionTitleLocation: "left",
                    title: "Please enter the Paid Provider FTE and Volunteer data:",
                    showQuestionNumbers: "off"
                }
            ],
            title: "Other Programs/Services",
            navigationDescription: "OtherPrograms-Services"
        },
        {
            name: "administrativePage",
            elements: [
                {
                    type: "panel",
                    name: "panelAdministrativeFTE",
                    elements: [
                        {
                            type: "expression",
                            name: "administrative1",
                            title: "Management and Support Personnel:",
                            popupdescription: `Management team, including the CEO, chief financial officer (CFO), chief information officer (CIO), chief medical officer (CMO), chief operations officer (COO), and human resources (HR) director, as well as other non-clinical support and office support personnel. For medical directors or other personnel whose time is split between clinical and non-clinical activities, report here only that portion of their FTE corresponding to the corporate management function.`,
                        },
                        {
                            type: "text",
                            name: "managementAndSupportStaffPaidFTE",
                            title: "Paid Provider FTE",
                            inputType: "number",
                            min: "0",
                            max: 999,
                            defaultValue: 0,
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "managementAndSupportStaffVolunteers",
                            title: "Number of Volunteers",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "managementAndSupportStaffHours",
                            title: "Volunteer Hours",
                            inputType: "number",
                            defaultValue: 0,
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "html",
                            html: "<hr></hr>"
                        },
                        {
                            type: "expression",
                            name: "administrative2",
                            title: "Fiscal and Billing Personnel:",
                            popupdescription: `Personnel performing accounting and billing functions in support of health center operations for services performed within the scope of project. Do not include the CFO; that is reported under Management and Support Personnel.`,
                        },
                        {
                            type: "text",
                            name: "fiscalAndBillingStaffPaidFTE",
                            title: "Paid Provider FTE",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            max: 999,
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "fiscalAndBillingStaffVolunteers",
                            title: "Number of Volunteers",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "fiscalAndBillingStaffHours",
                            title: "Volunteer Hours",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "html",
                            html: "<hr></hr>"
                        },
                        {
                            type: "expression",
                            name: "administrative3",
                            title: "IT Personnel:",
                            popupdescription: `Information systems technical personnel who maintain and operate the computing systems that support functions performed within the clinic's scope of project and IT personnel managing the hardware and software of a health IT system (including EHR/EMR). Report IT personnel performing data entry, help desk support, and training and technical assistance functions as part of the other medical personnel or appropriate service category for which they perform these functions. <b><u>Do not</u></b> report IT personnel designing medical forms and conducting analysis of HIT data here; that is reported under Quality Improvement Personnel in Staffing and Utilization for Other Progams/Services.`,
                        },
                        {
                            type: "text",
                            name: "itStaffPaidFTE",
                            title: "Paid Provider FTE",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            max: 999,
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "itStaffVolunteers",
                            title: "Number of Volunteers",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "itStaffHours",
                            title: "Volunteer Hours",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "html",
                            html: "<hr></hr>"
                        },
                        {
                            type: "panel",
                            name: "panelTotalAdministrative",
                            elements: [
                                {
                                    type: "expression",
                                    name: "totalAdministrativeFTE",
                                    title: "Paid Provider FTE"
                                },
                                {
                                    type: "expression",
                                    name: "totalAdministrativeFTE1",
                                    startWithNewLine: false,
                                    title: " ",
                                    expression: "{managementAndSupportStaffPaidFTE} + {fiscalAndBillingStaffPaidFTE} + {itStaffPaidFTE}"
                                },
                                {
                                    type: "expression",
                                    name: "totalAdministrativeVolunteers",
                                    title: "Number of Volunteers"
                                },
                                {
                                    type: "expression",
                                    name: "totalAdministrativeVolunteers1",
                                    startWithNewLine: false,
                                    title: " ",
                                    expression: "{managementAndSupportStaffVolunteers} + {fiscalAndBillingStaffVolunteers} + {itStaffVolunteers}"
                                },
                                {
                                    type: "expression",
                                    name: "totalAdministrativeHours",
                                    title: "Volunteer Hours"
                                },
                                {
                                    type: "expression",
                                    name: "totalAdministrativeHours1",
                                    startWithNewLine: false,
                                    title: " ",
                                    expression: "{managementAndSupportStaffHours} + {fiscalAndBillingStaffHours} + {itStaffHours}"
                                }
                            ],
                            title: "Total Administrative:"
                        }
                    ],
                    questionTitleLocation: "left",
                    title: "Please enter the Paid Provider FTE and Volunteer data:",
                    showQuestionNumbers: "off"
                }
            ],
            title: "Administrative and Facility",
            navigationDescription: "Administrative"
        },
        {
            name: "facilityPage",
            elements: [
                {
                    type: "panel",
                    name: "panelFacilityFTE",
                    elements: [
                        {
                            type: "expression",
                            name: "facility1",
                            title: "Facility Personnel:",
                            popupdescription: `Personnel with facility support and maintenance responsibilities, including custodians, housekeeping personnel, groundskeepers, security personnel, and other maintenance personnel. If facility functions are contracted (e.g., janitorial services), <b><u>do not</b></u> include an FTE.`,
                        },
                        {
                            type: "text",
                            name: "facilityStaffPaidFTE",
                            title: "Paid Provider FTE",
                            inputType: "number",
                            min: "0",
                            max: 999,
                            defaultValue: 0,
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "facilityStaffVolunteers",
                            title: "Number of Volunteers",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "facilityStaffHours",
                            title: "Volunteer Hours",
                            inputType: "number",
                            defaultValue: 0,
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "html",
                            html: "<hr></hr>"
                        },
                        {
                            type: "expression",
                            name: "facility2",
                            title: "Patient Support Personnel:",
                            popupdescription: `Intake personnel, front desk personnel, and patient health records personnel. Eligibility assistance workers are not included.`,
                        },
                        {
                            type: "text",
                            name: "patientSupportStaffPaidFTE",
                            title: "Paid Provider FTE",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            max: 999,
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "patientSupportStaffVolunteers",
                            title: "Number of Volunteers",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "text",
                            name: "patientSupportStaffHours",
                            title: "Volunteer Hours",
                            defaultValue: 0,
                            inputType: "number",
                            min: "0",
                            width: "400px"
                        },
                        {
                            type: "html",
                            html: "<hr></hr>"
                        },
                        {
                            type: "panel",
                            name: "panelTotalFacility",
                            elements: [
                                {
                                    type: "expression",
                                    name: "totalFacilityFTE",
                                    title: "Paid Provider FTE"
                                },
                                {
                                    type: "expression",
                                    name: "totalFacilityFTE1",
                                    startWithNewLine: false,
                                    title: " ",
                                    expression: "{facilityStaffPaidFTE} + {patientSupportStaffPaidFTE} "
                                },
                                {
                                    type: "expression",
                                    name: "totalFacilityVolunteers",
                                    title: "Number of Volunteers"
                                },
                                {
                                    type: "expression",
                                    name: "totalFacilityVolunteers1",
                                    startWithNewLine: false,
                                    title: " ",
                                    expression: "{facilityStaffVolunteers} + {patientSupportStaffVolunteers} "
                                },
                                {
                                    type: "expression",
                                    name: "totalFacilityHours",
                                    title: "Volunteer Hours"
                                },
                                {
                                    type: "expression",
                                    name: "totalFacilityHours1",
                                    startWithNewLine: false,
                                    title: " ",
                                    expression: "{facilityStaffHours} + {patientSupportStaffHours} "
                                }
                            ],
                            title: "Total Facility:"
                        }
                    ],
                    questionTitleLocation: "left",
                    title: "Please enter the Paid Provider FTE and Volunteer data:",
                    showQuestionNumbers: "off"
                }
            ],
            title: "    ● Facility",
            navigationDescription: "Facility"
        },
        {
            name: "totalAdministrativeFacilityPage",
            elements: [
                {
                    type: "panel",
                    name: "panelTotalAdministrativeFacility",
                    elements: [
                        {
                            type: "expression",
                            name: "totalAdministrativeFacilityFTE",
                            title: "Paid Provider FTE"
                        },
                        {
                            type: "expression",
                            name: "totalAdministrativeFacilityFTE1",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalAdministrativeFTE1} + {totalFacilityFTE1} "
                        },
                        {
                            type: "expression",
                            name: "totalAdministrativeFacilityVolunteers",
                            title: "Number of Volunteers"
                        },
                        {
                            type: "expression",
                            name: "totalAdministrativeFacilityVolunteers1",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalAdministrativeVolunteers1} + {totalFacilityVolunteers1} "
                        },
                        {
                            type: "expression",
                            name: "totalAdministrativeFacilityHours",
                            title: "Volunteer Hours"
                        },
                        {
                            type: "expression",
                            name: "totalAdministrativeFacilityHours1",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalAdministrativeHours1} + {totalFacilityHours1} "
                        }
                    ],
                    title: "Total Administrative And Facility Services:",
                    questionTitleLocation: "left",
                    showQuestionNumbers: "off"
                }

            ],
            title: "    ● Total Administrative And Facility",
            navigationDescription: "TotalAdministrativeAndFacilityServices"
        },
        {
            name: "workforcePreliminary",
            navigationDescription: "PreliminaryQuestions",
            elements: [
                {
                    type: "panel",
                    name: "workforcePanel",
                    elements: [
                        {
                            type: "boolean",
                            name: "workforce1",
                            title: "1.Does your health center provide any health professional education/training that is a hands-on, practical, or clinical experience? ",
                            description: "Health professional education/training does not include continuing education units."
                        },
                        {
                            type: "radiogroup",
                            name: "workforce1a",
                            visibleIf: "{workforce1} == true",
                            title: "1a. If yes, which category best describes your clinic's role in the health professional education/training process?",
                            choices: [
                                "a. Sponsor",
                                "b. Training Site Partner",
                            ],
                            showOtherItem: true,
                            otherText: "c. Other",
                            popupdescription: `
                            <p>
                              Select <strong>option "a. Sponsor "</strong> "A Sponsor hosts a comprehensive health profession education and/or training program, the implementation of which may require 
                              partnerships with other entities that deliver focused, time-limited education and/or training (e.g., a teaching clinic with a family medicine residency program).
                            </p>
                            
                            <p>
                              Select <strong>Option "b. A Training Site Partner" </strong> 
                              A Training Site Partner delivers focused, time-limited education and/or training to learners in support of a comprehensive curriculum hosted by another health profession education provider (e.g., month-long primary care dentistry experience for dental students).
                            </p>
                            `,
                        }
                    ],
                    showQuestionNumbers: "off"
                }
            ],
            title: "Workforce"
        },
        {
            visibleIf: "{workforce1} == true",
            name: "workforceMedicalEducation",
            title: "    ● MedicalEducation-Training",
            navigationDescription: "MedicalEducation-Training",
            elements: [

                {
                    type: "panel",
                    name: "panelWorkforce2",
                    description: "Enter non-negative integers. ",
                    elements: [
                        {
                            type: "expression",
                            name: "workforceQ2",
                            title: "2. Please indicate the range of health professional education/training offered at your clinic and how many individuals you have trained in each category within the last year.",
                            hideNumber: true,
                            popupdescription: "Examples of pre-graduate/certificate training include student clinical rotations or externships. A residency, fellowship, or practicum would be examples of post-graduate training. Include non-clinic individuals trained by your clinic."
                        },
                        ...workforceRecord("medicalFamily", "Family Physicians"),
                        ...workforceRecord("medicalGP", "General Practitioners"),
                        ...workforceRecord("medicalInternists", "Internists"),
                        ...workforceRecord("medicalOBGYN", "OBGYN"),
                        ...workforceRecord("medicalPediatricians", "Pediatricians"),
                        ...workforceRecord("medicalOtherSpecialtyPhysicians", "Other Specialty Physicians"),
                        ...workforceRecord("medicalNursePractitioners", "Nurse Practitioners"),
                        ...workforceRecord("medicalPhysicianAssistants", "Physician Assistants"),
                        ...workforceRecord("medicalCertifiedNurse", "Certified Nurse Midwives"),
                        ...workforceRecord("medicalRegisteredNurses", "Registered Nurses"),
                        ...workforceRecord("medicalLicensedPracticalNurses", "Licensed Practical Nurses/Vocational Nurses"),
                        ...workforceRecord("medicalMedicalAssistants", "Medical Assistants"),
                    ],
                    title: "Medical"
                }
            ],
        },
        {
            visibleIf: "{workforce1} == true",
            name: "workforceDentalEducation",
            title: "    ● DentalEducation-Training",
            navigationDescription: "DentalEducation-Training",
            elements: [

                {
                    type: "panel",
                    name: "panelWorkforce3",
                    elements: [
                        {
                            type: "expression",
                            name: "workforceQ3",
                            title: "2. Please indicate the range of health professional education/training offered at your clinic and how many individuals you have trained in each category within the last year.",
                            hideNumber: true
                        },
                        ...workforceRecord("dentalDentist", "Dentists"),
                        ...workforceRecord("dentalTherapists", "Therapists"),
                        ...workforceRecord("dentalHygienists", "Dental Hygienists"),
                        ...workforceRecord("dentalAssistants", "Dental Assistants"),
                    ],
                    title: "Dental"
                }
            ],
        },
        {
            visibleIf: "{workforce1} == true",
            name: "workforceVision",
            title: "    ● VisionEducation-Training",
            navigationDescription: "VisionEducation-Training",
            elements: [

                {
                    type: "panel",
                    name: "panelWorkforce4",
                    elements: [
                        {
                            type: "expression",
                            name: "workforceQ4",
                            title: "2. Please indicate the range of health professional education/training offered at your clinic and how many individuals you have trained in each category within the last year.",
                            hideNumber: true
                        },
                        ...workforceRecord("visionOphthalmologists", "Ophthalmologists"),
                        ...workforceRecord("visionOptometrists", "Optometrists"),
                    ],
                    title: "Vision"
                }
            ],
        },
        {
            visibleIf: "{workforce1} == true",
            name: "workforceMentalEducation",
            title: "    ● MentalEducation-Training",
            navigationDescription: "MentalEducation-Training",
            elements: [

                {
                    type: "panel",
                    name: "panelWorkforce5",
                    elements: [
                        {
                            type: "expression",
                            name: "workforceQ5",
                            title: "2. Please indicate the range of health professional education/training offered at your clinic and how many individuals you have trained in each category within the last year.",
                            hideNumber: true
                        },
                        ...workforceRecord("mentalPsychiatrists", "Psychiatrists"),
                        ...workforceRecord("mentalClinicalPyschologists", "Clinical Pyschologists"),
                        ...workforceRecord("mentalClinicalSocialWorkers", "Clinical Social Workers"),
                        ...workforceRecord("mentalProfessionalCounselors", "Professional Counselors"),
                        ...workforceRecord("mentalMarriageandFamilyTherapists", "Marriage and Family Therapists"),
                        ...workforceRecord("mentalPyschiatricNurseSpecialists", "Pyschiatric Nurse Specialists"),
                        ...workforceRecord("mentalMentalHealthNursePractitioners", "Mental Health Nurse Practitioners"),
                        ...workforceRecord("mentalMentalHealthPhysicianAssistants", "Mental Health Physician Assistants"),
                        ...workforceRecord("mentalSubstanceUseDisorderPersonnel", "Substance Use Disorder Personnel"),
                    ],
                    title: "Mental Health"
                }
            ],
        },
        {
            visibleIf: "{workforce1} == true",
            name: "workforceOtherProfessionalsEducation",
            title: "    ● OtherProfessionalsEducation-Training",
            navigationDescription: "OtherProfessionalsEducation-Training",
            elements: [

                {
                    type: "panel",
                    name: "panelWorkforce6",
                    elements: [
                        {
                            type: "expression",
                            name: "workforceQ6",
                            title: "2. Please indicate the range of health professional education/training offered at your clinic and how many individuals you have trained in each category within the last year.",
                            hideNumber: true
                        },
                        ...workforceRecord("otherProfessionalsChiropractors", "Chiropractors"),
                        ...workforceRecord("otherProfessionalsDietitiansNutritionists", "Dietitians/Nutritionists"),
                        ...workforceRecord("otherProfessionalsPharmacists", "Pharmacists"),
                        {
                            type: "panel",
                            name: "panelWorkforceotherProfessionalOther",
                            elements: [
                                {
                                    type: "text",
                                    name: `otherProfessionalOther_other`,
                                    title: "(Specfiy)",
                                },
                                {
                                    type: "text",
                                    name: `otherProfessionalOther_pregraduateCertificate`,
                                    title: "Pre-Graduate Certificate",
                                    inputType: "number",
                                    min: "0",
                                    defaultValue: 0,

                                },
                                {
                                    type: "text",
                                    name: `otherProfessionalOther_postgraduateCertificateFamily`,
                                    title: "Post Graduate Certificate",
                                    inputType: "number",
                                    min: "0",
                                    defaultValue: 0,
                                }
                            ],
                            questionTitleLocation: "left",
                            title: `Other`,
                            showQuestionNumbers: "off"
                        }
                    ],
                    title: "OtherProfessionals Health"
                }
            ],
        },
        {
            name: "OtherDataPoints",
            title: "    ● OtherDataPoints",
            navigationDescription: "OtherDataPoints",
            elements: [
                {
                    type: "panel",
                    name: "panelWorkforce7",
                    elements: [
                        {
                            type: "expression",
                            name: "workforceQ3",
                            title: "3. Provide the number of clinic personnel serving as preceptors at your clinic.",
                            titleLocation: "top",
                        },
                        {
                            type: "text",
                            name: "workforceQ3Count",
                            title: "Count",
                            width: "200px",
                            inputType: "number",
                            min: "0",
                            defaultValue: 0,
                        },
                        {
                            type: "expression",
                            name: "workforceQ4",
                            title: "4. Provide the number of clinic personnel (non-preceptors) supporting clinic training programs.",
                            titleLocation: "top",
                        },
                        {
                            type: "text",
                            name: "workforceQ4Count",
                            title: "Count",
                            width: "200px",
                            inputType: "number",
                            min: "0",
                            defaultValue: 0,
                        },
                        {
                            type: "radiogroup",
                            name: "workforceQ5",
                            title: "5. How often does your clinic conduct satisfaction surveys for providers working for the clinic?",
                            choices: [
                                "a.Monthly",
                                "b. Quarterly",
                                "c. Annually",
                                "d. We do not currently conduct provider satisfaction surveys",
                            ],
                            showOtherItem: true,
                            otherText: "e. Other, please specify",
                            titleLocation: "top",
                        },
                        {
                            type: "radiogroup",
                            name: "workforceQ6",
                            title: "6. How often does your clinic conduct satisfaction surveys for general personnel working for the clinic?",
                            choices: [
                                "a.Monthly",
                                "b. Quarterly",
                                "c. Annually",
                                "d. We do not currently conduct provider satisfaction surveys",
                            ],
                            showOtherItem: true,
                            otherText: "e. Other, please specify",
                            titleLocation: "top",
                        }
                    ],
                    questionTitleLocation: "left",
                    showQuestionNumbers: "off"
                }
            ],
        },
        {
            title: "Overall Total",
            navigationDescription: "OverallTotal",
            name: "OverallTotal",
            elements: [
                {
                    type: "panel",
                    name: "panelOverallTotalFTE",
                    elements: [
                        {
                            type: "html",
                            width: "220px",
                            name: "overallTotal1",
                            html: "<p style=\"font-size:14px; color=#142a4c;font-weight: Bold;\">Total Paid Providers FTEs	</p>"
                        },
                        {
                            type: "expression",
                            name: "overallTotalPaidFTE",
                            width: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: " {totalMedicalServicesPaidFTE} + {totalDentalsFTE} + {totalVisionsFTE} + {totalTotalPaidFTEBehavioralHealth} + {totalOtherProfessionalPersonnelFTE} + {totalEnablingFTE} + {totalOtherProgramsServicesFTE1} + {totalAdministrativeFacilityFTE1} ",
                        },
                        {
                            type: "html",
                            width: "220px",
                            name: "overallTotal2",
                            html: "<p style=\"font-size:14px; color=#142a4c;font-weight: Bold;\">Total Visits	</p>"
                        },
                        {
                            type: "expression",
                            name: "overallTotalVisits",
                            width: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalMedicalServicesVisits} + {totalDentalVisits} + {totalVisionVisits} + {totalTotalVisitsBehavioralHealth} + {totalOtherProfessionalPersonnelVisits} + {totalEnablingVisits} ",
                        },
                        {
                            type: "html",
                            width: "220px",
                            name: "overallTotal3",
                            html: "<p style=\"font-size:14px; color=#142a4c;font-weight: Bold;\">Total Virtual Visits	</p>"
                        },
                        {
                            type: "expression",
                            name: "overallTotalVirtualVisits",
                            width: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalMedicalServicesVirtualVisits} + {totalDentalVirtualVisits} + {totalVisionVirtualVisits} + {totalTotalVirtualVisitsBehavioralHealth} + {totalOtherProfessionalPersonnelVirtualVisits} + {totalEnablingVirtualVisits} ",
                        },
                        {
                            type: "html",
                            width: "220px",
                            name: "overallTotal4",
                            html: "<p style=\"font-size:14px; color=#142a4c;font-weight: Bold;\">Total Clinic Visits	</p>"
                        },
                        {
                            type: "expression",
                            name: "overallTotalClinicVisits",
                            width: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{overallTotalVisits}+{overallTotalVirtualVisits}",
                        }
                    ],
                    questionTitleLocation: "left",
                    title: "Full-time Employees:",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelOverallTotalVolunteers",
                    elements: [
                        {
                            type: "html",
                            width: "220px",
                            name: "overallTotal5",
                            html: "<p style=\"font-size:14px; color=#142a4c;font-weight: Bold;\">Total Volunteers	</p>"
                        },
                        {
                            type: "expression",
                            name: "overallTotalVolunteers",
                            width: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalMedicalServicesVolunteers} + {totalDentalVolunteers} + {totalVisionVolunteers} + {totalTotalBehavioralHealthVolunteers} + {totalOtherProgramsServicesVolunteers1} +{totalAdministrativeFacilityVolunteers1}",
                        },
                        {
                            type: "html",
                            width: "220px",
                            name: "overallTotal6",
                            html: "<p style=\"font-size:14px; color=#142a4c;font-weight: Bold;\">Total Hours	</p>"
                        },
                        {
                            type: "expression",
                            name: "overallTotalVolunteersHours",
                            width: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalMedicalServicesVolunteersHours}+ {totalDentalHoursV} + {totalVisionHoursV} + {totalTotalBehavioralHealthHours} + {totalOtherProgramsServicesHours1} +{totalAdministrativeFacilityHours1}",
                        },
                        {
                            type: "html",
                            width: "220px",
                            name: "overallTotal7",
                            html: "<p style=\"font-size:14px; color=#142a4c;font-weight: Bold;\">Total Visits	</p>"
                        },
                        {
                            type: "expression",
                            name: "overallTotalVolunteersVisits",
                            width: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: "{totalMedicalServicesVolunteersVisits} + {totalDentalVisitsV} + {totalVisionVisitsV} + {totalTotalBehavioralHealthVisitsV} ",
                        }

                    ],
                    questionTitleLocation: "left",
                    title: "Volunteers:",
                    showQuestionNumbers: "off",
                },
                {
                    type: "panel",
                    name: "panelOverallTotalTotals",
                    showQuestionNumbers: "off",
                    questionTitleLocation: "left",
                    elements: [
                        {
                            type: "html",
                            name: "overallTotalT1",
                            width: "220px",
                            maxwidth: "220px",
                            html: "<p style=\"font-size:14px; color=#142a4c;font-weight: Bold;\"> Total Virtual Visits </p>"
                        },
                        {
                            type: "expression",
                            name: "overallTotalTotalVirtualVisits",
                            maxwidth: "220px",
                            startWithNewLine: false,
                            expression: " {overallTotalVirtualVisits}",
                            title: " ",
                        },
                        {
                            type: "html",
                            name: "overallTotalT2",
                            width: "220px",
                            maxwidth: "220px",
                            html: "<p style=\"font-size:14px; color=#142a4c;font-weight: Bold;\">Total  Visits</p>"
                        },
                        {
                            type: "expression",
                            name: "overallTotalTotalVisits",
                            maxwidth: "220px",
                            startWithNewLine: false,
                            title: " ",
                            expression: " {overallTotalVisits} + {overallTotalVolunteersVisits}",
                        }
                    ]
                }
            ]
        }
    ]
}