import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label, List } from "reactstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import "./NetworkDataHub.scss";
import { apiClient, ApiEndpoints, ApplicationPermissions } from "utilities";
import { useForm } from "react-hook-form";
import { ReactSortable } from "react-sortablejs";
import { useUserContext } from "contexts";
import { trackPromise } from "react-promise-tracker";

export const Announcements = () => {
  const user = useUserContext();
  const canCreate = user.hasPermission(ApplicationPermissions.NetworkDataHub.CreateAnnouncements);
  const canUpdate = user.hasPermission(ApplicationPermissions.NetworkDataHub.UpdateAnnouncements);
  const canDelete = user.hasPermission(ApplicationPermissions.NetworkDataHub.DeleteAnnouncements);
  const { handleSubmit } = useForm();
  const [announcements, setAnnouncements] = useState([]);
  const [isAddMode, setIsAddMode] = useState(false);
  const [shouldRerender, setShouldRerender] = useState(false);

  const handleReorder = async (event) => {
    const url = `${ApiEndpoints.NetworkDataHub.Announcements.Reorder}`;
    const prevAnnouncements = [...announcements]; // new scoped array
    const [movedItem] = prevAnnouncements.splice(event.oldDraggableIndex, 1);
    const newAnnouncements = prevAnnouncements;
    newAnnouncements.splice(event.newDraggableIndex, 0, movedItem);

    for (var i = 0; i < newAnnouncements.length; i++) {
      newAnnouncements[i].order = i;
    }

    trackPromise(apiClient
      .put(url, newAnnouncements))
  };

  const handleAdd = () => {
    setIsAddMode(true);
    setAnnouncements([...announcements, { message: "" }]);
  };

  const handleCancel = (announcement, index) => {
    announcements[index].isEditMode = false;
    setAnnouncements([...announcements]);
    setShouldRerender(!shouldRerender);

    if (!announcement.isEditMode) {
      setIsAddMode(false);
    }
  };

  const insertData = async (index, announcement) => {
    const url = `${ApiEndpoints.NetworkDataHub.Announcements.Create}`;
    announcement.order = index;
    await trackPromise(apiClient
      .put(url, announcement, {
        // @ts-ignore
        toastConfig: {
          showSuccess: true,
          successMessage: `Announcement ${
            announcement.isEditMode ? "updated" : "created"
          } successfully!`,
        },
      })
      .then(() => {
        setIsAddMode(false);
        setShouldRerender(!shouldRerender);
      }))
  };

  const handleEdit = (index, announcement) => {
    announcements[index] = { ...announcement, isEditMode: true };
    setAnnouncements([...announcements]);
  };

  const handleChange = (event, index) => {
    const announcement = announcements[index];
    announcement[event.target.name] = event.target.value;
    setAnnouncements([...announcements]);
  };

  const handleDelete = (id) => {
    trackPromise(apiClient
    .delete(`${ApiEndpoints.NetworkDataHub.Announcements.Delete}/${id}`, {
      // @ts-ignore
      toastConfig: {
        showSuccess: true,
        successMessage: `Announcement deleted successfully!`,
      },
    })
    .then(() => {
      setAnnouncements(announcements.filter((a) => a.id !== id));
    }))
  };

  useEffect(() => {
    trackPromise(apiClient
      .get(ApiEndpoints.NetworkDataHub.Announcements.View)
      .then((response) => {
        setAnnouncements(response.data);
      })
      .catch((err) => {
        console.error(err);
        setAnnouncements([]);
      }));
  }, [setAnnouncements, shouldRerender]);

  return (
    <div
      id="announcements"
      className="tertiary-border px-4 py-3 d-flex flex-column justify-content-between"
    >
      <div>
        <h4 className="text-center">
          <u>Announcements</u>
        </h4>
        <List type="unstyled" className="d-flex flex-column px-5 py-2">
          <ReactSortable
            list={announcements}
            disabled={!user.hasPermission(ApplicationPermissions.NetworkDataHub.CreateAnnouncements)}
            setList={setAnnouncements}
            onUpdate={(event) => {
              handleReorder(event);
            }}
          >
            {announcements?.map((announcement, index) => {
              if (announcement.isEditMode || announcement.id == null) {
                return (
                  <li key={index} className="">
                    <Form
                      onSubmit={handleSubmit(
                        async () => await insertData(index, announcement)
                      )}
                    >
                      <Label for={`message-${index}`}>
                        <b>
                          {announcement.isEditMode ? "Update" : "New"}{" "}
                          Announcement
                        </b>
                      </Label>
                      <FormGroup>
                        <Input
                          id={`message-${index}`}
                          name="message"
                          type="textarea"
                          onChange={(event) => handleChange(event, index)}
                          value={announcement.message}
                        />
                      </FormGroup>
                      <FormGroup className="mt-3 submit-btn-group">
                        <Button
                          type="submit"
                          color="primary"
                          className="float-end ms-3 btn-sm pill-btn"
                        >
                          Save
                        </Button>
                        <Button
                          color="secondary"
                          className="float-end ms-3 btn-sm pill-btn"
                          onClick={() => handleCancel(announcement, index)}
                        >
                          Cancel
                        </Button>
                      </FormGroup>
                    </Form>
                  </li>
                );
              } else {
                return (
                  <li key={index} className={canUpdate ? "moveable-item" : ""}>
                    <span>
                      {canUpdate &&
                        <FaEdit
                          className="me-1 link-primary"
                          onClick={(event) => {
                            handleEdit(index, announcement);
                          }}
                        />
                      }
                      {canDelete && 
                        <FaTrashAlt
                          className="me-3 link-primary"
                          onClick={() => handleDelete(announcement.id)}
                        />
                      }
                    </span>
                    {announcement.message}
                    <hr />
                  </li>
                );
              }
            })}
          </ReactSortable>
        </List>
      </div>
      <div>
        {!isAddMode && canCreate && (
          <Button
            color="primary"
            className="float-end mt-3 pill-btn"
            outline
            onClick={() => handleAdd()}
          >
            + Announcement
          </Button>
        )}
      </div>
    </div>
  );
};
