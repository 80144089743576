import { ClinicSurveyComponent } from 'components/Survey/ClinicSurveyComponent';
import React, { useEffect, useState } from 'react';
import { manualDataJSON } from '../../utilities/surveys/manualDataJSON';
import { apiGetCurrentSurveyTemplate } from 'utilities';

export const ManualDataEntry = () => {
  const surveyName = 'ManualDataEntry';
  const [surveyJSON, setSurveyJSON] = useState();
  const [surveyVersion, setSurveyVersion] = useState(0);

  useEffect(() => {
    apiGetCurrentSurveyTemplate(surveyName,manualDataJSON).then((response)=>
    {
      if(response){
        setSurveyJSON(response.template);
        setSurveyVersion(response.version);
      }
    })
  }, []);
  return (
    <>
    {surveyJSON && <ClinicSurveyComponent json={surveyJSON} SurveyName={surveyName} version={surveyVersion}/>}
    </>
    );
  
}