import React, { useState, useEffect } from "react";
import { 
    Table, Input, Pagination, PaginationItem, 
    PaginationLink, Row, Col
} from "reactstrap";
import { apiClient } from "utilities";
import { debounce } from "lodash";
import { QueryParameter, getNextSortOrder, SortOrder } from "models";
import { SmartFilter } from "./SmartFilter";
import './smart-grid.scss';
import qs from "qs";
import { SortAscendingIcon, SortDescendingIcon, OutlineSwitchVerticalIcon } from "components/Icon";

const GridSortAscendingIcon = ({ iconProps = undefined }) => 
    (<SortAscendingIcon className="sort-icon" size={15} viewBoxSize={20} title="Sort Ascending" description="Sorts the grid in ascending order" iconProps={iconProps} /> );

const GridSortDescendingIcon = ({ iconProps = undefined }) => 
    (<SortDescendingIcon className="sort-icon" size={15} viewBoxSize={20} title="Sort Descending" description="Sorts the grid in descending order" iconProps={iconProps} /> );
    
export const SmartGrid = ({
    columnConfig = [],
    data = null,
    debounceWait = 500,
    filterConfig = null,
    isLoadingOverride = null,
    onQueryParameterChange = null,
    onSuccess = null,
    pageable = false,
    pageSize = 100,
    sortable = false,
    url = null,
    initialSortProperty = null,
    initialSortOrder = null,
    refreshGrid = false // Refresh Grid data by forcing API call
}) => {
    const isLocalGrid = data != null;
    let isLoadingDefault = isLoadingOverride != null 
        ? isLoadingOverride
        : false;
    
    const [selectedPageSize, setSelectedPageSize] = useState(pageSize);
    const [pageSizes] = useState([5, 10, 20, 50 ,100]);
    const [apiUpdateNeeded, setApiUpdateNeeded] = useState(url !== null);
    const [isLoading, setIsLoading] = useState(isLoadingDefault);
    const [allData, setAllData] = useState(data);
    const [queryParameter, setQueryParameter] = useState(new QueryParameter({
        filters: filterConfig, 
        limit: selectedPageSize,
        sortOrder: initialSortOrder,
        sortProperty: initialSortProperty
    }));
    const [resetPaging, setResetPaging] = useState(false);

    //store the next query parameter to execute if a request is pending already
    const [nextQueryParameter, setNextQueryParameter] = useState(null);
    
    const filterable = filterConfig != null;
    let localColConfig = processColumnConfig(columnConfig, filterConfig);
    let bodyClassName = `smart-grid-body${isLoading ? ' loading' : ''}`;

    //https://www.carlrippon.com/using-lodash-debounce-with-react-and-ts/
    const updateFilterValue = React.useRef(
        debounce(async (propertyName, newFilterValue) => {
            if(propertyName === null){
                return;
            }
            
            let newQueryParameter = new QueryParameter();
            Object.assign(newQueryParameter, queryParameter);

            for(let filter of newQueryParameter.filters){
                if(filter.propertyName.toUpperCase() === propertyName.toUpperCase()){
                    filter.filterValue = newFilterValue;
                    setResetPaging(true);
                    break;
                }
            }
           
            //only change loading status if the SmartGrid is handling loading state
            if(url !== null){
                setApiUpdateNeeded(true);
            }

            handleQueryParameterChange(newQueryParameter);
        }, debounceWait)
    ).current;

    const handleQueryParameterChange = React.useRef((queryParameterToChange) => {
        if(isLoading === true && apiUpdateNeeded === true){
            setNextQueryParameter(queryParameterToChange);
        } else {
            setQueryParameter(queryParameterToChange);
        }
    }).current;

    const handleSetIsLoading = React.useRef((isLoadingValue) => {
        if(isLoadingOverride == null){
            setIsLoading(isLoadingValue);
        }
    }).current;

    const updateSortValues = (event, propertyName) => {
        event.preventDefault();

        //we need a new copy of the query parameter
        let newQueryParameter = getEditableQueryParameter(queryParameter);

        //if we are sorting the same column, get the next sort order, otherwise, sort by Ascending.
        let nextSortOrder = getNextSortOrder(queryParameter.sortOrder, newQueryParameter.sortProperty, propertyName);

        newQueryParameter.sortProperty = propertyName;
        newQueryParameter.sortOrder = nextSortOrder;

        setResetPaging(true);
        handleQueryParameterChange(newQueryParameter);
    };

    const onNextPage = () => {
        let newQueryParameter = getEditableQueryParameter(queryParameter);
        newQueryParameter.offset += queryParameter.limit;

        handleQueryParameterChange(newQueryParameter);
    }

    const onPreviousPage = () => {
        let newQueryParameter = getEditableQueryParameter(queryParameter);
        newQueryParameter.offset -= selectedPageSize;

        if(newQueryParameter.offset < 0){
            newQueryParameter.offset = 0;
        }

        handleQueryParameterChange(newQueryParameter);
    }

    //updates the internal data store if we are using local data
    useEffect(() => {
        if(data != null){
            setAllData(data);
        }
    }, [data])

    // If URL changes, need to reset things
    useEffect(() => {
        if(url !== null && !isLocalGrid){
            setApiUpdateNeeded(true);
            setResetPaging(true);
        }      
    }, [url]);

    useEffect(() => {
        if (onQueryParameterChange) {
            onQueryParameterChange(queryParameter);
        }

        if(url != null && !isLoading){
            setApiUpdateNeeded(true);
        }
    }, [onQueryParameterChange, queryParameter, queryParameter.filters, refreshGrid]);

    useEffect(() => {
        if (!isLocalGrid && url != null && apiUpdateNeeded === true && isLoading === false) {
            
            handleSetIsLoading(true);

            let newQueryParameter = getEditableQueryParameter(queryParameter);

            if(resetPaging)
                newQueryParameter.offset = 0;

                apiClient.get(url
                    , {
                    params: newQueryParameter,
                    paramsSerializer: 
                    {
                        encode: (params)=> qs.stringify(params, { arrayFormat: 'brackets', encode: false, allowDots: true })
                    }
                   
                }
                ).then(response => {
                setResetPaging(false);
                //update the query parameter with the new count/paging data
                newQueryParameter.limit = response.data.limit;
                newQueryParameter.offset = response.data.offset;
                newQueryParameter.totalCount = response.data.totalCount;

                //once the call is complete, set the data and disable the loading spinner
                setAllData(response.data.results);    
                
                //if there is a nextQueryParameter, it means we have a pending request. 
                //copy the search terms of that request and set it in motion to execute
                if(nextQueryParameter !== null){
                    newQueryParameter.filters = nextQueryParameter.filters;
                    newQueryParameter.sortOrder = nextQueryParameter.sortOrder;
                    newQueryParameter.sortProperty = nextQueryParameter.sortProperty;

                    setNextQueryParameter(null);
                } else {
                    setApiUpdateNeeded(false);
                }

                setQueryParameter(newQueryParameter);
                handleSetIsLoading(false);
                
                //if an onSuccess handler was provided, call it here
                if(onSuccess != null){
                    onSuccess(response);
                }
            });
        }
    }, [isLoadingOverride, apiUpdateNeeded])

    const onPageSizeChange = (selected) =>
    {        
        let newQueryParameter = getEditableQueryParameter(queryParameter);
        newQueryParameter.limit = selected;
        newQueryParameter.offset = 0;

        setSelectedPageSize(selected);
        handleQueryParameterChange(newQueryParameter);
    };

    // If data is modify inside a custom component
    // we update data to reflecto those changes
    // this is usefull when using components that
    // requires being re-render when modify like inputs
    const setRow = (row, i) => {
        const newData = [...allData];
        newData[i] = row;
        setAllData(newData);
    }

    return (
        <>
          <div className="smart-grid-wrapper">
            <Table className={`${isLoading ? 'loading' : ''}`}>
                <thead>
                    <tr>
                        {localColConfig.map(config => 
                            <ColumnHeader 
                                onClick={(e) => updateSortValues(e, config.columnName)} 
                                config={config} 
                                sortable={sortable}
                                queryParameter={queryParameter}
                                key={`header-${config.columnName}`} 
                                disabled={isLoading}
                            />
                        )}
                    </tr>
                    {filterable && 
                        <tr>
                            {localColConfig.map(config =>
                                
                                <td key={`header-${config.columnName}-input`}>
                                { 
                                    
                                    config.filterable && (config.filterComponent != null
                                    ?   <config.filterComponent 
                                            value={config.filterValue}
                                            onChange={(e) => updateFilterValue(config.columnName, e.target.value)}
                                        />
                                    :   <SmartFilter
                                            columnName={config.columnName}
                                            value={config.filterValue}
                                            onChange={(e) => updateFilterValue(config.columnName, e.target.value)}
                                            hidden={!filterable}
                                            filterType={config.filterType}
                                        />
                                )}
                                </td>
                            )}
                        </tr>
                    }                      
                </thead>

                <tbody className={bodyClassName}>
                    { allData != null && allData.length > 0 
                            ?   allData.map((row, i) => 
                                    <tr key={`row-${i}`}>
                                        {localColConfig.map(config => 
                                            <td key={`row-${i}-col-${config.columnName}`}>
                                                {config.component != null 
                                                    ? config.component(row, i, setRow) 
                                                    : `${row[config.columnName] ?? ''}`}
                                            </td>
                                        )}
                                    </tr>      
                                )
                            : <></>
                    }
                </tbody>
                
            </Table>
            <Row className={bodyClassName}>
                <Col size={12}>
                    {pageable &&
                        <>
                            <Pagination aria-label={formatPagingDisplay(queryParameter)} className='smart-grid-pagination'>
                                <PaginationItem disabled={queryParameter.offset < 1}>
                                    <PaginationLink className="smart-grid-pagination-item"
                                        previous
                                        onClick={onPreviousPage}
                                        disabled={isLoading}
                                    />
                                </PaginationItem>
                                <PaginationItem active>
                                    <PaginationLink className="center-pagination smart-grid-pagination-item" disabled={isLoading}>
                                        {formatPagingDisplay(queryParameter)}
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem disabled={queryParameter.offset + queryParameter.limit >= queryParameter.totalCount}>
                                    <PaginationLink className="smart-grid-pagination-item"
                                        next
                                        onClick={onNextPage}
                                        disabled={isLoading}
                                    />
                                </PaginationItem>                           
                            </Pagination>
                            <Pagination aria-label={`page size selector`} className='smart-grid-pagination'>                           
                                <PaginationItem disabled={queryParameter.offset + queryParameter.limit >= queryParameter.totalCount} >
                                    <div className='d-flex justify-content-centers align-items-center'>
                                        <label htmlFor='page-size-selector' className='d-none'>Page size selector</label>
                                        <span className='text-nowrap pe-2'>Show</span>
                                        <Input id="page-size-selector" type="select" value={selectedPageSize}  onChange={(e) => onPageSizeChange(e.target.value)} aria-label={`page size selector`} disabled={isLoading} >
                                            {pageSizes.map((ps, i) => <option value={ps} key={i}>{ps}</option>)}
                                        </Input>
                                        <span className='text-nowrap ps-2'> Records.</span>
                                    </div>                                    
                                </PaginationItem>
                            </Pagination>
                        </>
                    }
                </Col>
            </Row>
          </div>
        </>
    );
};

const ColumnHeader = ({config, onClick, sortable, queryParameter, disabled}) => {
    
    if(sortable && config.sortable){
        return (
            <th key={`header-${config.columnName}`}>
                <a className={disabled? "sort-column sort-column-disabled": "sort-column" } href="#" onClick={onClick}>
                    <HeaderText config={config} queryParameter={queryParameter} isSortable={true} />
                </a>          
            </th>
        );
    } 
    
    return (
        <th key={`header-${config.columnName}`}>
            <HeaderText config={config} queryParameter={queryParameter} />
        </th>
    ); 
}

const HeaderText = ({config, queryParameter, isSortable = false }) => {  
    return (
    <div id={`header-${config.columnName}-input-label`} className="smart-grid-label">
        {config.headerText}
        { queryParameter.sortOrder !== SortOrder.None && queryParameter.sortProperty === config.columnName
            ? queryParameter.sortOrder === SortOrder.Ascending 
                ? <GridSortAscendingIcon />
                : <GridSortDescendingIcon /> 
            : isSortable 
                        ?  <OutlineSwitchVerticalIcon className="sort-icon" size={15} viewBoxSize={24} title="Column Sort" description="Column can be sorted"/>
                        :  <GridSortAscendingIcon iconProps={{ visibility: "hidden" }}  />
        }
    </div>
    );
}

const getEditableQueryParameter = (queryParameter) => {
    let newQueryParameter = new QueryParameter();
    Object.assign(newQueryParameter, queryParameter);
    return newQueryParameter;
}

const formatPagingDisplay = (queryParameter) => {   
    const offset = queryParameter?.offset || 0;
    const totalRecordsCount = queryParameter.totalCount;

    if(totalRecordsCount == 0){
        return 'No records found';
    }

    let currentPageMin = offset + 1;
    let currentPageMax = offset + queryParameter.limit;

    if(currentPageMax >= totalRecordsCount || currentPageMax === 0){
        currentPageMax = totalRecordsCount;
    }

    if(currentPageMin > currentPageMax){
        currentPageMin = currentPageMax;
    }

    if(currentPageMin == 0 && totalRecordsCount > 0){
        currentPageMin = 1;
    }

    return `Showing ${currentPageMin} - ${currentPageMax} of ${queryParameter.totalCount} total records`;
}

const processColumnConfig = (columnConfig, filterConfig) => {

    if(filterConfig == null){
        return columnConfig;
    }
    
    let processedColumnConfig = [];
    Object.assign(processedColumnConfig, columnConfig);

    for(let config of processedColumnConfig){
        
        const filterParameter = filterConfig?.filter(fp => fp.propertyName.toUpperCase() == config.columnName?.toUpperCase())[0];
        const filterable = filterParameter != null;
    
        config.filterable = filterable;
        
        if(filterable){
            let filterComponent = filterParameter.component;
            
            config.filterComponent = filterComponent;
            config.filterType = filterParameter.filterType;
        }   
    }

    return processedColumnConfig;
}