import React from "react";
import IdGenerator from '../components/IdGenerator';

const SVG_PATH_GO_BACK = "M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z";
const SVG_PATH_INFO ="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z";
const SVG_PATH_NOTIFICATION = "M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z";
const SVG_PATH_CALENDAR_1 = "M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z";
const SVG_PATH_CALENDAR_2 = "M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z";
const SVG_PATH_DOWN = "M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z";
const SVG_PATH_UP = "M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z";
const SVG_PATH_X_SQUARE = "M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z";
const SVG_PATH_SORT_ASCENDING = "M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z";
const SVG_PATH_SORT_DESCENDING = "M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z";
const SVG_PATH_EXCLAMATION_1 = "M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z";
const SVG_PATH_EXCLAMATION_2 = "M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z";
const SVG_PATH_CHECK_CIRCLE_1 = "M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z";
const SVG_PATH_CHECK_CIRCLE_2 = "M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z";
const SVG_PATH_DASH_CIRCLE_1 = "M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z";
const SVG_PATH_DASH_CIRCLE_2 = "M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z";
const SVG_PATH_CALENDAR_CHECK = "M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z";
const SVG_PATH_FILE_BAR_GRAPH = "M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-2 11.5v-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z";
const SVG_PATH_FOLDER_PLUS_1 = "m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z";
const SVG_PATH_FOLDER_PLUS_2 = "M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z";
const SVG_PATH_X_CIRCLE_1 = "M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z";
const SVG_PATH_X_CIRCLE_2 = "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z";
const SVG_PATH_PERSON_FILL = "M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z";

const Icon = ({ path, iconProps, title, description, size, className, fillRuleValue, viewBoxSize, customPath = undefined, fillValue = "currentColor" }) => {
  const uniqueId = IdGenerator("icon");
  const titleId = `svg-title-${uniqueId}`;
  const descId = `svg-desc-${uniqueId}`;

  return (
    <svg
      stroke="currentColor"
      fill={fillValue}
      strokeWidth="0"
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      aria-describedby={`${titleId} ${descId}`}
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...className && {className: className}}
      {...iconProps}
    >
      <title id={titleId}>{title}</title>
      <desc id={descId}>{description}</desc>
      { customPath && customPath()}
      { !customPath && (Array.isArray(path) ? (
        path.map((p, index) => <path key={`path-${index}`} {...fillRuleValue && {fillRule: fillRuleValue}} {...fillRuleValue && {clipRule: fillRuleValue}} d={p}></path>)
      ) : (
        <path {...fillRuleValue && {fillRule: fillRuleValue}} {...fillRuleValue && {clipRule: fillRuleValue}} d={path}></path>
      ))}
    </svg>
  );
};

const renderIcon = (path, iconProps, title, description, size, className, viewBoxSize , fillRuleValue = undefined, customPath = undefined, fillValue = "currentColor") => (
  <Icon
    path={path}
    title={title}
    description={description}
    size={size}
    className={className}
    iconProps={iconProps}
    fillRuleValue={fillRuleValue}
    viewBoxSize ={viewBoxSize}
    customPath={customPath}
    fillValue={fillValue}
  />
);

export const GoBackIcon = ({ size, className = undefined }) =>
  renderIcon(SVG_PATH_GO_BACK, {style: {marginRight: '5px'}}, 'Go Back', 'Returns to previous page.', size, className, 16);

export const InformationIcon = ({ size, className = undefined, title, description, iconProps = undefined }) =>
  renderIcon(SVG_PATH_INFO, iconProps, title, description, size, className, size);  

export const NotificationIcon = ({ size, className = undefined, title, description, iconProps = undefined }) =>
    renderIcon(SVG_PATH_NOTIFICATION, iconProps, title, description, size, className, size);  

export const CalendarIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon([SVG_PATH_CALENDAR_1, SVG_PATH_CALENDAR_2], iconProps, title, description, size, className, viewBoxSize );
    
export const DownIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon(SVG_PATH_DOWN, iconProps, title, description, size, className, viewBoxSize, "evenodd");

export const UpIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon(SVG_PATH_UP, iconProps, title, description, size, className, viewBoxSize, "evenodd");   

export const XFillSquareIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon(SVG_PATH_X_SQUARE, iconProps, title, description, size, className, viewBoxSize);   

export const SortAscendingIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon(SVG_PATH_SORT_ASCENDING, iconProps, title, description, size, className, viewBoxSize);
    
export const SortDescendingIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon(SVG_PATH_SORT_DESCENDING, iconProps, title, description, size, className, viewBoxSize);

export const OutlineSwitchVerticalIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon(undefined, iconProps, title, description, size, className, viewBoxSize, undefined, 
        () => (<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"></path>));

export const ExclamationIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon([SVG_PATH_EXCLAMATION_1, SVG_PATH_EXCLAMATION_2], iconProps, title, description, size, className, viewBoxSize );

export const CheckCircleIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon([SVG_PATH_CHECK_CIRCLE_1, SVG_PATH_CHECK_CIRCLE_2], iconProps, title, description, size, className, viewBoxSize );

export const ArrowClockwiseIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon(undefined, iconProps, title, description, size, className, viewBoxSize, undefined,() => (
    <>
        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"></path>
        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"></path>
    </>
    ));

export const DashCircleIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon([SVG_PATH_DASH_CIRCLE_1, SVG_PATH_DASH_CIRCLE_2], iconProps, title, description, size, className, viewBoxSize );

export const FillCalendarCheckIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon(SVG_PATH_CALENDAR_CHECK, iconProps, title, description, size, className, viewBoxSize);

export const FileBarGraphIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon(SVG_PATH_FILE_BAR_GRAPH, iconProps, title, description, size, className, viewBoxSize);

export const FolderPlusIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon([SVG_PATH_FOLDER_PLUS_1, SVG_PATH_FOLDER_PLUS_2], iconProps, title, description, size, className, viewBoxSize );

export const XCircleIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon([SVG_PATH_X_CIRCLE_1, SVG_PATH_X_CIRCLE_2], iconProps, title, description, size, className, viewBoxSize );

export const OutlineClipboardCopyIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon(undefined, iconProps, title, description, size, className, viewBoxSize, undefined,() => (
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"></path>
    ), "none");

export const OutlinePlusCircleIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize, fill }) =>
    renderIcon(undefined, iconProps, title, description, size, className, viewBoxSize, undefined,() => (
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    ), fill);

export const OutlineMinusCircleIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize, fill }) =>
    renderIcon(undefined, iconProps, title, description, size, className, viewBoxSize, undefined,() => (
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    ), fill);

export const MenuIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon(undefined, iconProps, title, description, size, className, viewBoxSize, undefined,() => (
        <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
    ));

export const PersonFillIcon = ({ size, className = undefined, title, description, iconProps = undefined, viewBoxSize }) =>
    renderIcon(SVG_PATH_PERSON_FILL, iconProps, title, description, size, className, viewBoxSize);
