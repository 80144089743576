export const manualDataJSON = {
    showProgressBar: "top",
    showCompletedPage: false,
    showQuestionNumbers: "off",
    showTOC: true,
    focusFirstQuestionAutomatic: false,
    title: "Manual Data Entry",
    checkErrorsMode: "onValueChanged",
    pages: [
        {
            name: "PercentPatientsDiscount",
            navigationTitle: "Patients",
            navigationDescription: "Patients",
            title: "Sliding Fee Data",
            description: "Enter non-negative integer",
            width: "100%",
            elements: [
                {
                    title: "Please enter number of patients by payor type:",
                    type: "panel",
                    name: "percentOfTotalPatientsDiscount",
                    questionTitleLocation: "left",
                    elements: [
                        {
                            type: "text",
                            name: "NoPatients",
                            title: "# Patients that received a slide of costs using the Sliding Fee Discount Scale",
                            inputType: "number",
                            defaulValue: 0,
                            min: 0,
                            validators: [
                                {
                                    type: "numeric",
                                    text: "Value cannot be less than 0",
                                    minValue: 0,
                                },
                                {
                                    type: "regex",
                                    text: "Value must be a whole number",
                                    regex: "^\\d+$"
                                }],
                        },
                        {
                            type: "text",
                            name: "Discount Total",
                            title: "Discount Total",
                            inputType: "number",
                            defaulValue: 0,
                            min: 0,
                            validators: [
                                {
                                    type: "numeric",
                                    text: "Value cannot be less than 0",
                                    minValue: 0,
                                }
                            ],
                        },
                    ]
                }
            ],
        },
        {
            name: "medicalServices",
            title: "Medical Services",
            navigationDescription: "Medical Services",
            elements: [
                {
                    type: "panel",
                    name: "medicalServicesPanel",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                                  <li> Volunteers provide services for which your clinic does not pay (e.g. pro bono, in-kind, etc.). Only visits (including virtual visits) provided by volunteer providers should be entered in the Volunteer section.</li></ul`,
                        },
                        {
                            type: "boolean",
                            name: "medical2",
                            title: "Do you have volunteers that provide medical services?",
                        }
                    ]
                }
            ],
            
        },
        {
            name: "Physicians",
            title: "    ● Physicians",
            navigationDescription: "Physicians",
            visibleIf: "{medical2} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelPhysiciansV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    questionTitleLocation: "left",
                    visibleIf: "{medical2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "familyV",
                            title: "Family Physicians",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "familyHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "familyVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "familyVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({familyVisitsV} / {totalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "generalPractitionerV",
                            title: "General Practitioner ",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "gpHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "gpVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({generalPractitionerV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "gpVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({gpVisitsV} / {totalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "internistV",
                            title: "Internist",
                            inputType: "number",
                            width: "200px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "internistHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "internistVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({internistV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "internistVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({internistVisitsV} / {totalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "obgynV",
                            title: "OB/GYN",
                            inputType: "number",
                            width: "200px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "obgynHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "obgynVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({obgynV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "obgynVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({obgynVisitsV} / {totalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "pediatriciansV",
                            title: "Pediatricians",
                            inputType: "number",
                            width: "200px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "pediatriciansHoursV",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "pediatriciansVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({pediatriciansV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "pediatriciansVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({pediatriciansVisitsV} / {totalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "otherPhysicianV",
                            title: "Other Speciality Physicians",
                            inputType: "number",
                            width: "200px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherPhysicianHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherPhysicianVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherPhysicianV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherPhysicianVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherPhysicianVisitsV} / {totalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },
                    ],
                },
                {
                    type: "panel",
                    name: "panelTotalVolunteers",
                    visibleIf: "{medical2} = true",
                    elements: [
                        {
                            type: "expression",
                            name: "totalVolunteers",
                            title: "Total Volunteers",
                            expression: "{familyV} + {generalPractitionerV} + {internistV} + {obgynV} + {pediatriciansV} + {otherPhysicianV}"
                        },
                        {
                            type: "expression",
                            name: "totalHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{familyHoursV} + {gpHoursV} + {internistHoursV} + {obgynHoursV} + {pediatriciansHoursV} + {otherPhysicianHoursV}"
                        },
                        {
                            type: "expression",
                            name: "totalVisitsV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{familyVisitsV} + {gpVisitsV} + {internistVisitsV} + {obgynVisitsV} + {pediatriciansVisitsV} + {otherPhysicianVisitsV}"
                        },

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                }
            ]
        },
        {
            name: "DentalServicesPage",
            title: "Dental Services",
            navigationDescription: "Dental Services",
            elements: [
                {
                    type: "panel",
                    name: "dentalPanel",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            <li> Volunteers provide services for which your clinic does not pay (e.g. pro bono, in-kind, etc.). Only visits (including virtual visits) provided by volunteer providers should be entered in the Volunteer section.</li>
                            </ul>`,
                        },
                        {
                            type: "boolean",
                            name: "dental2",
                            title: "Do you have volunteers that provide Dental services?"
                        }
                    ]
                }
            ]
        },
        {
            name: "Dental",
            title: "    ● Dental",
            navigationDescription: "DentalFTE",
            visibleIf: "{dental2} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelDentalsV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    questionTitleLocation: "left",
                    visibleIf: "{dental2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "dentistV",
                            title: "Dentists",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentistHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentistVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({dentistV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "dentistVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({dentistVisitsV} / {totalDentalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "dentalHygenistV",
                            title: "Dental Hygientist ",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentalHygenistHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentalHygenistVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({dentalHygenistV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "dentalHygenistVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({dentalHygenistVisitsV} / {totalDentalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "dentalTherapistV",
                            title: "Dental Therapist",
                            inputType: "number",
                            width: "200px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentalTherapistHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "dentalTherapistVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({dentalTherapistV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "dentalTherapistVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({dentalTherapistVisitsV} / {totalDentalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "otherDentalV",
                            title: "Other Dental Personnel",
                            inputType: "number",
                            width: "200px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherDentalHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherDentalVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherDentalV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherDentalVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherDentalVisitsV} / {totalDentalVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        }
                    ],
                },
                {
                    type: "panel",
                    name: "panelDentalTotalVolunteers",
                    visibleIf: "{dental2} = true",
                    elements: [
                        {
                            type: "expression",
                            name: "totalDentalVolunteers",
                            title: "Total Volunteers",
                            expression: "{dentistV} + {dentalHygenistV} + {dentalTherapistV} + {otherDentalV} "
                        },
                        {
                            type: "expression",
                            name: "totalDentalHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{dentistHoursV} + {dentalHygenistHoursV} + {dentalTherapistHoursV} + {otherDentalHoursV} "
                        },
                        {
                            type: "expression",
                            name: "totalDentalVisitsV",
                            startWithNewLine: false,
                            title: "Total Clinic Visits",
                            expression: "{dentistVisitsV} + {dentalHygenistVisitsV} + {dentalTherapistVisitsV} + {otherDentalVisitsV} "
                        }
                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                }

            ]
        },

        {
            name: "VisionServicesPage",
            title: "Vision Services",
            navigationDescription: "Vision Services",
            elements: [
                {
                    type: "panel",
                    name: "visionPanel",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            <li>Volunteers provide services for which your clinic does not pay (e.g. pro bono, in-kind, etc.). Only visits (including virtual visits) provided by volunteer providers should be entered in the Volunteer section.</li>
                            </ul>`,
                        },
                        {
                            type: "boolean",
                            name: "vision2",
                            title: "Do you have volunteers that provide Vision services?"
                        }
                    ]
                }
            ]
        },

        {
            name: "Vision",
            title: "    ● Vision",
            navigationDescription: "VisionFTE",
            visibleIf: "{vision2} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelVisionV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    questionTitleLocation: "left",
                    visibleIf: "{vision2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "ophthalmologistsV",
                            title: "Ophthalmologists",
                            inputType: "number",
                            min: "0",
                            width: "250px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "ophthalmologistsHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "ophthalmologistsVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({ophthalmologistsV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "ophthalmologistsVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({ophthalmologistsVisitsV} / {totalVisionVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "optometristsV",
                            title: "Optometrists",
                            inputType: "number",
                            min: "0",
                            width: "250px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "optometristsHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "optometristsVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({ophthalmologistsV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "optometristsVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({optometristsVisitsV} / {totalVisionVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "otherVisionV",
                            title: "Other Vision Personnel",
                            inputType: "number",
                            width: "250px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherVisionHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },


                    ],
                },
                {
                    type: "panel",
                    name: "panelVisionTotalVolunteers",
                    visibleIf: "{vision2} = true",
                    elements: [
                        {
                            type: "expression",
                            name: "totalVisionVolunteers",
                            title: "Total Volunteers",
                            expression: "{ophthalmologistsV} + {optometristsV} + {otherVisionV}  "
                        },
                        {
                            type: "expression",
                            name: "totalVisionHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{ophthalmologistsHoursV} + {optometristsHoursV} + {otherVisionHoursV} "
                        },
                        {
                            type: "expression",
                            name: "totalVisionVisitsV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{ophthalmologistsVisitsV} + {optometristsVisitsV}   "
                        }

                    ],
                    questionTitleLocation: "top",
                    showQuestionNumbers: "off"
                }
            ]
        },

        {
            name: "BehavioralHealthServicesPage",
            title: "Behavioral Health",
            navigationDescription: "Behavioral Health",
            elements: [
                {
                    type: "panel",
                    name: "behavioralHealthPanel",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            </li>Volunteers provide services for which your clinic does not pay (e.g. pro bono, in-kind, etc.). Only visits (including virtual visits) provided by volunteer providers should be entered in the Volunteer section.</li>                            
                            </ul>`,
                        },
                        {
                            type: "boolean",
                            name: "behavioralHealth2",
                            title: "Do you have volunteers that provide Behavioral Health services?"
                        }
                    ]
                }
            ]
        },
        {
            name: "mentalHealthPage",
            title: "    ● Mental Health",
            navigationDescription: "BehavioralMental",
            visibleIf: "{behavioralHealth2} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelMentalV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    questionTitleLocation: "left",
                    visibleIf: "{behavioralHealth2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "psychiatristsV",
                            title: "Psychiatrists",
                            inputType: "number",
                            min: "0",
                            width: "250px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "psychiatristsHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "psychiatristsVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({psychiatristsV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "psychiatristsVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({psychiatristsVisitsV} / {totalMentalHealthVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "licensedClinicalPsychologistsV",
                            title: "Licensed Clinical Psychologists",
                            inputType: "number",
                            min: "0",
                            width: "250px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "licensedClinicalPsychologistsHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "licensedClinicalPsychologistsVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({licensedClinicalPsychologistsV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "licensedClinicalPsychologistsVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({licensedClinicalPsychologistsVisitsV} / {totalMentalHealthVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "licensedClinicalSocialWorkersV",
                            title: "Licensed Clinical Social Workers",
                            inputType: "number",
                            width: "250px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "licensedClinicalSocialWorkersHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "licensedClinicalSocialWorkersVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({licensedClinicalSocialWorkersV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "licensedClinicalSocialWorkersVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({licensedClinicalSocialWorkersVisitsV} / {totalMentalHealthVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "otherLicensedMentalV",
                            title: "Other Lisensed Mental Health Providers",
                            inputType: "number",
                            width: "250px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherLicensedMentalHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherLicensedMentalVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherLicensedMentalV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherLicensedMentalVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherLicensedMentalVisitsV} / {totalMentalHealthVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "otherMentalV",
                            title: "Other Licensed Mental Health Staff",
                            inputType: "number",
                            width: "250px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherMentalHoursV",
                            title: "Hours",
                            inputType: "number",
                            startWithNewLine: false,
                            width: "150px",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherMentalVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherMentalV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherMentalVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherMentalVisitsV} / {totalMentalHealthVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        }
                    ],
                    width: "90%",
                },
                {
                    type: "panel",
                    name: "panelMentalTotalVolunteers",
                    visibleIf: "{behavioralHealth2} = true",
                    elements: [
                        {
                            type: "expression",
                            name: "totalMentalHealthVolunteers",
                            title: "Total Volunteers",
                            expression: "{psychiatristsV} + {licensedClinicalPsychologistsV} + {licensedClinicalSocialWorkersV} + {otherLicensedMentalV} + {otherMentalV}  "
                        },
                        {
                            type: "expression",
                            name: "totalMentalHealthHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{psychiatristsHoursV} + {licensedClinicalPsychologistsHoursV} + {licensedClinicalSocialWorkersHoursV} + {otherLicensedMentalHoursV} + {otherMentalHoursV}  "
                        },
                        {
                            type: "expression",
                            name: "totalMentalHealthVisitsV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{psychiatristsVisitsV} + {licensedClinicalPsychologistsVisitsV} + {licensedClinicalSocialWorkersVisitsV} + {otherLicensedMentalVisitsV} + {otherMentalVisitsV}  "
                        }

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                }
            ]
        },

        {
            name: "substanceUseDisorderPage",
            title: "    ● Substance Use Disorder",
            navigationDescription: "BehavioralSUD",
            visibleIf: "{behavioralHealth2} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelSubstanceUseV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    questionTitleLocation: "left",
                    visibleIf: "{behavioralHealth2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "substanceAbuseServicesV",
                            title: "Substance Abuse Services",
                            inputType: "number",
                            min: "0",
                            width: "200px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "substanceAbuseServicesHoursV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "substanceAbuseServicesVisitsV",
                            width: "150px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({substanceAbuseServicesV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "substanceAbuseServicesVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({substanceAbuseServicesVisitsV} / {totalSubstanceUseDisorderVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        }
                    ],
                },
                {
                    type: "panel",
                    name: "panelSubstanceUseTotalVolunteers",
                    visibleIf: "{behavioralHealth2} = true",
                    elements: [
                        {
                            type: "expression",
                            name: "totalSubstanceUseDisorderVolunteers",
                            title: "Total Volunteers",
                            expression: "{substanceAbuseServicesV}  "
                        },
                        {
                            type: "expression",
                            name: "totalSubstanceUseDisorderHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{substanceAbuseServicesHoursV}  "
                        },
                        {
                            type: "expression",
                            name: "totalSubstanceUseDisorderVisitsV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{substanceAbuseServicesVisitsV}  "
                        }

                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                }
            ]
        },

        {
            name: "otherProfessionalServicesPage",
            title: "Other Professional Personnel",
            navigationDescription: "Other Professional Personnel",
            elements: [
                {
                    type: "panel",
                    name: "otherProfessionalServicesPanel",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `<ul>
                            <li> Volunteers provide services for which your clinic does not pay (e.g. pro bono, in-kind, etc.). Only visits (including virtual visits) provided by volunteer providers should be entered in the Volunteer section.</li>
                            </ul>
                            `,
                        },
                        {
                            type: "boolean",
                            name: "otherProfessionalServices2",
                            title: "Do you have volunteers that provide Other Professional services?"
                        }
                    ]
                }
            ]
        },
        {
            name: "otherProfessionalPersonnelPage",
            title: "    ● Other Professional",
            navigationDescription: "OtherFTE",
            visibleIf: "{otherProfessionalServices2} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelOtherProfessionalPersonnelV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    visibleIf: "{otherProfessionalServices2} = true",
                    questionTitleLocation: "left",
                    width: "90%",
                    elements: [
                        {
                            type: "text",
                            name: "otherProfessionalPersonnelV",
                            title: "Other Prof. Personnel",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherProfessionalPersonnelHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherProfessionalPersonnelVisitsV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({otherProfessionalPersonnelV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "otherProfessionalPersonnelVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({otherProfessionalPersonnelVisitsV} / {totalOtherProfessionalPersonnelVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        }
                    ],
                },
                {
                    type: "panel",
                    name: "panelOtherProfessionalPersonnelTotalFTEV",
                    elements: [
                        {
                            type: "expression",
                            name: "totalOtherProfessionalPersonnelFTEV",
                            title: "Total Volunteers",
                            expression: "{otherProfessionalPersonnelV}"
                        },
                        {
                            type: "expression",
                            name: "totalOtherProfessionalPersonnelHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: "{otherProfessionalPersonnelHoursV}"
                        },
                        {
                            type: "expression",
                            name: "totalOtherProfessionalPersonnelVisitsV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{otherProfessionalPersonnelVisitsV}"
                        }
                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                },
            ]
        },

        {
            name: "enablingServicesPage",
            title: "Enabling Services",
            navigationDescription: "Enabling Services",
            elements: [
                {
                    type: "panel",
                    name: "enablingServicesPanel",
                    elements: [
                        {
                            type: "expression",
                            title: " ",
                            titleLocation: "left",
                            popupdescription: `
                            Volunteers provide services for which your clinic does not pay (e.g. pro bono, in-kind, etc.). Only visits (including virtual visits) provided by volunteer providers should be entered in the Volunteer section. 
                            <ul>
                            <li><b>Case Managers:<b> Personnel who assist patients in the management of their health and social needs, including assessment of patient medical and/or social service needs; establishment of service plans; and maintenance of referral, tracking, and follow-up systems. Include case managers, care coordinators, referral coordinators, and other local titles. Case managers may provide health education and/or eligibility assistance in the course of their case management functions; do not parse out this time unless the personnel has dedicated time to other enabling service categories. </li>
                            <li><b>Patient/Community Education Specialists:<b> Health educators, with or without specific degrees in this area. Only services provided one-on-one with the patient can be reported. Group education classes or visits are not reported. Health education is provided to support the delivery of other health care services as described above and is rarely the only type of service provided to a patient. </li>
                            <li><b>Outreach Workers:<b> Personnel conducting case finding, education, or other services designed to identify potential patients or clients and/or facilitate access or referral of potential clinic patients to available clinic services. </li>
                            <li><b>Transportation Personnel:<b> Personnel who provide transportation for patients (e.g., van drivers) or arrange for transportation (e.g., for bus or taxi vouchers), including personnel who arrange for local transportation or longer-distance transportation to major cities in extremely remote clinic locations. </li>
                            <li><b>Eligibility Assistance Workers:<b> Personnel (e.g., patient navigators, certified assisters, eligibility workers) who provide assistance in securing access to available health, social service, pharmacy, and other assistance programs, including Medicaid, Medicare, WIC, Supplemental Security Income (SSI), food stamps through the Supplemental Nutrition Assistance Program (SNAP), Temporary Assistance for Needy Families (TANF), PAPs, and related assistance programs. For health centers, this includes personnel hired under the HRSA Outreach and Enrollment grants. </li>
                            <li><b>Interpretation Staff:<b> Personnel whose full-time or dedicated time is devoted to translation and/or interpretation services. Do not include the portion of the time a nurse, medical assistant, or other support personnel providing interpretation, translation, or bilingual services during their other activities. </li>
                            <li><b>Community Health Workers:<b> Members of communities who work in association with the local health care system in both urban and rural environments and usually share ethnicity, language, socioeconomic status, and/or life experiences with the community members they serve. Personnel may be called community health workers, community health advisors, lay health advocates, promotoras, community health representatives, peer health promoters, or peer health educators. They may perform some or all of the tasks of other enabling services workers. If some of their time is dedicated to these other functions, report them on those lines. Do not include personnel better classified under other service categories, such as Other Medical Personnel or Other Dental Personnel. </li>
                            <li><b>Other Enabling Services:<b> Any staff not previously reported performing enabling services such as child care. </li>
                            </ul>
                            `,
                        },
                        {
                            type: "boolean",
                            name: "enablingServices2",
                            title: "Do you have volunteers that provide Enabling services?"
                        }
                    ]
                }
            ]
        },
        {
            name: "enablingPage",
            title: "    ● Enabling",
            visibleIf: "{enablingServices2} = true",
            navigationDescription: "EnablingFTE",
            elements: [
                {
                    type: "panel",
                    name: "panelEnablingV",
                    title: "Please enter Volunteer and correllating visit data: ",
                    questionTitleLocation: "left",
                    visibleIf: "{enablingServices2} = true",
                    elements: [
                        {
                            type: "text",
                            name: "caseManagersV",
                            title: "Case Managers",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "caseManagersHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "caseManagersVisitsV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({caseManagersV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "caseManagersVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({caseManagersVisitsV} / {totalEnablingVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "communityEducationSpecialistsV",
                            title: "Patient/ Community Education Specialists",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "communityEducationSpecialistsHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "communityEducationSpecialistsVisitsV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({communityEducationSpecialistsV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "communityEducationSpecialistsVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({communityEducationSpecialistsVisitsV} / {totalEnablingVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },



                        {
                            type: "text",
                            name: "outreachWorkersV",
                            title: "Outreach Workers",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "outreachWorkersHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },


                        {
                            type: "text",
                            name: "transportationPersonnelV",
                            title: "Transportation Personnel",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "transportationPersonnelHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "transportationPersonnelVisitsV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Visits",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            maxValueExpression: "iif({transportationPersonnelV}>0,9999,0)",
                            maxErrorText: "If there is a volunteer visit there should a Volunteer ",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "expression",
                            name: "transportationPersonnelVisitsPctV",
                            width: "100px",
                            startWithNewLine: false,
                            titleLocation: "hidden",
                            expression: `(roundTo(({transportationPersonnelVisitsV} / {totalEnablingVisitsV}) * 100, 2)) + " % of visits"`,
                            displayStyle: "percent",
                            maximumFractionDigits: 2
                        },


                        {
                            type: "text",
                            name: "eligibilityAssistanceWorkersV",
                            title: "Eligibility Assistance Workers",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "eligibilityAssistanceWorkersHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },


                        {
                            type: "text",
                            name: "interpretationPersonnelV",
                            title: "Interpretation Personnel",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "interpretationPersonnelHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },


                        {
                            type: "text",
                            name: "communityHealthWorkersV",
                            title: "Community Health Workers",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "communityHealthWorkersHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },


                        {
                            type: "text",
                            name: "otherEnablingV",
                            title: "Other Enabling Services",
                            inputType: "number",
                            min: "0",
                            width: "220px",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "otherEnablingHoursV",
                            width: "160px",
                            startWithNewLine: false,
                            title: "Hours",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                    ],
                    width: "90%",
                },
                {
                    type: "panel",
                    name: "panelEnablingTotalVolunteers",
                    visibleIf: "{enablingServices2} = true",
                    elements: [
                        {
                            type: "expression",
                            name: "totalEnablingFTEV",
                            title: "Total Paid Providers FTES",
                            expression: "{caseManagersV} + {communityEducationSpecialistsV} + {outreachWorkersV} + {eligibilityAssistanceWorkersV} + {transportationPersonnelV} + {interpretationPersonnelV} + {communityHealthWorkersV} + {otherEnablingV}"
                        },
                        {
                            type: "expression",
                            name: "totalEnablingHoursV",
                            startWithNewLine: false,
                            title: "Total Hours",
                            expression: `{caseManagersHoursV} + {communityEducationSpecialistsHoursV}
                                    + {outreachWorkersHoursV} + {transportationPersonnelHoursV}
                                    + {eligibilityAssistanceWorkersHoursV} + {interpretationPersonnelHoursV}
                                    + {communityHealthWorkersHoursV} + {otherEnablingHoursV}`
                        },
                        {
                            type: "expression",
                            name: "totalEnablingVisitsV",
                            startWithNewLine: false,
                            title: "Total Visits",
                            expression: "{caseManagersVisitsV} + {communityEducationSpecialistsVisitsV} + {transportationPersonnelVisitsV}"
                        }
                    ],
                    questionTitleLocation: "top",
                    width: "90%",
                    showQuestionNumbers: "off"
                }
            ]
        },

        {
            name: "PharmacyPage",
            elements: [
                {
                    type: "panel",
                    name: "pharmacyPanel",
                    elements: [
                        {
                            type: "boolean",
                            name: "pharmacy1",
                            title: "Do you provide Pharmacy services?",
                            isRequired: true
                        }
                    ]
                }
            ],
            title: "Pharmacy Services",
            navigationDescription: "PharmacyServices"
        },
        {
            name: "pagePharmacyServices",
            visibleIf: "{pharmacy1} = true",
            navigationDescription: "PharmacyFTE",
            title:"    ● Pharmacy",
            elements: [
                {
                    type: "panel",
                    name: "panelPharmacy",
                    description: "Enter non-negative integer",
                    elements: [
                        {
                            type: "text",
                            name: "pharmacyPersonnel",
                            title: "Pharmacy Personnel",
                            inputType: "number",
                            min: "0",
                            defaultValue: 0,
                            popupdescription: `Pharmacists (including clinical pharmacists), pharmacy technicians, pharmacist assistants, and others supporting pharmaceutical services. <b><u>Please note:</u></b> The time (and cost) of individuals spending all or part of their time in assisting patients to apply for free medications from pharmaceutical companies (pharmacy assistance programs [PAP]) are to be reported under “Eligibility Assistance Workers” in the Enabling Services section of Staffing and Utilization.`,
                        },
                        {
                            type: "text",
                            name: "pharmacyVolunteers",
                            title: "Pharmacy Volunteers",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                        },
                        {
                            type: "text",
                            name: "pharmacyHours",
                            title: "Pharmacy Hours",
                            inputType: "number",
                            min: "0",
                            defaultValue: 0,
                        }
                    ],
                    questionTitleLocation: "left",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelTotalPharmacy",
                    description: "Enter non-negative integer",
                    elements: [
                        {
                            type: "text",
                            name: "PAPProgramApplications",
                            title: "PAP Program Applications Filed",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }],
                            popupdescription: `Indicate the number of Prescription Assistance Program new and refill applications that have been filed during the reporting year.`,
                        },
                        {
                            type: "text",
                            name: "340BProgramMedications",
                            title: "340B Program Medications Dispensed - Health centers Only",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }],
                            popupdescription: `Indicate the number of medications dispensed through the 340B Drug Pricing Program during the reporting period.`,
                        },
                        {
                            type: "text",
                            name: "unusedMedicationsProgram",
                            title: "Unused Medications Program Prescriptions Dispensed",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }],
                            popupdescription: `Indicate the number of medications dispensed through the Unused Medications Repository during the reporting period.`,
                        },
                        {
                            type: "html",
                            html: "<hr></hr>"
                        },
                        {
                            type: "expression",
                            name: "totalTotalPharmacy",
                            title: "Total :",
                            titleLocation: "top",
                            expression: `({PAPProgramApplications} + {340BProgramMedications} + {unusedMedicationsProgram})`
                        },
                    ],
                    questionTitleLocation: "left",
                    title: "Total Pharmacy Counts",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panelTotalPharmacyPatients",
                    description: "Enter non-negative integer",
                    elements: [
                        {
                            type: "text",
                            name: "PAPProgram",
                            title: "PAP Program",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            popupdescription: `Indicate the number of unduplicated patients who have received a prescription through a Prescription Assistance Program during the reporting year.`,
                        },
                        {
                            type: "text",
                            name: "340BProgramFQHC",
                            title: "340B Program - Health centers Only",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            popupdescription: `Indicate the number of unduplicated patients who have received a prescription through the 340B Drug Pricing Program during the reporting year.`,
                        },
                        {
                            type: "text",
                            name: "unusedMedicationsProgramPatients",
                            title: "Unused Medications Program",
                            inputType: "number",
                            min: "0",
                            defaultValueExpression: "0",
                            popupdescription: `Indicate the number of unduplicated patients who have received a prescription through the Unused Medications Repository during the reporting year.`,
                        },
                        {
                            type: "html",
                            html: "<hr></hr>"
                        },
                        {
                            type: "expression",
                            name: "totalTotalPharmacyPatients",
                            title: "Total :",
                            titleLocation: "top",
                            expression: `({PAPProgram} + {340BProgramFQHC} + {unusedMedicationsProgramPatients})`
                        },
                    ],
                    questionTitleLocation: "left",
                    title: "Total Pharmacy Patients",
                    showQuestionNumbers: "off"
                },
                {
                    type: "panel",
                    name: "panel340BClinic",
                    elements: [
                        {
                            type: "radiogroup",
                            name: "is340BClinic",
                            title: "Is the Clinic a 340B Clinic?",
                            titleLocation: "top",
                            choices: [
                                "Yes",
                                "No",
                                "NA"
                            ],
                            popupdescription: `Clinics with a 340B program can have a clinic owned and managed program, a program managed in-house, and/or external contracts.`
                        },
                        {
                            type: "checkbox",
                            name: "clinicType",
                            visibleIf: "{is340BClinic} = 'Yes'",
                            title: " ",
                            choices: [
                                {
                                    value: "Owned",
                                    text: "Clinic Owned and Managed"
                                },
                                {
                                    value: "Managed",
                                    text: "Clinic Managed In House"
                                },
                                {
                                    value: "External",
                                    text: "External Contract"
                                }
                            ]
                        }
                    ],
                    questionTitleLocation: "left",
                    title: "340B Clinic",
                    showQuestionNumbers: "off"
                }
            ],
        },

        {
            name: "SchoolPage",
            elements: [
                {
                    type: "panel",
                    name: "SchoolPanel",
                    width: "480px",
                    elements: [
                        {
                            type: "boolean",
                            name: "school1",
                            title: "Do you provide School services?",
                            isRequired: true
                        }
                    ]
                }
            ],
            title: "School Services",
            navigationDescription: "School Services"
        },
        {
            name: "pageSchoolServices",
            title: "    ● Services Provided In Schools",
            navigationDescription: "SchoolProvided",
            visibleIf: "{school1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelServicesProvided",
                    questionStartIndex: "1",
                    showQuestionNumbers: "onpanel",
                    elements: [
                        {
                            type: "radiogroup",
                            name: "isProvidedServicesSchool",
                            title: "Does your clinic provide services in a school?",
                            choices: [
                                "Yes",
                                "No"
                            ]
                        },
                        {
                            type: "text",
                            name: "numberOfSchool-based",
                            title: "Number of School-based sites where the clinic offers services?",
                            visibleIf: "{isProvidedServicesSchool} = 'Yes'",
                        },
                        {
                            type: "checkbox",
                            name: "schoolServicesOffered",
                            title: "What services does your clinic offer at one or more of these sites?",
                            visibleIf: "{isProvidedServicesSchool} = 'Yes'",
                            choices: [
                                "Primary Medical",
                                "Behavioral Health/Counseling",
                                "Oral Health Screening/Referrals",
                                "Dental Sealants",
                                "Full Dental ",
                                "Substance Use/Opioid Use",
                                "Integrated Primary and Behavioral Health Care",
                                "Vision",
                                "Pharmacy"
                            ],
                            showOtherItem: true,
                            otherText: "Other (please describe)"
                        }
                    ],
                    title: "Services Provided In Schools"
                }
            ],
        },
        {
            name: "pageSchoolBasedClinics",
            title: "    ● School Based Services",
            navigationDescription: "School-Based",
            visibleIf: "{school1} = true",
            elements: [
                {
                    type: "panel",
                    name: "panelSchoolBasedClinics",
                    questionStartIndex: "1",
                    showQuestionNumbers: "onpanel",
                    elements: [
                        {
                            type: "radiogroup",
                            name: "isSchoolBasedClinics",
                            title: "Does your clinic provide services in a school-based clinic?",
                            choices: [
                                "Yes",
                                "No"
                            ]
                        },
                        {
                            type: "text",
                            name: "numberOfSchool-sites",
                            title: "Number of school sites where the clinic offers services?",
                            visibleIf: "{isSchoolBasedClinics} = 'Yes'",
                        },
                        {
                            type: "checkbox",
                            name: "schoolSitesServicesOffered",
                            title: "What services does your clinic offer at one or more of these sites?",
                            visibleIf: "{isSchoolBasedClinics} = 'Yes'",
                            choices: [
                                "Primary Medical",
                                "Behavioral Health/Counseling",
                                "Oral Health Screening/Referrals",
                                "Dental Sealants",
                                "Full Dental ",
                                "Substance Use/Opioid Use",
                                "Integrated Primary and Behavioral Health Care",
                                "Vision",
                                "Pharmacy"
                            ],
                            showOtherItem: true,
                            otherText: "Other (please describe)"
                        }
                    ],
                    title: "School-Based Clinics"
                }
            ],
        },

        {
            name: "clinicalMedicalMeasures",
            navigationTitle: "Clinical Measures - Medical",
            navigationDescription: "ClinicalMeasures",
            title: "Clinical Measures",
            elements: [
                {
                    type: "panel",
                    name: "clinicalMedicalMeasuresNotes",
                    title: "Medical Measures",
                    questionTitleLocation: "left",
                    elements: [
                        {
                            type: "comment",
                            name: "clinicalMedicalMeasuresNotesElement",
                            title: "Please note any comments that you have regarding data reported for your medical measures, challenges in obtaining the data, etc.",
                        },
                    ]
                }
            ]
        },

        {
            name: "clinicalDentalMeasures",
            navigationTitle: "Clinical Measures - Dental",
            navigationDescription: "ClinicalDental",
            title: "Clinical Measures",
            elements: [
                {
                    type: "panel",
                    name: "clinicalDentalMeasuresNotes",
                    title: "Dental Measures",
                    questionTitleLocation: "left",
                    elements: [
                        {
                            type: "comment",
                            name: "clinicalDentalMeasuresNotesElement",
                            title: "Please note any comments that you have regarding data reported for your dental measures, challenges in obtaining the data, etc.",
                        },
                    ]
                }
            ]
        },

        {
            name: "financialMedicalMeasures",
            navigationTitle: "Financial Measures - Medical",
            navigationDescription: "FinancialMeasures",
            title: "Financial Measures",
            elements: [
                {
                    type: "panel",
                    name: "financialMedicalMeasuresNotes",
                    title: "Medical Measures",
                    questionTitleLocation: "left",
                    elements: [
                        {
                            type: "comment",
                            name: "financialMedicalMeasuresNotesElement",
                            title: "Please note any comments that you have regarding data reported for your medical measures, challenges in obtaining the data, etc.",
                        },
                    ]
                }
            ]
        },

        {
            name: "financialDentalMeasures",
            navigationTitle: "Financial Measures - Dental",
            navigationDescription: "FinancialDental",
            title: "Financial Measures",
            elements: [
                {
                    type: "panel",
                    name: "financialDentalMeasuresNotes",
                    title: "Dental Measures",
                    questionTitleLocation: "left",
                    elements: [
                        {
                            type: "comment",
                            name: "financialDentalMeasuresNotesElement",
                            title: "Please note any comments that you have regarding data reported for your dental measures, challenges in obtaining the data, etc.",
                        },
                    ]
                }
            ]
        },

        //Data Measures
        {
            name: "EHRCapabilitiesandQualityRecognition",
            navigationTitle: "EHR Capabilities and Quality Recognition",
            navigationDescription: "Data Measures",
            elements: [
                {
                    type: "panel",
                    name: "ehr",
                    title: "2022 Clinic Electronic Health Record (EHR) Capabilities and Quality Recognition",
                    elements: [
                        {
                            type: "radiogroup",
                            name: "didClinicUseEHR",
                            title: "1. By December 31st of the reporting year, did your clinic currently have an Electronic Health Record (EHR) system installed and in use?",
                            popupdescription: `
                    <p>
                      For the purposes of this response, "providers" mean all medical providers including physicians, nurse practitioners, physician assistants, and certified nurse midwives. 
                      Although some or all of the dental, mental health, or other providers may also have used the system, as may medical support staff, this is not required to choose <strong>option "a"</strong>. 
                      For the purposes of this response, "all sites" means all permanent sites where medical providers serve clinic medical patients and does not include administrative-only locations, 
                      hospitals or nursing homes, mobile vans, or sites used on a seasonal or temporary basis. You may check this option even if a few, newly hired, untrained employees are the only 
                      ones not using the system. 
                    </p>
      
                    <p>
                      Select <strong>option "b"</strong> if one or more permanent sites did not have the EHR installed, or in use (even if this is planned), or if one or more medical providers (as defined above) do not
                       yet use the system. When determining if all providers have access to the system, the clinic should also consider part-time and locum providers who serve clinic patients. Do not 
                       select this option if the only medical providers who did not have access were those who were newly hired and still being trained on the system. 
                    </p>
                    
                    <p>
                      Select <strong>"no"</strong> if no EHR was in use on December 31st, even if you had the system installed and training had started. 
                    </p>
                    `,
                            choices: [
                                "a. Yes, installed at all sites and used by all providers",
                                "b. Yes, but only installed at some sites",
                                "c. No"
                            ],
                            isRequired: true
                        },
                        {
                            type: "multipletext",
                            name: "ehrProductInfo",
                            visibleIf: "{didClinicUseEHR} notempty and {didClinicUseEHR} <> 'c. No'",
                            title: "In the spaces provided below, enter the EHR vendor and product name in use at your clinic on December 31st of the reporting year. If you have more than one EHR (if, for example, you acquired another practice with its own EHR), report the primary EHR.",
                            items: [
                                {
                                    name: "ehrVendor",
                                    title: "Vendor",
                                    isRequired: true
                                },
                                {
                                    name: "ehrProduct",
                                    title: "Product Name",
                                    isRequired: true
                                },
                            ]
                        },
                        {
                            type: "radiogroup",
                            name: "isNotLastYearsEhr",
                            visibleIf: "{didClinicUseEHR} notempty and {didClinicUseEHR} <> 'c. No'",
                            title: "1a. Did you switch to your current EHR from a previous system this year?",
                            isRequired: true,
                            choices: [
                                "a. Yes",
                                "b. No"
                            ]
                        },
                        {
                            type: "radiogroup",
                            name: "usesMoreThanOneEhr",
                            visibleIf: "{didClinicUseEHR} notempty and {didClinicUseEHR} <> 'c. No'",
                            title: "1b. Do you use more than one EHR or data system across your organization?",
                            isRequired: true,
                            choices: [
                                "a. Yes",
                                "b. No"
                            ]
                        },
                        {
                            type: "radiogroup",
                            name: "usesMoreThanOneEhr_Reason",
                            title: "What is the reason?",
                            visibleIf: "{didClinicUseEHR} notempty and {didClinicUseEHR} <> 'c. No' and {usesMoreThanOneEhr} = 'a. Yes'",
                            isRequired: true,
                            choices: [
                                "a. Second EHR/data system is used during transition to primary EHR",
                                "b. Second EHR/data system is specific to one service type (e.g., dental, behavioral health)",
                                "c. Second EHR/data system is used at specific sites with no plan to transition",
                            ],
                            showOtherItem: true,
                            otherText: "d. Other (please describe)",
                        },
                        {
                            type: "checkbox",
                            name: "doesElectronicExchangeWithWhichProviders",
                            title: "2. Which of the following key providers/health care settings does your clinic electronically exchange clinical information with? (Select all that apply)",
                            isRequired: true,
                            choices: [
                                "a. Hospitals/Emergency Rooms",
                                "b. Specialty Clinicians",
                                "c. Other Primary Care Providers",
                                "d. Labs or Imaging",
                                "e. Health Information Exchange",
                            ],
                            showNoneItem: true,
                            noneText: "f. None of the Above",
                            showOtherItem: true,
                            otherText: "g. Other (please describe)",
                        },
                        {
                            type: "checkbox",
                            name: "doesEngagePatientsThroughWhichHealthIT",
                            title: "3. Does your clinic engage patients through health IT in any of the following ways? (Select all that apply)",
                            isRequired: true,
                            choices: [
                                "a. Patient Portals",
                                "b. Kiosks",
                                "c. Secure Messaging",
                            ],
                            showNoneItem: true,
                            noneText: "d. No, we do not engage patients using health IT",
                            showOtherItem: true,
                            otherText: "e. Other (please describe)",
                        },
                        {
                            type: "radiogroup",
                            name: "howIsDataCollectedForNDH",
                            title: "4. How do you collect data for reporting to the Network Data Hub (previously referred to as QRS)?",
                            isRequired: true,
                            choices: [
                                "a. We use the EHR to extract automated reports.",
                                "b. We use the EHR but only to access individual patient charts.",
                                "c. We use the EHR in combination with another data analytic system.",
                                "d. We do not use the EHR."
                            ],
                        },
                        {
                            type: "checkbox",
                            name: "howIsHealthITandEhrUtilized",
                            title: "5. How does your clinic utilize health IT and EHR data beyond direct patient care? (Select all that apply)",
                            isRequired: true,
                            choices: [
                                "a. Quality Improvement",
                                "b. Population Health Management",
                                "c. Program Evaluation",
                                "d. Research",
                            ],
                            showNoneItem: true,
                            noneText: "e. We do not utilize health IT and EHR data beyond direct patient care",
                            showOtherItem: true,
                            otherText: "f. Other (please describe)",
                        },
                        {
                            type: "radiogroup",
                            name: "doesCollectSocialRiskFactorData",
                            title: "6. Does your clinic collect data on individual patients' social risk factors, outside of the data reportable in this system?",
                            isRequired: true,
                            choices: [
                                "a. Yes",
                                "b. No, but in planning stages to collect this information",
                                "c. No, not planning to collect this information"
                            ],
                            popupdescription: `Clinics should respond “a. Yes.” if they are screening for social risks, meaning they have a consistent set of questions that are asked of individual patients uniformly for the purposes of collecting information on the non-medical, health-related social needs of patients, such as housing instability and/or food insecurity, beyond those demographic patient characteristics captured elsewhere. Otherwise, please select option (b) or (c) as appropriate.`
                        },
                        {
                            type: "text",
                            name: "patientsScreenedForSocialRisk",
                            title: "6a. How many clinic patients were screened for social risk factors using a standardized screener during the calendar year?",
                            visibleIf: "{doesCollectSocialRiskFactorData} = 'a. Yes'",
                            inputType: "number",
                            min: 0,
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "checkbox",
                            name: "whichStandardizedScreenersAreUsed",
                            title: "7. Which Standardized screener(s) for social risk factors, if any, do you use? (select all that apply)",
                            visibleIf: "{doesCollectSocialRiskFactorData} = 'a. Yes'",
                            choices: [
                                "a. Accountable Health Communities Screening Guide",
                                "b. Upstream risks Screening Tool and Guide",
                                "c. iHELP",
                                "d. Recommended Social and Behavioral Domains for EHRs",
                                "e. Protocol for Responding to and Assessing Patients' Assets, Risks and Experiences (PRAPARE)",
                                "f. Well Child Care, Evaluation, Community Resources, Advocacy Referral, Education (WE CARE)",
                                "g. WellRx",
                                "h. Health Leads Screening Toolkit",
                            ],
                            showNoneItem: true,
                            noneText: "i. Do not use a standardized screener",
                            showOtherItem: true,
                            otherText: "j. Other (please describe)",
                            popupdescription: `Use of a standardized screener is typically full use of the tool as designed with the understanding that all data elements may not be collected for all patients at a single encounter. If you are using a modified (e.g. shortened or customized) version of a standardized tool, please select the standardized tool and select Other to note the modifications to the tool.`,
                        },
                        {
                            type: "multipletext",
                            name: "totalPositiveScreenings",
                            title: "7a. Please provide the total number of patients that screened positive for the following:",
                            visibleIf: "{doesCollectSocialRiskFactorData} = 'a. Yes'",
                            items: [
                                {
                                    name: "foodInsecurity",
                                    title: "a. Food Insecurity",
                                    inputType: "number",
                                    validators: [
                                        {
                                            type: "numeric",
                                            text: "Value cannot be less than 0",
                                            minValue: 0,
                                        },
                                        {
                                            type: "regex",
                                            text: "Value must be a whole number",
                                            regex: "^\\d+$"
                                        }
                                    ]
                                },
                                {
                                    name: "housingInsecurity",
                                    title: "b. Housing Insecurity",
                                    inputType: "number",
                                    validators: [
                                        {
                                            type: "numeric",
                                            text: "Value cannot be less than 0",
                                            minValue: 0,
                                        },
                                        {
                                            type: "regex",
                                            text: "Value must be a whole number",
                                            regex: "^\\d+$"
                                        }
                                    ]
                                },
                                {
                                    name: "financialStrain",
                                    title: "c. Financial strain",
                                    inputType: "number",
                                    validators: [
                                        {
                                            type: "numeric",
                                            text: "Value cannot be less than 0",
                                            minValue: 0,
                                        },
                                        {
                                            type: "regex",
                                            text: "Value must be a whole number",
                                            regex: "^\\d+$"
                                        }
                                    ]
                                },
                                {
                                    name: "lackOfTransportation",
                                    title: "d. Lack of transportation / access to public transportation",
                                    inputType: "number",
                                    validators: [
                                        {
                                            type: "numeric",
                                            text: "Value cannot be less than 0",
                                            minValue: 0,
                                        },
                                        {
                                            type: "regex",
                                            text: "Value must be a whole number",
                                            regex: "^\\d+$"
                                        }
                                    ]
                                }
                            ],
                        },
                        {
                            type: "checkbox",
                            name: "reasonNotUsingStandardizedAssessment",
                            title: "7b. If you do not use a standardized assessment to collect this information, please comment why. (Select all that apply)",
                            visibleIf: "{doesCollectSocialRiskFactorData} <> 'a. Yes'",
                            isRequired: true,
                            choices: [
                                "a. Have not considered/unfamiliar with assessments",
                                "b. Lack of funding for addressing these unmet social needs of patients",
                                "c. Lack of training for staff to discuss these issues with patients",
                                "d. Recommended Social and Behavioral Domains for EHRs",
                                "e. Inability to include in patient intake and clinical workflow",
                                "f. Not Needed",
                            ],
                            showOtherItem: true,
                            otherText: "g. Other (please describe)",
                        },
                        {
                            type: "radiogroup",
                            name: "doesUsePDMPdb",
                            title: "8. Does your clinic integrate a statewide Prescription Drug Monitoring Program (PDMP) database into the health information systems such as Health Information Exhchanges, Electronic Health Record (EHR) systems; and/or Pharmacy Dispensing Software (PDS) to streamline provider access to controlled substance prescriptions?",
                            choices: [
                                "a. Yes",
                                "b. No",
                                "c. Not sure"
                            ],
                            popupdescription: `K-TRACS is the Prescription Drug Monitoring Program in Kansas. Clinics may also connect to APPRISS, which is a multi-state PDMP.`,
                        },
                    ]
                },
            ],
        },
        {
            name: "OtherDataElements",
            navigationTitle: "Other Data Elements",
            navigationDescription: "OtherDataMeasures",
            elements: [
                {
                    type: "panel",
                    name: "matForOpioidUseDisorder",
                    title: "1. Medication-Assisted Treatment (MAT) for Opioid Use Disorder",
                    description: "Enter non-negative integers",
                    elements: [
                        {
                            type: "text",
                            inputType: "number",
                            name: "totalProvidersWithDATAWaiver",
                            title: "a. How many qualified providers, on-site, or with whom the clinic has contracts, had obtained a Drug Addiction Treatment Act of 2000 (DATA) waiver to treat opioid use disorder with medications specifically proposed by the U.S. Food and Drug Administration (FDA) for that indication during the measurement period?",
                            min: 0,
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }]
                        },
                        {
                            type: "text",
                            inputType: "number",
                            name: "totalPatientsThatReceivedMATByProviderWithDATAWaiver",
                            title: "b. How many unduplicated patients received medication-assisted tratment such as ICD-10 F11.xx for opioid use disorder from a qualified provider with a DATA waiver during the measurement period?",
                            min: 0,
                            validators: [{
                                type: "regex",
                                text: "Value must be a whole number",
                                regex: "^\\d+$"
                            }],
                            popupdescription: `Medication-Assisted Treatment codes include, but may not be limited to, ICD-10 F11.xx.`,
                        },
                    ]
                },
                {
                    type: "panel",
                    name: "telehealth",
                    title: "Telehealth",

                    elements: [
                        {
                            type: "radiogroup",
                            name: "didUseTelehealth",
                            title: "2. Did your organization use telehealth in order to provide remote clinical services?",
                            isRequired: true,
                            choices: [
                                "a. Yes",
                                "b. No"
                            ],
                            popupdescription: `The term “telehealth” includes “telemedicine” services, but encompasses a broader scope of remote healthcare services. Telemedicine is specific to remote clinical services, whereas telehealth may include remote non-clinical services, such as provider training, administrative meetings, and continuing medical education, inaddition to clinical services. For more information about telehealth, please go to <a href="https://telehealth.hhs.gov/patients/understanding-telehealth/">https://telehealth.hhs.gov/patients/understanding-telehealth/</a>.`
                        },
                        {
                            type: "checkbox",
                            name: "whoDidYouCommunicateWithViaTelehealth",
                            title: "2a1. Who did you use telehealth to communicate with? (Select all that apply)",
                            visibleIf: "{didUseTelehealth} = 'a. Yes'",
                            choices: [
                                "Patients at remote locations from your organization (e.g., home telehealth, satellite locations)",
                                "Specialists outside your organization (e.g., specialists at referral centers)"
                            ],
                        },
                        {
                            type: "checkbox",
                            name: "whatTelehealthTechUsed",
                            title: "2a2. What telehealth technologies do you use? (Select all that apply)",
                            visibleIf: "{didUseTelehealth} = 'a. Yes'",
                            choices: [
                                "Real time telehalth (e.g., video conference)",
                                "Store-and-forward telehealth (e.g., secure email with photos or videos of patient examinations)",
                                "Remote Patient monitoring",
                                "Mobile health (mHealth)",
                            ]
                        },
                        {
                            type: "checkbox",
                            name: "whatPrimaryHealthServicesUsed",
                            title: "2a3. What primary telehealth services were used at your organization? (Select all that apply)",
                            visibleIf: "{didUseTelehealth} = 'a. Yes'",
                            choices: [
                                "Primary Care",
                                "Oral Health",
                                "Behavioral health: Mental health",
                                "Behavioral health: Substance use disorder",
                                "Dermatology",
                                "Chronic Conditions",
                                "Disaster Management",
                                "Consumer and professional health education",
                                "Provider-to-provider consultation",
                                "Radiology",
                                "Nutrition and dietary counseling",
                            ],
                            showOtherItem: true,
                            otherText: "Other (please describe)"
                        },
                        {
                            type: "checkbox",
                            name: "reasonNoTelehealthUsed",
                            title: "2b. If you did not have telehealth services, please comment why. (Select all that apply)",
                            visibleIf: "{didUseTelehealth} = 'b. No'",
                            choices: [
                                "Have not considered/unfamiliar with telehealth service options",
                                "Policy barriers",
                                "Inadequate broadband/telecommunication service",
                                "Lack of funding for telehealth equipment",
                                "Lack of training for telehealth services",
                                "Not needed",
                            ],
                            showOtherItem: true,
                            otherText: "Other (please describe)"
                        },
                        {
                            type: "checkbox",
                            name: "policyBarriers",
                            title: "Policy Barriers (Select all that apply)",
                            visibleIf: "{didUseTelehealth} = 'b. No' and {reasonNoTelehealthUsed} contains 'Policy barriers'",
                            choices: [
                                "Lack of or limited reimbursement",
                                "Credentialing, licensing, or privileging",
                                "Privacy and security",
                            ],
                            showOtherItem: true,
                            otherText: "Other (please describe)"
                        },
                        {
                            type: "checkbox",
                            name: "inadequateTelecommunicationService",
                            title: "Inadequate broadband/telecommunication service (Select all that apply)",
                            visibleIf: "{didUseTelehealth} = 'b. No' and {reasonNoTelehealthUsed} contains 'Inadequate broadband/telecommunication service'",
                            choices: [
                                "Cost of service",
                                "Lack of infrastructure",
                            ],
                            showOtherItem: true,
                            otherText: "Other (please describe)"
                        },
                    ]
                },
                {
                    type: "panel",
                    name: "outreachAndEnrollmentAssistance",
                    title: "Outreach and Enrollment Assistance",
                    description: "Enter non-negative integer",

                    elements: [
                        {
                            type: "multipletext",
                            name: "totalAssistsByTrainedAssisters",
                            title: "3. Provide the number of all assists provided during the past year by all trained assisters (e.g., certified application counselor or equivalent) working on behalf of the clinic (employees, contractors, or volunteers), regardless of the funding source that is supporting the assisters' activities. Outreach and enrollment assists are defined as customizable education sessions about affordable health insurance coverage options (one-on-one or small group) and any other assistance provided by a clinic assister to facilitate enrollment.",
                            popupdescription: `<b>Important Note:</b> According to HRSA's Outreach and Enrollment Assists Reporting at <a href="https://bphc.hrsa.gov/data-reporting/uds-training-and-technical-assistance/uds-novel-coronavirus-disease-covid-19-reporting/outreach-enrollment-assists-reporting">https://bphc.hrsa.gov/data-reporting/uds-training-and-technical-assistance/uds-novel-coronavirus-disease-covid-19-reporting/outreach-enrollment-assists-reporting</a>, "Assists reported should be limited to those provided to health center patients (i.e., individuals who had at least one UDS countable visit during the reporting year) and any family members who will benefit from that O/E assistance. In these cases where a health center patient is representing other family members, include the patient and each represented family member in the count." This is a departure from HRSA's historic stance that <b>all</b> assists should be entered; the new guidance is not reflected in this question in the 2022 HRSA UDS Manual. `,
                            items: [
                                {
                                    name: "totalAssists",
                                    title: "Enter Number of Assists",
                                    inputType: "number",
                                    validators: [
                                        {
                                            type: "numeric",
                                            text: "Value cannot be less than 0",
                                            minValue: 0,
                                        },
                                        {
                                            type: "regex",
                                            text: "Value must be a whole number",
                                            regex: "^\\d+$"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "multipletext",
                            name: "totalPatientsThatReceivedCOVID19Vaccine",
                            title: "4. How many patients received a FDA-approved COVID-19 vaccine during the calendar year at your clinic?",
                            popupdescription: `COVID-19 vaccine CPT-I codes: 0001A-0004A, 0011A- 0014A, 0021A-0024A, 0031A-0034A, 0041A-0044A, 0051A-0054A, 0064A, 0071A, 0072A, 91300-91307, 91308-91310`,
                            items: [
                                {
                                    name: "totalAssists",
                                    title: "Enter Number of Patients",
                                    inputType: "number",
                                    validators: [
                                        {
                                            type: "numeric",
                                            text: "Value cannot be less than 0",
                                            minValue: 0,
                                        },
                                        {
                                            type: "regex",
                                            text: "Value must be a whole number",
                                            regex: "^\\d+$"
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "panel",
                    name: "COVID19",
                    title: "COVID-19",
                    description: "Count regardless of primacy. For example, if a patient has pneumonia confirmed due to COVID-19, both COVID-19 and pneumonia are documented in the medical record and the coding for COVID-19 would be reflected.",

                    elements: [
                        {
                            type: "text",
                            name: "totalVisitsWithICD10Coded",
                            title: "Number of visits at which selected ICD-10 codes for COVID-19 have been coded.",
                            inputType: "number",
                            min: 0,
                            popupdescription: `COVID-19 diagnosis ICD-10 code: U07.01`,
                        },
                        {
                            type: "text",
                            name: "totalPatientsWithMoreThanOneCOVID19Coding",
                            title: "Number of patients who have had one or more visits where COVID-19 has been coded.",
                            inputType: "number",
                            min: 0,
                            popupdescription: `COVID-19 diagnosis ICD-10 code: U07.01`
                        },
                        {
                            type: "text",
                            name: "totalVisitsWithCOVID19TestPerformed",
                            title: "Number of visits at which COVID-19 testing occurred for clinic patients.",
                            inputType: "number",
                            min: 0,
                            popupdescription: `COVID-19 diagnostic testing codes: CPT-4: 87426, 87428, 87635, 87636, 87637; HCPCS: U0001, U0002, U0003, U0004; and CPT PLA: 0202U, 0223U, 0225U, 0240U, 0241U
                    COVID-19 antibody testing codes: CPT-4: 86318, 86328, 86408, 86409, 86413, 86769; and CPT PLA: 0224U, 0226U`
                        },
                        {
                            type: "text",
                            name: "totalPatientsWithMoreThanOneCOVID19Test",
                            title: "Number of patients who have had one or more visits where COVID-19 tests occurred.",
                            inputType: "number",
                            min: 0,
                            popupdescription: `COVID-19 diagnostic testing codes: CPT-4: 87426, 87428, 87635, 87636, 87637; HCPCS: U0001, U0002, U0003, U0004; and CPT PLA: 0202U, 0223U, 0225U, 0240U, 0241U
                    COVID-19 antibody testing codes: CPT-4: 86318, 86328, 86408, 86409, 86413, 86769; and CPT PLA: 0224U, 0226U`
                        },
                        {
                            type: "comment",
                            name: "COVID19Notes",
                            title: "Notes",
                            titleLocation: "left"
                        },
                    ]
                }
            ],
        },
    ]
}