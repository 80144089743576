export class FilterParameter {
    propertyName;
    filterValue;
    allowPartialMatch;
    getAllWhenNull;
    component;
    filterType;

    constructor(propertyName, allowPartialMatch = false, filterValue = null, getAllWhenNull = true, component = null, filterType = FilterType.Text){
        this.propertyName = propertyName;
        this.filterValue = filterValue;
        this.allowPartialMatch = allowPartialMatch;
        this.getAllWhenNull = getAllWhenNull;
        this.component = component;
        this.filterType = filterType;
    }
}

export class QueryParameter {
    sortOrder;
    sortProperty;
    filters = [];
    offset = 0;
    limit = 100;
    totalCount = 0;

    constructor(props){
        this.sortOrder = props?.sortOrder || SortOrder.None;
        this.sortProperty = props?.sortProperty;
        this.filters = props?.filters || [];
        this.offset = props?.offset || 0;
        this.limit = props?.limit || 100;
        this.totalCount = props?.totalCount || 0;
    }
}

export class ColDisplayConfig {
    columnName;
    headerText;
    sortable;
    filterable;
    component;
    filterComponent;
    filterType;

    constructor(columnName, headerText, filterable = false, sortable = true, component = null, filterComponent = null, filterType = FilterType.Text){
        this.columnName = columnName;
        this.headerText = headerText;
        this.filterable = filterable;
        this.sortable = sortable;
        this.component = component;
        this.filterComponent = filterComponent;
        this.filterType = filterType;
    }
}

export const SortOrder = {
    Ascending: 0,
    Descending: 1,
    None: 2
};

export const FilterType = {
    Select: 1,
    Date: 2,
    Text: 3
}

export const getNextSortOrder = (sortOrder, propertyName, newPropertyName = null) => {
        
    let newSortOrder = sortOrder;

    if(propertyName !== newPropertyName){
        return SortOrder.Ascending;
    }

    switch(sortOrder){
        case SortOrder.Ascending: newSortOrder = SortOrder.Descending;
            break;
        case SortOrder.Descending: newSortOrder = SortOrder.None;
            break;
        
        case null:
        case SortOrder.None: 
        default: newSortOrder = SortOrder.Ascending;
    }

    return newSortOrder;
}