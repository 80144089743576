import React, { useState } from "react";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import SubMenu from "./SubMenu";
import { useLocation } from 'react-router-dom';
import './SideBar.scss';

const menuSelected= (location, menu)=>
{
  var selected="unselected";
  if(location.pathname.indexOf(menu.target) > -1 && menu.target !== '#')
    selected = "selected";
  else if(location.hash.replace('#','')  === menu.title.replace(/\s/g, ''))
    selected = "selected";
  else
    selected= "unselected";
  
  return selected;
}

const SideBar = ({ isOpen, toggle, sideMenuItems, state = undefined }) => 
{
  const location = useLocation();

  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <div className="sidebar-header">
        <span color="info" onClick={toggle} style={{ color: "#fff" }}>
          &times;
        </span>
        <h5>   {sideMenuItems.title}</h5>
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3" name="sidebar" id="sidebar">
          {sideMenuItems.items?.map((menu, index) => {
            if(menu.submenus)
              return (<SubMenu key={index} title={menu.title} target={menu.target} items={menu.submenus} state={state} />) 
            else
              return (
              <NavItem key={index}>
                <NavLink 
                  tag={Link} 
                  exact={"true"}
                  to={{
                    pathname: menu.target === '#' ? menu.target+menu.title.replace(/\s/g, ''): menu.target,
                    state : state
                  }}
                  id = {`menu-${menu.title.replace(/\s/g, '')}`}
                  className={menuSelected(location,menu)}>
                  {menu.title}
                </NavLink>
              </NavItem>
              )
          })}
        </Nav>
      </div>
    </div>
  );
}

export default SideBar;
