import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import "./NetworkDataHub.scss";
import { apiClient, ApiEndpoints, ApiRoutes, ApplicationPermissions } from "utilities";
import { Link } from "react-router-dom";
import { Announcements, Resources, Contacts } from ".";
import { useUserContext } from "contexts";
import { trackPromise } from "react-promise-tracker";

export const NetworkDataHub = () => {
  const user = useUserContext();
  const canUpdateSettings = user.hasPermission(ApplicationPermissions.NetworkDataHub.UpdateSettings);
  const canUpdateClinicDataEntry = user.hasAnyPermission(Object.values(ApplicationPermissions.ClinicManagement));
  const [settings, setSettings] = useState([]);
  const [shouldRerender, setShouldRerender] = useState(false);

  const dataEntryLink = () => {
    if (!user.isFHQCClinicType) {
      return ApiRoutes.ClinicDataEntry
    } else {
      return ApiRoutes.ClinicUploadManualEntry
    }
  }

  const toggleSetting = async (event, setting) => {
    const url = ApiEndpoints.NetworkDataHub.Settings.Create;
    setting.isActive = event.target.checked;
    await trackPromise(apiClient
      .put(url, setting, {
        // @ts-ignore
        toastConfig: {
          showSuccess: true,
          successMessage: `${setting.displayName} is now ${
            setting.isActive ? "Enabled" : "Disabled"
          }.`,
        },
      })
      .then(() => {
        setShouldRerender(!shouldRerender);
      }));
  };

  useEffect(() => {
    trackPromise(apiClient
      .get(ApiEndpoints.NetworkDataHub.Settings.View)
      .then((response) => {
        response.data.sort((a, b) => b.id.localeCompare(a.id));
        setSettings(response.data);
      })
      .catch((err) => {
        console.error(err);
      }));
  }, [setSettings, shouldRerender]);

  return (
    <>
      <h3>Network Data Hub</h3>
      <Form className="row mt-4 d-flex justify-content-end">
        {settings?.map((setting, index) => {
          if (canUpdateSettings || ((setting.isActive) && canUpdateClinicDataEntry)) {
            return (
              <div
                key={index}
                className="col-xl-4 col-lg-6 pb-2 d-flex justify-content-end align-items-center"
              >
                {canUpdateSettings &&
                <FormGroup switch className="me-3">
                  <Input
                    className="ndh-setting me-2"
                    name={setting.id}
                    type="switch"
                    checked={setting.isActive}
                    onChange={(event) => {
                      toggleSetting(event, setting);
                    }}>
  
                    </Input>
                    <Label check className="text-sm">
                      <b className="small">Enabled</b>
                    </Label>
                </FormGroup>
                }
  
                <Link
                  to={
                    setting.id === "preliminaryDataEntry"
                      ? ApiRoutes.PreliminaryData
                      : dataEntryLink()
                  }
                >
                  <Button
                    type="button"
                    className="pill-btn btn btn-secondary border-2 px-5"
                    color="primary"
                    outline
                  >
                    <b>{setting.displayName}</b>
                  </Button>
                </Link>
              </div>
            );
          }
        })}
      </Form>
      <div className="row my-4">
        <div className="col-xl-8 col-lg-6 mb-4 mb-lg-0">
          <Announcements />
        </div>
        <div className="col-xl-4 col-lg-6">
          <Resources />
          <Contacts />
        </div>
      </div>
    </>
  );
};
