export const sideMenuItemsNDH = {
    ClinicDetails:
    {
        title: 'Clinic Details',
        items: [
            {
                title: "Clinic Information NDH",
                target: "/ClinicDataEntry/ClinicDetails#ClinicInformationNDH",
            },
            {
                title: "Active Accounts NDH",
                target: "/ClinicDataEntry/ClinicDetails/Users#ActiveAccountsNDH",
            },
        ],
    },
    UDSDataUpload:
    {
        title: 'UDS Data Upload',
        items: [
            {
                title: "UDS Data Upload",
                target: '/ClinicDataEntry/UDSDataUpload',
            }
        ]
    },
    DataEntryReview:
    {
        title: 'Data Entry Review',
        target: '/ClinicDataEntry/DataEntryReview#ClinicDetails',
        items: [
            {
                title: "Clinic Details",
                target: "/ClinicDataEntry/DataEntryReview#ClinicDetails"
            },
            {
                title: "UDS Data Upload",
                target: "/ClinicDataEntry/DataEntryReview#UDSDataUpload"
            },
            {
                title: "Manual Data Entry",
                target: "/ClinicDataEntry/DataEntryReview#ManualDataEntry",
            },
            {
                title: "Data Submission",
                target: "/ClinicDataEntry/DataEntryReview#DataSubmission"
            },
        ]
    }

}