// Should be kept in sync with the server-side constants in "KCC.Core.Models.ApplicationPermission"
export const ApplicationPermissions = {
    NetworkDataHub: {
        CreateAnnouncements: 1,
        UpdateAnnouncements: 2,
        DeleteAnnouncements: 3,
        CreateResources: 4,
        UpdateResources: 5,
        DeleteResources: 6,
        CreateContacts: 7,
        UpdateContacts: 8,
        DeleteContacts: 9,
        UpdateSettings: 10,
        NDHEditorTool: 47
    },

    CoverKansas: {
        CoverKanasUser: 11,
        UpdateForm: 15,
        SubmitNavigatorReport: 46,
    },

    // Pandemic: {
    //     UploadData: 16,
    //     ViewUploads: 17
    // },

    AccountManagement: {
        ViewClinics: 18,
        ViewUsers: 19,
        UpdateUsers: 20,
        RequestUserDeactivation: 21,
        ApproveUserDeactivation: 22,
        CreateUsers: 23,
        CreateClinics: 24,
        UpdateClinics: 25,
        DeleteClinics: 26,
        CreateRoles: 27,
        UpdateRoles: 28,
        DeleteRoles: 29,
        ViewRoles: 30,
        CreateNotifications: 31,
        UpdateNotifications: 32,
        DeleteNotifications: 33,
        ViewNotifications: 34,
    },

    ClinicManagement: {
        ViewClinicDetails: 35,
        ViewClinicUsers: 36,
        UpdateClinicDetails: 37,
        CreateClinicUsers: 38,
        UpdateClinicUsers: 39,
        RequestClinicDeactivation: 40,
        ApproveClinicDeactivation: 41,
        UpdateDataEntry: 42,
        SubmitDataEntry: 43,
        RequestAmendment: 44,
        UDSFileUpload: 45
    },
};
