import { useUserContext } from "contexts";
import React, { useEffect, useState } from "react";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { trackPromise } from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { apiClient, ApiEndpoints } from "utilities";

export const UDSDataUpload = (props) => {
  const user = useUserContext();
  const history = useHistory();
  const [allowUpload, setAllowUpload] = useState(true);
  const [existingFileUploadDate, setExistingFileUploadDate] = useState("");
  const [formIsInvalid, setFormIsInvalid] = useState(false);
  const [uploadThisSession, setUploadThisSession] = useState(window.sessionStorage.getItem("UDSDataUpload") ?? "");

  const fetchExistingUploadInfo = async () => {
    const url = ApiEndpoints.ClinicManagement.ClinicPatientData.UdsFileUpload;
    await trackPromise(apiClient
            .get(`${url}Date/${user.clinicId}`)
            .then((response) => {
              if (response.status === 200 && response.data !== "") {
                const uploadDate = new Date(response.data).toLocaleDateString();
                setAllowUpload(false);
                setExistingFileUploadDate(uploadDate);
              }
            }));
  }

  const handleSave = async (event) => {
    event.preventDefault();
    const url = ApiEndpoints.ClinicManagement.ClinicPatientData.UdsFileUpload;
    const nextStep = props.isReview ? "ClinicDataEntry/DataEntryReview#ManualDataEntry" : "/ClinicDataEntry/ManualDataEntry#Patients";
    
    if (event.currentTarget.elements.udsFileUpload?.files.length === 0 && allowUpload === true) {
      setFormIsInvalid(true);
      return;
    }
    
    if (allowUpload === false) {
      history.push(nextStep);
    }

    if (event.currentTarget.elements.udsFileUpload?.files.length > 0 && allowUpload === true) {
      const form = new FormData();
      form.append('file', event.currentTarget.elements.udsFileUpload?.files[0]);
      await trackPromise(apiClient.post(`${url}/${user.clinicId}`, form, {
        // @ts-ignore
        toastConfig: {
          showSuccess: true,
          successMessage: "UDS file uploaded successfully!",
        }
      })
      .then((response) => {
        const filename =  event.target.elements.udsFileUpload?.files[0].name;
        window.sessionStorage.setItem("UDSDataUpload", filename);
        setUploadThisSession(filename);
        history.push(nextStep);
      })
      .catch((error) => {
        let message ="UDS file upload failed.";
        
        if (error.response.status === 400) {
          message += ` ${error.response.data}`
        }

        toast.error(message);
        return;
      }));
    }
  };

  useEffect(() => { 
    fetchExistingUploadInfo();
  }, []);

  return (
    <>
      <h3 className="primary title mb-4">UDS File Upload</h3>
      <Row>
        <Col sm={11}>
          {existingFileUploadDate !== "" && existingFileUploadDate !== null &&
          <FormGroup>
            {(uploadThisSession === "") ?
              <p className="mx-2">A UDS file was uploaded on <b>{existingFileUploadDate}</b>. Would you like to upload a new file and replace the existing one?</p>
              : <p className="mx-2">You uploaded file <b>{uploadThisSession}</b>. Would you like to upload a new file and replace the existing one?</p> 
            }
            <Input
              id="allowUpload"
              name="allowUpload"
              type="checkbox"
              onChange={() => { setAllowUpload(!allowUpload)}}
              className="m-2"
            />
            <Label for="allowUpload">Yes, upload a new file and replace the existing one.</Label>
          </FormGroup>
          }
          <Form onSubmit={handleSave}>
            {allowUpload === true && 
            <FormGroup row className="m-2">
              <Label
                for="udsFileUpload"
                sm={4}
              >
                <b className={allowUpload === true ? "" : "text-muted"}>Select UDS file for Upload</b>
              </Label>
              <Col sm={8}>
                <Input
                  id="udsFileUpload"
                  name="udsFileUpload"
                  type="file"
                  invalid={allowUpload === true && formIsInvalid === true }
                />
              <FormFeedback className="float-end">
                Please select a UDS file to upload.
              </FormFeedback>
              </Col>
            </FormGroup>
            }
            <FormGroup row className="my-5">
              <Col className="d-flex justify-content-end align-items-baseline">
                <Label className="small-label-inline col-form-label px-3 py-5 link-primary" for="confirmationCodeSubmitButton">
                  Manual Data Entry
                </Label>
                <Button
                  id="submitButton"
                  name="submitButton"
                  type="submit"
                  color="primary"
                  className="btn-stepper-next"
                  outline
                >
                  <BsFillArrowRightCircleFill size={40} />
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </>
  );
};
