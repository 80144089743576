const specialPopulationRecord = (elementName, title, totalPopulation, description = undefined) => {
  return [
    {
      type: "text",
      name: `${elementName}`,
      title: `${title}`,
      enableIf: `{${elementName}_nanr} empty`,
      inputType: "number",
      defaultValueExpression: "0",
      width: "400px",
      min: 0,
      validators: [
        {
          type: "numeric",
          text: "Value cannot be less than 0",
          minValue: 0,
        },
        {
          type: "regex",
          text: "Value must be a whole number",
          regex: "^\\d+$"
        }],
    },
    {
      type: "checkbox",
      name: `${elementName}_nanr`,
      title: " ",
      startWithNewLine: false,
      choices: ["NA/NR"],
      width: "150px",
    },
    {
      type: "expression",
      name: `${elementName}_Percentage`,
      startWithNewLine: false,
      title: " ",
      expression: `(roundTo(({${elementName}} / {${totalPopulation}}) * 100, 2)) + " % of Patients "`,
      width: "150px",
      popupdescription: description,
    },
  ]
}

const vulnerablePopulationType = (elementName, title, description = undefined) => {

  return [
    {
      type: "panel",
      name: `${elementName}_vulnerablePopulationsPanel`,
      showQuestionNumbers: "off",
      questionTitleLocation: "left",

      elements: [
        {
          type: "checkbox",
          name: `${elementName}_nanr`,
          title: `${title}:`,
          choices: ["NA/NR"],
          width: "400px",
        },
        {
          type: "text",
          name: `${elementName}_nonHispanicLatino`,
          title: "Non-Hispanic/Latino Ethnicity",
          enableIf: `{${elementName}_nanr} empty`,
          inputType: "number",
          defaultValueExpression: "0",
          width: "400px",
          min: 0,
          validators: [
            {
              type: "numeric",
              text: "Value cannot be less than 0",
              minValue: 0,
            },
            {
              type: "regex",
              text: "Value must be a whole number",
              regex: "^\\d+$"
            }],

        },
        {
          type: "text",
          name: `${elementName}_hispanicLatino`,
          title: "Hispanic/Latino Ethinicity",
          enableIf: `{${elementName}_nanr} empty`,
          inputType: "number",
          defaultValueExpression: "0",
          width: "400px",
          min: 0,
          validators: [
            {
              type: "numeric",
              text: "Value cannot be less than 0",
              minValue: 0,
            },
            {
              type: "regex",
              text: "Value must be a whole number",
              regex: "^\\d+$"
            }],
        },
        {
          type: "text",
          name: `${elementName}_unreported`,
          title: "Unreported/Refused to Report",
          enableIf: `{${elementName}_nanr} empty`,
          inputType: "number",
          defaultValueExpression: "0",
          width: "400px",
          min: 0,
          validators: [
            {
              type: "numeric",
              text: "Value cannot be less than 0",
              minValue: 0,
            },
            {
              type: "regex",
              text: "Value must be a whole number",
              regex: "^\\d+$"
            }],
        },
        {
          type: "html",
          html: "<hr></hr>"
        },
        {
          type: "expression",
          name: `${elementName}_total`,
          title: "Total :",
          titleLocation: "left",
          expression: `({${elementName}_hispanicLatino} + {${elementName}_nonHispanicLatino} + {${elementName}_unreported})`
        },
        {
          type: "expression",
          name: `${elementName}_totalPercentage`,
          startWithNewLine: false,
          title: " ",
          titleLocation: "left",
          expression: `(roundTo(({${elementName}_total}/{total_vulnerablePopulation}) * 100, 2)) + " % of Total Population "`
        }
      ]
    }
  ]
}

export const patientDataJSON = {
  showProgressBar: "top",
  showCompletedPage: false,
  showTOC: true,
  focusFirstQuestionAutomatic: false,
  title: "Patient Data",
  checkErrorsMode: "onValueChanged",
  pages: [
    {
      name: "Patient",
      navigationTitle: "Unduplicated Patients by Income",
      navigationDescription: "Unduplicated Patients by Income",
      title: "Unduplicated Patients by Income",
      elements: [
        {
          type: "panel",
          name: "patientsPanel",
          showQuestionNumbers: "off",
          questionTitleLocation: "left",
          description: "Enter non-negative integers",
          elements: [
            {
              type: "html",
              title: " ",
              titleLocation: "hidden",
              popupdescription: `<ul><li> Report patients by income, as defined by the clinic’s policy. Children, with the exception of emancipated minors or those presenting for minor consent services, should be classified under their parents’ or guardians’ income.</li>
                    <li> Patients whose information was not collected within 12 months prior to their last visit in the calendar year are reported as “Unknown.”</li></ul`,
            },
            {
              type: "text",
              name: "100FPL",
              title: "100 FPL and Below",
              enableIf: "{nanr100FP} empty",
              inputType: "number",
              defaultValueExpression: "0",
              width: "400px",
              min: 0,
              validators: [
                {
                  type: "numeric",
                  text: "Value cannot be less than 0",
                  minValue: 0,
                },
                {
                  type: "regex",
                  text: "Value must be a whole number",
                  regex: "^\\d+$"
                }],
            }, {
              type: "checkbox",
              name: "nanr100FP",
              title: " ",
              startWithNewLine: false,
              choices: ["NA/NR"],
              width: "150px",
            },
            {
              type: "expression",
              name: "100Percent",
              startWithNewLine: false,
              title: " ",
              expression: "roundTo(({100FPL} / {total}) * 100, 2) + \" % of Patients \"",
              width: "150px"
            },
            {
              type: "text",
              name: "150FPL",
              title: "101-150 FPL",
              enableIf: "{nanr150FP} empty",
              inputType: "number",
              defaultValueExpression: "0",
              width: "400px",
              min: 0,
              validators: [
                {
                  type: "numeric",
                  text: "Value cannot be less than 0",
                  minValue: 0,
                },
                {
                  type: "regex",
                  text: "Value must be a whole number",
                  regex: "^\\d+$"
                }],

            }, {
              type: "checkbox",
              name: "nanr150FP",
              startWithNewLine: false,
              title: " ",
              choices: ["NA/NR"],
              width: "150px",
            },
            {
              type: "expression",
              name: "150Percent",
              startWithNewLine: false,
              title: " ",
              expression: "(roundTo(({150FPL} / {total}) * 100, 2)) + \" % of Patients \"",
              width: "150px",
            },
            {
              type: "text",
              name: "200FPL",
              title: "151-200 FPL",
              enableIf: "{nanr200FP} empty",
              inputType: "number",
              defaultValueExpression: "0",
              width: "400px",
              min: 0,
              validators: [
                {
                  type: "numeric",
                  text: "Value cannot be less than 0",
                  minValue: 0,
                },
                {
                  type: "regex",
                  text: "Value must be a whole number",
                  regex: "^\\d+$"
                }],
            }, {
              type: "checkbox",
              name: "nanr200FP",
              startWithNewLine: false,
              title: " ",
              choices: ["NA/NR"],
              width: "150px",
            },
            {
              type: "expression",
              name: "200Percent",
              startWithNewLine: false,
              title: " ",
              expression: "(roundTo(({200FPL} / {total}) * 100, 2)) + \" % of Patients \"",
              width: "150px",
            },
            {
              type: "text",
              name: "Above200",
              title: "Above 200 FPL",
              enableIf: "{nanr201FP} empty",
              inputType: "number",
              defaultValueExpression: "0",
              width: "400px",
              min: 0,
              validators: [
                {
                  type: "numeric",
                  text: "Value cannot be less than 0",
                  minValue: 0,
                },
                {
                  type: "regex",
                  text: "Value must be a whole number",
                  regex: "^\\d+$"
                }],
            },
            {
              type: "checkbox",
              name: "nanr201FP",
              startWithNewLine: false,
              title: " ",
              choices: ["NA/NR"],
              width: "150px",
            },
            {
              type: "expression",
              name: "Above200Percent",
              startWithNewLine: false,
              title: " ",
              expression: "(roundTo(({Above200} / {total}) * 100, 2)) + \" % of Patients \"",
              width: "150px",
            },
            {
              type: "text",
              name: "Unknown",
              title: "Unknown",
              enableIf: "{nanrUnknown} empty",
              inputType: "number",
              defaultValueExpression: "0",
              width: "400px",
              min: 0,
              validators: [
                {
                  type: "numeric",
                  text: "Value cannot be less than 0",
                  minValue: 0,
                },
                {
                  type: "regex",
                  text: "Value must be a whole number",
                  regex: "^\\d+$"
                }],
            }, {
              type: "checkbox",
              name: "nanrUnknown",
              startWithNewLine: false,
              title: " ",
              choices: ["NA/NR"],
              width: "150px",
            },
            {
              type: "expression",
              name: "UnknownPercent",
              startWithNewLine: false,
              title: " ",
              expression: "(roundTo(({Unknown} / {total}) * 100, 2)) + \" % of Patients \"",
              width: "150px",
            },
          ],
          title: "Please enter number of patients by income category:"
        },
        {
          type: "panel",
          name: "panelTotalFTEPatients",
          showQuestionNumbers: "off",
          elements: [
            {
              type: "expression",
              name: "total",
              title: "Total Patients",
              expression: "{100FPL} + {150FPL} + {200FPL} + {Above200} + {Unknown}"
            },
            {
              type: "expression",
              title: "% of Total Patients",
              startWithNewLine: false,
              expression: "(roundTo(({total} / {total}) * 100, 2)) + \" % \""
            }
          ]
        }
      ]
    },
    {
      name: "PayorType",
      navigationTitle: "Unduplicated Patients by Payor Type",
      navigationDescription: "Unduplicated Patients by Payor Type",
      title: "Unduplicated Patients by Payor Type",
      elements: [{
        type: "panel",
        name: "payorTypePanel",
        showQuestionNumbers: "off",
        questionTitleLocation: "left",
        title: "Please enter number of patients by payor type:",
        description: "Enter non-negative integers",
        elements: [
          {
            type: "html",
            title: " ",
            titleLocation: "left",
            popupdescription: ` <p> Report the primary medical insurance patients had at the time of their last visit regardless of whether that insurance was billed or paid for any or all of the visit services. <b><u>Do not</u></b> report public programs that reimburse for selected services, such as KAN Be Healthy; Early Detection Works (EDW) Program; or Title X, as a patient’s primary medical insurance. Every patient reported must be reported once (and only once). Note that <b><u>there is no “unknown” insurance classification-clinics </b></u> (with the exception of free/donation-based clinics) are required to obtain medical insurance information from all patients. </p>
            <ul>
              <li> Medicare: Patients covered by the federal insurance program for the aged, blind, and disabled (Title XVIII of the Social Security Act), patients who have Medicare and Medicaid (“dually eligible”), patients who have Medicare and a private (“Medigap”) insurance, and patients enrolled in “Medicare Advantage” products. Do not include Medicare-enrolled patients who are still working and are insured by both an employer-based plan and Medicare; these patients should be reported on Private Insurance because the employer-based insurance plan is billed first. </li>
              <li> Medicaid: Patients covered by state-run programs operating under the guidelines of Titles XIX and XXI (as appropriate) of the Social Security Act, include Kansas Medicaid and KanCare. Include patients covered by “state-only” programs covering individuals who are ineligible for federal matching funds (e.g., undocumented children, pregnant patients) and paid through Medicaid, if they cannot otherwise be identified as having another insurance. Do not include patients enrolled in both Medicaid and Medicare; these patients should be reported on Medicare. Do not include MediKan. </li>
              <li> CHIP/SCHIP: Patients covered by the Children’s Health Insurance Program (CHIP) Reauthorization Act and provided through the state’s Medicaid program. If there is no way to distinguish between Medicaid and CHIP administered through Medicaid, classify all covered patients as Medicaid. </li>
              <li> Other Public: Report state and/or local government programs that provide a broad set of benefits for eligible individuals; CHIP programs that are run through the private sector, often administered through health maintenance organizations (HMOs); and CHIP patients who are on plans administered by Medicaid coordinated care organizations (CCOs). Include any public-paid or subsidized private insurance not reported elsewhere. Do not report uninsured individuals whose visit may be covered by a public source with limited benefits, such as Title X, KAN Be Healthy, EDW, AIDS Drug Assistance Program providing pharmaceutical coverage for HIV patients, etc. or patients covered by workers’ compensation. Do not include patients who have insurance through federal or state insurance exchanges.</li>
              <li> Private Insurance: Patients with health insurance provided by private (commercial) and not-for-profit companies; individuals may obtain insurance through employers or on their own. Include patients who purchase insurance through the federal or state exchanges. Private insurance includes insurance purchased for public employees or retirees, such as Tricare, Trigon, or the Federal Employees Benefits Program.</li>
              <li> Uninsured: Patients who did not have medical insurance at the time of their last visit. This may include patients who were insured earlier in the year, as well as patients whose visit was paid for by a third-party source that was not insurance, such as KAN Be Healthy, EDW, Title X, or some state or local safety net or indigent care programs. Report a minor receiving services with parental consent and children seen in a school-based service site under their parent’s health insurance. </li>
            <ul>
            `,
          },
          {
            type: "text",
            name: "medicare",
            title: "Medicare",
            enableIf: "{nanrMedicare} empty",
            inputType: "number",
            defaultValueExpression: "0",
            width: "400px",
            min: 0,
            validators: [
              {
                type: "numeric",
                text: "Value cannot be less than 0",
                minValue: 0,
              },
              {
                type: "regex",
                text: "Value must be a whole number",
                regex: "^\\d+$"
              }],

          }, {
            type: "checkbox",
            name: "nanrMedicare",
            title: " ",
            startWithNewLine: false,
            choices: ["NA/NR"],
            width: "150px",
          },
          {
            type: "expression",
            name: "medicarePct",
            startWithNewLine: false,
            title: " ",
            expression: "(roundTo(({medicare} / {totalPayorType}) * 100, 2)) + \" % \"",
            width: "50px",
          },
          {
            type: "text",
            name: "medicaid",
            title: "Medicaid",
            enableIf: "{nanrMedicaid} empty",
            inputType: "number",
            defaultValueExpression: "0",
            width: "400px",
            min: 0,
            validators: [
              {
                type: "numeric",
                text: "Value cannot be less than 0",
                minValue: 0,
              },
              {
                type: "regex",
                text: "Value must be a whole number",
                regex: "^\\d+$"
              }],
          }, {
            type: "checkbox",
            name: "nanrMedicaid",
            startWithNewLine: false,
            title: " ",
            choices: ["NA/NR"],
            width: "150px",
          },
          {
            type: "expression",
            name: "medicaidPct",
            startWithNewLine: false,
            title: " ",
            expression: "(roundTo(({medicaid} / {totalPayorType}) * 100, 2)) + \" % \"",
            width: "50px",
          },
          {
            type: "text",
            name: "chipSchip",
            title: "CHIP/SCHIP (Title21)",
            enableIf: "{nanrChipSchipP} empty",
            inputType: "number",
            defaultValueExpression: "0",
            width: "400px",
            min: 0,
            validators: [
              {
                type: "numeric",
                text: "Value cannot be less than 0",
                minValue: 0,
              },
              {
                type: "regex",
                text: "Value must be a whole number",
                regex: "^\\d+$"
              }],
          }, {
            type: "checkbox",
            name: "nanrChipSchipP",
            startWithNewLine: false,
            title: " ",
            choices: ["NA/NR"],
            width: "150px",
          },
          {
            type: "expression",
            name: "chipSchipPct",
            startWithNewLine: false,
            title: " ",
            expression: "(roundTo(({chipSchip} / {totalPayorType}) * 100, 2)) + \" % \"",
            width: "50px",
          },
          {
            type: "text",
            name: "otherPublic",
            title: "Other Public",
            enableIf: "{nanrOtherPublic} empty",
            inputType: "number",
            defaultValueExpression: "0",
            width: "400px",
            min: 0,
            validators: [
              {
                type: "numeric",
                text: "Value cannot be less than 0",
                minValue: 0,
              },
              {
                type: "regex",
                text: "Value must be a whole number",
                regex: "^\\d+$"
              }],
          },
          {
            type: "checkbox",
            name: "nanrOtherPublic",
            startWithNewLine: false,
            title: " ",
            choices: ["NA/NR"],
            width: "150px",
          },
          {
            type: "expression",
            name: "otherPublicPct",
            startWithNewLine: false,
            title: " ",
            expression: "(roundTo(({otherPublic} / {totalPayorType}) * 100, 2)) + \" % \"",
            width: "50px",
          },
          {
            type: "text",
            name: "privateInsurance",
            title: "Private Insurance",
            enableIf: "{nanrPrivateInsurance} empty",
            inputType: "number",
            defaultValueExpression: "0",
            width: "400px",
            min: 0,
            validators: [
              {
                type: "numeric",
                text: "Value cannot be less than 0",
                minValue: 0,
              },
              {
                type: "regex",
                text: "Value must be a whole number",
                regex: "^\\d+$"
              }],
          }, {
            type: "checkbox",
            name: "nanrPrivateInsurance",
            startWithNewLine: false,
            title: " ",
            choices: ["NA/NR"],
            width: "150px",
          },
          {
            type: "expression",
            name: "privateInsurancePct",
            startWithNewLine: false,
            title: " ",
            expression: "(roundTo(({privateInsurance} / {totalPayorType}) * 100, 2)) + \" % \"",
            width: "50px",
          },
          {
            type: "text",
            name: "uninsured",
            title: "Uninsured",
            enableIf: "{nanrUninsured} empty",
            inputType: "number",
            defaultValueExpression: "0",
            width: "400px",
            min: 0,
            validators: [
              {
                type: "numeric",
                text: "Value cannot be less than 0",
                minValue: 0,
              },
              {
                type: "regex",
                text: "Value must be a whole number",
                regex: "^\\d+$"
              }],
          }, {
            type: "checkbox",
            name: "nanrUninsured",
            startWithNewLine: false,
            title: " ",
            choices: ["NA/NR"],
            width: "150px",
          },
          {
            type: "expression",
            name: "uninsuredPct",
            startWithNewLine: false,
            title: " ",
            expression: "(roundTo(({uninsured} / {totalPayorType}) * 100, 2)) + \" % \"",
            width: "50px",
          },
        ],
      },
      {
        type: "panel",
        name: "totalPayorTypePanel",
        showQuestionNumbers: "off",
        elements: [
          {
            type: "expression",
            name: "totalPayorType",
            title: "Total ",
            expression: "{medicare} + {medicaid} + {chipSchip} + {otherPublic} + {privateInsurance} + {uninsured}"
          },
          {
            type: "expression",
            title: "% Total of Patients",
            startWithNewLine: false,
            expression: "(roundTo(({totalPayorType} / {totalPayorType}) * 100, 2)) + \" % \""
          }
        ]
      }
      ]
    },
    {
      name: "SpecialPopulation",
      title: "Unduplicated Patients by Special and Vulnerable Populations",
      navigationTitle: "Special Population",
      navigationDescription: "Special Population",
      elements: [
        {
          type: "panel",
          name: "specialPopulationPanel",
          title: "Special Population",
          showQuestionNumbers: "off",
          questionTitleLocation: "left",
          description: "Enter non-negative integers",
          elements: [
            ...specialPopulationRecord("totalAgriculturalWorkers", "Total Agricultural Workers or Dependents", "totalSpecialPopulation", `- Migratory Agricultural Workers are patients whose principal employment is in agriculture and who establish a temporary home for the purposes of such employment as a migratory agricultural worker, as defined by section 330(g) of the PHS Act. Family members may or may not move with the worker or establish a temporary home. Include aged and disabled former migratory agricultural workers, as defined in section 330(g)(1)(B), and their family members. Aged and disabled former agricultural workers include those who were previously migratory agricultural workers but who no longer work in agriculture because of age or disability.            - Seasonal Agricultural Workers are patients whose principal employment is in agriculture on a seasonal basis (e.g., harvesting graing during the limited months of a harvesting season), but who DO NOT establish a temporary home for purposes of such employment.             - Agricultural workers are usually hired laborers who are paid piecework, hourly, or daily wages. Include patients who have been so employed within 24 months of their last visit, as well as their dependent family members who are patients of the clinic.`),
            ...specialPopulationRecord("totalHomeless", "Total Homeless", "totalSpecialPopulation", `Patients who lack housing (without regard to whether the individual is a member of a family). Include patients whose primary residence during the night is a supervised public or private facility that provides temporary living accommodations and patients who reside in transitional housing or permanent supportive housing. Children and youth at risk of homelessness, homeless veterans, and veterans at risk of homelessness may be included.`),
            ...specialPopulationRecord("totalSchoolBasedHealthCenterPatients", "Total School Based Health Center Patients", "totalSpecialPopulation", `Patients served at in-scope school-based service sites located on or near school grounds, limited to preschool, kindergarten, and primary through secondary schools (excluding colleges and universities), that provide on-site comprehensive preventive and primary health services. Services are targeted to the students at the school but may also be provided to siblings or parents and may occasionally include patients residing in the immediate vicinity of the school. Do not include as patients students who only receive screening services or mass treatment, such as vaccinations or fluoride treatments, at a school.            `),
            ...specialPopulationRecord("totalVeterans", "Total Veterans", "totalSpecialPopulation", `Patients who affirmatively indicate they previously served in the Air Force, Army, Coast Guard, Marines, Navy, Space Force, or as a commissioned officer of the Public Health Service or National Oceanic and Atmospheric Administration. In addition, include patients who served in the National Guard or Reserves on active duty status. Do not report veterans of other nations’ militaries, even if they served in wars in which the United States was also involved. Do not report military members who served on active duty (full-time status in their military capacity) at the time of their last visit during the year.`),
            ...specialPopulationRecord("totalPublicHousingPatients", "Total Public Housing Patients", "totalSpecialPopulation", `Patients served at clinic service delivery sites that meet the statutory definition for the PHPC program (located in or immediately accessible to public housing) and all patients seen at the health center service delivery site if it is located in or immediately accessible to agency-developed, -owned, or -assisted low-income housing, including mixed-finance projects. Not all patients served at service delivery sites located in or immediately accessible to public housing are themselves residents of public housing, but they are to be included in the count. Do not consider Section 8 housing units that receive no public housing agency support other than Section 8 housing vouchers as public housing.`)
          ]
        },
        {
          type: "panel",
          name: "totalSpecialPopulationPanel",
          showQuestionNumbers: "off",
          elements: [
            {
              type: "expression",
              name: "totalSpecialPopulation",
              title: "Total Patients",
              expression: `{totalAgriculturalWorkers} 
              + {totalHomeless} + {totalSchoolBasedHealthCenterPatients}
              + {totalVeterans} + {totalPublicHousingPatients}`
            },
            {
              type: "expression",
              title: "% of Total Patients",
              startWithNewLine: false,
              expression: `(roundTo(({totalSpecialPopulation} / {totalSpecialPopulation}) * 100, 2)) + " % "`
            }
          ]
        }
      ]
    },
    {
      name: "VulnerablePopulation",
      navigationTitle: "Vulnerable Population",
      navigationDescription: "Vulnerable Population",
      title: "Unduplicated Patients by Special and Vulnerable Populations",
      elements: [
        {
          type: "panel",
          name: `vulnerablePopulationsPanel`,
          title: "Vulnerable Population",
          showQuestionNumbers: "off",
          questionTitleLocation: "left",
          description: "All races must be reported by ethnicity - Hispanic or Latino/a, Non-Hispanic or Latino/a, or Unreported/Refused to Report Ethnicity. Enter non-negative integers.",
          elements:
            [
              ...vulnerablePopulationType("asian", "Asian", `Patients having ancestry in any of the original peoples of Asia, Southeast Asia, or the Indian subcontinent including, for example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippine Islands, Indonesia, Thailand, or Vietnam.`),
              ...vulnerablePopulationType("nativeHawaiian", "Native Hawaiian", `Patients having ancestry in any of the original peoples of Hawai''i.`),
              ...vulnerablePopulationType("otherPacificIslander", "Other Pacific Islander", `Patients having ancestry in any of the original peoples of Guam, Samoa, Tonga, Palau, Chuuk, Yap, Saipan, Kosrae, Ebeye, Pohnpei or other Pacific Islands in Micronesia, Melanesia, or Polynesia.`),
              ...vulnerablePopulationType("blackAfricanAmerican", "Black/African American", `Patients having ancestry in any of the Black racial groups of Africa.`),
              ...vulnerablePopulationType("americanIndianAlaskanNative", "American Indian/Alaskan Native", `Patients who trace their ancestry to any of the original peoples of North, South, and Central America and who maintain tribal affiliation or community attachment.`),
              ...vulnerablePopulationType("white", "White", `Patients having ancestry in any of the original peoples of Europe, the Middle East, or North Africa.`),
              ...vulnerablePopulationType("moreThanOneRace", "More than One Race", `Patients who have chosen two or more races on the intake form. “More than one race” must not appear as a selection option on your intake form.`),
              ...vulnerablePopulationType("unreportedRefusedToReport", "Unreported/Refused to Report", `Patients who left the race part of the intake form blank or those who indicated that they choose not to disclose this data.`),
            ]
        },
        {
          type: "panel",
          name: `total_vulnerablePopulationsPanel`,
          title: "Total Population",
          showQuestionNumbers: "off",
          questionTitleLocation: "left",
          elements: [
            {
              type: "expression",
              name: `total_hispanicLatino`,
              title: "Hispanic/Latino Ethinicity:",
              expression: `{asian_hispanicLatino} 
                + {nativeHawaiian_hispanicLatino} + {otherPacificIslander_hispanicLatino} 
                + {blackAfricanAmerican_hispanicLatino} + {americanIndianAlaskanNative_hispanicLatino} 
                + {moreThanOneRace_hispanicLatino} + {unreportedRefusedToReport_hispanicLatino} + {white_hispanicLatino}`
            },
            {
              type: "expression",
              name: "total_hispanicLatinoPercentage",
              startWithNewLine: false,
              title: " ",
              titleLocation: "left",
              expression: `(roundTo(({total_hispanicLatino}/{total_vulnerablePopulation}) * 100, 2)) + " % of Total Population "`
            },
            {
              type: "expression",
              name: `total_nonHispanicLatino`,
              title: "Non-Hispanic/Latino Ethnicity:",
              expression: `{asian_nonHispanicLatino} 
                + {nativeHawaiian_nonHispanicLatino} + {otherPacificIslander_nonHispanicLatino} 
                + {blackAfricanAmerican_nonHispanicLatino} + {americanIndianAlaskanNative_nonHispanicLatino} 
                + {moreThanOneRace_nonHispanicLatino} + {unreportedRefusedToReport_nonHispanicLatino} + {white_nonHispanicLatino}`
            },
            {
              type: "expression",
              name: "total_nonHispanicLatinoPercentage",
              startWithNewLine: false,
              title: " ",
              titleLocation: "left",
              expression: `(roundTo(({total_nonHispanicLatino}/{total_vulnerablePopulation}) * 100, 2)) + " % of Total Population "`
            },
            {
              type: "expression",
              name: `total_unreported`,
              title: "Unreported/Refused to Report:",
              expression: `{asian_unreported} 
                + {nativeHawaiian_unreported} + {otherPacificIslander_unreported} 
                + {blackAfricanAmerican_unreported} + {americanIndianAlaskanNative_unreported} 
                + {moreThanOneRace_unreported} + {unreportedRefusedToReport_unreported} + {white_unreported}`
            },
            {
              type: "expression",
              name: "total_unreportedPercentage",
              startWithNewLine: false,
              title: " ",
              titleLocation: "left",
              expression: `(roundTo(({total_unreported}/{total_vulnerablePopulation}) * 100, 2)) + " % of Total Population "`
            },
            {
              type: "html",
              html: "<hr></hr>"
            },
            {
              type: "expression",
              name: `total_vulnerablePopulation`,
              title: "Total:",
              expression: `{total_hispanicLatino} + {total_nonHispanicLatino} + {total_unreported}`
            },
            {
              type: "expression",
              name: "total_vulnerablePopulationPercentage",
              startWithNewLine: false,
              title: " ",
              titleLocation: "left",
              expression: `(roundTo(({total_vulnerablePopulation}/{total_vulnerablePopulation}) * 100, 2)) + " % of Total Population "`
            }
          ]
        }
      ],
    },
    {
      name: "PatientsbyLanguage",
      title: "Unduplicated Patients by Special and Vulnerable Populations",
      navigationTitle: "Patients by Language",
      navigationDescription: "Patients by Language",
      elements: [
        {
          type: "panel",
          name: "patientsByLanguagePanel",
          showQuestionNumbers: "off",
          questionTitleLocation: "left",
          title: "Patients by Language",
          description: "Enter non-negative integer",
          elements: [
            {
              type: "html",
              title: " ",
              startWithNewLine: false,
              titleLocation: "top",
              popupdescription: `Patients who are best served in a language other than English, including those who are best served in sign language. Include those patients who were served in a second language by a bilingual provider and those who may have brought their own interpreter and patients residing in areas where a language other than English is the dominant language.`
            },
            {
              type: "text",
              name: "patientsByLanguage",
              enableIf: "{nanrPatientsByLanguage} empty",
              title: "Patients Best Served in a Language Other than English",
              inputType: "number",
              defaultValue: 0,
              min: 0,
              validators: [
                {
                  type: "numeric",
                  text: "Value cannot be less than 0",
                  minValue: 0,
                },
                {
                  type: "regex",
                  text: "Value must be a whole number",
                  regex: "^\\d+$"
                }],
            },
            {
              type: "checkbox",
              name: "nanrPatientsByLanguage",
              startWithNewLine: false,
              title: " ",
              choices: ["NA/NR"],
              width: "150px",
            }
          ]
        }
      ],
    },
    {
      name: "SexualOrientation",
      title: "Unduplicated Patients by Special and Vulnerable Populations",
      navigationTitle: "Sexual Orientation",
      navigationDescription: "Sexual Orientation",
      elements: [
        {
          type: "panel",
          name: "sexualOrientationPanel",
          showQuestionNumbers: "off",
          questionTitleLocation: "left",
          title: "Sexual Orientation",
          description: "Enter non-negative integer",
          elements: [
            ...specialPopulationRecord("so_lesbianOrGay", "Lesbian or Gay", "totalSexualOrientation", `Patients who are emotionally and sexually attracted to people of their own gender.`),
            ...specialPopulationRecord("so_straightOrHeterosexual", "Straight or heterosexual", "totalSexualOrientation", `Patients who are emotionally and sexually attracted to people of a different gender.`),
            ...specialPopulationRecord("so_bisexual", "Bisexual", "totalSexualOrientation", `Patients who are emotionally and sexually attracted to people of their own gender and people of other genders.`),
            ...specialPopulationRecord("so_other", "Something else", "totalSexualOrientation", `Patients who identify themselves as queer, asexual, pansexual, or another sexual orientation not captured in other categories.`),
            ...specialPopulationRecord("so_dontKnow", "Don't Known", "totalSexualOrientation", `Patients who self-report that they don't know their sexual orientation.`),
            ...specialPopulationRecord("so_undisclosed", "Choose not to disclose", "totalSexualOrientation", `Patients who chose not to disclose their sexual orientation.`),
            ...specialPopulationRecord("so_unknown", "Unknown", "totalSexualOrientation", `Patients for whom the clinic does not know the sexual orientation.`),

            {
              type: "panel",
              name: "totalSexualOrientationPanel",
              showQuestionNumbers: "off",
              elements: [
                {
                  type: "expression",
                  name: "totalSexualOrientation",
                  title: "Total Patients",
                  expression: `{so_lesbianOrGay} + {so_straightOrHeterosexual} + {so_bisexual} + {so_other} + {so_undisclosed} + {so_unknown} + {so_dontKnow}`
                },
                {
                  type: "expression",
                  title: "% of Total Patients",
                  startWithNewLine: false,
                  expression: `(roundTo(({totalSexualOrientation} / {totalSexualOrientation}) * 100, 2)) + " % "`
                }
              ]
            }
          ]
        }
      ],
    },
    {
      name: "GenderIdentity",
      title: "Unduplicated Patients by Special and Vulnerable Populations",
      navigationTitle: "Gender Identity",
      navigationDescription: "Gender Identity",
      elements: [
        {
          type: "panel",
          name: "genderIdentityPanel",
          showQuestionNumbers: "off",
          questionTitleLocation: "left",
          title: "Gender Identity",
          description: "Enter non-negative integer",
          elements: [
            ...specialPopulationRecord("gi_male", "Male", "totalGenderIdentity", `Patients who identify themselves as a man/male.`),
            ...specialPopulationRecord("gi_female", "Female", "totalGenderIdentity", `Patients who identify themselves as a woman/female.`),
            ...specialPopulationRecord("gi_transgenderMale", "Transgender Man/Transgender Male/Transmasculine", "totalGenderIdentity", `Transgender patients who describe their gender identity as man/male or a person who was assigned female sex at birth and identifies with masculinity.`),
            ...specialPopulationRecord("gi_transgenderFemale", "Transgender Woman/Transgender Female/Transfeminine", "totalGenderIdentity", `Transgender patients who describe their gender identity as woman/female or a person who was assigned male sex at birth and identifies with femininity.`),
            ...specialPopulationRecord("gi_other", "Other", "totalGenderIdentity", `Patients who do not think that one of the four categories above adequately describes them. Include patients who identify themselves as genderqueer or non-binary.`),
            ...specialPopulationRecord("gi_undisclosed", "Choose not to disclose", "totalGenderIdentity", `Patients who chose not to disclose their gender.`),
            ...specialPopulationRecord("gi_unknown", "Unknown", "totalGenderIdentity", `Patients for whom the health center does not know the gender identity.`),
            {
              type: "html",
              html: "<hr></hr>"
            },
            {
              type: "expression",
              name: `totalGenderIdentity`,
              title: "Total:",
              expression: `{gi_male} + {gi_female} + {gi_transgenderMale} + {gi_transgenderFemale} + {gi_other} + {gi_undisclosed} + {gi_unknown}`
            },
            {
              type: "expression",
              name: "total_genderIdentityPercentage",
              startWithNewLine: false,
              title: " ",
              titleLocation: "left",
              expression: `(roundTo(({totalGenderIdentity}/{totalGenderIdentity}) * 100, 2)) + " % of Total Population "`
            }
          ]
        }
      ],
    },
    {
      name: "PercentPatientsDiscount",
      navigationTitle: "Patient",
      navigationDescription: "Percent of Total Patients Receiving Sliding Fee Discount",
      title: "Sliding Fee Data",
      description: "Enter non-negative integer",
      elements: [
        {
          title: "Please enter number of patients by payor type:",
          type: "panel",
          name: "percentOfTotalPatientsDiscount",
          showQuestionNumbers: "off",
          questionTitleLocation: "left",
          elements: [
            {
              type: "text",
              name: "NoPatients",
              title: "# Patients that received a slide of costs using the Sliding Fee Discount Scale",
              inputType: "number",
              defaulValue: 0,
              min: 0,
              validators: [
                {
                  type: "numeric",
                  text: "Value cannot be less than 0",
                  minValue: 0,
                },
                {
                  type: "regex",
                  text: "Value must be a whole number",
                  regex: "^\\d+$"
                }],
            },
            {
              type: "text",
              name: "Discount Total",
              title: "Discount Total",
              inputType: "number",
              defaulValue: 0,
              min: 0,
              validators: [
                {
                  type: "numeric",
                  text: "Value cannot be less than 0",
                  minValue: 0,
                }
              ],
            },
          ]
        }
      ],
    }
  ]
};