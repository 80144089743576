import { ClinicSurveyComponent } from "components/Survey/ClinicSurveyComponent";
import React, { useEffect, useState } from "react";
import { clinicalMeasuresJSON } from "../../utilities/surveys/clinicalMeasuresJSON";
import { apiGetCurrentSurveyTemplate } from "utilities";

export const ClinicalMeasures = () => {
  const surveyName = 'ClinicalMeasures';
  const [surveyJSON, setSurveyJSON] = useState();
  const [surveyVersion, setSurveyVersion] = useState(0);

  useEffect(() => {
    apiGetCurrentSurveyTemplate(surveyName,clinicalMeasuresJSON).then((response)=>
    {
      if(response){
        setSurveyJSON(response.template);
        setSurveyVersion(response.version);
      }
    })
  }, []);

  return (
    <>
    {surveyJSON &&
    <ClinicSurveyComponent
      json={surveyJSON}
      SurveyName={surveyName}
      version={surveyVersion}
    />
    }
    </>
  );
};
