import React, { useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';
import { AuthService } from "services";
import { toast } from "react-toastify";
import '../Clinic/Clinic.scss';
import { useUserContext } from 'contexts';
import { ApplicationPermissions } from 'utilities';

export const CreateUser = () => {

    const history = useHistory();

    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        supervisor: "",
        accountId: "",
        clinicId: "",
        roles: [],
        clinics: [],
    });

    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [accountId, setAccountId] = useState("");
    const [isNavigator, setIsNavigator] = useState(false);
    const userContext = useUserContext();
    const [isCoverKansasUser] = useState(userContext?.hasPermission(ApplicationPermissions.CoverKansas.CoverKanasUser));

    const onSubmit = (e) => {
        e.preventDefault();
        AuthService.register(userName, userPassword, userFirstName, userLastName, accountId).then(e => {
            toast.success(`${userName} was registered successfully!`);
            history.goBack();
        });
    };

    const updateUserDetailsModel = (propertyName, newValue) => {
        let newUser = {...user};
        newUser[propertyName] = newValue;

        setUser(newUser);
    }

    const onRoleCheckChange = (index) => {
        let roles = user.roles;
        let checkedState = user.roles[index].isSelected;

        //verify we have a value at this index and that the value is a boolean,
        //toggle the value of the boolean and update the userDetails model
        if(checkedState != null && typeof checkedState === typeof true){
            roles[index].isSelected = !checkedState;

            updateUserDetailsModel("roles", roles);
        }
    }

    const handleIsNavigatorChange = (event) =>  {
        if (!event.currentTarget.checked) {
            setAccountId("");
        }

        setIsNavigator(event.currentTarget.checked);
    }

    return (
        <Row>
            <Col md={10}>
            <p className="primary title">Create User</p>
                <Form
                    name="createUserForm"
                    onSubmit={onSubmit}
                    className="mb-5"
                >
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="userFirstNameInput" className="label ms-0">
                                        First Name
                                    </Label>
                                    <Input
                                        id="userFirstNameInput"
                                        name="userFirstNameInput"
                                        placeholder="Your first name"
                                        type="text"
                                        onChange={(e) => setUserFirstName(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="userLastNameInput" className="label ms-0">
                                        Last Name
                                    </Label>
                                    <Input
                                        id="userLastNameInput"
                                        name="userLastNameInput"
                                        placeholder="Your last name, a.k.a. family name"
                                        type="text"
                                        onChange={(e) => setUserLastName(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="userEmailInput" className="label ms-0">
                                        Email
                                    </Label>
                                    <Input
                                        id="userEmailInput"
                                        name="email"
                                        placeholder="Your email address will also be used as your username"
                                        type="email"
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {isCoverKansasUser && (<Row className="mt-4">
                            <Col>
                            <Alert color="primary">
                                <div>
                                    <p><strong>Cover Kansas Navigator Users</strong></p>
                                    <small>Check option below if user will need access to Cover Kansas as a Navigator.</small>
                                </div>
                                <div className="d-flex align-items-center">
                                    <Input
                                        id="isNavigator"
                                        className="m-1"
                                        type="checkbox"
                                        onChange={(e) => handleIsNavigatorChange(e)}
                                    />
                                    <Label for="isNavigator" className="label m-1">
                                        Create Navigator Account
                                    </Label>
                                </div>
                                {isNavigator &&
                                    <FormGroup className="row">
                                        <Col md={6}>                                    
                                            <Input
                                                id="accountId"
                                                name="accountId"
                                                className="m-1"
                                                type="text"
                                                disabled
                                                value={accountId}
                                            />
                                        </Col>
                                        <Col md={6}>
                                        {accountId !== "" && 
                                        <Button 
                                            outline
                                            id="clearAccountId"
                                            name="clearAccountId"
                                            className="mx-2" 
                                            color="primary" 
                                            onClick={() => setAccountId("")}
                                        >
                                            Clear
                                        </Button>
                                        }
                                        <Button 
                                            id="generateAccountId"
                                            name="generateAccountId"
                                            className="mx-2" 
                                            color="primary" 
                                            onClick={async () => setAccountId(await AuthService.generateNewAccountId())}
                                        >
                                            Generate Account ID
                                        </Button>
                                        </Col>
                                    </FormGroup>
                                }
                            </Alert>
                            </Col>
                        </Row>)}
                        <Row>
                            <Col>
                            <FormGroup className={"mt-5 d-flex justify-content-end"}>
                                    <Button className={"mx-2"} color="secondary" 
                                            onClick={() => history.goBack()}>
                                    Cancel
                                    </Button>
                                <Button 
                                    id="createUserSubmitButton"
                                    name="createUserSubmitButton"
                                    className={"mx-2"} 
                                    color="primary" 
                                    type="submit">
                                    Create User
                                </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                </Form>
            </Col>
        </Row>
    );
}